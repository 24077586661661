import { api } from "@/config";

export const orders = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/orders${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, params) {
      return api.get(orders.url(), {
        params: params ,
      });
    },
    show({ context }, { order_id }) {
      return api.get(orders.url(order_id));
    },
    add({ context }, { payload }) {
      return api.post(orders.url(), payload);
    },
    delete({ context }, { orderId }) {
      return api.delete(orders.url(orderId));
    },
  },
};
