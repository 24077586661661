import { api } from "@/config";

export const accountType = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/account-type${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }) {
      return api.get(accountType.url());
    },
    add({ context }, { payload }) {
      return api.post(accountType.url(), payload);
    },
    update({ context }, { id, payload }) {
      return api.patch(accountType.url(id), payload);
    },
    delete({ context }, { id }) {
      return api.delete(accountType.url(id));
    },
  },
};
