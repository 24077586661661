import { api } from "@/config";

export const adminPermission = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/admin-permission${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { permission_id } = { permission_id: "" }) {
      return api.get(adminPermission.url(permission_id));
    },
    add({ context }, { payload }) {
      return api.post(adminPermission.url(), payload);
    },
    update({ context }, { id, payload }) {
      return api.patch(adminPermission.url(id), payload);
    },
    delete({ context }, { id }) {
      return api.delete(adminPermission.url(id));
    },
  },
};
