import { api } from "@/config";

export const adminRole = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/admin-role${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { role_id } = { role_id: "" }) {
      return api.get(adminRole.url(role_id));
    },
    add({ context }, { payload }) {
      return api.post(adminRole.url(), payload);
    },
    update({ context }, { id, payload }) {
      return api.patch(adminRole.url(id), payload);
    },
    delete({ context }, { id }) {
      return api.delete(adminRole.url(id));
    },
  },
};
