<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table
          :filters="['name', 'account_number', 'mobile_number', 'email']"
          :data="users"
          :columns="columns"
          @pageChange="fetchBarredUsers($event)"
          @filter="fetchBarredUsers({ query: $event.query })"
          :paginationConfig="paginationConfig"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.type === 'amount'">
              {{ formatAmount(record[column.dataIndex], true) }}
            </template>
            <template v-if="column.key === 'status'">
              <span v-if="!hasAccess('update-user')">
                {{ record.status ? "Active" : "InActive" }}
              </span>

              <div
                v-else
                class="status-toggle d-flex justify-content-between align-items-center"
              >
                <input
                  :name="record.id"
                  type="checkbox"
                  :id="record.id"
                  :checked="record.status == 'Active' ? true : false"
                  @change="updateUserStatus(record.id, $event.target.checked)"
                  class="check"
                />
                <label :for="record.id" class="checktoggle">checkbox</label>
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" :to="'edit-user/' + record.id">
                <img src="../../../assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a
                class="me-3 confirm-text"
                href="javascript:void(0);"
                @click="showAlert"
              >
                <img src="../../../assets/img/icons/delete.svg" alt="img" />
              </a>
            </template> </template
        ></f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Number",
    dataIndex: "account_number",
    sorter: {
      compare: (a, b) => {
        a = a.account_number;
        b = b.account_number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Mobile Number",
    dataIndex: "mobile_number",
    sorter: (a, b) => a.mobile_number.localeCompare(b.mobile_number),
  },
  {
    title: "email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Type",
    dataIndex: "account_type",
    sorter: {
      compare: (a, b) => {
        a = a.account_type.toLowerCase();
        b = b.account_type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due",
    dataIndex: "due",
    type: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.due.toLowerCase();
        b = b.due.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Credit Limit",
    dataIndex: "credit_limit",
    type: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.credit_limit.toLowerCase();
        b = b.credit_limit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at?.toLowerCase();
        b = b.created_at?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions, mapGetters } from "vuex";
import { formatAmount } from "@/helpers";

export default {
  data() {
    return {
      filter: false,
      title: "Barred Users",
      title1: "Users Barred from Placing Order",
      items: [],
      account_type: null,
      users: [],
      columns,
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
      formatAmount,
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    ...mapActions({
      _fetchBarredUsers: "users/barred",
      _updateUser: "users/update",
    }),
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
    fetchBarredUsers({ page = null, per_page = null, query = null } = {}) {
      console.log(this.paginationConfig);
    
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };
      this.searchQuery = query;
      this._fetchBarredUsers(params).then((apiResponse) => {
        const response = apiResponse.data;
        this.users = response.data.map((user) => {
          return {
            ...user,
            account_type: user.account_type.name,
            created_at: user.date,
            status: user.is_active == 1 ? "Active" : "InActive",
          };
        });

        this.paginationConfig = response.pagination;
      });
    },
    updateUserStatus(userId, status) {
      this._updateUser({ user_id: userId, payload: { status: status } }).then(
        (res) => {
          this.fetchBarredUsers();
        }
      );
    },
  },
  watch: {
    
  },
  mounted() {
    this.fetchBarredUsers();
  },
  name: "userlists",
};
</script>
