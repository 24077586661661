<template>
  <!-- add Modal -->
  <div class="modal fade" id="addpayment" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Bank Detail</h5>
          <b-button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Bank Name</label>
                <input class="form-control" v-model="newBankDetail.bank_name" name="bank_name" required type="text" />
                <span class="text-danger" v-if="errors.bank_name">{{
                  errors.bank_name
                }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Account Number</label>
                <input class="form-control" v-model="newBankDetail.account_number" name="account_number" required
                  type="number" />
                <span class="text-danger" v-if="errors.account_number">{{
                  errors.account_number
                }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Account Name</label>
                <input class="form-control" v-model="newBankDetail.account_name" name="account_name" required
                  type="text" />
                <span class="text-danger" v-if="errors.account_name">{{
                  errors.account_name
                }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <label>Status</label>
                <vue-select v-model="newBankDetail.is_active" name="is_active" required :options="Status"
                  placeholder="Choose Status" />
                <span class="text-danger" v-if="errors.is_active">{{
                  errors.is_active
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit" id="submit" @click="addBankDetail" disabled>
            Confirm
          </button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- edit Modal -->
  <div class="modal fade" id="editpayment" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit payment type</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Bank Name</label>
                <input class="form-control" v-model="selectedBankDetail.bank_name" required type="text" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Account Number</label>
                <input id="account_number" @input="reportValidity('account_number', 'update')" class="form-control"
                  required v-model="selectedBankDetail.account_number" type="number" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Account Name</label>
                <input class="form-control" v-model="selectedBankDetail.account_name" required type="text" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <label>Status</label>
                <vue-select v-model="selectedBankDetail.is_active" required :options="Status"
                  placeholder="Choose Status" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit" id="update" @click="updateBankDetail">
            Update
          </button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    selected: Object,
  },
  data() {
    return {
      Status: [
        { id: 1, text: "Active" },
        { id: 0, text: "InActive" },
      ],
      selectedBankDetail: {},
      newBankDetail: {
        bank_name: null,
        account_name: null,
        account_number: null,
        is_active: null,
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions({
      _checkValidity: "checkInputValidity",
      _addBankDetail: "bankDetailsadd",
      _updateDetail: "bankDetailsupdate",
    }),
    reportValidity(element_id, callbackElementId) {
      let input = document.getElementById(element_id);
      let callbackElement = document.getElementById(callbackElementId);
      if (!input.checkValidity()) {
        input.reportValidity();
        callbackElement.disabled = true;
      } else {
        callbackElement.disabled = false;
      }
    },
    validate() {
      let detail = this.selectedBankDetail;
      if (!detail.bank_name.match(".{1}")) {
        Swal.fire({
          title: "Bank Name Cannot Be Empty",
        });
        return false;
      } else if (!detail.account_name.match(".{1}")) {
        Swal.fire({
          title: "Account Name Cannot Be Empty",
        });
        return false;
      } else {
        return true;
      }
    },
    updateBankDetail() {
      if (this.validate()) {
        this._updateDetail({
          id: this.selectedBankDetail.id,
          payload: this.selectedBankDetail,
        })
          .then((response) => {
            $(".modal").modal("hide");
            this.selectedBankDetail = null;
            this.$emit("onbankDetailAdded");
          })
          .catch((error) => { });
      }
    },
    checkValidity() {
      this._checkValidity({ callbackElementId: "submit" }).then((res) => {
        this.errors = res;
      });
    },
    addBankDetail() {
      this._addBankDetail({ payload: this.newBankDetail }).then((response) => {
        $(".modal").modal("hide");
        this.newBankDetail = {
          bank_name: null,
          account_name: null,
          account_number: null,
          is_active: null,
        };
        this.$emit("onbankDetailAdded");
      });
    },
  },
  watch: {
    newBankDetail: {
      handler() {
        this._checkValidity({ callbackElementId: "submit" }).then((res) => {
          this.errors = res;
        });
      },
      deep: true,
    },
    selected: {
      handler(_selected) {
        this.selectedBankDetail = _selected;
      },
    },
  },
};
</script>
