<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar" :class="$route.meta.sidebarClass">
    <div class="slimScrollDiv">
      <div class="sidebar-inner slimscroll">
        <perfect-scrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHanle"
        >
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Main</h6>
                <ul>
                  <li
                    v-bind:class="{
                      active: currentPath == 'index' || currentPath == 'index',
                    }"
                  >
                    <router-link to="/"
                      ><vue-feather type="grid"></vue-feather
                      ><span> Dashboard</span>
                    </router-link>
                  </li>
                  <li
                    v-bind:class="{
                      active: currentPath == 'chat' || currentPath == 'chat',
                    }"
                  >
                    <router-link to="/chat"
                      ><vue-feather type="message-square"></vue-feather
                      ><span> Chat</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="submenu-open"
                v-if="hasAccess('.*products.*') || hasAccess('.*transit')"
              >
                <h6 class="submenu-hdr">Products</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'products' ||
                        currentPath == 'editproduct'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/products"
                      ><vue-feather type="box"></vue-feather
                      ><span>Products</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'addproduct' ? 'active' : 'notactive'
                      "
                      to="/addproduct"
                      ><vue-feather type="plus-square"></vue-feather
                      ><span>Create Product</span></router-link
                    >
                  </li>
                  <li v-if="isSuperAdmin() || isVendor()">
                    <router-link
                      :class="
                        currentPath == 'importproduct' ? 'active' : 'notactive'
                      "
                      to="/products/import"
                      ><vue-feather type="download"></vue-feather
                      ><span>Import Products</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="currentPath == 'pending-products'"
                      to="/pending-products"
                      ><vue-feather type="loader"></vue-feather
                      ><span>Pending Products</span></router-link
                    >
                  </li>
                  <li v-if="isSuperAdmin()">
                    <router-link
                      :class="
                        currentPath == 'categorylist' ? 'active' : 'notactive'
                      "
                      to="/categorylist"
                      ><vue-feather type="codepen"></vue-feather
                      ><span>Category</span></router-link
                    >
                  </li>
                  <li v-if="isSuperAdmin()">
                    <router-link
                      :class="
                        currentPath == 'brandlist' ? 'active' : 'notactive'
                      "
                      to="/brandlist"
                      ><vue-feather type="tag"></vue-feather
                      ><span>Brands</span></router-link
                    >
                  </li>
                  <li v-if="isSuperAdmin()">
                    <router-link
                      :class="
                        currentPath == 'subcategorylist'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/subcategorylist"
                      ><vue-feather type="speaker"></vue-feather
                      ><span>Sub Category</span></router-link
                    >
                  </li>

                  <!-- INTRANSIT START -->
                  <li class="submenu">
                    <a
                      v-bind:class="transitMenu ? 'active' : 'notactive'"
                      href="#sidebarTransit"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarTransit"
                      v-on:click="transitMenu = !transitMenu"
                      :class="{ subdrop: transitMenu }"
                      v-if="
                        hasAccess('add-transit') || hasAccess('view-transits')
                      "
                      ><vue-feather type="truck"></vue-feather
                      ><span>In Transit</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarTransit">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'add-intransit'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/add-intransit"
                          >Add Intransit</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'transits' ? 'active' : 'notactive'
                          "
                          to="/transits"
                          >In Transits
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <!-- INTRANSIT END -->

                  <!-- <li>
                    <router-link :class="currentPath == 'barcode' ? 'active' : 'notactive'" to="/barcode"><vue-feather
                        type="align-justify"></vue-feather><span>Print Barcode</span></router-link>
                  </li>
                  <li>
                    <router-link :class="currentPath == 'importproduct' ? 'active' : 'notactive'
                      " to="/importproduct"><vue-feather type="minimize-2"></vue-feather><span>Import
                        Products</span></router-link>
                  </li> -->
                </ul>
              </li>

              <li
                class="submenu-open"
                v-if="
                  hasAccess('orders') ||
                  hasAccess('view-users-account') ||
                  hasAccess('sales')
                "
              >
                <h6 class="submenu-hdr">Sales</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'orderlist' ? 'active' : 'notactive'
                      "
                      to="/orderlist"
                      ><vue-feather type="file-text"></vue-feather
                      ><span>Orders</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'saleslist' ||
                        currentPath == 'sales-details'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/saleslist"
                      ><vue-feather type="shopping-cart"></vue-feather
                      ><span>Sales</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'pending-saleslist'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/pending-saleslist"
                      ><vue-feather type="loader"></vue-feather
                      ><span>Pending Sales</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users-account' ? 'active' : 'notactive'
                      "
                      to="/users-account"
                      ><vue-feather type="user"></vue-feather
                      ><span>Users Account </span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'completed-saleslist'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/completed-saleslist"
                      ><vue-feather type="archive"></vue-feather
                      ><span>Completed Sales</span></router-link
                    >
                  </li>

                  <li>
                    <router-link
                      :class="
                        currentPath == 'quotationlist' ||
                        currentPath == 'editquotation'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/quotationlist"
                      ><vue-feather type="save"></vue-feather
                      ><span>Quotation</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="currentPath == 'pos' ? 'active' : 'notactive'"
                      to="/pos"
                      ><vue-feather type="hard-drive"></vue-feather
                      ><span>POS</span></router-link
                    >
                  </li>
                  <li class="submenu">
                    <a
                      v-bind:class="transferMenu ? 'active' : 'notactive'"
                      href="#sidebarTransfer"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarTransfer"
                      v-on:click="transferMenu = !transferMenu"
                      :class="{ subdrop: transferMenu }"
                      ><vue-feather type="shuffle"></vue-feather
                      ><span>Transfer</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarTransfer">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'transferlist' ||
                            currentPath == 'edittransfer'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/transferlist"
                          >Transfer List</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'importtransfer'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/importtransfer"
                          >Import Transfer
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="submenu" v-if="hasAccess('access-sales-return')">
                    <a
                      v-bind:class="returnMenu ? 'active' : 'notactive'"
                      data-bs-target="#sidebarsalesreturnlist"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarsalesreturnlist"
                      v-on:click="returnMenu = !returnMenu"
                      :class="{ subdrop: returnMenu }"
                      ><vue-feather type="corner-up-left"></vue-feather
                      ><span>Return</span><span class="menu-arrow"></span
                    ></a>
                    <ul
                      class="collapse menu-dropdown"
                      id="sidebarsalesreturnlist"
                    >
                      <li>
                        <router-link
                          :class="
                            currentPath == 'salesreturnlist' ||
                            currentPath == 'editsalesreturn'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/salesreturnlist"
                          >Sales Return</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'purchasereturnlist'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/purchasereturnlist"
                          >Purchase Return</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Flow</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'confirm-sales' ? 'active' : 'notactive'
                      "
                      to="/confirm-sales"
                      ><vue-feather type="shopping-bag"></vue-feather
                      ><span>Confirm Sales</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'warehouse-sales'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/warehouse-sales"
                      ><vue-feather type="shopping-cart"></vue-feather
                      ><span>Warehouse</span></router-link
                    >
                  </li>

                  <li>
                    <router-link
                      :class="
                        currentPath == 'gatesales' ? 'active' : 'notactive'
                      "
                      to="/gate-sales"
                      ><vue-feather type="external-link"></vue-feather
                      ><span>Gate</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'dispatchedsales'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/dispatched-sales"
                      ><vue-feather type="truck"></vue-feather
                      ><span>Dispatched</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'completedsales' ? 'active' : 'notactive'
                      "
                      to="/completed-sales"
                      ><vue-feather type="archive"></vue-feather
                      ><span>Completed</span></router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- CRATE -->
              <li class="submenu-open" v-if="hasAccess('access-crate-report')">
                <h6 class="submenu-hdr">Crate / Empties Management</h6>

                <ul>
                  <li>
                    <router-link
                      :class="
                        currentUrl == '/crate-report' ? 'active' : 'notactive'
                      "
                      to="/crate-report"
                      ><vue-feather type="file-text"></vue-feather
                      ><span>Crate Report</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentUrl == '/crate-report/previous'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/crate-report/previous"
                      ><vue-feather type="archive"></vue-feather
                      ><span>Previous Report</span></router-link
                    >
                  </li>
                </ul>
              </li>

              <!-- CRATE -->
              <li class="submenu-open d-none" v-if="isSuperAdmin()">
                <!-- hasAccess('crate') -->
                <h6 class="submenu-hdr">Create / Vendor Management</h6>

                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'crate-report' ? 'active' : 'notactive'
                      "
                      to="/vendor-list"
                      ><vue-feather type="file-text"></vue-feather
                      ><span>Create Vendors</span></router-link
                    >
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="hasAccess('purchases')">
                <h6 class="submenu-hdr">Purchases</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'purchaselist' ||
                        currentPath == 'editpurchase'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/purchaselist"
                      ><vue-feather type="shopping-bag"></vue-feather
                      ><span>Purchases</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'importpurchase' ? 'active' : 'notactive'
                      "
                      to="/importpurchase"
                      ><vue-feather type="minimize-2"></vue-feather
                      ><span>Import Purchases</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'purchaseorderreport'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/purchaseorderreport"
                      ><vue-feather type="file-minus"></vue-feather
                      ><span>Purchase Order</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'purchasereturnlist'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/purchasereturnlist"
                      ><vue-feather type="refresh-cw"></vue-feather
                      ><span>Purchase Return</span></router-link
                    >
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="hasAccess('finance')">
                <h6 class="submenu-hdr">Finance & Accounts</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'receipts' ? 'active' : 'notactive'
                      "
                      to="/receipts"
                      ><vue-feather type="refresh-cw"></vue-feather
                      ><span>Payment Receipts</span></router-link
                    >
                  </li>
                  <li class="submenu">
                    <a
                      v-bind:class="expenseMenu ? 'active' : 'notactive'"
                      href="#sidebarExpense"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarExpense"
                      v-on:click="expenseMenu = !expenseMenu"
                      :class="{ subdrop: expenseMenu }"
                      ><vue-feather type="file-text"></vue-feather
                      ><span>Expense</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarExpense">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'expenselist'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/expenselist"
                          >Expenses</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'expensecategory'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/expensecategory"
                          >Expense Category</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasAccess('user')">
                <h6 class="submenu-hdr">Customers</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users/wholeseller'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/users/wholeseller"
                      ><vue-feather type="user"></vue-feather
                      ><span>WholeSellers</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users/tems' ? 'active' : 'notactive'
                      "
                      to="/users/guinness"
                      ><vue-feather type="users"></vue-feather
                      ><span>Guiness Direct</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users/walkin' ? 'active' : 'notactive'
                      "
                      to="/users/vsr"
                      ><vue-feather type="user-check"></vue-feather
                      ><span>SE / VSR</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users/ssf' ? 'active' : 'notactive'
                      "
                      to="/users/online"
                      ><vue-feather type="home"></vue-feather
                      ><span>Online Users</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li
                class="submenu-open"
                v-if="
                  hasAccesses(
                    'access-pending-credit-limit-increase',
                    'access-processed-credit-limit-increase'
                  )
                "
              >
                <h6 class="submenu-hdr">Credit Limit Increase</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'limit-increase/pending'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/limit-increase/pending"
                      ><vue-feather type="loader"></vue-feather
                      ><span>Pending</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'limit-increase/processed'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/limit-increase/processed"
                      ><vue-feather type="user-check"></vue-feather
                      ><span>Processed</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu-open" v-if="hasAccess('reports')">
                <h6 class="submenu-hdr">Reports</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'purchaseorderreport'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/purchasereport"
                      ><vue-feather type="pie-chart"></vue-feather
                      ><span>Purchase report</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'paymentreport' ? 'active' : 'notactive'
                      "
                      to="/paymentreport"
                      ><vue-feather type="bar-chart"></vue-feather
                      ><span>Payment report</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'inventoryreport'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/inventoryreport"
                      ><vue-feather type="credit-card"></vue-feather
                      ><span>Inventory Report</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'invoicereport' ? 'active' : 'notactive'
                      "
                      to="/invoicereport"
                      ><vue-feather type="file"></vue-feather
                      ><span>Invoice Report</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'supplierreport' ? 'active' : 'notactive'
                      "
                      to="/supplierreport"
                      ><vue-feather type="database"></vue-feather
                      ><span>Supplier Report</span></router-link
                    >
                  </li>
                </ul>
              </li>
              <!-- ADMIN MGT FOR VENDORS -->
              <li class="submenu-open" v-if="isSuperAdmin() || isVendor()">
                <h6 class="submenu-hdr">Admin Management</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'admin-role' ? 'active' : 'notactive'
                      "
                      to="/admin-role"
                    >
                      <vue-feather type="database"></vue-feather>
                      <span>Role Management</span>
                    </router-link>
                  </li>
                  <li v-if="isVendor()">
                    <router-link
                      :class="currentPath == 'admin' ? 'active' : 'notactive'"
                      to="/admin"
                    >
                      <vue-feather type="database"></vue-feather>
                      <span>Admin List</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="isSuperAdmin()">
                <h6 class="submenu-hdr">Vendor Management</h6>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="vendorMenu ? 'active' : 'notactive'"
                      data-bs-target="#sidebarVendorsManage"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarVendorsManage"
                      v-on:click="vendorMenu = !vendorMenu"
                      :class="{ subdrop: vendorMenu }"
                      ><vue-feather type="users"></vue-feather
                      ><span>Manage Vendors</span
                      ><span class="menu-arrow"></span
                    ></a>
                    <ul
                      class="collapse menu-dropdown"
                      id="sidebarVendorsManage"
                    >
                      <!-- <li>
                        <router-link
                          :class="
                            currentPath == 'newvendor' || currentPath == 'newvendoredit'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/new-vendor"
                          >New Vendor
                        </router-link>
                      </li> -->
                      <li>
                        <router-link
                          :class="
                            currentPath == 'vendorslist'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/vendors-list"
                          >Vendors List</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'pendingvendors'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/pending-vendors"
                          >Pending Vendors</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'pendingvendors'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/rejected-vendors"
                          >Rejected Vendors</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="hasAccess('user')">
                <h6 class="submenu-hdr">User Management</h6>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="userMenu ? 'active' : 'notactive'"
                      data-bs-target="#sidebarUsers"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarUsers"
                      v-on:click="userMenu = !userMenu"
                      :class="{ subdrop: userMenu }"
                      ><vue-feather type="users"></vue-feather
                      ><span>Manage Users</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarUsers">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'newuser' ||
                            currentPath == 'newuseredit'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/newuser"
                          >New User
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'userlists' ? 'active' : 'notactive'
                          "
                          to="/userlists"
                          >Users List</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'barredusers'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/barredusers"
                          >Barred Users</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'users-report' ? 'active' : 'notactive'
                      "
                      to="/users-report"
                    >
                      <vue-feather type="activity"></vue-feather>
                      <span>Users Report</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Promo MGT -->
              <li class="submenu-open" v-if="isSuperAdmin()">
                <h6 class="submenu-hdr">Promo Material</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'userlists' ? 'active' : 'notactive'
                      "
                      to="/coupons"
                    >
                      <vue-feather type="gift"></vue-feather>
                      <span>Coupons</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Stock MGT -->
              <li class="submenu-open" v-if="isSuperAdmin()">
                <h6 class="submenu-hdr">Stock</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'userlists' ? 'active' : 'manage-stock'
                      "
                      to="/manage-stock"
                    >
                      <vue-feather type="package"></vue-feather>
                      <span>Manage Stock</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'userlists' ? 'active' : 'stock-adjust'
                      "
                      to="/stock-adjust"
                    >
                      <vue-feather type="clipboard"></vue-feather>
                      <span>Stock Adjustment</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'userlists' ? 'active' : 'stock-transfer'
                      "
                      to="/stock-transfer"
                    >
                      <vue-feather type="truck"></vue-feather>
                      <span>Stock Transfer</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="isSuperAdmin()">
                <h6 class="submenu-hdr">Adverts</h6>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="splashVideo ? 'active' : 'notactive'"
                      :class="{ subdrop: splashVideo }"
                      href="#sidebarAdverts"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarAdverts"
                      ><vue-feather type="smartphone"></vue-feather
                      ><span>Mobile</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarAdverts">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'adverts/mobile/video'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/adverts/mobile/video"
                          >Splash Screen Video</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li class="submenu">
                    <a
                      v-bind:class="pushNotification ? 'active' : 'notactive'"
                      :class="{ subdrop: pushNotification }"
                      href="#pushNotification"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="pushNotification"
                    >
                      <vue-feather type="bell"></vue-feather>
                      <span>Push Notification</span>
                      <span class="menu-arrow"></span>
                    </a>
                    <ul class="collapse menu-dropdown" id="pushNotification">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'notification/create'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/notification/create"
                          >Add Notification</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'notification/campaign'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/notification/campaign"
                          >Campaign</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li class="submenu-open" v-if="isSuperAdmin() || isVendor()">
                <h6 class="submenu-hdr">Archive</h6>
                <ul>
                  <li>
                    <router-link
                      :class="
                        currentPath == 'archive/orders' ? 'active' : 'notactive'
                      "
                      to="/archive/orders"
                    >
                      <vue-feather type="archive"></vue-feather>
                      <span>Orders</span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="submenu-open">
                <h6 class="submenu-hdr">Settings</h6>
                <ul>
                  <li class="submenu" v-if="isSuperAdmin()">
                    <a
                      v-bind:class="settingMenu ? 'active' : 'notactive'"
                      href="#sidebarSettings"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarSettings"
                      v-on:click="settingMenu = !settingMenu"
                      :class="{ subdrop: settingMenu }"
                      ><vue-feather type="settings"></vue-feather
                      ><span>Settings</span><span class="menu-arrow"></span
                    ></a>
                    <ul class="collapse menu-dropdown" id="sidebarSettings">
                      <li>
                        <router-link
                          :class="
                            currentPath == 'generalsettings'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/generalsettings"
                          >General Settings</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'vendorsetting'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/vendor-settings"
                          >Vendor Settings</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'usersettings'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/user-settings"
                          >User Settings</router-link
                        >
                      </li>

                      <li>
                        <router-link
                          :class="
                            currentPath == 'emailsettings'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/emailsettings"
                          >Email Settings</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'paymentsettings'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/paymentsettings"
                          >Payment Settings</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'currencysettings'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/currencysettings"
                          >Currency Settings</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'grouppermissions' ||
                            currentPath == 'createpermission' ||
                            currentPath == 'editpermission'
                              ? 'active'
                              : 'notactive'
                          "
                          to="/grouppermissions"
                          >Group Permissions</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :class="
                            currentPath == 'taxrates' ? 'active' : 'notactive'
                          "
                          to="/taxrates"
                          >Tax Rates</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a v-on:click="logOut()"
                      ><vue-feather type="log-out"></vue-feather
                      ><span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import store from "@/store";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { router } from "@/router";
export default {
  data() {
    return {
      productMenu: false,
      salesMenu: false,
      purchaseMenu: false,
      expenseMenu: false,
      quotationMenu: false,
      transferMenu: false,
      returnMenu: false,
      transitMenu: false,
      peopleMenu: false,
      placesMenu: false,
      errorpageMenu: false,
      reportMenu: false,
      userMenu: false,
      vendorMenu: false,
      settingMenu: false,
      splashVideo: false,
      pushNotification: false,
      elementMenu: false,
      chartMenu: false,
      iconMenu: false,
      formMenu: false,
      tableMenu: false,
      chatMenu: false,
      crateTypeMenu: false,
      authenticationPageMenu: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
    //  isactive : true
  },
  methods: {
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
      isVendor: "isVendor",
    }),
    ...mapMutations({
      logOut: "logOut",
    }),
    hasAccesses(...accesses) {
      return store.getters.hasAccesses(...accesses);
    },
    canAccess(route) {
      return store.getters.canAccess(route);
    },
    scrollHanle(evt) {},
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    PerfectScrollbar,
  },

  computed: {
    ...mapGetters({ hasAccess: "hasAccess" }),

    currentPath() {
      return this.$route.name;
    },
    productMenu() {
      return (
        this.$route.name == "products" ||
        this.$route.name == "product-details" ||
        this.$route.name == "addproduct" ||
        this.$route.name == "editproduct" ||
        this.$route.name == "editcategory" ||
        this.$route.name == "categorylist" ||
        this.$route.name == "addcategory" ||
        this.$route.name == "editsubcategory" ||
        this.$route.name == "subcategorylist" ||
        this.$route.name == "subaddcategory" ||
        this.$route.name == "brandlist" ||
        this.$route.name == "editbrand" ||
        this.$route.name == "addbrand" ||
        this.$route.name == "importproduct" ||
        this.$route.name == "barcode"
      );
    },
    crateTypeMenu() {
      return this.$route.name == "cratetypes";
    },
    salesMenu() {
      return (
        this.$route.name == "saleslist" ||
        this.$route.name == "sales-details" ||
        this.$route.name == "pos" ||
        this.$route.name == "add-sales" ||
        this.$route.name == "editsalesreturns" ||
        this.$route.name == "edit-sales" ||
        this.$route.name == "salesreturnlists" ||
        this.$route.name == "createsalesreturns"
      );
    },
    purchaseMenu() {
      return (
        this.$route.name == "purchaselist" ||
        this.$route.name == "addpurchase" ||
        this.$route.name == "editpurchase" ||
        this.$route.name == "importpurchase"
      );
    },
    expenseMenu() {
      return (
        this.$route.name == "expenselist" ||
        this.$route.name == "createexpense" ||
        this.$route.name == "editexpense" ||
        this.$route.name == "expensecategory"
      );
    },
    quotationMenu() {
      return (
        this.$route.name == "quotationlist" ||
        this.$route.name == "addquotation" ||
        this.$route.name == "editquotation"
      );
    },
    transferMenu() {
      return (
        this.$route.name == "transferlist" ||
        this.$route.name == "addtransfer" ||
        this.$route.name == "edittransfer" ||
        this.$route.name == "importtransfer"
      );
    },
    returnMenu() {
      return (
        this.$route.name == "salesreturnlist" ||
        this.$route.name == "editsalesreturn" ||
        this.$route.name == "createsalesreturn" ||
        this.$route.name == "createpurchasereturn" ||
        this.$route.name == "editpurchasereturn"
      );
    },
    transitMenu() {
      return (
        this.$route.name == "add-intransit" ||
        this.$route.name == "view-transit" ||
        this.$route.name == "transits"
      );
    },
    peopleMenu() {
      return (
        this.$route.name == "customerlist" ||
        this.$route.name == "addcustomer" ||
        this.$route.name == "editcustomer" ||
        this.$route.name == "supplierlist" ||
        this.$route.name == "editsupplier" ||
        this.$route.name == "addsupplier" ||
        this.$route.name == "userlist" ||
        this.$route.name == "edituser" ||
        this.$route.name == "adduser" ||
        this.$route.name == "editstore" ||
        this.$route.name == "storelist" ||
        this.$route.name == "addstore"
      );
    },
    placesMenu() {
      return (
        this.$route.name == "newcountry" ||
        this.$route.name == "editcountry" ||
        this.$route.name == "countrieslist" ||
        this.$route.name == "editstate" ||
        this.$route.name == "newstate" ||
        this.$route.name == "statelist"
      );
    },
    errorpageMenu() {
      return this.$route.name == "error-404" || this.$route.name == "error-500";
    },
    reportMenu() {
      return (
        this.$route.name == "purchaseorderreport" ||
        this.$route.name == "inventoryreport" ||
        this.$route.name == "salesreport" ||
        this.$route.name == "invoicereport" ||
        this.$route.name == "purchasereport" ||
        this.$route.name == "supplierreport" ||
        this.$route.name == "customerreport"
      );
    },
    userMenu() {
      return (
        this.$route.name == "newuser" ||
        this.$route.name == "userlists" ||
        this.$route.name == "barredusers" ||
        this.$route.name == "newuseredit"
      );
    },
    vendorMenu() {
      return (
        this.$route.name == "vendors-list" ||
        this.$route.name == "rejected-vendors" ||
        this.$route.name == "pending-vendors"
      );
    },
    settingMenu() {
      return (
        this.$route.name == "generalsettings" ||
        this.$route.name == "emailsettings" ||
        this.$route.name == "editpermission" ||
        this.$route.name == "createpermission" ||
        this.$route.name == "paymentsettings" ||
        this.$route.name == "currencysettings" ||
        this.$route.name == "grouppermissions" ||
        this.$route.name == "taxrates"
      );
    },
    elementMenu() {
      return (
        this.$route.name == "sweetalerts" ||
        this.$route.name == "tooltip" ||
        this.$route.name == "popover" ||
        this.$route.name == "ribbon" ||
        this.$route.name == "clipboard" ||
        this.$route.name == "drag-drop" ||
        this.$route.name == "rangeslider" ||
        this.$route.name == "rating" ||
        this.$route.name == "toastr" ||
        this.$route.name == "counter" ||
        this.$route.name == "scrollbar" ||
        this.$route.name == "spinner" ||
        this.$route.name == "notification" ||
        this.$route.name == "lightbox" ||
        this.$route.name == "stickynote" ||
        this.$route.name == "timeline" ||
        this.$route.name == "form-wizard"
      );
    },
    chartMenu() {
      return (
        this.$route.name == "chart-apex" ||
        this.$route.name == "chart-js" ||
        this.$route.name == "chart-morris" ||
        this.$route.name == "chart-flot"
      );
    },
    iconMenu() {
      return (
        this.$route.name == "icon-fontawesome" ||
        this.$route.name == "icon-feather" ||
        this.$route.name == "icon-ionic" ||
        this.$route.name == "icon-material" ||
        this.$route.name == "icon-pe7" ||
        this.$route.name == "icon-simpleline" ||
        this.$route.name == "icon-themify" ||
        this.$route.name == "icon-weather" ||
        this.$route.name == "icon-typicon" ||
        this.$route.name == "icon-flag"
      );
    },
    formMenu() {
      return (
        this.$route.name == "form-basic-inputs" ||
        this.$route.name == "form-input-groups" ||
        this.$route.name == "form-horizontal" ||
        this.$route.name == "form-vertical" ||
        this.$route.name == "form-mask" ||
        this.$route.name == "form-validation" ||
        this.$route.name == "form-select2" ||
        this.$route.name == "form-fileupload"
      );
    },
    tableMenu() {
      return (
        this.$route.name == "tables-basic" || this.$route.name == "data-tables"
      );
    },
    splashVideo() {
      return this.$route.path.match(/adverts\/mobile/);
    },
    pushNotification() {
      return this.$route.path.match(/notification/);
    },
    chatMenu() {
      return (
        this.$route.name == "chat" ||
        this.$route.name == "calendar" ||
        this.$route.name == "email"
      );
    },
    currentUrl() {
      return this.$route.fullPath;
    },
  },
  mounted() {
    // hide routes
    let sidebar = document.querySelector("#sidebar");
    if (sidebar) {
      let tabs = sidebar.querySelectorAll("[href]");
      if (tabs.length > 0) {
        tabs.forEach((tab) => {
          let path = tab.attributes.getNamedItem("href").value;

          /** Skip Paths that are #id */
          if (!path.match("#")) {
            if (!store.getters.canAccess(path)) {
              tab.remove();
            }
            if (path.match("hidden")) {
              tab.remove();
            }
          }
        });
      }
    }
  },
};
</script>
