<template>
  <div>
    <div class="modal fade" id="addsplashvideo" data-bs-focus="false" tabindex="-1" aria-labelledby="addsplashvideo"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add A New Video</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Tag</label>
                  <div class="input-groupicon">
                    <input class="form-control" v-model="new_video.tag" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Target Users</label>
                  <vue-select v-model="new_video.account_type_id" :options="accountTypes" placeholder="Target Users" />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Video</label>
                  <div class="image-upload">
                    <input required multiple name="video" accept="video/*" @input="pickFile($event.target)" type="file" />
                    <div class="image-uploads">
                      <img src="../../../../assets/img/icons/upload.svg" alt="img" />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                  <span class="text-danger" v-if="errors.video">
                    {{ errors.video }}
                  </span>
                </div>
                <div v-if="videoPreview" class="video-upload-preview">
                  <video id="new_video" controls>
                    <source :src="videoPreview" type="" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="submit" type="button" @click="addSplashVideo" class="btn btn-submit">
              Submit
            </button>
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    _video: Object,
  },
  data() {
    return {
      access_code: null,
      errors: {},
      accountTypes: [],
      videoPreview: null,
      new_video: {
        account_type_id: null,
        tag: null,
        video: null,
      },
    };
  },
  methods: {
    ...mapActions({
      _getAccountTypes: "accountType/get",
      _addUser: "users/add",
      _addSplashVideo: "mobileAds/addSplashVideo",
    }),
    ...mapMutations({
      _notify: "notify",
    }),
    addSplashVideo() {
      this._addSplashVideo({ payload: this.new_video }).then((res) => {
        /** stop playing video if it's playing */
        let element = document.getElementById("new_video");
        this.videoPreview = null;
        element.load();

        this.new_video = {};

        $(".modal").modal("hide");
        this.$emit("onVideoAdded");
        this._notify({
          title: "Video Added",
          message: "New Video Successfully Uploaded",
        });
      });
    },
    getAccountTypes() {
      this._getAccountTypes().then((response) => {
        this.accountTypes = response.data.data.map((account_type) => {
          return {
            text: account_type.name,
            id: account_type.id,
          };
        });
      });
    },
    pickFile(event) {
      this.videoPreview = null;
      let file = event.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.videoPreview = reader.result;
      };
      this.new_video.video = file;
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    this.getAccountTypes();
  },
  watch: {
    _video: {
      handler(_new) {
        this.getAccountTypes();
      },
    },
  },
};
</script>
<style>
.video-upload-preview {
  border-radius: 6px;
  overflow: hidden;
  display: block;
  height: 300px;
}

.video-upload-preview video {
  height: 100%;
  width: 100%;
}
</style>
