<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span class="bg-lightgreen badges" v-if="record.status">Active</span>
              <span class="bg-lightred badges" v-if="!record.status">InActive</span></template>
            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" :to="'edit-admin/' + record.id">
                <img src="../../../assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a class="me-3 confirm-text" href="javascript:void(0);" @click="deleteAdmin">
                <img src="../../../assets/img/icons/delete.svg" alt="img" />
              </a>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },
  {
    title: "Admin Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Admin Email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email;
        b = b.email;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Role",
    dataIndex: "role",
    sorter: {
      compare: (a, b) => {
        a = a.role.toLowerCase();
        b = b.role.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions } from "vuex";

export default {
  data() {
    return {
      filters: ["name", "email"],
      title: "Admin List",
      title1: "Manage your Admins",
      items: [
        {
          text: "Add Admin",
          addname: "/newadmin",
        },
      ],
      account_type: null,
      data: [],
      columns,
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
  methods: {
    ...mapActions({
      _getAdmins: "admin/get",
      _deleteAdmin: "admin/delete",
    }),
    deleteAdmin() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "This Admin Will Be Deleted",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteAdmin({ admin_id: this.$route.params.id });
          Swal.fire("Deleted!", "Admin Successfully Deleted");
        }
      });
    },
    getAdmins() {
      this.account_type = this.$route.params.account_type;
      this._getAdmins({ account_type: this.account_type }).then((response) => {
        this.data = response.data.data.map((user) => {
          return {
            id: user.id,
            name: user.name,
            account_number: user.account_number,
            mobile_number: user.mobile_number,
            email: user.email,
            role: user.role.name,
            created_at: user.created_at,
            status: user.status,
          };
        });
      });
    },
  },
  watch: {
    $route: {
      handler(_old, _new) {
        this.getAdmins();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAdmins();
  },
  name: "admin",
};
</script>
