<template>
  <!-- Logo -->
  <div class="header-left active">
    <div class="logo logo-normal">
      <img src="../assets/img/logo_lg.jpg" alt="" />
    </div>
    <div class="logo logo-white">
      <img src="../assets/img/logo_lg.jpg" />
    </div>
    <div class="logo-small">
      <img src="../assets/img/logo_sm.jpg" alt="" />
    </div>
    <a
      :class="$route.meta.hideClass"
      id="toggle_btn"
      href="javascript:void(0);"
    >
      <vue-feather type="chevrons-left" class="feather-16"></vue-feather>
    </a>
  </div>
  <!-- /Logo -->
</template>
