import { units } from ".";

export class Product {
  constructor() {
    this.defaultPrice = {
      account_type_id: null,
      required_qty: 1,
      price: 1,
      isSample: true,
    };
    this.name = null;
    this.unit = null;
    this.crate_price = null;
    this.has_crate = false;
    this.wob_price = null;
    this.bottle_price = null;
    this.category_id = null;
    this.sub_category_id = null;
    this.brand_id = null;
    this.delivery_cost = 0;
    this.description = null;
    this.expiry_date = null;
    this.tax_percent = 0;
    this.qty_per_crate = null;
    this.available_bottles = null;
    this.available_crates = null;
    this.excess_bottles = 0;
    this.min_qty_threshold = 0;
    this.max_qty_threshold = 0;
    this.discount_percent = 0;
    this.unit_crate = null;
    /** Extra Data*/
    this.extras = {
      has_price_variants: false,
      has_user_prices: false,
    };
    this.images = [];
    this.prices = [];
    this.price_variants = [];
    this.user_prices = [this.defaultPrice];
  }

  fillProductDetails(productDetails) {
    this.unit = productDetails.unit;
    this.name = productDetails.name;
    this.crate_type_id = productDetails.crate_type?.id;
    this.price = productDetails.price;
    this.category_id = productDetails.category.id;
    this.sub_category_id = productDetails.sub_category.id;
    this.brand_id = productDetails.brand.id;
    this.delivery_cost = productDetails.delivery_cost;
    this.description = productDetails.description;
    this.expiry_date = productDetails.expiry_date;
    this.tax_percent = productDetails.tax;
    this.available_bottles = productDetails.available_bottles;
    this.min_qty_threshold = productDetails.min_qty_threshold;
    this.max_qty_threshold = productDetails.max_qty_threshold;
    this.discount_percent = productDetails.discount;
    this.available_crates = productDetails.available_crates;
    this.excess_bottles = productDetails?.excess_bottles;
    this.qty_per_crate = productDetails.qty_per_crate;
    this.crate_price = productDetails.crate_price;
    this.bottle_price = productDetails.bottle_price;
    this.wob_price = productDetails.wob_price;
    this.has_crate = productDetails.has_crate;
    this.images = productDetails.images;
    this.extras = productDetails.extras;
    this.price_variants = productDetails.price_variants;
    const userPrices = productDetails.user_prices.map((product_price) => {
      return {
        price: product_price.price,
        account_type_id: product_price.account_type.id,
        required_qty: product_price.required_qty,
        account_type: product_price.account_type,
      };
    });
    this.user_prices = [this.defaultPrice, ...userPrices];
  }

  prepData() {
    let data = { ...this };
    data.available_quantity =
      data.unit == units.onlyBottle
        ? data.available_bottles
        : data.available_crates;

    data.has_crate = this.has_crate ? 1 : 0;
    data.extras.has_user_prices = this.extras.has_user_prices ? 1 : 0;
    data.extras.has_price_variants = this.extras.has_price_variants ? 1 : 0;
    data.user_prices = this.user_prices.filter((price) => !price.isSample); // to avoid taking the isSample
    return data;
  }
}
