<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="row">
                  <div class="form-group col-lg-3 col-md-3 col-12">
                    <label>Customer Name</label>
                    <a-select
                      v-model:value="newOrder.user_account_number"
                      show-search
                      placeholder=""
                      class="ant-select w-100"
                      :options="customers"
                      :filter-option="filterOption"
                    >
                    </a-select>
                  </div>
                  <div class="form-group col-lg-3 col-md-3 col-12">
                    <label>Requested By</label>
                    <a-select
                      v-model:value="newOrder.requested_by"
                      show-search
                      placeholder=""
                      class="ant-select w-100"
                      :options="userThatCanRequest4Others"
                      :filter-option="filterOption"
                    ></a-select>
                  </div>

                  <div class="form-group col-lg-3 col-md-3 col-12">
                    <label>Payment Date </label>
                    <div class="input-groupicon">
                      <input
                        v-model="newOrder.payment_date"
                        required
                        type="date"
                        class="form-control"
                        name="payment_date"
                      />
                    </div>
                  </div>
                  <div
                    class="form-group col-lg-3 col-md-3 col-12"
                    v-if="isSuperAdmin"
                  >
                    <label>Vendor (Store)</label>
                    <div class="input-groupicon">
                      <vue-select
                        @select="getProducts(newOrder.store_id)"
                        v-model="newOrder.store_id"
                        :options="vendors"
                        placeholder="Choose Vendor"
                        class="col-12"
                      />
                    </div>
                  </div>
                  <!-- PRODUCTS -->
                  <div class="form-group col-lg-3 col-md-3 col-12">
                    <label>Product</label>
                    <a-select
                      class="ant-select w-100"
                      :filter-option="filterOption"
                      show-search
                      v-model:value="newProduct.id"
                      required
                      name="product"
                      :options="products"
                    />
                  </div>

                  <div
                    v-if="newOrder.is_wholeseller && canIssueCustomPrice"
                    class="form-group col-lg-3 col-md-3 col-12"
                  >
                    <label>Custom Price</label>

                    <div class="d-flex align-items-end">
                      <input
                        v-if="newProduct.has_custom_price"
                        type="number"
                        class="form-control form-control-sm"
                        v-model="newProduct.custom_price"
                        placeholder="Custom Price"
                      />
                      <button
                        class="btn orange btn-sm"
                        v-if="!newProduct.has_custom_price"
                        @click="addCustomPrice()"
                      >
                        Add Custom Price
                      </button>
                      <button
                        class="btn orange btn-sm"
                        v-else
                        @click="removeCustomPrice()"
                      >
                        &times;
                      </button>
                    </div>
                  </div>

                  <div
                    v-if="hasPriceVariant()"
                    class="form-group col-lg-3 col-md-3 col-12"
                  >
                    <label>Price Variation</label>
                    <a-select
                      class="w-100"
                      v-model:value="newProduct.price_variant_id"
                      :options="getPriceVariants()"
                    />
                  </div>
                  <!-- SHOW IF NO VARIANT WAS SELECTED -->
                  <div
                    v-if="!newProduct.price_variant_id"
                    class="form-group col-lg-3 col-md-3 col-12"
                  >
                    <label>Unit</label>
                    <a-select
                      class="w-100"
                      v-model:value="newProduct.unit"
                      :options="units"
                    />
                  </div>

                  <div class="form-group col-lg-3 col-md-3 col-12">
                    <label
                      >Quantity
                      <i>
                        {{
                          newProduct.unit &&
                          `(${selectedProductMaxQty} ${newProduct.unit})`
                        }}
                      </i>
                    </label>
                    <input
                      :max="selectedProductMaxQty"
                      :min="1"
                      required
                      name="quantity"
                      type="number"
                      class="form-control"
                      placeholder="Required Crates"
                      v-model="newProduct.qty"
                    />
                  </div>
                  <div
                    class="form-group col-lg-3 col-md-3 col-12 d-flex align-items-end"
                  >
                    <button
                      id="addProduct"
                      class="btn btn-primary"
                      @click="addProduct()"
                    >
                      <i class="icon icon-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Qty</th>
                      <th>Available</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Subtotal</th>
                      <th>Tax</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedProducts">
                    <tr
                      v-for="(item, key, index) in selectedProducts"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="productimgname">
                        <a class="product-img">
                          <img
                            :src="productsDetail[item.id].image"
                            alt="product"
                          />
                        </a>
                        <a href="javascript:void(0);">{{
                          productsDetail[item.id].name
                        }}</a>
                      </td>
                      <td>{{ item.qtyText }}</td>
                      <td>{{ productsDetail[item.id].available_qty_desc }}</td>
                      <td>{{ formatAmount(item.price, true) }}</td>
                      <td>{{ formatAmount(item.discount, true) }}</td>
                      <td>{{ formatAmount(item.subtotal, true) }}</td>
                      <td>{{ formatAmount(item.tax, true) }}</td>
                      <td>{{ formatAmount(item.total, true) }}</td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          class="delete-set"
                          @click="deleteProduct(key)"
                          ><img
                            src="../../../assets/img/icons/delete.svg"
                            alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <a
                  v-on:click="addOrder()"
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                  id="submit"
                  >Submit</a
                >
                <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderDueError
      :due-reports="userDueReport.data"
      :min-amount2-pay="userDueReport.minAmount2Pay"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { checkInputValidity } from "@/helpers/validator";
import { formatAmount } from "@/helpers";
import { getCurrentDateTime } from "@/helpers/datetime";
import { useRouter } from "vue-router";
import OrderDueError from "./modals/order-due-error.vue";
import apiResponseCode from "@/constants/apiResponseCode";

const store = useStore();
const router = useRouter();
// static datas
const title = ref("Add Order");
const title1 = ref("Add your new order");

const userDueReport = ref({ data: [], minAmount2Pay: 0 });

const products = ref([]);
const productsDetail = ref([]);
const productUnits = {
  onlyBottle: "Bottle",
  onlyCrate: "Crate",
  bottleAndCrate: "Bottle & Crate",
};
const users = ref([]);
const requestedByUsers = ref([]);
const customers = computed(() =>
  users.value.map((user) => ({
    label: user.name,
    value: user.account_number,
    can_order_4_others: user.can_order_4_others,
  }))
);

const userThatCanRequest4Others = computed(() => [
  { label: "None", value: null },
  ...requestedByUsers.value
    .filter(
      (user) =>
        user.account_number != newOrder.value.user_account_number &&
        user.can_order_4_others
    )
    .map((user) => ({
      label: user.name,
      value: user.account_number,
      can_order_4_others: user.can_order_4_others,
    })),
]);
const units = computed(() => {
  const currentProductDetail = productsDetail.value[newProduct.id];
  let _units = [];
  if (!currentProductDetail) {
    _units = [];
  } else if (currentProductDetail.unit) {
    _units =
      currentProductDetail.unit != productUnits.bottleAndCrate
        ? [{ value: currentProductDetail.unit }]
        : [
            { value: productUnits.onlyBottle },
            { value: productUnits.onlyCrate },
          ];
  } else {
    _units = [{ value: productUnits.onlyCrate }];
  }
  return _units;
});

const currentProduct = computed(() => productsDetail.value[newProduct.id]);
// reactive
const newOrder = ref({
  is_wholeseller: false,
  payment_date: getCurrentDateTime(),
  user_account_number: null,
  products: [],
  requested_by: null,
  store_id: null,
});
const newProduct = reactive({
  id: null,
  unit: null,
  qty: 0,
  custom_price: null,
  has_custom_price: false,
  price_variant_id: null,
});
const selectedProducts = ref({});

const hasAccess = (access) => store.getters["hasAccess"](access);
const isSuperAdmin = computed(() => store.getters.isSuperAdmin);

const canIssueCustomPrice = computed(() => {
  return hasAccess("issue-custom-price-for-a-product");
});

const vendors = computed(() => {
  return store.state.vendorReport.vendors.map((vendor) => {
    return {
      text: vendor.name,
      id: vendor.id,
    };
  });
});

const selectedProductMaxQty = computed(() => {
  if (!currentProduct.value) {
    return 1;
  } else {
    const { available_crates, available_bottles } = currentProduct.value;
    if (newProduct.price_variant_id) {
      return getPriceVariants().find(
        (priceVariant) => priceVariant.id == [newProduct.price_variant_id]
      )?.available;
    } else {
      return newProduct.unit == productUnits.onlyCrate
        ? available_crates
        : available_bottles;
    }
  }
});
// functions
function deleteProduct(index) {
  delete selectedProducts.value[index];
}

function filterOption(input, option) {
  let labelSearch =
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  let valueSearch =
    String(option.value).toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return labelSearch || valueSearch;
}

function validate() {
  let error = "";
  let data = newOrder.value;
  if (data.user_account_number == null) {
    error = "User";
  } else if (!newOrder.value.payment_date) {
    error = "Payment Date";
  } else if (Object.values(selectedProducts.value).length == 0) {
    error = "Product";
  }

  if (error) {
    Swal.fire({
      title: `Select a ${error}`,
      icon: "warning",
    });
    return false;
  } else {
    return true;
  }
}

function addCustomPrice() {
  newProduct.has_custom_price = true;
}

function removeCustomPrice() {
  newProduct.has_custom_price = false;
  newProduct.custom_price = null;
}

function getUsers(allUsers = false) {
  const params = allUsers && { fetch_users_that_can_order: true };
  store.dispatch("users/getIds", params).then((response) => {
    const data = response.data.data.map((user) => {
      return {
        ...user,
        is_wholeseller: user.wholeseller,
      };
    });
    if (allUsers) {
      requestedByUsers.value = data;
    } else {
      users.value = data;
      if (users.value.length == 1) {
        newOrder.value.user_account_number = data[0].account_number;
      }
    }
  });
}
function getStores() {
  store.dispatch("vendorReport/getStoreNames");
}

function isAvailable(product) {
  switch (product.unit) {
    case productUnits.onlyBottle:
      return product.available_bottles > 0;
    case productUnits.onlyCrate:
      return product.available_crates > 0;
    case productUnits.bottleAndCrate:
      return product.available_bottles > 0 || product.available_crates > 0;
    default:
      return false;
  }
}
function getProducts(vendorId = null) {
  store
    .dispatch("product/fetchProductsFewDetails", { store_id: vendorId })
    .then((response) => {
      products.value = [];
      productsDetail.value = {};
      response.data.data.forEach((product) => {
        if (isAvailable(product)) {
          const {
            id,
            unit,
            available_crates,
            available_qty_desc,
            available_bottles,
            qty_per_crate,
            images,
            name,
            crate_price,
            bottle_price,
            price_variants,
            user_prices,
            extras,
          } = product;
          productsDetail.value[id] = {
            id,
            unit,
            available_crates,
            available_qty_desc,
            available_bottles,
            qty_per_crate,
            image: images[0]?.url,
            name,
            crate_price,
            bottle_price,
            price_variants,
            user_prices,
            extras,
          };
          products.value.push({
            value: id,
            label: name,
          });
        }
      });
    });
}

function addProduct() {
  if (!newProduct.id) {
    Swal.fire({
      title: "Select A Product",
      icon: "warning",
    });
    return;
  }

  const productDetails = productsDetail.value[newProduct.id];
  const { available_crates, available_bottles } = productDetails;
  const orderedQty = newProduct.qty;

  const exceedsLimit =
    (newProduct.unit == productUnits.onlyBottle &&
      orderedQty > available_bottles) ||
    (newProduct.unit == productUnits.onlyCrate &&
      orderedQty > available_crates);

  if (exceedsLimit) {
    const message =
      newProduct.unit == productUnits.onlyBottle
        ? `${available_bottles} Bottle(s)`
        : `${available_crates} Crate(s)`;
    Swal.fire({
      title: `You Cannot Order More Than ${message}`,
      icon: "warning",
    });
  } else {
    const { price, qtyText } = getProductPriceNdQtyText(productDetails);
    const subtotal = price * orderedQty;

    selectedProducts.value[newProduct.id] = {
      id: newProduct.id,
      unit: newProduct.unit,
      custom_price: newProduct.custom_price,
      has_custom_price: newProduct.has_custom_price,
      price_variant_id: newProduct.price_variant_id,
      has_price_variant: newProduct.has_price_variant,
      orderedQty,
      qtyText,
      price,
      subtotal,
      total: parseFloat(subtotal),
    };

    Object.assign(newProduct, {
      id: null,
      qty: 1,
      custom_price: null,
      has_price_variant: false,
      price_variant_id: null,
    });
  }
}

function getProductPriceNdQtyText(productDetails) {
  let price = null;
  let unit = null;

  if (newProduct.has_custom_price) {
    price = newProduct.custom_price;
    unit = newProduct.unit;
  } else if (newProduct.price_variant_id) {
    const variant = getPriceVariants(newProduct.id).find(
      (price) => price.id == newProduct.price_variant_id
    );
    price = variant.price;
    unit = variant.name;
  } else {
    price =
      newProduct.unit == productUnits.onlyCrate
        ? productDetails.crate_price
        : productDetails.bottle_price;
    unit = newProduct.unit;
  }
  return {
    price,
    qtyText: `${newProduct.qty} ${unit}(s)`,
  };
}

function hasPriceVariant() {
  if (!currentProduct.value) {
    return false;
  } else {
    return currentProduct.value.extras.has_price_variants;
  }
}

function getPriceVariants() {
  if (!currentProduct.value) {
    return [];
  } else {
    const prices = currentProduct.value.price_variants.map((price) => ({
      value: price.id,
      label: `${price.name} (${formatAmount(price.price, true)})`,
      price: price.price,
      name: price.name,
      id: price.id,
      available: price.available_qty,
    }));
    return [
      {
        value: null,
        label: "None",
        price: null,
        id: null,
      },
      ...prices,
    ];
  }
}

// async
async function addOrder(increaseCreditLimit = false) {
  if (validate()) {
    newOrder.value.increase_credit_limit = increaseCreditLimit;
    newOrder.value.products = Object.values(selectedProducts.value).map(
      (product) => {
        const data = {
          id: product.id,
          qty: product.orderedQty,
          has_price_variant: product.has_price_variant,
          has_custom_price: product.has_custom_price,
          custom_price: product.custom_price,
          unit: product.unit,
        };
        if (product.price_variant_id) {
          data["price_variant_id"] = product.price_variant_id;
        }
        return data;
      }
    );
    store.state.toastError = false;

    store
      .dispatch("orders/add", { payload: newOrder.value })
      .then((response) => {
        store.commit("notify", {
          type: "success",
          title: "Order Created",
          message: "Order Created Successfully",
        });
        router.push({ name: "orderlist" });
      })
      .catch((error) => {
        let data = error.response.data;
        switch (data.code) {
          case apiResponseCode.HAS_DUE_ABOVE_A_MONTH:
            userDueReport.value.data = data.errors.orders;
            userDueReport.value.minAmount2Pay = data.errors.min_amount_to_pay;
            $("#orderError").modal("show");
            break;
          default:
            if (data.is_credit_limit) {
              store.commit("confirm", {
                title: data.message,
                message: "Request For Credit Limit Increase?",
                callback: (isConfrimed) => {
                  if (isConfrimed) {
                    store.commit("confirm", {
                      title: "This Order Will Be Saved As Draft",
                      message:
                        "You will not see this in the Order List Until The Credit Limit Increase Request Is Approved",
                      callback: (isConfrimed) => {
                        if (isConfrimed) {
                          addOrder(true);
                        }
                      },
                    });
                  }
                },
              });
            } else {
              Swal.fire(data?.message, "", "error");
              store.state.toastError = true;
            }
            break;
        }
      });
  }
}
onMounted(() => {
  getUsers();
  getUsers(true);
  if (!isSuperAdmin.value) {
    getProducts();
  } else {
    getStores();
  }
});

// watch
watch(
  newProduct,
  () => {
    if (newProduct.price_variant_id) {
      newProduct.unit = getPriceVariants().find(
        (priceVariant) => priceVariant.id == [newProduct.price_variant_id]
      )?.name;
    }
    setTimeout(() => checkInputValidity("addProduct"), 500);
  },
  { immediate: true }
);
watch(
  () => newOrder.value.user_account_number,
  (accountNumber) => {
    const user = users.value.find(
      (customer) => customer.account_number == accountNumber
    );
    if (user) {
      newOrder.value.is_wholeseller = user.is_wholeseller;
    }
  }
);
</script>
<style></style>
