<script setup>
import { useMutation, useQuery } from "@tanstack/vue-query";
import { baseUrl, api } from "@/config/index";
import { ref, watchEffect } from "vue";

//
const query = useQuery({
  queryFn: async (page) => await api.get(`${baseUrl}/vendors?status=Pending&page=${page}`),
  queryKey: ["pending-vendors"],
});

const { mutateAsync, isPending, reset } = useMutation({
  mutationFn: async (param) => await api.post(`${baseUrl}/update-vendor-request`, param),
  mutationKey: ["update-pending-vendor"],
});

const mutatatingId = ref();
const buttonId = ref();
watchEffect(() => (buttonId.value = mutatatingId.value));

const updateVendor = async (param) => {
  mutateAsync(param).then(query.refetch).catch(reset);
  mutatatingId.value = param.id;
};

const columns = [
  {
    sorter: true,
  },
  {
    title: "Vendor Name",
    dataIndex: "first_name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Business Name",
    dataIndex: "business_name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Mobile Number",
    dataIndex: "mobile_number",
    sorter: (a, b) => a.mobile_number.localeCompare(b.mobile_number),
  },
  {
    title: "email",
    dataIndex: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at?.toLowerCase();
        b = b.created_at?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
</script>

<template>
  <!-- Main Wrapper -->

  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader
          :title="'Pending Vendors'"
          :title1="'Manage pending Vendors'"
          :items="[]"
        />
        <f-table
          :filters="['name', 'account_number', 'mobile_number', 'email']"
          :data="query?.data?.value?.data?.data"
          :columns="columns"
        >
          <template #header>
            <div v-if="isPending" class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <button
                class="me-3 confirm-text"
                @click="updateVendor({ id: record.id, status: 'verified' })"
              >
                <img
                  title="Approve Vendor"
                  style="width: 25px"
                  src="../../../assets/img/icons/approve-verified.png"
                  alt="img"
                />
              </button>
              <button
                class="me-3 confirm-text"
                @click="updateVendor({ id: record.id, status: 'rejected' })"
              >
                <img
                  title="Reject Vendor"
                  style="width: 25px"
                  src="../../../assets/img/icons/close-circle1.svg"
                  alt="img"
                />
              </button>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  all: unset;
  cursor: pointer;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: green;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
