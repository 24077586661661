<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :filters="filters" :data="data" :columns="columns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <a class="product-img">
                <img :src="record.img" alt="" />
              </a>
            </template>
            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" to="/editbrand">
                <img src="@/assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a
                v-if="a"
                class="me-3 confirm-text"
                href="javascript:void(0);"
                @click="showAlert"
              >
                <img src="@/assets/img/icons/delete.svg" alt="img" />
              </a>
            </template> </template
        ></f-table>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Image",
    dataIndex: "img",
    key: "id",
    sorter: (a, b) => a.img.localeCompare(b.img),
  },
  {
    title: "Brand Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Brand Description",
    dataIndex: "brand",
    sorter: (a, b) => a.brand.localeCompare(b.brand),
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions } from "vuex";
export default {
  data() {
    return {
      filter: false,
      filters: ["name"],
      title: "Brand List",
      title1: "Manage your Brand",
      items: [
        {
          text: "Add Brand",
          addname: "/addbrand",
        },
      ],
      data: [],
      columns,
    };
  },
  methods: {
    ...mapActions({
      _getBrands: "brand/get",
      _deleteBrand: "delete",
    }),
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Brand deleted.");
        }
      });
    },

    getBrands() {
      //  = [];
      this._getBrands().then((response) => {
        this.data = response.data.data.map((brand) => {
          return {
            img: brand.image,
            name: brand.name,
            brand: brand.name,
            id: brand.id,
          };
        });
      });
    },
  },
  mounted() {
    this.getBrands();
  },
  name: "brandlist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
