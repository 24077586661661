<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items">
          <template #right>
            <div class="d-flex flex-wrap justify-content-end gap-2">
              <button
                class="btn btn-outline-primary"
                :disabled="disableBtns"
                @click="isAboutToSchedule = !isAboutToSchedule"
              >
                <span v-if="!isAboutToSchedule">
                  Reschedule
                  <i class="ion ion-clock"></i>
                </span>
                <span v-else>Cancel</span>
              </button>
              <div>
                <div class="page-btn" v-if="isAboutToSchedule">
                  <a-date-picker
                    show-time
                    open
                    :style="{ height: '38px', width: '171px' }"
                    @change="saveNotification($event.$d)"
                  />
                </div>
              </div>
              <button
                :disabled="disableBtns"
                class="btn btn-primary"
                @click="saveNotification()"
              >
                Save with Date <i class="ion ion-paper-airplane"></i>
              </button>
            </div>
          </template>
        </pageaddheader>

        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label>Title</label>
                  <input
                    required
                    class="form-control"
                    v-model="notification.title"
                    type="text"
                    name="title"
                  />
                </div>
                <div class="form-group">
                  <label>Body</label>
                  <a-textarea
                    v-model:value="notification.body"
                    show-count
                    :maxlength="300"
                  />
                </div>
                <div class="form-group">
                  <label>Tags</label>
                  <div class="d-flex flex-wrap gap-2">
                    <Badge
                      v-for="tag in tags"
                      :key="tag"
                      class="tag"
                      :text="tag"
                      style="
                        background: #555;
                        font-size: 10px;
                        font-weight: bold;
                        font-style: italic;
                        gap: 0;
                        padding: 2px;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label>Target Audience</label>
                  <a-select
                    v-model:value="notification.audience"
                    class="w-100"
                    :options="audience"
                  />
                </div>

                <div class="form-group" v-if="showAccountType">
                  <label>Account Type</label>
                  <a-select
                    v-model:value="notification.account_type_id"
                    class="w-100"
                    :options="accountTypes"
                  />
                </div>

                <f-table
                  v-if="showUserList"
                  :filters="filters"
                  :columns="columns"
                  :data="users"
                  :row-selection="{
                    selectedRowKeys: selectedUserId,
                    onChange: onSelectChange,
                  }"
                >
                  <template #cardTop>
                    <label class="mb-2">Select Users</label>
                  </template>
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'user'">
                      <div class="d-flex gap-2 align-items-center">
                        <ImgwithFallback
                          :alt="record.name"
                          :src="record.profile_picture"
                        />
                        {{ record.name }}
                      </div>
                    </template>
                  </template>
                </f-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import Badge from "@/components/Badge.vue";
import ImgwithFallback from "@/components/imgwithFallback.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";

const columns = [
  {
    sorter: true,
  },
  {
    title: "User",
    key: "user",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const filters = ["name", "email"];
const title = "Edit Push Notification";
const title1 = "";
const items = ref([]);
const route = useRoute();
const router = useRouter();
/** if the value of notification.audience is 200 or 300 */
const disableBtns = computed(() => {
  return !notification.title || !notification.body || !notification.audience;
});
const tags = ref([
  "__USER_FULLNAME__",
  "__USER_FIRSTNAME__",
  "__USER_LASTNAME__",
  "__USER_EMAIL__",
]);
const audience = ref([
  { label: "All Users", value: "All Users" },
  { label: "Users Except", value: "Users Except" },
  { label: "Selected Users", value: "Selected Users" },
  { label: "Selected Account Type", value: "Selected Account Type" },
]);
const notificationId = computed(() => route.params.id);
// ref data
const showAccountType = computed(() => {
  return notification.audience == "Selected Account Type";
});
const showUserList = computed(() => {
  return ["Users Except", "Selected Users"].includes(notification.audience);
});
const isAboutToSchedule = ref(false);
const users = ref([]);
const accountTypes = ref([]);
const onSelectChange = (selectedRowKeys) => {
  notification.users_id = selectedRowKeys;
};
const notification = reactive({
  title: null,
  body: null,
  users_id: [],
  audience: null,
  account_type_id: null,
  deliver_at: null,
});

// functions
async function saveNotification(scheduledTime = null) {
  store.commit("confirm", {
    title: "are you sure about this?",
    message:
      'Clicking "Ok" will update and send this notification once the Scheduled time reach',
    callback: async (save) => {
      if (save) {
        notification.deliver_at = scheduledTime;
        await store.dispatch("pushNotification/update", {
          id: notificationId.value,
          payload: notification,
        });
        store.commit("notify", {
          message:
            "Notification Saved ✔️, Will Be Sent to Specified Users Soon",
        });
        router.push("/notification");
      }
    },
    confirmButtonText: "Ok",
  });
}

const getAccountTypes = async () => {
  const response = await store.dispatch("accountType/get");
  accountTypes.value = response.data.data.map((account_type) => {
    return {
      label: account_type.name,
      value: account_type.id,
    };
  });
};
function getUserIds() {
  store.dispatch("users/getIds").then((response) => {
    users.value = response.data.data.map((user) => ({
      key: user.id,
      id: user.id,
      name: user.name,
      profile_picture: user.profile_picture,
    }));
  });
}
function fetchNotificationDetails() {
  store
    .dispatch("pushNotification/show", notificationId.value)
    .then((response) => {
      const notificationData = response.data.data;
      setNotificationData(notificationData);
    });
}
function setNotificationData(notificationData) {
  notification.title = notificationData.title;
  notification.body = notificationData.body;
  notification.users_id = notificationData.users_id;
  notification.audience = notificationData.audience;
  notification.account_type_id = notificationData.account_type_id;
  notification.deliver_at = notificationData.deliver_at;
}
onMounted(() => {
  getAccountTypes();
  getUserIds();
  fetchNotificationDetails();
});
</script>
