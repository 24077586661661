import { api } from "@/config";
import { socket } from "@/config/websocket";

export const chat = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/chat${path}`;
  },
  namespaced: true,
  state: {
    newMessage: null,
  },
  actions: {
    updateConnectionId({ context }, connectionID) {
      return api.post(`/connection`, {
        connection_id: connectionID,
      });
    },
    sendMessage({ context }, { receiver_chat_id, message }) {
      return api.post(chat.url(), {
        receiver_chat_id: receiver_chat_id,
        message: message,
      });
    },
    getUsers({ context }) {
      return api.get(chat.url("all"));
    },
    getChattedUsers({ context, state }) {
      state.newMessage = null;
      return api.get(chat.url("users"));
    },
    // Return all chat with an admin
    getChats({ context, state }, { sender_id }) {
      state.newMessage = null;
      return api.get(chat.url(sender_id));
    },
  },
};
