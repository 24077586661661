<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper" v-if="Object.values(supplierReport).length > 0">
      <div class="content">
        <div class="card-body">
          <div class="tabs-set">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="purchase-tab" data-bs-toggle="tab" data-bs-target="#purchase"
                  type="button" role="tab" aria-controls="purchase" aria-selected="true">
                  Purchase
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="">
            <!-- <pageheader :title="title" :title1="title1" :items="items"/> -->
            <pageaddheader :title="title" :title1="title1" :items="items" />

            <div
              class="col-lg-4 col-sm-6 col-12 d-flex mb-3 gap-2"
              style="padding-right: 25px"
            >
              <!-- <a-range-picker class="col-10" @change="setDateRange" 
              :options="{ maxDate: currentDate }"
              :disabled-date="disabledAfterToday" /> -->
              <custom-datepicker @change="setDateRange"></custom-datepicker>
              <button class="btn btn-primary" @click="getSuppliers()">Fetch</button>
            </div>
            <br />
          </div>
        </div>

        <!-- /order list -->
        <f-table :filters="['product_name']" :columns="columns" :data="supplierReport.suppliers">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'order'">
              <router-link title="Click to View Order" :to="'orders/' + record.order_id">{{ record.order_reference
              }}</router-link>
              <template v-if="column.key === 'status'">
                <span class="badges bg-lightgreen" v-if="record.Status === 'Confirmed'">{{ record.Status }}</span>
                <span class="badges bg-lightred" v-else-if="record.Status === 'Pending'">{{ record.Status }}</span>
              </template>
            </template>
          </template>
        </f-table>

        <!-- /order list -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Order date",
    dataIndex: "order_date",
    sorter: (a, b) => a.order_date.localeCompare(b.order_date),
  },
  {
    title: "Product name",
    dataIndex: "product_name",
    sorter: {
      compare: (a, b) => {
        a = a.product_name.toLowerCase();
        b = b.product_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Purchased QTY",
    dataIndex: "purchased_qty",
    sorter: (a, b) => a.purchased_qty.localeCompare(b.purchased_qty),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
  },

  // {
  //   title: "Admin",
  //   dataIndex: "customer_name",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Paid.toLowerCase();
  //       b = b.Paid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
];

import { mapActions, mapGetters } from "vuex";
import store from "../../../../store";


export default {
  data() {
    const currentDate = new Date().toISOString().split("T")[0];

    return {
      filter: true,

      filter: {
        value: null,
      },
      title1: "Manage your Reports",
      startdate: currentDate,
      data: [],
      filtered: [],
      columns,
      currentDate,
      paginationConfig: {},
      supplierReport: [],
      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    setDateRange(context, dates) {
      this.dateRange.startDate = dates[0];
      this.dateRange.endDate = dates[1];
    },
    ...mapActions({
      _getOrders: "payments/get",
      _deleteOrder: "payments/delete",
    }),
    
    deleteOrder(orderId) {
      Swal.fire({
        title: "Are you sure?",
        text: "This Order Will be deleted entirely!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteOrder({ orderId: orderId }).then((res) => {
            store.commit("notify", {
              type: "success",
              title: "Order Delete",
              message: "Order Deleted Successfully",
            });
            this.getSuppliers();
          });
        }
      });
    },
    getSuppliers() {
      store
        .dispatch("suppliers/getSupplierReport", { dateRange: this.dateRange })
        .then((response) => {
          this.supplierReport = response.data.data;
          //   this.supplierReport.suppliers =  response.data.data.suppliers.map((supplier) => {
          //   return {
          //     Status: supplier.status,
          //   };
          // })
        });
    },
  },
  mounted() {
    this.getSuppliers();
    if (!this.hasAccess("create-orders")) {
      this.items = [];
    }
  },
  name: "invoicereport",
};
</script>
