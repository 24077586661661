<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo_lg.jpg" alt="" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo_lg.jpg" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Create an Account</h3>
                <h4>Continue where you left off</h4>
              </div>
              <div class="register">
                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                    border-radius: 10px;
                    align-items: center;
                  ">
                  <div class="form-group" style="flex-grow: 1;">
                    <label>Store Name</label>
                    <div class="form-addons">
                      <div class="" style="position: relative;">
                        <input type="text" :class="`form-control ${storeNameExists == false
                          ? 'is-valid'
                          : storeNameExists
                            ? 'is-invalid'
                            : ''
                          }`" required v-model="formData.store_name" name="store_name" @input="({ target: { value } }) => checkStoreName(value)
                            " style="width: 100%;" />
                        <transition name="slide-in" style="
                            flex-grow: 1;
                            background: red;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 100%;
                            border-radius: 5px;
                            padding: 8px;
                          ">
                          <span v-if="storeNameExists" style="
                              border-radius: 0;
                              white-space: nowrap;
                              color: white;
                            ">Store name already exists.</span>
                        </transition>
                      </div>
                    </div>
                  </div>

                  <!-- <transition name="slide-in">
                    <span
                      v-if="storeNameExists === true"
                      style="border-radius: 0"
                      class="notification error"
                      >Store name already exists.</span
                    >
                  </transition> -->
                </div>

                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                  ">
                  <div class="form-group" style="flex: 1; max-width: 50%; margin-bottom: 2px;">
                    <label>First Name</label>
                    <div class="form-addons">
                      <input type="text" required v-model="formData.first_name" name="first_name" class="form-control"
                        style="width: 100%;" :rules="nameRules" />

                    </div>
                  </div>

                  <div class="form-group" style="
                      flex: 1;
                      max-width: 50%;
                      margin-left: 20px;
                      margin-bottom: 2px;
                    ">
                    <label>Last Name</label>
                    <div class="form-addons">
                      <input name="last_name" type="text" required v-model="formData.last_name" class="form-control"
                        style="width: 100%;" />

                    </div>
                  </div>
                </div>
                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                  ">
                  <div class="form-group" style="flex: 1; max-width: 50%; margin-bottom: 2px;">
                    <label>Business Name <i class="badge bg-dark bg-opacity-50 text-sm-center ">Opional</i></label>
                    <div class="form-addons">
                      <input v-model="formData.business_name" type="text" class="form-control" style="width: 100%;" />
                    </div>

                  </div>

                  <div class="form-group" style="
                      flex: 1;
                      max-width: 50%;
                      margin-left: 20px;
                      margin-bottom: 2px;
                    ">
                    <label>Registration Number <i
                        class="badge bg-dark bg-opacity-50 text-sm-center ">Opional</i></label>
                    <div class="form-addons">
                      <input type="text" v-model="formData.registration_number" class="form-control"
                        style="width: 100%;" />
                    </div>

                  </div>
                </div>
                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                  ">
                  <div class="form-group" style="flex: 1; max-width: 50%; margin-bottom: 2px;">
                    <label>Business Address </label>
                    <div class="form-addons">
                      <input name="business_address" type="text" required v-model="formData.business_address"
                        class="form-control" style="width: 100%;" />
                    </div>

                  </div>

                  <div class="form-group" style="
                      flex: 1;
                      max-width: 50%;
                      margin-left: 20px;
                      margin-bottom: 2px;
                    ">
                    <label for="state">State</label>
                    <div class="form-addons">
                      <vue-select :options="states" require v-model="formData.state_id" name="state" />
                    </div>

                  </div>
                </div>
                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                  ">
                  <div class="form-group" style="flex: 1; max-width: 50%; margin-bottom: 2px;">
                    <label>LGA</label>
                    <div class="form-addons">
                      <vue-select :options="selectedStateLGAs" require v-model="formData.local_government_area_id"
                        name="local_government_area" />
                    </div>

                  </div>

                  <div class="form-group" style="
                      flex: 1;
                      max-width: 50%;
                      margin-left: 20px;
                      margin-bottom: 2px;
                    ">
                    <label>Landmark</label>
                    <div class="form-addons" style="margin-bottom: 3px;">
                      <input name="landmark" type="text" required v-model="formData.landmark" class="form-control"
                        style="width: 100%;" />
                    </div>

                  </div>
                </div>
                <div class="form-login" style="display: flex; justify-content: space-between;">
                  <div class="form-group" style="flex: 1; max-width: 50%; margin-bottom: 2px;">
                    <label>Email</label>
                    <div class="form-addons">
                      <input name="email" type="email" required v-model="formData.email" style="width: 100%;"
                        :class="`form-control`" />
                    </div>
                  </div>

                  <div class="form-group" style="
                      flex: 1;
                      max-width: 50%;
                      margin-left: 20px;
                      margin-bottom: 2px;
                    ">
                    <label>Phone Number</label>
                    <div class="form-addons">
                      <input name="mobile_number" type="text" required v-model="formData.mobile_number"
                        :class="`form-control`" style="width: 100%;" minlength="14" />
                    </div>

                  </div>
                </div>
                <div class="form-login" style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                  ">
                  <div class="form-group col-12" style="">
                    <label>Password</label>
                    <div class="pass-group">
                      <input name="password" id="password" :type="!toggle?.password ? 'password' : 'text'" required
                        v-model="formData.password" class="form-control pass-input" placeholder="Password"
                        style="width: 100%;" minlength="8" title="Password must be at least 8 characters long" />
                      <span @click="toggleItem('password')" class="fas toggle-password" :class="{
                        'fa-eye': toggle?.password,
                        'fa-eye-slash': !toggle?.password,
                      }"></span>
                    </div>

                  </div>

                </div>
                <div>
                  <!-- Other template code -->
                </div>
                <!-- <div v-if="!isRecaptchaVerified" id="recaptcha-container"></div>  -->

                <div ref="" id="recaptcha-container" class="recaptcha-container"></div>

                <!-- <div v-if="isLoading" class="loader" style="font-size: 20px;">Loading...</div> -->

                <!-- Loading icon -->
                <div v-if="isLoading" class="loader">
                  <i class="fas fa-spinner fa-spin" style="font-size: 30px; color: gray;"></i>
                </div>

                <div class="form-login">
                  <button :disabled="!canContinue" id="submitForm" class="btn btn-login"
                    @click="sendPhoneVerificationCode(appVerifier)">
                    {{ isSingningUp ? "Registering..." : "Submit" }}
                  </button>
                </div>
              </div>

              <div class="signinform text-center">
                <h4>
                  Already a user?
                  <router-link to="/signin" class="hover-a">Sign In</router-link>
                </h4>
              </div>

            </div>
          </div>
          <div class="login-img">
            <img src="../../../assets/img/login.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->

    <!-- Modal -->
    <div v-if="toggle.phoneVeridficationModal" class="modal">
      <div class="modal-content">
        <div class="card" style="margin-bottom: 0;">
          <div class="card-header" style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 0;
            ">
            <h5 class="card-title">Verify Your Phone</h5>
          </div>
          <p style="padding-inline: 20px; margin: 0; max-width: 80%;">
            We sent verification code to your phone number
            {{ formData.mobile_number }}
          </p>
          <span style="
              padding-inline: 20px;
              display: flex;
              align-items: center;
              gap: 10px;
              margin-top: 10px;
            ">
          </span>
          <div class="card-body">
            <b-form action="#">
              <div class="form-group">
                <label>Enter Code</label>
                <b-form-input @input="resetPhoneverificationInput" id="verificationCode" type="text"
                  class="form-control"></b-form-input>
                <span v-if="errors.phoneVerified === false" class="error-message">Invalid code submitted.</span>
              </div>

              <div ref="" id="resend-recaptcha-container" class="resend-recaptcha-container"></div>

              <div class="d-flex text-center justify-content-center">

                <cirlcular-preloader v-if="isSingningUp" />
              </div>

              <div class="text-end">
                <div class="my-2">
                  <b @click="resendVerificationCode()" v-if="canResend" class="resend-btn fw-bolder ">Resend</b>
                  <span style="color: #ff9f43" v-else>{{ countdown }}</span>
                </div>
                <button :disabled="isSingningUp" style="width: 100%;" @click="submitVerificationCode()"
                  variant="primary" class="btn btn-login btn-primary">
                  {{ isSingningUp ? "Registering..." : "Confirm" }}
                </button>

              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>import { ref, onMounted, computed, reactive, watch } from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import store from "@/store";
import { useRouter } from "vue-router";
import { moveElement, sleep } from "@/helpers";
import { firebaseConfig } from "@/config/firebase";
import { checkInputValidity } from "@/helpers/validator";
import { updatePendingRequest } from "@/helpers/request";

// Vue Router
const router = useRouter();

// Reactive references
const recaptchaDone = ref(false);
const intervalId = ref(null);
const states = ref([]);
const lgas = ref([]);
const storeNameExists = ref(null);
const phoneConfirmationResult = ref(null);
const canResend = ref(false);
const isResending = ref(false);
const countdown = ref(null);
const canContinue = ref(false);
const isSingningUp = ref(false);
const appVerifier = ref(null);

// Computed properties
const selectedStateLGAs = computed(() => {
  const data = lgas.value.find((lga) => formData.state_id == lga.state_id)?.lgas ?? [];
  return data.map((lga) => ({
    id: lga.id,
    text: lga.name,
  }));
});

// Error tracking
const errors = ref({
  auth: {
    email: [],
    password: [],
    store_name: [],
    first_name: [],
    mobile_number: [],
    store_name: [],
  },
  phoneVerified: null,
});

// Toggle states
const toggle = ref({
  password: false,
  confirm_password: false,
  phoneVeridficationModal: false,
});

// Reactive form data
const formData = reactive({
  store_name: null,
  first_name: null,
  last_name: null,
  business_name: null,
  registration_number: null,
  business_address: null,
  landmark: null,
  email: null,
  mobile_number: "+234",
  password: null,
  local_government_area_id: null,
  state_id: null,
  admin_type: "VENDOR_ADMIN",
});

// Toggle functionality
const toggleItem = (el_name) => {
  toggle.value[el_name] = !toggle?.value?.[el_name];
};

// Validation
const validateForm = () => {
  const errors = checkInputValidity();
  canContinue.value = Object.values(errors).length == 0 && recaptchaDone.value;
};

// Fetch states
const fetchStates = async () => {
  const response = await store.dispatch("utils/getStates");
  const data = response.data.data;
  states.value = data.map((state) => ({
    id: state.id,
    text: state.name,
  }));
  lgas.value = data.map((state) => ({
    state_id: state.id,
    lgas: state.local_government_areas,
  }));
};

// Check store name
const checkStoreName = (storeName) => {
  if (storeName !== "") {
    store
      .dispatch("utils/checkStoreName", storeName)
      .then((response) => {
        storeNameExists.value = false;
      })
      .catch((error) => {
        storeNameExists.value = true;
      });
  } else {
    storeNameExists.value = null;
  }
};

// Recaptcha initialization
function initRecaptcha() {
  const elementId = "recaptcha-container";
  try {
    if (!appVerifier.value) {
      const recaptcha = new firebase.auth.RecaptchaVerifier(
        elementId,
        {
          size: "normal", // Adjust the size as needed
          callback: (response) => {
            recaptchaDone.value = true;
            recaptchaCallback(response);
          },
          "expired-callback": () => {
            initRecaptcha();
            validateForm();
          },
        }
      );

      appVerifier.value = recaptcha;
      recaptcha.render().then((widgetId) => {
        document
          .getElementById(elementId)
          .setAttribute("data-widget-id", widgetId);
      });
    }
  } catch (error) {
    console.error("Error initializing reCAPTCHA:", error);
  }
}

// Recaptcha callback
function recaptchaCallback(response) {
  if (isResending.value) {
    sendPhoneVerificationCode();
  } else {
    validateForm();
  }
}

// Send phone verification code
async function sendPhoneVerificationCode() {
  if (formData.mobile_number?.length < 14) {
    errors.value.auth.mobile_number = ["Invalid mobile number!"];
    return;
  }
  errors.value.auth.mobile_number = [];
  updatePendingRequest();
  try {
    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(formData.mobile_number, appVerifier.value);
    phoneConfirmationResult.value = confirmationResult;
    !isResending.value && toggleItem("phoneVeridficationModal");

    store.commit("notify", { type: "success", title: "OTP Sent to Your Number" })
    startCountdown(5)

  } catch (error) {
    console.error("Error during phone number verification:", error);
    Swal.fire("Something Went Wrong", error.message, "error")
  }
  updatePendingRequest(false);
}

// Save vendor
async function saveVendor() {
  isSingningUp.value = true;
  store.dispatch("utils/registerVendor", formData).then((response) => {
    router.push("/verifyemail");
  }).finally(() => {
    toggleItem("phoneVeridficationModal");
    isSingningUp.value = false;
  });
}

// Submit verification code
const submitVerificationCode = async () => {
  updatePendingRequest();
  const verificationCode = document.getElementById("verificationCode").value;
  try {
    const userCredential = await phoneConfirmationResult.value.confirm(
      verificationCode
    );

    const user = userCredential.user;
    if (user) {
      saveVendor();
    } else {
      errors.value.phoneVerified = false;
    }
  } catch (error) {
    console.error("Error during phone number verification:", error);
  }
  updatePendingRequest(false);
}

// Start countdown
function startCountdown(seconds) {
  clearInterval(intervalId.value);
  canResend.value = false;
  let time = seconds;
  function updateCountdown() {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    countdown.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    if (time > 0) {
      time--;
    } else {
      clearInterval(intervalId.value);
      canResend.value = true;
    }
  }

  updateCountdown();
  intervalId.value = setInterval(updateCountdown, 1000);
}

// Resend verification code
const resendVerificationCode = () => {
  isResending.value = true;
  moveElement("recaptcha-container", "resend-recaptcha-container");
  initRecaptcha();
}

// Watch form data and validate on change
watch(formData, () => validateForm(), { deep: true, immediate: true });

// On component mount
onMounted(() => {
  validateForm();
  fetchStates();
  firebase.initializeApp(firebaseConfig);
  initRecaptcha();
});

</script>

<style scoped>
.notification {
  display: block;
  padding: 10px;
  margin-top: 26px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: 2px solid #ff6b6b;
  /* Border color for a friendly look */
  background-color: #ff6b6b;
  /* Background color for an error notification */
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 0.5s;
}

.slide-in-enter,
.slide-in-leave-to {
  transform: translateX(100%);
}

/* Add this CSS to style the modal */
/* Style for the modal background */
.modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Style for the modal content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;
  /* Adjust the width as needed */
  max-width: 400px;
}

/* Style for the close button */
.close {
  color: #aaa;
  float: right;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #333;
  text-decoration: none;
}

/* Style for the form input and button */
label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

button {
  background-color: #ff9f43;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-outline-primary {
  background: #fff !important;
  color: #ff9f43 !important;
  border: 1px solid #ff9f43;
}

button:hover {
  background-color: #ff9f43;
}

.resend-btn {
  color: #ff9f43;
  cursor: pointer;
}

.error-message {
  color: red;
}

.span {
  font-size: 50px;
  /* Adjust the size as needed */
  color: green;
  /* Set the color as needed */
}
</style>