import { api } from "@/config";

export const pushNotification = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/push-notification${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, params = {}) {
      return api.get(pushNotification.url(), {
        params: { ...params },
      });
    },
    show({ context }, id) {
      return api.get(pushNotification.url(id));
    },
    update({ context }, { id, payload }) {
      return api.patch(pushNotification.url(id), payload);
    },
    add({ context }, payload) {
      return api.post(pushNotification.url(), payload);
    },
    delete({ context }) {
      return api.delete(pushNotification.url(orderId));
    },
  },
};
