<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1">
          <template #right>
            <button v-if="false" class="btn btn-primary">Request For New Category</button>
          </template>
        </pageaddheader>
        <!-- <RequestCategory /> -->
        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <TapTarget :visible="showTapTarget" :target-selector="targets[currentTarget]" >
              <h2>Welcome to the App!</h2>
              <p>This is a simple tap target for walkthroughs.</p>
              <button @click="switchTarget()">Got it!</button>
            </TapTarget> -->

            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group" id="productName">
                  <label>Product Name</label>
                  <input required class="form-control" v-model="product.name" type="text" name="name" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group" id="productCategory">
                  <label>Category</label>
                  <vue-select required aria-required="true" v-model="product.category_id" :options="Category"
                    placeholder="Choose Category" name="category" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Sub Category</label>
                  <vue-select required :options="SubCategory" v-model="product.sub_category_id"
                    placeholder="Choose Sub Category" name="sub_category">
                  </vue-select>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Brand</label>
                  <vue-select required v-model="product.brand_id" :options="Brand" placeholder="Choose Brand"
                    name="brand" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Unit</label>
                  <vue-select :options="Units" v-model="product.unit" placeholder="Choose Unit" name="unit" required />
                </div>
              </div>
              <!-- qty -->
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('C', 'BC')">
                <div class="form-group">
                  <label>Available Crates</label>
                  <input required class="form-control" v-model="product.available_crates" type="number" min="0"
                    name="available_crates" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('B', 'BC')">
                <div class="form-group">
                  <label>Available Bottles</label>
                  <b-form-input required :value="product.available_bottles + product.excess_bottles" type="number"
                    min="0" readonly name="available_bottles" v-if="unitIs('BC')" />
                  <b-form-input required v-model="product.available_bottles" type="number" min="0"
                    name="available_bottles" v-else />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('BC')">
                <div class="form-group">
                  <label>Excess Bottle</label>
                  <input required class="form-control" v-model="product.excess_bottles" type="number" min="0"
                    name="excess_bottles" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('BC')">
                <div class="form-group">
                  <label>Bottles Per Crate</label>
                  <b-form-input required v-model="product.qty_per_crate" type="number" min="0" name="qty_per_crate" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Expiry Date</label>
                  <b-form-input required v-model="product.expiry_date" type="date" name="expiry_date" />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Min Qty Threshold</label>
                  <b-form-input required v-model="product.min_qty_threshold" type="number" min="0"
                    name="min_qty_threshold" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Max Qty Threshold</label>
                  <b-form-input required v-model="product.max_qty_threshold" type="number" min="0"
                    name="max_qty_threshold" />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <b-form-textarea required id="textarea" rows="3" max-rows="6" v-model="product.description"
                    name="description"></b-form-textarea>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('C', 'BC')">
                <div class="form-group">
                  <label>Crate Price</label>
                  <b-form-input step="any" required v-model="product.crate_price" name="crate_price" type="number"
                    min="0" placeholder="Crate Price" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('B', 'BC')">
                <div class="form-group">
                  <label>Bottle Price</label>
                  <b-form-input step="any" required v-model="product.bottle_price" name="bottle_price" type="number"
                    min="0" placeholder="Bottle Price" />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="false">
                <div class="form-group">
                  <label>Wob Price</label>
                  <input-switch name="wob_price" label="WOB Price" v-model:value="product.wob_price"
                    v-model:checked="product.has_crate" :required="product.has_crate" :disabled="!product.has_crate">
                  </input-switch>
                </div>
              </div>
              <a-tabs v-model:activeKey="activeKey" tab-position="top" animated class="mb-5" id="priceVariant"
                style="min-height:150px">
                <template #rightExtra>
                  <a-dropdown-button @click="handleButtonClick">
                    <div>
                      <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        More Options
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item">
                          <a-checkbox v-model:checked="product.extras.has_user_prices">User Price</a-checkbox>
                        </a>
                        <a class="dropdown-item">
                          <a-checkbox v-model:checked="product.extras.has_price_variants">Price
                            Variation</a-checkbox>
                        </a>
                      </div>
                    </div>
                  </a-dropdown-button>
                </template>
                <a-tab-pane key="cratePrices" tab="User Prices" v-if="product.extras.has_user_prices">
                  <div class="col-12 mb-3">
                    <b-table :items="product?.user_prices" :fields="userPriceFields" bordered striped>
                      <template #cell(account_type)="data">
                        <vue-select :required="!data.item.isSample" v-model="data.item.account_type_id"
                          placeholder="Select Account Type" class="w-100" :options="unsetAccountTypes"
                          v-if="data.item.isSample" />
                        <span v-else> {{ data.item.account_type.name }}</span>
                      </template>
                      <template #cell(required_qty)="data">
                        <b-form-input :required="!data.item.isSample" v-model="data.item.required_qty" type="number"
                          min="0" :name="`${data.index}_qty`" class="form-control" />
                      </template>
                      <template #cell(price)="data">
                        <b-form-input step="any" :required="!data.item.isSample" v-model="data.item.price" type="number"
                          min="0" :name="`${data.index}_price`" class="form-control" />
                      </template>
                      <template #cell(action)="data">
                        <a v-if="data.item.isSample" class="confirm-text" href="javascript:void(0);" @click="showAlert">
                          <button class="btn btn-primary " @click="addUserPrice">Add</button>
                        </a>
                        <a v-else class="confirm-text" href="javascript:void(0);" @click="removeUserPrice(data.index)">
                          <img src="../../../assets/img/icons/delete.svg" alt="img" />
                        </a>
                      </template>
                    </b-table>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="priceVariant" tab="Price Variation" v-if="product.extras.has_price_variants">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12 pe-md-4 pe-lg-4"
                      :style="{ borderRight: '1px solid rgba(0,0,0,0.1)' }">
                      <a-typography-text strong class="my-4 b-block">
                        Add Price Variation
                      </a-typography-text>
                      <div class="row my-3">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Name</label>
                            <input v-model="priceVariant.name" class="form-control form-control-sm" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Price</label>
                            <input v-model="priceVariant.price" step="any" class="form-control form-control-sm"
                              type="number" min="0" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Unit</label>
                            <a-select v-model:value="priceVariant.unit" class="w-100" :options="variantUnits" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Quantity</label>
                            <input v-model="priceVariant.qty" step="any" class="form-control form-control-sm"
                              type="number" min="0" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                          <button class="btn btn-sm btn-primary" @click="addpriceVariant()">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12  overflow-hidden ">
                      <div class="row gap-2 overflow-scroll overflow-x-hidden p-3" style="max-height: 260px;">
                        <Badge outlined v-for="(price, index) in product.price_variants"
                          @clicked="removepriceVariant(index)" right-icon="typcn typcn-delete" title="Click To Delete">
                          {{ `${price.name}(${formatAmount(price.price, true)})` }}<br />
                          {{ `${price.qty} ${price.unit}(s)` }}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
              <!-- EXTRA END -->

              <div class="col-lg-12">
                <div class="form-group">
                  <label> Product Image</label>
                  <div class="image-upload">
                    <input required multiple name="image" accept="image/png, image/jpeg"
                      @input="pickFile($event.target.files)" type="file" />
                    <div class="image-uploads">
                      <img src="../../../assets/img/icons/upload.svg" alt="img" />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product-list">
                  <ul class="row">
                    <li v-for="(image, index) in images" :key="index">
                      <div class="productviews">
                        <div class="productviewsimg">
                          <img :src="image.src" alt="img" />
                        </div>
                        <div class="productviewscontent">
                          <div class="productviewsname">
                            <h2>{{ image.name }}</h2>
                            <h3>{{ image.size }}KB</h3>
                          </div>
                          <a href="javascript:void(0);" class="hideset" v-on:click="deleteImage(index)">x</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12">
                <button disabled id="submit" v-on:click="addProduct" href="javascript:void(0);"
                  class="btn btn-submit me-2">
                  Submit
                </button>
                <router-link to="/products" class="btn btn-cancel">Cancel</router-link>
              </div>

            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import RequestCategory from "@/components/modals/request-category.vue";
import TapTarget from "@/components/tap-target.vue";
import Badge from "@/components/Badge.vue";
import store from "@/store";
import { Product } from "@/models/products";
import { checkInputValidity } from "@/helpers/validator";
import { formatAmount } from "@/helpers";

// hooks
const router = useRouter();
// data
const units = {
  onlyBottle: "Bottle",
  onlyCrate: "Crate",
  bottleAndCrate: "Bottle & Crate",
}
const Units = ref(Object.values(units));
const variantUnits = computed(() => {
  if (unitIs('BC')) {
    return [{ value: 'Bottle' }, { value: 'Crate' }];
  } else {
    return [{ value: product.unit }];
  }
});

const title = ref("Create Product");
const title1 = ref("Create new product");
const Category = ref([{ text: "Choose Category", id: 0, selected: true }]);
const SubCategory = ref([{ text: "Choose Sub Category", id: 0, selected: true }]);
const Brand = ref([{ text: "Choose Brand", id: 0, selected: true }]);
const accountTypes = ref([]);
/** Account Types that have no Price Assigned to them */
const unsetAccountTypes = computed(() => {
  return accountTypes.value.filter((account_type) => !product.user_prices.some((price) => !price.isSample && price.account_type_id == account_type.id));
});

const product = reactive(new Product());
const images = ref([]);

const userPriceFields = ref([
  { key: "account_type", label: "User" },
  { key: "required_qty", label: "Quantity" },
  { key: "price", label: "Price" },
  { key: "action", label: "Action" },
]);
const priceVariant = ref({
  name: null,
  price: null,
  unit: null,
  qty: null,
});


// Methods
/**
 * @typedef {'C' | 'B' | 'BC'} Unit
 */

/**
 * Checks if a product's unit matches any of the specified units.
 *
 * @param {...Unit} _units - A list of unit identifiers to check against the product's unit.
 *                           Valid identifiers are:
 *                           - 'C' for onlyCrate
 *                           - 'B' for onlyBottle
 *                           - 'BC' for bottleAndCrate
 * @returns {boolean} - Returns true if the product's unit matches any of the specified units, otherwise false.
 */
const unitIs = (..._units) => {
  const unitsMapping = {
    C: units.onlyCrate,
    B: units.onlyBottle,
    BC: units.bottleAndCrate,
  };
  return _units.some((unit) => unitsMapping[unit] == product.unit);
}
const getAccountTypes = async () => {
  const response = await store.dispatch("accountType/get");
  accountTypes.value = response.data.data.map((account_type) => {
    return {
      text: account_type.name,
      id: account_type.id,
    };
  });
};

const getCategories = async () => {
  const response = await store.dispatch("category/get");
  response.data.data.map((category) => {
    Category.value.push({
      text: category.name,
      id: category.id,
    });
  });
};

const getBrands = async () => {
  const response = await store.dispatch("brand/get");
  response.data.data.map((brand) => {
    Brand.value.push({
      text: brand.name,
      id: brand.id,
    });
  });
};

const getSubCategories = async (category_Id) => {
  const response = await store.dispatch("subcategory/get", { category_id: category_Id });
  SubCategory.value = response.data.data.map((subcategory) => {
    return {
      text: subcategory.name,
      id: subcategory.id,
    };
  });
};

const deleteImage = (index) => {
  images.value.splice(index, 1);
  product.images.splice(index, 1);
};

const pickFile = (files) => {
  for (let index = 0; index < files.length; index++) {
    let reader = new FileReader();
    const file = files[index];
    reader.onload = (e) => {
      images.value.push({
        src: e.target.result,
        name: file.name,
        size: Math.floor(e.total / 1000),
      });
    };
    reader.readAsDataURL(file);
    product.images.push(file);
  }
};

const addProduct = async () => {
  const response = await store.dispatch("product/add", { payload: product.prepData() });
  Swal.fire("Product Added", "");
  Object.assign(product, new Product());
  router.push({ name: "productlist" });
};

const checkValidity = () => {
  checkInputValidity("submit");
};


const addpriceVariant = () => {
  product.price_variants.push(priceVariant.value);
  priceVariant.value = {};
};
const removepriceVariant = (index) => {
  product.price_variants.splice(index, 1);
};

const addUserPrice = () => {
  const data = product.user_prices[0];
  const selectedAccountType = accountTypes.value.find((accountType) => accountType.id == data.account_type_id);
  console.log(selectedAccountType, data)
  product.user_prices.push({
    account_type: { name: selectedAccountType.text },
    account_type_id: data.account_type_id,
    price: data.price,
    required_qty: data.required_qty
  });
  // reset
  data.price = 1;
  data.required_qty = 1;
  data.account_type_id = null;

};
const removeUserPrice = (index) => {
  product.user_prices.splice(index, 1);
};


// Watch
watch(product, (newVal) => {
  if (unitIs('BC')) {
    newVal.available_bottles = newVal.qty_per_crate * newVal.available_crates;
  }

  setTimeout(() => {
    checkValidity();
  }, 2);
}, { deep: true });

watch(() => product.category_id, (newCategoryId) => {
  getSubCategories(newCategoryId);
}, { deep: true });

// Lifecycle hooks
onMounted(() => {
  getCategories();
  getBrands();
  getAccountTypes();
});
</script>

<style>
.productviewsname h2 {
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
}
</style>
