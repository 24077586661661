<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>Role Name</label>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 col-12 d-flex gap-2">
                      <div class="col-lg-8 d-flex form-control p-0 align-content-center">
                        <span v-if="isVendor" class="custom-role-name-prefix">{{
                          adminProfile.store_name
                        }}</span>

                        <input v-model="newRole.name" placeholder="Enter Role Name" class="custom-role-name-input" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-12">
                <label>Order Status</label>
                <vue-select :options="orderStatuses" placeholder="Status Of Order the Place"
                  v-model="newRole.settings.ORDER_STATUS" />
              </div>
            </div>
            <div class="mx-1 row mb-3" v-if="!isSuperAdmin">
              <f-table :filters="filters" :data="permissions" :columns="permissionsColumns">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key == 'action'">
                    <label class="inputcheck">
                      <input :value="record.id" v-model="newRole.permissions" type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </template>
                </template>
              </f-table>
            </div>

            <div class="col-lg-12">
              <a v-on:click="addRole" href="javascript:void(0);" class="btn btn-submit me-2">Submit</a>
              <router-link to="/categorylist" class="btn btn-cancel">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { router } from "@/router";
import store from "@/store";
import { OrderStatuses } from "@/models/order";
const permissionsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  { title: "Action", key: "action" },
];
export default {
  data() {
    return {
      title: "Add Role",
      title1: "Add New Admin Role",
      filters: ["name", "description"],
      permissions: [],
      newRole: {
        settings: {
          ORDER_STATUS: null
        },
        permissions: [],
        name: null,
      },
      permissionsColumns,
      orderStatuses: [{ id: 'Default', text: "Default" }, ...Object.values(OrderStatuses)],
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
      isVendor: "isVendor",
    }),
    ...mapState({
      adminProfile: "adminProfile",
    }),
  },
  methods: {
    ...mapActions({
      _addRole: "adminRole/add",
      _getPermissions: "adminPermission/get",
    }),
    processSettings(settings) {
      return Object.entries(settings)
        .filter(([, value]) => value !== 'Default')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    },
    addRole() {
      let payload = { ...this.newRole };
      payload.settings = this.processSettings(this.newRole.settings);
      this._addRole({ payload: payload }).then((response) => {
        Swal.fire({ title: "Role Added", icon: "success" });
        router.push({ name: "admin-role" });
      });
    },
    getPermissions() {
      this._getPermissions().then((response) => {
        this.permissions = response.data.data;
      });
    },
  },
  mounted() {
    this.getPermissions();
  },
  name: "add-role",
};
</script>
