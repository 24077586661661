<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <div class="card" v-if="data">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>Category Name</label>
                  <input type="text" v-model="data.name" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>Category Code</label>
                  <input type="text" v-model="data.code" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    v-model="data.description"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label> Product Image</label>
                  <div class="image-upload">
                    <input @change="pickImage($event.target)" type="file" />
                    <div class="image-uploads">
                      <img
                        src="@/assets/img/icons/upload.svg"
                        alt="img"
                      />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="image" class="col-12">
                <div class="product-list">
                  <ul class="row">
                    <li
                      class="ps-0"
                      :style="{
                        display: filter ? 'block' : 'none',
                      }"
                    >
                      <div class="productviews">
                        <div class="productviewsimg">
                          <img :src="image.src" alt="img" />
                        </div>
                        <div class="productviewscontent">
                          <div class="productviewsname">
                            <h2>{{ image.name }}</h2>
                            <h3>{{ image.size }}</h3>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="hideset"
                            v-on:click="filter = !filter"
                            >x</a
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12">
                <a
                  v-on:click="updateCategory"
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                  >Submit</a
                >
                <router-link to="/categorylist" class="btn btn-cancel"
                  >Cancel</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import { router } from "@/router";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      category_id: this.$route.params.id,
      image: null,
      data: {
        name: "Loading...",
        code: "Loading...",
        description: "Loading...",
        image: null,
      },
      filter: true,
      title: "Product Edit Category",
      title1: "Edit a product Category",
    };
  },
  methods: {
    ...mapActions({
      _getCategory: "category/get",
      _updateCategory: "category/update",
    }),
    getCategory() {
      this._getCategory(this.category_id).then((response) => {
        let category = response.data.data;
        this.data = {
          name: category.name,
          code: category.code,
          description: category.description,
        };
        this.image = {
          src: category.image,
          name: category.name,
          size: "",
        };
      });
    },
    updateCategory() {
      this._updateCategory({
        category_id: this.category_id,
        payload: this.data,
      })
        .then((response) => {
          Swal.fire("Category Updated");
          router.push({ name: "categorylist" });
        })
        .catch((error) => {
          Swal.fire("Unable to Update Category");
        });
    },
    pickImage(event) {
      let file = event.files[0];
      this.data.image = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = {
          src: e.target.result,
          name: file.name,
          size: Math.floor(e.total / 1000),
        };
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    this.getCategory();
  },
  name: "editcategory",
};
</script>
