<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Basic Inputs</h5>
              </div>
              <div class="card-body">
                <b-form action="#">
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Text Input</label>
                    <div class="col-md-10">
                      <b-form-input type="text" class="form-control"></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Password</label>
                    <div class="col-md-10">
                      <b-form-input type="password" class="form-control"></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Disabled Input</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control"
                        disabled="disabled"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Readonly Input</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control"
                        value="readonly"
                        disabled="disabled"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Placeholder</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Placeholder"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">File Input</label>
                    <div class="col-md-10">
                      <b-form-input class="form-control" type="file"></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Default Select</label>
                    <div class="col-md-10">
                      <vue-select :options="Select" placeholder="-- Select --" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Radio</label>
                    <div class="col-md-10">
                      <div class="radio">
                        <label> <input type="radio" name="radio" /> Option 1 </label>
                      </div>
                      <div class="radio">
                        <label> <input type="radio" name="radio" /> Option 2 </label>
                      </div>
                      <div class="radio">
                        <label> <input type="radio" name="radio" /> Option 3 </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Checkbox</label>
                    <div class="col-md-10">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" /> Option 1
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" /> Option 2
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" /> Option 3
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Textarea</label>
                    <div class="col-md-10">
                      <b-form-textarea
                        id="textarea"
                        v-model="text"
                        rows="5"
                        max-rows="6"
                        placeholder="Enter text here"
                      ></b-form-textarea>
                    </div>
                  </div>
                  <div class="form-group mb-0 row">
                    <label class="col-form-label col-md-2">Input Addons</label>
                    <div class="col-md-10">
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <b-form-input class="form-control" type="text"></b-form-input>
                        <b-button variant="primary">Button</b-button>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Input Sizes</h5>
              </div>
              <div class="card-body">
                <b-form action="#">
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Large Input</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control form-control-lg"
                        placeholder=".form-control-lg"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Default Input</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder=".form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group mb-0 row">
                    <label class="col-form-label col-md-2">Small Input</label>
                    <div class="col-md-10">
                      <b-form-input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder=".form-control-sm"
                      ></b-form-input>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Select: [
        "-- Select --",
        "Option 1",
        "Option 2",
        "Option 3",
        "Option 4",
        "Option 5",
      ],
      title: "Basic Inputs",
      text: "Dashboard",
      text1: "Basic Inputs",
      name: "/index",
    };
  },

  name: "form-basic-inputs",
};
</script>
