<template>
 <!-- Main Wrapper -->
 <div class="main-wrapper">
    <layoutstwo></layoutstwo>
    <div class="container">
            <indextwosidebar></indextwosidebar>
        
        <div class="page-wrapper page-wrapper-two">
            <div class="content">
                
                <indexpage></indexpage>
                
            </div>
        </div>
    </div>

</div>
</template>