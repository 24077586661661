<template>
  <!-- show payment Modal -->
  <div
    class="modal fade"
    id="orderError"
    tabindex="-1"
    aria-labelledby="orderError"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white">
          <h5 class="modal-title">
            <div class="d-flex align-center gap-1">
              <vue-feather type="info"></vue-feather>
              <h3>
                You cannot place Order for this User.
              </h3>
            </div>
          </h5>
          <button
            type="button"
            class="close text-white"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="error-holder">
            <p class="text-danger">
              Please inform the User to Pay a minimum of
              <b class="fw-bold">{{ formatAmount(minAmount2Pay, true) }}</b>
              to clear his/her previous dues
            </p>
          </div>

          <div class="mt-3">
            <h4 class="text-muted my-3">Due Report</h4>
            <FilterTable
              :table-height="'auto'"
              :columns="columns"
              :data="dueReports"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'reference'">
                  <router-link
                    title="Click to View Order"
                    :to="'orders/' + record.id"
                    target="_blank"
                    >{{ record.reference }}</router-link
                  >
                </template>
                <template v-if="column.key == 'due'">
                  {{ formatAmount(record.due, true) }}
                </template>
              </template>
            </FilterTable>
            <p class="text-muted">
              If you believe this is an error, please contact your system
              administrator.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button primary class="btn btn-cancel" @click="closeModal()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import FilterTable from "../../table/filter-table.vue";
import { formatAmount, getMonthName } from "@/helpers";
const columns = ref([
  {
    title: "Reference",
    key: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Due",
    key: "due",
    sorter: {
      compare: (a, b) => {
        a = a.due;
        b = b.due;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
]);

// props
const props = defineProps({
  dueReports: Array,
  minAmount2Pay: Number,
});

function resetData() {}
function closeModal() {
  $("#orderError").modal("hide");
  resetData();
}
onMounted(() => {
  $("#orderError").on("shown.bs.modal", (event) => {
    resetData();
  });
});
</script>

<style>
.error-holder {
  background: #dc354621;
  border-left: 5px solid #dc3545;
  padding: 15px;
}
</style>
