<template>
  <!-- show payment Modal -->
  <div class="modal fade" id="showDetails" data-bs-focus="false" tabindex="-1" aria-labelledby="createpayment"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Credit Limit Increase Request</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Customer</label>
                <input type="text" :value="selectedLimitIncrease.customer_name" readonly />

              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Current Limit</label>
                <input type="text" readonly :value="selectedLimitIncrease.current_limit" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Debt Health</label>
                <input type="text" readonly :value="selectedLimitIncrease.debt_health" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Requested Amount</label>
                <input type="text" readonly :value="selectedLimitIncrease.requested_amount" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment Date</label>
                <div class="input-groupicon">
                  <input class="form-control" readonly :value="selectedLimitIncrease.payment_date" />
                  <div class="addonset">
                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                  </div>
                  
                </div>
              </div>
            </div>


            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Requested By</label>
                <input type="text" readonly :value="selectedLimitIncrease.admin_name" />
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->

</template>

<script>
import store from "@/store";
import { _sale } from "@/store/models";
import { ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
const currentDate = ref(new Date());
export default {
  props: {
    selectedLimitIncrease: Object,
  },
  data() {
    return {
    };
  },
  name: "limitincreasemodal"
};
</script>
