/* eslint-disable prettier/prettier */

export let _sale = {
  id: "Loading...",
  payment_status: "Loading...",
  status: "Loading...",
  total: 0,
  sub_total: 0,
  date: "Loading...",
  tax: "Loading...",
  discount: "Loading...",
  shipping: null,
  invoice: {
    reference: "Loading...",
    payment_status: "Loading...",
    status: "Loading...",
  },
  user: {
    id: 1,
    name: "Loading...",
    email: "Loading...",
    mobile_number: "Loading...",
    address: "Loading...",
  },
  reference: "Loading...",
  products: [
    {
      id: "Loading...",
      image: "Loading...",
      name: "Loading...",
      price: "Loading...",
      tax: "Loading...",
      qty: 5,
      discount: "Loading...",
      sub_total: "Loading...",
    },
  ],
};

export let _one_sale = {
  id: "Loading...",
  Date: "Loading...",
  CustomerName: "Loading...",
  Reference: "Loading...",
  Status: "Loading...",
  Payment: "Loading...",
  Total: "Loading...",
  Paid: "Loading...",
};

export let _user = {
  name: "Loading...",
  account_number: "xxxxxxxxx",
  balance: null,
  due: null,
};
