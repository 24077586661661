<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="profile-set">
              <div class="profile-head"></div>
              <div class="profile-top">
                <div class="profile-content">
                  <div class="profile-contentimg">
                    <img style="height: 100px; width: 100px; overflow: hidden" :src="imagePreview" alt="img"
                      id="blah" />
                    <div class="profileupload">
                      <input type="file" id="imgInp" @change="pickFile($event.target)" />
                      <a href="javascript:void(0);"><img src="../../assets/img/icons/edit-set.svg" alt="img" /></a>
                    </div>
                  </div>
                  <div class="profile-contentname">
                    <h2>
                      {{ adminData.first_name + " " + adminData.last_name }}
                    </h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" v-model="adminData.first_name" placeholder="William" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" v-model="adminData.last_name" placeholder="Castilo" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="adminData.email" placeholder="william@example.com" />
                </div>
              </div>

              <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                  <label>Password</label>
                  <div class="pass-group">
                    <input v-if="showPassword" type="text" class="pass-input" v-model="adminData.password" />
                    <input v-else type="password" class="pass-input" v-model="adminData.password" />
                    <span class="fas toggle-password" @click="toggleShow" :class="{
          'fa-eye': showPassword,
          'fa-eye-slash': !showPassword,
        }"></span>
                  </div>
                </div>
              </div>
              <div v-if="adminData.is_vendor">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Business Names</label>
                    <input type="text" v-model="adminData.store_name" placeholder="william@example.com" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <a @click="updateProfile" href="javascript:void(0);" class="btn btn-submit me-2">Submit</a>
                <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import store from '../../store';
export default {
  data() {
    return {
      title: "Profile",
      title1: "User Profile",
      showPassword: false,
      password: null,
      imagePreview: null,
      adminData: {},
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    ...mapActions({
      _updateProfile: "updateProfile",
    }),
    ...mapMutations({
      _storeUser: "storeUser",
      _showLoader: "showLoader",
    }),
    updateProfile() {
      this._showLoader({ title: "Updating Profile" });
      this._updateProfile({ payload: this.adminData })
        .then((response) => {
          this._storeUser(JSON.stringify(response.data.data));
          Swal.fire({
            title: "Profile Updated",
            icon: "success",
          });
          window.location.reload();
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error"
          });
        });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    pickFile(target) {
      let file = target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.adminData.profile_picture = file;
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    let details = store.state.adminProfile;
    this.adminData = {
      first_name: details.first_name,
      last_name: details.last_name,
      last_name: details.last_name,
      email: details.email,
      store_name: details.store_name,
      is_vendor: details.account_type.name == "Vendor",
      profile_picture: null,
      password: null,
    };
    this.imagePreview = details.profile_picture;
  },
  name: "profile",
};
</script>
