<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">Ionic Icon</div>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <i
                        class="ion ion-ionic"
                        data-bs-toggle="tooltip"
                        title="ion-ionic"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-up-a"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-up-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-right-a"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-right-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-down-a"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-down-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-left-a"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-left-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-up-b"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-up-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-right-b"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-right-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-down-b"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-down-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-left-b"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-left-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-up-c"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-up-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-right-c"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-right-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-down-c"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-down-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-left-c"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-left-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-return-right"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-return-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-return-left"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-return-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-swap"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-swap"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-shrink"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-shrink"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-expand"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-expand"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-move"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-move"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-resize"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-resize"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chevron-up"
                        data-bs-toggle="tooltip"
                        title="ion-chevron-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chevron-right"
                        data-bs-toggle="tooltip"
                        title="ion-chevron-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chevron-down"
                        data-bs-toggle="tooltip"
                        title="ion-chevron-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chevron-left"
                        data-bs-toggle="tooltip"
                        title="ion-chevron-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-navicon-round"
                        data-bs-toggle="tooltip"
                        title="ion-navicon-round"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-navicon"
                        data-bs-toggle="tooltip"
                        title="ion-navicon"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-drag"
                        data-bs-toggle="tooltip"
                        title="ion-drag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-log-in"
                        data-bs-toggle="tooltip"
                        title="ion-log-in"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-log-out"
                        data-bs-toggle="tooltip"
                        title="ion-log-out"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-checkmark-round"
                        data-bs-toggle="tooltip"
                        title="ion-checkmark-round"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-checkmark"
                        data-bs-toggle="tooltip"
                        title="ion-checkmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-checkmark-circled"
                        data-bs-toggle="tooltip"
                        title="ion-checkmark-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-close-round"
                        data-bs-toggle="tooltip"
                        title="ion-close-round"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-close"
                        data-bs-toggle="tooltip"
                        title="ion-close"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-close-circled"
                        data-bs-toggle="tooltip"
                        title="ion-close-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-plus-round"
                        data-bs-toggle="tooltip"
                        title="ion-plus-round"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-plus"
                        data-bs-toggle="tooltip"
                        title="ion-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-plus-circled"
                        data-bs-toggle="tooltip"
                        title="ion-plus-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-minus-round"
                        data-bs-toggle="tooltip"
                        title="ion-minus-round"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-minus"
                        data-bs-toggle="tooltip"
                        title="ion-minus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-minus-circled"
                        data-bs-toggle="tooltip"
                        title="ion-minus-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-information"
                        data-bs-toggle="tooltip"
                        title="ion-information"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-information-circled"
                        data-bs-toggle="tooltip"
                        title="ion-information-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-help"
                        data-bs-toggle="tooltip"
                        title="ion-help"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-help-circled"
                        data-bs-toggle="tooltip"
                        title="ion-help-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-help-buoy"
                        data-bs-toggle="tooltip"
                        title="ion-help-buoy"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-asterisk"
                        data-bs-toggle="tooltip"
                        title="ion-asterisk"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-alert"
                        data-bs-toggle="tooltip"
                        title="ion-alert"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-alert-circled"
                        data-bs-toggle="tooltip"
                        title="ion-alert-circled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-refresh"
                        data-bs-toggle="tooltip"
                        title="ion-refresh"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-loop"
                        data-bs-toggle="tooltip"
                        title="ion-loop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-shuffle"
                        data-bs-toggle="tooltip"
                        title="ion-shuffle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-home"
                        data-bs-toggle="tooltip"
                        title="ion-home"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-search"
                        data-bs-toggle="tooltip"
                        title="ion-search"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-flag"
                        data-bs-toggle="tooltip"
                        title="ion-flag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-star"
                        data-bs-toggle="tooltip"
                        title="ion-star"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-heart"
                        data-bs-toggle="tooltip"
                        title="ion-heart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-heart-broken"
                        data-bs-toggle="tooltip"
                        title="ion-heart-broken"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-gear-a"
                        data-bs-toggle="tooltip"
                        title="ion-gear-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-gear-b"
                        data-bs-toggle="tooltip"
                        title="ion-gear-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-toggle-filled"
                        data-bs-toggle="tooltip"
                        title="ion-toggle-filled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-toggle"
                        data-bs-toggle="tooltip"
                        title="ion-toggle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-settings"
                        data-bs-toggle="tooltip"
                        title="ion-settings"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-wrench"
                        data-bs-toggle="tooltip"
                        title="ion-wrench"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-folder"
                        data-bs-toggle="tooltip"
                        title="ion-folder"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-hammer"
                        data-bs-toggle="tooltip"
                        title="ion-hammer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-edit"
                        data-bs-toggle="tooltip"
                        title="ion-edit"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-trash-a"
                        data-bs-toggle="tooltip"
                        title="ion-trash-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-trash-b"
                        data-bs-toggle="tooltip"
                        title="ion-trash-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-document"
                        data-bs-toggle="tooltip"
                        title="ion-document"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-document-text"
                        data-bs-toggle="tooltip"
                        title="ion-document-text"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-clipboard"
                        data-bs-toggle="tooltip"
                        title="ion-clipboard"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-scissors"
                        data-bs-toggle="tooltip"
                        title="ion-scissors"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-funnel"
                        data-bs-toggle="tooltip"
                        title="ion-funnel"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-bookmark"
                        data-bs-toggle="tooltip"
                        title="ion-bookmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-email"
                        data-bs-toggle="tooltip"
                        title="ion-email"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-folder"
                        data-bs-toggle="tooltip"
                        title="ion-folder"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-filing"
                        data-bs-toggle="tooltip"
                        title="ion-filing"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-archive"
                        data-bs-toggle="tooltip"
                        title="ion-archive"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-reply"
                        data-bs-toggle="tooltip"
                        title="ion-reply"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-reply-all"
                        data-bs-toggle="tooltip"
                        title="ion-reply-all"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-forward"
                        data-bs-toggle="tooltip"
                        title="ion-forward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-share"
                        data-bs-toggle="tooltip"
                        title="ion-share"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-paper-airplane"
                        data-bs-toggle="tooltip"
                        title="ion-paper-airplane"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-link"
                        data-bs-toggle="tooltip"
                        title="ion-link"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-paperclip"
                        data-bs-toggle="tooltip"
                        title="ion-paperclip"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-compose"
                        data-bs-toggle="tooltip"
                        title="ion-compose"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-briefcase"
                        data-bs-toggle="tooltip"
                        title="ion-briefcase"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-medkit"
                        data-bs-toggle="tooltip"
                        title="ion-medkit"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-at" data-bs-toggle="tooltip" title="ion-at"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pound"
                        data-bs-toggle="tooltip"
                        title="ion-pound"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-quote"
                        data-bs-toggle="tooltip"
                        title="ion-quote"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-cloud"
                        data-bs-toggle="tooltip"
                        title="ion-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-upload"
                        data-bs-toggle="tooltip"
                        title="ion-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-more"
                        data-bs-toggle="tooltip"
                        title="ion-more"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-grid"
                        data-bs-toggle="tooltip"
                        title="ion-grid"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-calendar"
                        data-bs-toggle="tooltip"
                        title="ion-calendar"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-clock"
                        data-bs-toggle="tooltip"
                        title="ion-clock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-compass"
                        data-bs-toggle="tooltip"
                        title="ion-compass"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pinpoint"
                        data-bs-toggle="tooltip"
                        title="ion-pinpoint"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-pin" data-bs-toggle="tooltip" title="ion-pin"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-navigate"
                        data-bs-toggle="tooltip"
                        title="ion-navigate"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-location"
                        data-bs-toggle="tooltip"
                        title="ion-location"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-map" data-bs-toggle="tooltip" title="ion-map"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-model-s"
                        data-bs-toggle="tooltip"
                        title="ion-model-s"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-locked"
                        data-bs-toggle="tooltip"
                        title="ion-locked"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-unlocked"
                        data-bs-toggle="tooltip"
                        title="ion-unlocked"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-key" data-bs-toggle="tooltip" title="ion-key"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-graph-up-right"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-graph-up-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-graph-down-right"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-graph-down-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-arrow-graph-down-left"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-graph-down-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-stats-bars"
                        data-bs-toggle="tooltip"
                        title="ion-stats-bars"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-connection-bars"
                        data-bs-toggle="tooltip"
                        title="ion-connection-bars"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pie-graph"
                        data-bs-toggle="tooltip"
                        title="ion-pie-graph"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatbubble"
                        data-bs-toggle="tooltip"
                        title="ion-chatbubble"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatbubble-working"
                        data-bs-toggle="tooltip"
                        title="ion-chatbubble-working"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatbubbles"
                        data-bs-toggle="tooltip"
                        title="ion-chatbubbles"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatbox"
                        data-bs-toggle="tooltip"
                        title="ion-chatbox"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatbox-working"
                        data-bs-toggle="tooltip"
                        title="ion-chatbox-working"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-chatboxes"
                        data-bs-toggle="tooltip"
                        title="ion-chatboxes"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-person"
                        data-bs-toggle="tooltip"
                        title="ion-person"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-person-add"
                        data-bs-toggle="tooltip"
                        title="ion-person-add"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-person-stalker"
                        data-bs-toggle="tooltip"
                        title="ion-person-stalker"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-woman"
                        data-bs-toggle="tooltip"
                        title="ion-woman"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-man" data-bs-toggle="tooltip" title="ion-man"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-female"
                        data-bs-toggle="tooltip"
                        title="ion-female"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-male"
                        data-bs-toggle="tooltip"
                        title="ion-male"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-fork"
                        data-bs-toggle="tooltip"
                        title="ion-fork"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-knife"
                        data-bs-toggle="tooltip"
                        title="ion-knife"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-spoon"
                        data-bs-toggle="tooltip"
                        title="ion-spoon"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-beer"
                        data-bs-toggle="tooltip"
                        title="ion-beer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-wineglass"
                        data-bs-toggle="tooltip"
                        title="ion-wineglass"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-coffee"
                        data-bs-toggle="tooltip"
                        title="ion-coffee"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-icecream"
                        data-bs-toggle="tooltip"
                        title="ion-icecream"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pizza"
                        data-bs-toggle="tooltip"
                        title="ion-pizza"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-power"
                        data-bs-toggle="tooltip"
                        title="ion-power"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-mouse"
                        data-bs-toggle="tooltip"
                        title="ion-mouse"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-battery-full"
                        data-bs-toggle="tooltip"
                        title="ion-battery-full"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-battery-half"
                        data-bs-toggle="tooltip"
                        title="ion-battery-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-battery-low"
                        data-bs-toggle="tooltip"
                        title="ion-battery-low"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-battery-empty"
                        data-bs-toggle="tooltip"
                        title="ion-battery-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-battery-charging"
                        data-bs-toggle="tooltip"
                        title="ion-battery-charging"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-bluetooth"
                        data-bs-toggle="tooltip"
                        title="ion-bluetooth"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-calculator"
                        data-bs-toggle="tooltip"
                        title="ion-calculator"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-camera"
                        data-bs-toggle="tooltip"
                        title="ion-camera"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-eye" data-bs-toggle="tooltip" title="ion-eye"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-eye-disabled"
                        data-bs-toggle="tooltip"
                        title="ion-eye-disabled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-flash"
                        data-bs-toggle="tooltip"
                        title="ion-flash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-flash-off"
                        data-bs-toggle="tooltip"
                        title="ion-flash-off"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-qr-scanner"
                        data-bs-toggle="tooltip"
                        title="ion-qr-scanner"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-image"
                        data-bs-toggle="tooltip"
                        title="ion-image"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-images"
                        data-bs-toggle="tooltip"
                        title="ion-images"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-contrast"
                        data-bs-toggle="tooltip"
                        title="ion-contrast"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-wand"
                        data-bs-toggle="tooltip"
                        title="ion-wand"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-aperture"
                        data-bs-toggle="tooltip"
                        title="ion-aperture"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-monitor"
                        data-bs-toggle="tooltip"
                        title="ion-monitor"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-laptop"
                        data-bs-toggle="tooltip"
                        title="ion-laptop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ipad"
                        data-bs-toggle="tooltip"
                        title="ion-ipad"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-iphone"
                        data-bs-toggle="tooltip"
                        title="ion-iphone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ipod"
                        data-bs-toggle="tooltip"
                        title="ion-ipod"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-printer"
                        data-bs-toggle="tooltip"
                        title="ion-printer"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-usb" data-bs-toggle="tooltip" title="ion-usb"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-outlet"
                        data-bs-toggle="tooltip"
                        title="ion-outlet"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-bug" data-bs-toggle="tooltip" title="ion-bug"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-code"
                        data-bs-toggle="tooltip"
                        title="ion-code"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-code-working"
                        data-bs-toggle="tooltip"
                        title="ion-code-working"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-code-download"
                        data-bs-toggle="tooltip"
                        title="ion-code-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-fork-repo"
                        data-bs-toggle="tooltip"
                        title="ion-fork-repo"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-network"
                        data-bs-toggle="tooltip"
                        title="ion-network"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pull-request"
                        data-bs-toggle="tooltip"
                        title="ion-pull-request"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-merge"
                        data-bs-toggle="tooltip"
                        title="ion-merge"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios-game-controller-a-outline"
                        data-bs-toggle="tooltip"
                        title="ion-game-controller-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios-game-controller-b-outline"
                        data-bs-toggle="tooltip"
                        title="ion-game-controller-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-xbox"
                        data-bs-toggle="tooltip"
                        title="ion-xbox"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-playstation"
                        data-bs-toggle="tooltip"
                        title="ion-playstation"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-steam"
                        data-bs-toggle="tooltip"
                        title="ion-steam"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-closed-captioning"
                        data-bs-toggle="tooltip"
                        title="ion-closed-captioning"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-videocamera"
                        data-bs-toggle="tooltip"
                        title="ion-videocamera"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-film-marker"
                        data-bs-toggle="tooltip"
                        title="ion-film-marker"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-disc"
                        data-bs-toggle="tooltip"
                        title="ion-disc"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-headphone"
                        data-bs-toggle="tooltip"
                        title="ion-headphone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-music-note"
                        data-bs-toggle="tooltip"
                        title="ion-music-note"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-radio-waves"
                        data-bs-toggle="tooltip"
                        title="ion-radio-waves"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-speakerphone"
                        data-bs-toggle="tooltip"
                        title="ion-speakerphone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-mic-a"
                        data-bs-toggle="tooltip"
                        title="ion-mic-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-mic-b"
                        data-bs-toggle="tooltip"
                        title="ion-mic-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-mic-c"
                        data-bs-toggle="tooltip"
                        title="ion-mic-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-volume-high"
                        data-bs-toggle="tooltip"
                        title="ion-volume-high"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-volume-medium"
                        data-bs-toggle="tooltip"
                        title="ion-volume-medium"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-volume-low"
                        data-bs-toggle="tooltip"
                        title="ion-volume-low"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-volume-mute"
                        data-bs-toggle="tooltip"
                        title="ion-volume-mute"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-levels"
                        data-bs-toggle="tooltip"
                        title="ion-levels"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-play"
                        data-bs-toggle="tooltip"
                        title="ion-play"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pause"
                        data-bs-toggle="tooltip"
                        title="ion-pause"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-stop"
                        data-bs-toggle="tooltip"
                        title="ion-stop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-record"
                        data-bs-toggle="tooltip"
                        title="ion-record"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-skip-forward"
                        data-bs-toggle="tooltip"
                        title="ion-skip-forward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-skip-backward"
                        data-bs-toggle="tooltip"
                        title="ion-skip-backward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-eject"
                        data-bs-toggle="tooltip"
                        title="ion-eject"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-bag" data-bs-toggle="tooltip" title="ion-bag"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-card"
                        data-bs-toggle="tooltip"
                        title="ion-card"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-cash"
                        data-bs-toggle="tooltip"
                        title="ion-cash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pricetag"
                        data-bs-toggle="tooltip"
                        title="ion-pricetag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-pricetags"
                        data-bs-toggle="tooltip"
                        title="ion-pricetags"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-thumbsup"
                        data-bs-toggle="tooltip"
                        title="ion-thumbsup"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-thumbsdown"
                        data-bs-toggle="tooltip"
                        title="ion-thumbsdown"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-happy"
                        data-bs-toggle="tooltip"
                        title="ion-happy"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-sad" data-bs-toggle="tooltip" title="ion-sad"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-trophy"
                        data-bs-toggle="tooltip"
                        title="ion-trophy"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-podium"
                        data-bs-toggle="tooltip"
                        title="ion-podium"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ribbon-a"
                        data-bs-toggle="tooltip"
                        title="ion-ribbon-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ribbon-b"
                        data-bs-toggle="tooltip"
                        title="ion-ribbon-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-university"
                        data-bs-toggle="tooltip"
                        title="ion-university"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-magnet"
                        data-bs-toggle="tooltip"
                        title="ion-magnet"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-beaker"
                        data-bs-toggle="tooltip"
                        title="ion-beaker"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-flask"
                        data-bs-toggle="tooltip"
                        title="ion-flask"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-egg" data-bs-toggle="tooltip" title="ion-egg"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-earth"
                        data-bs-toggle="tooltip"
                        title="ion-earth"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-planet"
                        data-bs-toggle="tooltip"
                        title="ion-planet"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-lightbulb"
                        data-bs-toggle="tooltip"
                        title="ion-lightbulb"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-cube"
                        data-bs-toggle="tooltip"
                        title="ion-cube"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-leaf"
                        data-bs-toggle="tooltip"
                        title="ion-leaf"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-waterdrop"
                        data-bs-toggle="tooltip"
                        title="ion-waterdrop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-flame"
                        data-bs-toggle="tooltip"
                        title="ion-flame"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-fireball"
                        data-bs-toggle="tooltip"
                        title="ion-fireball"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-bonfire"
                        data-bs-toggle="tooltip"
                        title="ion-bonfire"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-umbrella"
                        data-bs-toggle="tooltip"
                        title="ion-umbrella"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-nuclear"
                        data-bs-toggle="tooltip"
                        title="ion-nuclear"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-no-smoking"
                        data-bs-toggle="tooltip"
                        title="ion-no-smoking"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-thermometer"
                        data-bs-toggle="tooltip"
                        title="ion-thermometer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-speedometer"
                        data-bs-toggle="tooltip"
                        title="ion-speedometer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-plane"
                        data-bs-toggle="tooltip"
                        title="ion-plane"
                      ></i>
                    </li>
                    <li>
                      <i class="ion ion-jet" data-bs-toggle="tooltip" title="ion-jet"></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-load-a"
                        data-bs-toggle="tooltip"
                        title="ion-load-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-load-b"
                        data-bs-toggle="tooltip"
                        title="ion-load-b"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-load-c"
                        data-bs-toggle="tooltip"
                        title="ion-load-c"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-load-d"
                        data-bs-toggle="tooltip"
                        title="ion-load-d"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-ionic-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-ionic-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-back"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-back"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-forward"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-forward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-up"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-right"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-down"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-left"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-thin-up"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-thin-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-thin-right"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-thin-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-thin-down"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-thin-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-arrow-thin-left"
                        data-bs-toggle="tooltip"
                        title="ion-ios-arrow-thin-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-circle-filled"
                        data-bs-toggle="tooltip"
                        title="ion-ios-circle-filled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-circle-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-circle-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-checkmark-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-checkmark-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-checkmark-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-checkmark-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-checkmark"
                        data-bs-toggle="tooltip"
                        title="ion-ios-checkmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-plus-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-plus-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-plus-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-plus-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-plus"
                        data-bs-toggle="tooltip"
                        title="ion-ios-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-close-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-close-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-close-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-close-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-close"
                        data-bs-toggle="tooltip"
                        title="ion-ios-close"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-minus-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-minus-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-minus-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-minus-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-minus"
                        data-bs-toggle="tooltip"
                        title="ion-ios-minus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-information-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-information-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-information-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-information-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-information"
                        data-bs-toggle="tooltip"
                        title="ion-ios-information"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-help-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-help-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-help-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-help-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-help"
                        data-bs-toggle="tooltip"
                        title="ion-ios-help"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-search"
                        data-bs-toggle="tooltip"
                        title="ion-ios-search"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-search-strong"
                        data-bs-toggle="tooltip"
                        title="ion-ios-search-strong"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-star"
                        data-bs-toggle="tooltip"
                        title="ion-ios-star"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-star-half"
                        data-bs-toggle="tooltip"
                        title="ion-ios-star-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-star-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-star-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-heart"
                        data-bs-toggle="tooltip"
                        title="ion-ios-heart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-heart-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-heart-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-more"
                        data-bs-toggle="tooltip"
                        title="ion-ios-more"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-more-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-more-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-home"
                        data-bs-toggle="tooltip"
                        title="ion-ios-home"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-home-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-home-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud-upload"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud-upload-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud-upload-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud-download"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-cloud-download-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-cloud-download-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-upload"
                        data-bs-toggle="tooltip"
                        title="ion-ios-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-upload-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-upload-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-download"
                        data-bs-toggle="tooltip"
                        title="ion-ios-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-refresh"
                        data-bs-toggle="tooltip"
                        title="ion-ios-refresh"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-refresh-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-refresh-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-refresh-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios-refresh-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-reload"
                        data-bs-toggle="tooltip"
                        title="ion-ios-reload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-loop-strong"
                        data-bs-toggle="tooltip"
                        title="ion-ios-loop-strong"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-loop"
                        data-bs-toggle="tooltip"
                        title="ion-ios-loop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-bookmarks"
                        data-bs-toggle="tooltip"
                        title="ion-ios-bookmarks"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion ion-ios-bookmarks-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios-bookmarks-outline"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Ionic Icon",
      text: "Dashboard",
      text1: "Ionic Icon",
      name: "/index",
    };
  },
  name: "icon-ionic",
};
</script>
