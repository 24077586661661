<template>
  <!-- Header Menu -->

  <ul class="nav user-menu" v-if="adminData">
    <snack-bar v-if="mode === 'store'" :text="selectedStoreName" :close="switchToAdminMode" />

    <li class="scroll-text-container">
      <div class="scroll-text">
        <h2></h2>
      </div>
    </li>

    <li>
      <div class="searchinputs">
        <span class="d-block">{{ adminData.last_login }}</span>
        <i style="font-style: italic; text-align: right; display: block; width: 100%">Last Login</i>
      </div>
    </li>

    <!-- Select Store -->
    <li v-if="isSuperAdmin()" class="nav-item dropdown has-arrow main-drop select-store-dropdown">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link select-store" data-bs-toggle="dropdown">
        <span class="user-info">
          <img src="@/assets/img/icons/approve-verified.png" alt="" class="img-fluid" />
          <span class="user-detail">
            <span class="user-name">{{ "Select Store" }}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <div v-if="isLoadingStores" class="loadin-holder dropdown-item d-flex align-items-center">
          <cirlcular-preloader />
        </div>
        <a v-else="!isLoadingStores" v-for="store in vendors" @click="setStoreName(store.id, store.name)"
          href="javascript:void(0);" class="dropdown-item">
          <!-- store.profile_picture -->
          <img :src="store.profile_picture" alt="" class="img-fluid" />
          <span>{{ store.name }}</span>
        </a>

        <a v-if="!isLoadingStores" href="javascript:void(0)" class="dropdown-item">
          <vue-feather v-if="vendors.length" type="more-vertical" />
          <span v-if="vendors.length" @click="getVendors">&nbsp;Refresh</span>
          <span v-else>&nbsp;No Stores Yet</span>
        </a>
      </div>
    </li>
    <!-- Notifications -->
    <li class="nav-item dropdown nav-item-box">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
        <vue-feather type="bell"></vue-feather><span class="badge rounded-pill">{{ unreadNotifications.length }}</span>
      </a>
      <div class="dropdown-menu notifications">
        <div class="topnav-dropdown-header">
          <span class="notification-title">Notifications</span>
          <a href="javascript:void(0)" class="clear-noti" @click="markSeen('all')"> Clear All </a>
        </div>
        <div class="noti-content">
          <ul class="notification-list">

            <li class="notification-message" v-for="(notification, index) in unreadNotifications" :key="index">
              <div @click="viewNotification(notification)" class="pointer py-2">
                <div class="media d-flex">
                  <span class=" avatar flex-shrink-0">
                    <vue-feather type="bell"></vue-feather>
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">{{ notification.title }}</span><br>
                      <span class="noti-message">{{ notification.message }}</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">{{ formatTimeStamp(notification.date) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <!-- <router-link to="">View all Notifications</router-link> -->
        </div>
      </div>
    </li>
    <!-- /Notifications -->
    <li class="nav-item nav-item-box">
      <router-link to="/generalsettings"><vue-feather type="settings"></vue-feather></router-link>
    </li>
    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
        <span class="user-info">
          <span class="user-letter">
            <img :src="adminData.profile_picture" alt="" class="img-fluid" />
          </span>
          <span class="user-detail">
            <span class="user-name">{{ shortenString(adminData.name, 10) }}</span>

            <span class="user-role">{{ shortenString(adminData.role.name, 15) }}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu menu-drop-user">
        <div class="profilename">
          <div class="profileset">
            <span class="user-img"><img :src="adminData.profile_picture" alt="" />
              <span class="status online"></span></span>
            <div class="profilesets">
              <h6>{{ shortenString(adminData.name, 12) }}</h6>
              <h5>{{ shortenString(adminData.role.name, 15) }}</h5>
            </div>
          </div>
          <hr class="m-0" />
          <router-link class="dropdown-item" to="/profile"><vue-feather type="user"></vue-feather>My
            Profile</router-link>
          <router-link class="dropdown-item" to="/generalsettings"><vue-feather
              type="settings"></vue-feather>Settings</router-link>
          <hr class="m-0" />
          <span @click="logOut" class="dropdown-item logout pb-0" style="cursor: pointer"><img
              src="../assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout</span>
        </div>
      </div>
    </li>
  </ul>
  <!-- /Header Menu -->
</template>

<script>
import store from "../store";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { useLocalStorage } from "@/hooks/useLocalStorage";
import { sleep, shortenString, formatTimeStamp } from "@/helpers";
import notificationUrls from "@/helpers/notificationUrls";
import { router } from "@/router";

export default {
  setup() {
    const storage = useLocalStorage();
    const mode = storage.getItem("mode");

    return {
      storage,
      sleep,
      shortenString,
      mode,
    };
  },
  computed: {
    ...mapGetters({
      formatTimeStamp: "formatTimeStamp"
    }),
    notifications() {
      return store.state.notification.notifications;
    },
    unreadNotifications() {
      return store.state.notification.unreadNotifications;
    },
    vendors() {
      return store.state.vendorReport.vendors;
    },
    selectedStoreName() {
      const store_name = this.storage.getItem("store-name");
      return store_name ? atob(this.storage.getItem("store-name")) : "Select Store";
    },

  },
  data() {
    return {
      languages: [
        {
          iso: "us",
          language: "us",
          title: "English",
        },
        {
          iso: "bl",
          language: "bl",
          title: "Francais",
        },
        {
          iso: "es",
          language: "es",
          title: "Spanish",
        },
        {
          iso: "de",
          language: "de",
          title: "German",
        },
      ],
      adminData: null,
      isLoadingStores: false,
      // stores: [],
    };
  },
  methods: {
    ...mapMutations({
      logOut: "logOut",
    }),
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
      isVendor: "isVendor",
      formatTimeStamp: "formatTimeStamp"
    }),
    ...mapActions({
      getNotifications: "notification/getNotifications",
      _markSeen: "notification/markSeen",
    }),
    markSeen(id) {
      this._markSeen({ id: id });
    },
    viewNotification(notification) {
      this.markSeen(notification.id);
      let url = notificationUrls[notification.type];
      if (url) {
        router.push(url);
      }
    },
    async getVendors() {
      this.isLoadingStores = true;
      await store.dispatch("vendorReport/getStoreNames");
      this.isLoadingStores = false;
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    switchToAdminMode() {
      this.storage.setItem("mode", null);
      window.location.reload();
    },

    onClosePopUpWindow() {
      this.storage.setItem("mode", null);
      this.sleep(0.4);
      this.storage.setItem("store-token", null);
    },

    async setStoreName(store_id, store_name) {
      this.storage.setItem("mode", "store");
      await this.sleep(0.5);
      this.storage.setItem("store-id", btoa(store_id));
      await this.sleep(0.5);
      this.storage.setItem("store-name", btoa(store_name));
      window.location.reload();
    },
  },
  mounted() {
    this.adminData = store.state.adminProfile;
    this.getVendors();
    this.getNotifications();
  },
};
</script>

<style scoped>
.user-letter {
  overflow: hidden;
}

.img-fluid {
  max-width: 100%;
  height: 50px;
  object-fit: cover;
}

.header .user-img img {
  height: 40px;
  object-fit: cover;
}

.select-store-dropdown .dropdown-menu .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.select-store-dropdown .dropdown-menu .dropdown-item img,
.select-store-dropdown .select-store img {
  margin-right: 8px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #f3f6f9;
}

.select-store-dropdown .dropdown-menu .dropdown-item span {
  font-size: 13px;
  font-weight: 500;
  color: #092c4c;
}

.select-store-dropdown .select-store {
  box-shadow: 0 0 2px inset rgb(148, 142, 142);
  padding: 2px 4px !important;
  border-radius: 5px !important;
}

.select-store-dropdown .select-store span {
  font-size: 13px;
  font-weight: 500;
  color: #092c4c;
}

.d-flex.btn * {
  color: white !important;
}

.loadin-holder {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* SCROLL MESSAGE */
.scroll-text-container {
  display: block;
  flex: 3;
  overflow: hidden;
}

.scroll-text {
  white-space: nowrap;
  /* Prevent text from wrapping */
  /* overflow: hidden; */
  /* Hide overflow */
  animation: scroll 20s linear infinite;
  /* Adjust duration as needed */
}

.scroll-text h2 {
  font-size: 20px;
  font-weight: bold;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
    /* Start off screen */
  }

  100% {
    transform: translateX(-100%);
    /* Scroll to the left */
  }
}
</style>

