<template>
  <!-- show payment Modal -->
  <div
    class="modal fade"
    id="inventoryHistory"
    tabindex="-1"
    aria-labelledby="inventoryHistory"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Inventory History</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <FilterTable
              :paginationConfig="{
                current: pagination.currentPage,
              }"
              :columns="columns"
              :data="inventoryHistory"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'admin'">
                  <span> {{ record.admin.name }}</span>
                </template>
              </template>
            </FilterTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import FilterTable from "../../table/filter-table.vue";
// stores
const store = useStore();

// props
const props = defineProps({
  productId: String,
});

const pagination = ref({
  currentPage: 1,
});
const columns = ref([
  {
    title: "Quantity",
    dataIndex: "quantity",
    sorter: {
      compare: (a, b) => {
        a = a.quantity.toLowerCase();
        b = b.quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Note",
    dataIndex: "note",
    sorter: {
      compare: (a, b) => {
        a = (a?.note || "").toLowerCase();
        b = (b?.note || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
]);
const inventoryHistory = ref([]);

async function fetchHistory() {
  const response = await store.dispatch(
    "product/getInventoryHistory",
    props.productId
  );
  inventoryHistory.value = response.data.data;
  pagination.value.currentPage = 1;
}

watch(
  () => props.productId,
  (newVal) => {
    if (!newVal) {
      resetData();
    }
  },
  { immediate: true }
);

function resetData() {
  inventoryHistory.value = [];
}
onMounted(() => {
  if (store.state.isSuperAdmin) {
    columns.value.push({
      title: "Admin",
      key: "admin",
      sorter: {
        compare: (a, b) => {
          a = (a?.admin.name || "").toLowerCase();
          b = (b?.admin.name || "").toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    });
  }
  $("#inventoryHistory").on("shown.bs.modal", (event) => {
    resetData();
    if (props.productId) {
      fetchHistory();
    }
  });
});
</script>
