<template>
  <div class="movable-bottom" id="movable-bottom" :style="styleObject" @mousedown="startDrag">
    <slot />
  </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeUnmount } from "vue";

const styleObject = reactive({
  position: "fixed",
  bottom: "5px",
  right: "5px", // Initial position from the left
  padding: "10px",
  borderRadius: "5px",
  cursor: "grab",
  zIndex: 1000,
});

let isDragging = false;
let startX = 0;
let startLeft = 0;

const startDrag = (event) => {
  isDragging = true;
  startX = event.clientX;
  startLeft = parseInt(styleObject.left, 10);
  document.addEventListener("mousemove", onDrag);
  document.addEventListener("mouseup", stopDrag);
};

const onDrag = (event) => {
  if (!isDragging) return;
  const dx = event.clientX - startX;
  const newLeft = startLeft + dx;

  // Ensure the element stays within the viewport
  const elementWidth = document.getElementById("movable-bottom").offsetWidth;
  const maxLeft = window.innerWidth - elementWidth;
  if (newLeft < 0) {
    styleObject.left = "0px";
  } else if (newLeft > maxLeft) {
    styleObject.left = `${maxLeft}px`;
  } else {
    styleObject.left = `${newLeft}px`;
  }
};

const stopDrag = () => {
  isDragging = false;
  document.removeEventListener("mousemove", onDrag);
  document.removeEventListener("mouseup", stopDrag);
};

onMounted(() => {
  document.addEventListener("mouseup", stopDrag);
});

onBeforeUnmount(() => {
  document.removeEventListener("mouseup", stopDrag);
});
</script>

<style scoped>
.movable-bottom {
  position: relative;
  user-select: none;
  z-index: 9999;
}
</style>
