import { api } from "@/config";

export const users = {
  url(...queries) {
    let extras = queries.length ? `/${queries.join("/")}` : "";
    return `/users${extras}`;
  },
  namespaced: true,
  actions: {
    reverseTransaction({ context }, { transactionId }) {
      return api.post(users.url("transactions", transactionId, "reverse"));
    },
    getTransactions({ context }, { accountNo }) {
      return api.get(users.url(accountNo, "transactions"));
    },
    downloadTransactions({ context }, { accountNo }) {
      return api.get(`/print/${accountNo}/transactions`, {
        responseType: "blob",
      });
    },
    downloadOrders({ context }, { accountNo }) {
      return api.get(`/print/${accountNo}/orders`, {
        responseType: "blob",
      });
    },
    getUsersAccount({ context }, { params } = { params: {} }) {
      return api.get(users.url("accounts"), { params: params });
    },

    fetchUserAnalysis({ context }, { accountNumber }) {
      return api.get(users.url("accounts", accountNumber, "analysis"));
    },
    fetchUserOrderHistory({ context }, { accountNumber, dateRange }) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get(users.url("accounts", accountNumber, "order-history"), {
        params: params,
      });
    },
    fetchUserOrderAndPayment({ context }, { accountNumber, payload }) {
      return api.get(
        users.url("accounts", accountNumber, "order-and-payment"),
        {
          params: payload,
        }
      );
    },

    clearDues({ context }, { accountNo }) {
      return api.post(users.url(accountNo, "clear-dues"));
    },
    credit({ context }, { accountNo, payload }) {
      return api.post(users.url(accountNo, "credit"), payload);
    },
    debit({ context }, { accountNo, payload }) {
      return api.post(users.url(accountNo, "debit"), payload);
    },
    get({ context }, params) {
      return api.get(users.url(), {
        params: params,
      });
    },
    barred({ context }, params) {
      return api.get(users.url("barred"), {
        params: params,
      });
    },
    getIds({ context }, params) {
      return api.get(users.url("ids"), { params: params });
    },
    delete({ context }, { user_id }) {
      return api.delete(users.url(user_id));
    },
    show({ context }, { user_id }) {
      return api.get(users.url(user_id));
    },
    add({ context }, { payload }) {
      return api.post(users.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    update({ context }, { user_id, payload }) {
      return api.post(
        users.url(user_id),
        { ...payload, _method: "PATCH" },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
