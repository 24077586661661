<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card" v-if="transitDetails">
          <div class="card-body" id="order-detail">
            <div class="card-sales-split">
              <h2>Transit Detail</h2>
              <ul>
                <li>
                  <a href="javascript:void(0);"><img src="../../../../assets/img/icons/edit.svg" alt="img" /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"><img src="../../../../assets/img/icons/pdf.svg" alt="img" /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"><img src="../../../../assets/img/icons/excel.svg" alt="img" /></a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="exportToPdf"><img src="../../../../assets/img/icons/printer.svg"
                      alt="img" /></a>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="row">
                <div class="col-lg-6">
                  <div class="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Delivery Number</h4>
                        <h5>{{ transitDetails.delivery_number }}</h5>
                      </li>
                      <li>
                        <h4>Order Number</h4>
                        <h5>{{ transitDetails.order_number }}</h5>
                      </li>
                      <li>
                        <h4>Transfer Number</h4>
                        <h5>{{ transitDetails.transfer_number }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Purchase Number</h4>
                        <h5>{{ transitDetails.purchase_number }}</h5>
                      </li>
                      <li>
                        <h4>Vehicle Number</h4>
                        <h5>{{ transitDetails.vehicle_number }}</h5>
                      </li>
                      <li class="total">
                        <h4>Order Date</h4>
                        <h5>{{ transitDetails.order_date }}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <h2 style="font-size: 14px; font-weight: 600">Products</h2>
              <br />
              <f-table :columns="productsColumns" :data="transitDetails.products"
                :filter="['name', 'number', 'description', 'batch_number']">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'action'">
                    <span style="cursor: pointer" data-bs-toggle="modal" data-bs-target="#confirmtransitproduct"
                      class="badges bg-lightgreen" @click="setTransitProductToUpdate(record.id)">
                      {{ record.status == "Confirmed" ? "View" : "Confirm" }}
                    </span>
                  </template>
                </template>
              </f-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transit-modal :selectedProducts="selectedProducts" :transitDetails="transitDetails"
      @transitConfirmedListener="getIntransits(true)"></transit-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import { productStore } from "@/store/product";

const productsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Number",
    dataIndex: "number",
    sorter: (a, b) => a.number.localeCompare(b.number),
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    sorter: (a, b) => a.quantity.localeCompare(b.quantity),
  },
  {
    title: "Batch No",
    dataIndex: "batch_number",
    sorter: (a, b) => a.batch_number.localeCompare(b.batch_number),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: "Action",
    key: "action",
  },
];
export default {
  data() {
    return {
      title: "Transit Detail",
      title1: "View Transit details",
      productsColumns,
      orderPayments: [],
      selectedProducts: null,
    };
  },
  computed: {
    ...mapGetters(["formatAmount"]),
    transitDetails() {
      let id = this.$route.params.id;
      return productStore.state.transits.find((transit) => transit.id == id);
    },
  },
  methods: {
    ...mapActions({
      _getOrderDetail: "orders/show",
    }),
    exportToPdf() {
      html2pdf(document.getElementById("order-detail"), {
        margin: 5,
        filename: this.transitDetails.delivery_number + ".pdf",
        enableLinks: true,

        image: {
          type: "png",
          quality: 50.98,
        },
      });
    },
    async getIntransits(force = false) {
      if (
        !productStore.state.transits ||
        !productStore.state.transits.length ||
        force
      ) {
        await productStore.dispatch("getTransits");
      }
    },
    setTransitProductToUpdate(productId) {
      this.selectedProducts = this.transitDetails.products.find(
        (product) => product.id == productId
      );
    },
  },
  mounted() {
    this.getIntransits();
  },
  name: "transit-details",
};
</script>

<style scoped>
.data,
b {
  line-height: 30px !important;
}
</style>
