<template>
  <div>

    <!-- edit Modal -->
    <div class="modal fade" id="accountTypeModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Credit Limit </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Account Type</label>
                  <input type="text" class="form-control" v-model="accountType.name" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Credit Limit</label>
                  <input class="form-control" v-model="accountType.credit_limit" type="number" />
                </div>
              </div>
              <div class="col-12">
                <a-checkbox checked-color="#ff0000" v-model:checked="accountType.can_order_for_other_users">
                  Can Order For Another User
                </a-checkbox>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-submit" @click="handleClick">
              Update
            </button>
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, watch } from 'vue'

const props = defineProps({
  accountType2Update: {
    type: null,
    required: true,
  },
});
const emit = defineEmits({
  addAccountType: () => { },
  updateAccountType: () => { },
});


const isUpdate = computed(() => !!props.accountType2Update);
const accountType = reactive({
  name: null,
  credit_limit: null,
  can_order_for_other_users: false,
});

const handleClick = () => {
  if (isUpdate.value) {
    emit("updateAccountType", accountType);
  } else {
    emit("addAccountType", accountType);
  }
  $("#accountTypeModal").modal("hide");
}

// watches
watch(() => props.accountType2Update, (accountType2Update) => {
  Object.assign(accountType, accountType2Update ? accountType2Update : {});
})
</script>
