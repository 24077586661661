<template>
  <!-- show payment Modal -->
  <div class="modal fade" id="showpayment" tabindex="-1" aria-labelledby="showpayment" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Show Payments</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Payment Method</th>
                  <th>Paid By</th>
                </tr>
              </thead>
              <tbody>
                <p v-if="payments.length == 0">No Payment Made</p>
                <tr v-for="payment in payments" v-bind:key="payment.id" class="bor-b1">
                  <td>{{ payment.date }}</td>
                  <td>{{ payment.reference }}</td>
                  <td>₦ {{ payment.amount }}</td>
                  <td>{{ payment.method }}</td>
                  <td>

                    <span>
                      {{ payment.paid_by.name }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->

  <!-- show payment Modal -->
  <div class="modal fade" id="createpayment" data-bs-focus="false" tabindex="-1" aria-labelledby="createpayment"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Payment</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Customer</label>
                <div class="input-groupicon">
                  <input class="form-control" :value="sale.user.name" readonly />
                  <div class="addonset">
                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Reference</label>
                <input type="text" readonly :value="sale.reference" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Total Amount</label>
                <input type="text" readonly :value="sale.total" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Paid Amount</label>
                <input type="text" readonly :value="formatAmount(sale.paid)" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Due</label>
                <input type="text" readonly :value="formatAmount(sale.due)" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Excess</label>
                <input type="text" readonly :value="formatAmount(sale.over_paid)" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment Amount</label>
                <input v-model="new_payment.amountPreview" type="text" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment type</label>
                <vue-select v-model="new_payment.method" :options="Cash" placeholder="Cash" />
              </div>
            </div>
            <div class="col-lg-12" v-if="false">
              <div class="form-group mb-0">
                <label>Note</label>
                <textarea v-model="new_payment.note" class="form-control">Paid</textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit" v-on:click="checkAccess()">
            Submit
          </button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->

  <!-- edit payment Modal -->
  <div class="modal fade" id="editpayment" tabindex="-1" aria-labelledby="editpayment" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Payment</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Customer</label>
                <div class="input-groupicon">
                  <datepicker v-model="startdate" placeholder="Choose Date" class="picker" :editable="true"
                    :clearable="false" />
                  <div class="addonset">
                    <img src="../../../assets/img/icons/datepicker.svg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Reference</label>
                <input type="text" value="INV/SL0101" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Received Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Paying Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment type</label>
                <vue-select :options="Cash" placeholder="Cash" />
              </div>
            </div>
            <Feature :tag="features">
              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Note</label>
                  <textarea class="form-control"></textarea>
                </div>
              </div>
            </Feature>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit">Submit</button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- edit payment Modal -->
</template>

<script>
import Feature from "@/components/feature/Feature.vue";
import { features } from "@/components/feature/feature";
import { _sale } from "@/store/models";
import { ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
const currentDate = ref(new Date());
export default {
  props: {
    order_id: String,
    sale_id: String,
  },
  data() {
    return {
      startdate: currentDate,
      sale: _sale,
      access_code: null,
      new_payment: {
        reference: null,
        method: "Cash",
        amount: null,
        note: null,
        access_code: null,
        amountPreview: null,
      },
      Cash: ["Cash", "POS", "Bank / Transfer", "Cheque"],
      payments: [],
    };
  },
  computed: {
    ...mapGetters({
      formatAmount: "formatAmount",
      hasAccess: "hasAccess",
    }),
    ...mapState(["adminPermissions"]),
  },
  methods: {
    ...mapActions({
      _getPayments: "payments/get",
      _addPayment: "payments/add",
      _getSaleDetail: "sales/show",
      _showOtpDialog: "showOtpDialog",
      _sendAccessCode: "accessCode/add",
      // _getPayments: "payments/delete",
    }),
    getPayments() {
      this._getPayments({ order_id: this.$props.order_id })
        .then((response) => {
          this.payments = response.data.data;
        })
        .catch((error) => { });
    },
    verifyOtp() {
      this._showOtpDialog({
        title: "Enter Payment Code",
        description: "Authorization Payment Code Sent to the Sales Manager!",
      }).then((response) => {
        this.access_code = response.value;
        this.new_payment.access_code = this.access_code;
        // get the access code
        if (this.access_code != null) {
          this.addPayment();
        } else {
          Swal.fire("You need the Authorization Payment Code");
        }
      });
    },
    checkAccess() {
      let adminPermissions = this.adminPermissions;
      if (adminPermissions.length > 0) {
        // check if the user is the super admin
        if (adminPermissions[0] == "*" || this.hasAccess("create-payment")) {
          this.addPayment();
        } else {
          this._sendAccessCode({
            permission_code: "create-payment",
            item_id: this.order_id,
          }).then((response) => {
            this.verifyOtp();
          });
          Swal.fire({
            title: "Sending Authorization Code To Sales Manager",
            html: `<div class="spinner-border text-dark" role="status"></div>`,
            closeOnClickOutside: false,
            buttons: false,
          });
        }
      }
    },
    addPayment() {
      this._addPayment({
        order_id: this.$props.order_id,
        payload: this.new_payment,
      })
        .then((response) => {
          Swal.fire("Payment Added");
          this.new_payment = {
            reference: null,
            method: "Cash",
            amount: null,
            note: null,
          };
          $(".modal").modal("hide");
          this.getPayments();
          this.getSaleDetail();
          this.$emit("paymentAddedListner");
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
    getSaleDetail() {
      this._getSaleDetail({ sale_id: this.$props.sale_id }).then((response) => {
        this.sale = response.data.data;
        this.new_payment.amountPreview = this.sale.due;
        this.new_payment.note = "Paid";
      });
    },
  },
  components: {
    Feature
  },
  watch: {
    order_id: {
      handler(_old, _new) {
        this.getPayments();
        this.getSaleDetail();
      },
    },
    "new_payment.amountPreview": {
      handler(val) {
        let amount = Number.parseInt(val?.replaceAll(",", "")) || 0;
        this.new_payment.amount = amount;
        // this.new_payment.amountPreview = amount.toLocaleString("en-US");
      },
    },
  },
};
</script>
