<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <!-- <pageheader :title="title" :title1="title1" :items="items"/> -->
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'image'">
              <div class="productimgname">
                <a
                  target="_blank"
                  title="Click to View"
                  :href="record.image"
                  class="product-img"
                >
                  <img :src="record.image" alt="product" />
                </a>
              </div>
            </template>
            <template v-if="column.key === 'status'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Status === 'Completed'"
                >{{ record.Status }}</span
              >
              <span
                class="badges bg-lightred"
                v-else-if="record.Status === 'Pending'"
                >{{ record.Status }}</span
              >
              <span class="badges bg-lightgrey" v-else>{{
                record.Status
              }}</span></template
            >
            <template v-if="column.key === 'order_reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.orderId"
                >{{ record.order_reference }}</router-link
              >
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Receipt",
    dataIndex: "image",
    key: "image",
    sorter: (a, b) => a["image"].localeCompare(b["image"]),
  },
  {
    title: "Order  Reference",
    dataIndex: "order_reference",
    key: "order_reference",
    sorter: (a, b) => a.order_reference.localeCompare(b.order_reference),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: {
      compare: (a, b) => {
        a = a.customer_name.toLowerCase();
        b = b.customer_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
];

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      filters: ["order_reference", "customer_name"],
      title: "Order Report",
      title1: "Manage your Orders",
      startdate: currentDate,
      items: [
        {
          text: "Add Order",
          addname: "/add-order",
        },
      ],
      data: [],
      columns,
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    ...mapActions({
      _getReceipts: "receipts/get",
    }),
    getReceipts() {
      this._getReceipts().then((response) => {
        this.data = response.data.data.map((receipt) => {
          return {
            id: receipt.id,
            orderId: receipt.order.id,
            image: receipt.image,
            order_reference: receipt.order.reference,
            customer_name: receipt.order.user.name,
            date: receipt.order.date,
            status: receipt.order.status,
          };
        });
      });
    },
  },
  mounted() {
    this.getReceipts();
  },
  name: "receiptlist",
};
</script>
