import { api } from "@/config";

export const category = {
  url(_path = "") {
    let path = _path != "" ? "/" + _path : "";
    return `/categories${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, category_id = null) {
      let id = category_id || "";
      return api.get(category.url(id));
    },
    add({ context }, { payload }) {
      return api.post(category.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    update({ context }, { category_id, payload }) {
      return api.post(category.url(category_id), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete({ context }, category_id) {
      return api.delete(category.url(category_id));
    },
  },
};
