export default {
  toastError: true,
  loaderMinimized: false,
  pendingRequests: 0,
  adminPermissions: JSON.parse(
    atob(localStorage.getItem("ADMIN_PERMISSIONS") || btoa("[]"))
  ),
  adminProfile: {},
  isAuth: !!localStorage.getItem("token"),
  accessToken: () => {
    let token = null;
    try {
      token = atob(localStorage.getItem("token"));
    } catch (error) {
      localStorage.clear();
    }
    return token;
  },
  isSuperAdmin: (state) => {
    return state.adminPermissions[0] == "*";
  },
};
