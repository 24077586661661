<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-sm-6 col-12">
                <div class="form-group">
                  <label>Order Reference</label>
                  <div class="row">
                    <div class="col-lg-10 col-sm-10 col-10 d-flex gap-2">
                      <input
                        list="orders"
                        type="text"
                        v-model="order_ref"
                        placeholder="Choose"
                        class="col-lg-8"
                      />
                      <!-- {{ orders }} -->
                      <datalist id="orders">
                        <option
                          v-for="order in orders"
                          :value="order.reference"
                          :key="order.id"
                        >
                          {{ order.reference }}
                        </option>
                      </datalist>
                    </div>
                    <div class="col-lg-2 col-sm-2 col-2 ps-0">
                      <div class="add-icon">
                        <span
                          ><img
                            v-on:click="selectOrder()"
                            src="../../../assets/img/icons/plus1.svg"
                            alt="img"
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Order Ref</th>
                      <th>Customer Name</th>
                      <th>Date</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody v-if="selected_orders">
                    <tr
                      v-for="(order, index) in selected_orders"
                      :key="index"
                      i="index"
                    >
                      <td>{{ order.reference }}</td>
                      <td>{{ order.user.name }}</td>
                      <td>{{ order.date }}</td>
                      <td>{{ order.total }}</td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          class="delete-set"
                          @click="removeOrder(order.reference)"
                          ><img
                            src="../../../assets/img/icons/delete.svg"
                            alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div hidden class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Order Tax</label>
                  <input type="text" />
                </div>
              </div>
              <div hidden class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div>
              </div>
              <div hidden class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Shipping</label>
                  <input type="text" />
                </div>
              </div>
              <div hidden class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Status</label>
                  <vue-select :options="Status" placeholder="Choose Status" />
                </div>
              </div>
              <div hidden class="row">
                <div class="col-lg-6">
                  <div class="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>₦ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount</h4>
                        <h5>₦ 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Shipping</h4>
                        <h5>₦ 0.00</h5>
                      </li>
                      <li class="total">
                        <h4>Grand Total</h4>
                        <h5>₦ 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <a
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                  v-on:click="addSale"
                  >Submit</a
                >
                <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import addsale from "../../../assets/json/addsale.json";
import { mapActions } from "vuex";
import { _sale } from "@/store/models";
export default {
  data() {
    return {
      title: "Add Sale",
      title1: "Add your new Sale",
      order_ref: null,
      selected_orders: {},
      startdate: currentDate,
      orders: [],
    };
  },
  methods: {
    ...mapActions({
      _addSale: "sales/add",
      _getOrders: "orders/get",
      _getOrderDetail: "orders/show",
    }),
    deleteEvent: function (addsale) {
      this.addsale.splice(this.addsale.indexOf(addsale), 1);
    },
    getOrders() {
      this._getOrders().then((response) => {
        response.data.data.map((order) => {
          this.orders.push({
            reference: order.reference,
            id: order.id,
            total: order.total,
            user: order.user,
            date: order.date,
          });
        });
      });
    },
    removeOrder(order_ref) {
      delete this.selected_orders[order_ref];
    },
    selectOrder() {
      for (let index = 0; index < this.orders.length; index++) {
        let order = this.orders[index];
        if (order.reference == this.order_ref) {
          this.selected_orders[this.order_ref] = order;
          this.order_ref = null;
          break;
        }
      }
    },
    addSale() {
      let refs = [];
      for (let order_ref in this.selected_orders) {
        refs.push(order_ref);
      }
      this._addSale({ order_ref_or_ids: refs })
        .then((response) => {
          Swal.fire("Sale Added", "", "success");
          this.$router.push({ name: "saleslist" });
        })
        .catch((error) => {
          Swal.fire(error.response.data.message, "", "error");
        });
    },
  },
  async mounted() {
    this.getOrders();
  },
  name: "add-sales",
};
</script>
