import { api } from "@/config";

export const bankDetails = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/bank-details${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, id = "") {
      return api.get(bankDetails.url(id));
    },
    add({ context }, { payload }) {
      return api.post(bankDetails.url(), payload);
    },
    update({ context }, { id, payload }) {
      return api.patch(bankDetails.url(id), payload);
    },
    delete({ context }, { id }) {
      return api.delete(bankDetails.url(id));
    },
  },
};
