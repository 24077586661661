<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Order Reference</label>
                  <div class="row">
                    <div class="col-lg-5 col-sm-5 col-5">
                      <input
                        list="order_references"
                        type="text"
                        v-model="newSaleReturn.order_ref"
                        placeholder="Order Reference"
                      />
                      <datalist id="order_references">
                        <option
                          v-for="order_reference in OrderRefs"
                          :key="order_reference"
                          :value="order_reference"
                        >
                          {{ order_reference }}
                        </option>
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-sm-6 col-12">
                <div class="form-group">
                  <label>Product Name</label>
                  <div class="row">
                    <div class="col-lg-10 col-sm-10 col-10 row">
                      <vue-select
                        v-model="new_product.id"
                        :options="selectedOrderProducts"
                        placeholder="Choose"
                        class="col-lg-8"
                      />
                      <div class="col-lg-4">
                        <input
                          class="form-control"
                          type="number"
                          v-model="new_product.qty"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-2 col-2 ps-0">
                      <div class="add-icon">
                        <span
                          ><img
                            v-on:click="addProduct()"
                            src="../../../assets/img/icons/plus1.svg"
                            alt="img"
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive mb-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Name</th>
                      <th>QTY</th>
                      <th>Price</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedProducts">
                    <tr v-for="(item, key, index) in selectedProducts" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="productimgname">
                        <a class="product-img">
                          <img :src="item.image" alt="product" />
                        </a>
                        <a href="javascript:void(0);">{{ item.name }}</a>
                      </td>
                      <td>{{ item.qty }}</td>
                      <td>{{ formatAmount(item.price) }}</td>
                      <td>{{ formatAmount(item.total) }}</td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          class="delete-set"
                          @click="deleteProduct(key)"
                          ><img src="../../../assets/img/icons/delete.svg" alt="svg"
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-12">
              <a
                v-on:click="checkAccess"
                href="javascript:void(0);"
                class="btn btn-submit me-2"
                >Submit</a
              >
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import { mapActions, mapGetters, mapState } from "vuex";
import { _sale } from "@/store/models";
export default {
  data() {
    return {
      title: "Add Sale Return",
      title1: "Add New Sale Return",
      new_product: {
        id: null,
        qty: 1,
      },
      selectedProducts: {},
      access_code: null,
      newSaleReturn: {
        order_ref: null,
        products: [],
      },
      startdate: currentDate,
      OrderRefs: [],
      allOrderProduct: {},
      selectedOrderProducts: [],
      Products: [],
      ProductsDetail: [],
    };
  },
  computed: {
    ...mapState({
      adminPermissions: "adminPermissions",
    }),
    ...mapGetters({
      formatAmount: "formatAmount",
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    ...mapActions({
      _getOrders: "orders/get",
      _getProducts: "product/fetchProductsFewDetails",
      _addSaleReturn: "saleReturn/add",
      _showOtpDialog: "showOtpDialog",
      _sendAccessCode: "accessCode/add",
    }),
    deleteProduct: function (index) {
      delete this.selectedProducts[index];
    },
    validate() {
      let error = "";
      let data = this.newSaleReturn;
      if (data.order_ref == null) {
        error = "Enter the Order Reference";
      } else if (Object.values(this.selectedProducts).length == 0) {
        error = "Select a Product";
      }

      if (error) {
        Swal.fire({
          title: error,
          icon: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    getOrders() {
      this._getOrders().then((response) => {
        response.data.data.map((order) => {
          this.allOrderProduct[order.reference] = order.products;
          this.OrderRefs.push(order.reference);
        });
      });
    },

    addProduct() {
      if (this.new_product.id == null) {
        Swal.fire({
          title: "Select A Product",
          icon: "warning",
        });
        return;
      }
      let product_details = this.selectedOrderProducts.find((product) => {
        return product.id == this.new_product.id;
      });

      let qty = product_details.qty;
      if (this.new_product.qty > qty) {
        Swal.fire({
          title: `The Ordered Quantity was ${qty}`,
          icon: "warning",
        });
        return;
      }

      this.selectedProducts[this.new_product.id] = {
        id: this.new_product.id,
        qty: this.new_product.qty,
        name: product_details.text,
        image: product_details.image,
        tax: product_details.tax,
        price: product_details.price,
        total: this.new_product.qty * product_details.price,
      };
      this.new_product = {
        id: null,
        qty: 1,
      };
    },
    verifyOtp() {
      this._showOtpDialog({
        title: "Enter Authorization Code",
        description: "Authorization Code Sent to the Supervisor!",
      }).then((response) => {
        this.access_code = response.value;
        // get the access code
        if (this.access_code != null) {
          this.addSaleReturn();
        } else {
          Swal.fire("You need the Authorization Code");
        }
      });
    },
    async checkAccess() {
      let adminPermissions = this.adminPermissions;
      if (adminPermissions.length > 0) {
        // check if the user is the super admin
        if (this.hasAccess("create-sales-return")) {
          this.addSaleReturn();
        } else {
          this._sendAccessCode({
            permission_code: "create-sales-return",
            item_id: this.newSaleReturn.order_ref,
            item_type: "reference",
          }).then((response) => {
            this.verifyOtp();
          });

          Swal.fire({
            title: "Sending Authorization Code To Supervisor",
            html: `<div class="spinner-border text-dark" role="status"></div>`,
            closeOnClickOutside: false,
            buttons: false,
          });
        }
      }
    },
    addSaleReturn() {
      if (this.validate()) {
        this.newSaleReturn.products = Object.values(this.selectedProducts).map(
          (product) => {
            return {
              id: product.id,
              qty: product.qty,
            };
          }
        );

        this._addSaleReturn({
          payload: {
            access_code: this.access_code,
            order_ref: this.newSaleReturn.order_ref,
            products: this.newSaleReturn.products,
          },
        })
          .then((response) => {
            Swal.fire("Sale Return Added", "", "success");
            this.$router.push({ name: "salesreturnlist" });
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "error");
          });
      }
    },
  },
  watch: {
    "newSaleReturn.order_ref": {
      handler(_new, _old) {
        this.selectedOrderProducts = [];
        let products = this.allOrderProduct[_new];
        if (products) {
          products.map((product) => {
            this.selectedOrderProducts.push({
              id: product.id,
              text: product.name,
              qty: product.qty,
              price: product.price,
              image: product.image,
            });
          });
        }
      },
    },
  },
  mounted() {
    this.getOrders();
  },
  name: "add-sales",
};
</script>
