<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span class="badges bg-lightgreen" v-if="record.Status === 'Approved'">{{
                record.Status
              }}</span>
              <span class="badges bg-lightred" v-else>{{ record.Status }}</span></template
            >
            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.reference }}</router-link
              >
            </template>
            <template v-else-if="column.key === 'pay'">
              <span class="badges bg-lightgreen" v-if="record.Payment === 'Paid'">{{
                record.Payment
              }}</span>
              <span class="badges bg-lightred" v-else>{{
                record.Payment
              }}</span></template
            >
            <template v-else-if="column.key === 'action'">
              <td class="action-table-data">
                <div class="edit-delete-action">
                  <a
                    class="me-2 p-2"
                    href="#edit-coupon"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <vue-feather type="edit"></vue-feather>
                  </a>
                  <a
                    class="confirm-text p-2"
                    @click="showConfirmation"
                    href="#delete-coupon"
                  >
                    <vue-feather type="trash-2"></vue-feather>
                  </a>
                </div>
              </td>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <saleslistmodal
    @paymentAddedListner="getSales"
    :order_id="selected_order_id"
    :sale_id="selected_sale_id"
  ></saleslistmodal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
const columns = [
  {
    sorter: true,
  },
  {
    title: "From Warehouse",
    dataIndex: "From_Warehouse",
    sorter: {
      compare: (a, b) => {
        a = a.From_Warehouse.toLowerCase();
        b = b.From_Warehouse.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "To Warehouse",
    dataIndex: "To_Warehouse",
    sorter: {
      compare: (a, b) => {
        a = a.To_Warehouse.toLowerCase();
        b = b.To_Warehouse.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "No of Products",
    dataIndex: "Products",
    sorter: {
      compare: (a, b) => {
        a = a.Products.toLowerCase();
        b = b.Products.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Quantity transfered",
    dataIndex: "Quantity",
    sorter: {
      compare: (a, b) => {
        a = a.Quantity.toLowerCase();
        b = b.Quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Ref Number",
    dataIndex: "Number",
    sorter: {
      compare: (a, b) => {
        a = a.Number.toLowerCase();
        b = b.Number.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    id: "1",
    From_Warehouse: "Lobar Handy",
    To_Warehouse: "Selosy",
    Products: "4",
    Quantity: "14",
    Number: "#145445",
    Date: "25 Jul 2023",
  },
  {
    id: "2",
    From_Warehouse: "Quaint Warehouse",
    To_Warehouse: "Logerro",
    Products: "21",
    Quantity: "10",
    Number: "#135478",
    Date: "28 Jul 2023",
  },
  {
    id: "3",
    From_Warehouse: "Traditional Warehouse",
    To_Warehouse: "Vesloo",
    Products: "15",
    Quantity: "14",
    Number: "#145124",
    Date: "24 Jul 2023",
  },
  {
    id: "4",
    From_Warehouse: "Cool Warehouse",
    To_Warehouse: "Crompy",
    Products: "14",
    Quantity: "74",
    Number: "#474541",
    Date: "15 Jul 2023",
  },
];

export default {
  data() {
    return {
      filters: ["customer_name", "reference", "customer_account_type"],
      title: "Stock Transfer",
      title1: "Manage your stock transfer",
      items: [
        {
          text: "Add New",
          addname: "#add-stock",
        },
      ],
      selected_order_id: null,
      selected_sale_id: null,
      columns,
      data,
      Completed: ["Completed", "Paid"],
    };
  },
  computed: {
    ...mapGetters(["hasAccess"]),
  },
  methods: {
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },
  mounted() {},
  name: "manage-coupons",
};
</script>
