<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">Feather Icon</div>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <vue-feather
                        type="activity"
                        data-bs-toggle="tooltip"
                        title="fe fe-activity"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="airplay"
                        data-bs-toggle="tooltip"
                        title="fe fe-airplay"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="alert-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-alert-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="alert-octagon"
                        data-bs-toggle="tooltip"
                        title="fe fe-alert-octagon"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="alert-triangle"
                        data-bs-toggle="tooltip"
                        title="fe fe-alert-triangle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="align-center"
                        data-bs-toggle="tooltip"
                        title="fe fe-align-center"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="align-justify"
                        data-bs-toggle="tooltip"
                        title="fe fe-align-justify"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="align-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-align-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="align-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-align-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="anchor"
                        data-bs-toggle="tooltip"
                        title="fe fe-anchor"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="aperture"
                        data-bs-toggle="tooltip"
                        title="fe fe-aperture"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-down-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-down-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-down-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-down-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-down-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-down-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-left-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-left-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-right-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-right-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-up-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-up-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-up-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-up-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="arrow-up-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-arrow-up-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="at-sign"
                        data-bs-toggle="tooltip"
                        title="fe fe-at-sign"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="award"
                        data-bs-toggle="tooltip"
                        title="fe fe-award"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bar-chart"
                        data-bs-toggle="tooltip"
                        title="fe fe-bar-chart"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bar-chart-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-bar-chart-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="battery"
                        data-bs-toggle="tooltip"
                        title="fe fe-battery"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="battery-charging"
                        data-bs-toggle="tooltip"
                        title="fe fe-battery-charging"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bell"
                        data-bs-toggle="tooltip"
                        title="fe fe-bell"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bell-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-bell-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bluetooth"
                        data-bs-toggle="tooltip"
                        title="fe fe-bluetooth"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bold"
                        data-bs-toggle="tooltip"
                        title="fe fe-bold"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="book"
                        data-bs-toggle="tooltip"
                        title="fe fe-book"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="book-open"
                        data-bs-toggle="tooltip"
                        title="fe fe-book-open"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="bookmark"
                        data-bs-toggle="tooltip"
                        title="fe fe-bookmark"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="box"
                        data-bs-toggle="tooltip"
                        title="fe fe-box"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="briefcase"
                        data-bs-toggle="tooltip"
                        title="fe fe-briefcase"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="calendar"
                        data-bs-toggle="tooltip"
                        title="fe fe-calendar"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="camera"
                        data-bs-toggle="tooltip"
                        title="fe fe-camera"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="camera-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-camera-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cast"
                        data-bs-toggle="tooltip"
                        title="fe fe-cast"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="check"
                        data-bs-toggle="tooltip"
                        title="fe fe-check"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="check-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-check-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="check-square"
                        data-bs-toggle="tooltip"
                        title="fe fe-check-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevron-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevron-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevron-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevron-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevron-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevron-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevron-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevron-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevrons-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevrons-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevrons-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevrons-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevrons-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevrons-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chevrons-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-chevrons-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="chrome"
                        data-bs-toggle="tooltip"
                        title="fe fe-chrome"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="clipboard"
                        data-bs-toggle="tooltip"
                        title="fe fe-clipboard"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="clock"
                        data-bs-toggle="tooltip"
                        title="fe fe-clock"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud-drizzle"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud-drizzle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud-lightning"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud-lightning"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud-rain"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud-rain"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cloud-snow"
                        data-bs-toggle="tooltip"
                        title="fe fe-cloud-snow"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="code"
                        data-bs-toggle="tooltip"
                        title="fe fe-code"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="codepen"
                        data-bs-toggle="tooltip"
                        title="fe fe-codepen"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="command"
                        data-bs-toggle="tooltip"
                        title="fe fe-command"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="compass"
                        data-bs-toggle="tooltip"
                        title="fe fe-compass"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="copy"
                        data-bs-toggle="tooltip"
                        title="fe fe-copy"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-down-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-down-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-down-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-down-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-left-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-left-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-left-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-left-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-right-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-right-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-right-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-right-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-up-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-up-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="corner-up-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-corner-up-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="cpu"
                        data-bs-toggle="tooltip"
                        title="fe fe-cpu"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="credit-card"
                        data-bs-toggle="tooltip"
                        title="fe fe-credit-card"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="crop"
                        data-bs-toggle="tooltip"
                        title="fe fe-crop"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="crosshair"
                        data-bs-toggle="tooltip"
                        title="fe fe-crosshair"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="database"
                        data-bs-toggle="tooltip"
                        title="fe fe-database"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="delete"
                        data-bs-toggle="tooltip"
                        title="fe fe-delete"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="disc"
                        data-bs-toggle="tooltip"
                        title="fe fe-disc"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="dollar-sign"
                        data-bs-toggle="tooltip"
                        title="fe fe-dollar-sign"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="download"
                        data-bs-toggle="tooltip"
                        title="fe fe-download"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="download-cloud"
                        data-bs-toggle="tooltip"
                        title="fe fe-download-cloud"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="droplet"
                        data-bs-toggle="tooltip"
                        title="fe fe-droplet"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="edit"
                        data-bs-toggle="tooltip"
                        title="fe fe-edit"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="edit-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-edit-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="edit-3"
                        data-bs-toggle="tooltip"
                        title="fe fe-edit-3"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="external-link"
                        data-bs-toggle="tooltip"
                        title="fe fe-external-link"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="eye"
                        data-bs-toggle="tooltip"
                        title="fe fe-eye"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="eye-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-eye-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="facebook"
                        data-bs-toggle="tooltip"
                        title="fe fe-facebook"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="fast-forward"
                        data-bs-toggle="tooltip"
                        title="fe fe-fast-forward"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="feather"
                        data-bs-toggle="tooltip"
                        title="fe fe-feather"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="file"
                        data-bs-toggle="tooltip"
                        title="fe fe-file"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="file-minus"
                        data-bs-toggle="tooltip"
                        title="fe fe-file-minus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="file-plus"
                        data-bs-toggle="tooltip"
                        title="fe fe-file-plus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="file-text"
                        data-bs-toggle="tooltip"
                        title="fe fe-file-text"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="film"
                        data-bs-toggle="tooltip"
                        title="fe fe-film"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="filter"
                        data-bs-toggle="tooltip"
                        title="fe fe-filter"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="flag"
                        data-bs-toggle="tooltip"
                        title="fe fe-flag"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="folder"
                        data-bs-toggle="tooltip"
                        title="fe fe-folder"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="folder-minus"
                        data-bs-toggle="tooltip"
                        title="fe fe-folder-minus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="folder-plus"
                        data-bs-toggle="tooltip"
                        title="fe fe-folder-plus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="git-branch"
                        data-bs-toggle="tooltip"
                        title="fe fe-git-branch"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="git-commit"
                        data-bs-toggle="tooltip"
                        title="fe fe-git-commit"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="git-merge"
                        data-bs-toggle="tooltip"
                        title="fe fe-git-merge"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="git-pull-request"
                        data-bs-toggle="tooltip"
                        title="fe fe-git-pull-request"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="github"
                        data-bs-toggle="tooltip"
                        title="fe fe-github"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="gitlab"
                        data-bs-toggle="tooltip"
                        title="fe fe-gitlab"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="globe"
                        data-bs-toggle="tooltip"
                        title="fe fe-globe"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="grid"
                        data-bs-toggle="tooltip"
                        title="fe fe-grid"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="hard-drive"
                        data-bs-toggle="tooltip"
                        title="fe fe-hard-drive"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="hash"
                        data-bs-toggle="tooltip"
                        title="fe fe-hash"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="headphones"
                        data-bs-toggle="tooltip"
                        title="fe fe-headphones"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="heart"
                        data-bs-toggle="tooltip"
                        title="fe fe-heart"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="help-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-help-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="home"
                        data-bs-toggle="tooltip"
                        title="fe fe-home"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="image"
                        data-bs-toggle="tooltip"
                        title="fe fe-image"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="inbox"
                        data-bs-toggle="tooltip"
                        title="fe fe-inbox"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="info"
                        data-bs-toggle="tooltip"
                        title="fe fe-info"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="instagram"
                        data-bs-toggle="tooltip"
                        title="fe fe-instagram"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="italic"
                        data-bs-toggle="tooltip"
                        title="fe fe-italic"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="layers"
                        data-bs-toggle="tooltip"
                        title="fe fe-layers"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="layout"
                        data-bs-toggle="tooltip"
                        title="fe fe-layout"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="life-buoy"
                        data-bs-toggle="tooltip"
                        title="fe fe-life-buoy"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="link"
                        data-bs-toggle="tooltip"
                        title="fe fe-link"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="link-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-link-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="linkedin"
                        data-bs-toggle="tooltip"
                        title="fe fe-linkedin"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="list"
                        data-bs-toggle="tooltip"
                        title="fe fe-list"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="loader"
                        data-bs-toggle="tooltip"
                        title="fe fe-loader"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="lock"
                        data-bs-toggle="tooltip"
                        title="fe fe-lock"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="log-in"
                        data-bs-toggle="tooltip"
                        title="fe fe-log-in"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="log-out"
                        data-bs-toggle="tooltip"
                        title="fe fe-log-out"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="mail"
                        data-bs-toggle="tooltip"
                        title="fe fe-mail"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="map"
                        data-bs-toggle="tooltip"
                        title="fe fe-map"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="map-pin"
                        data-bs-toggle="tooltip"
                        title="fe fe-map-pin"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="maximize"
                        data-bs-toggle="tooltip"
                        title="fe fe-maximize"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="maximize-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-maximize-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="menu"
                        data-bs-toggle="tooltip"
                        title="fe fe-menu"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="message-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-message-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="message-square"
                        data-bs-toggle="tooltip"
                        title="fe fe-message-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="mic"
                        data-bs-toggle="tooltip"
                        title="fe fe-mic"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="mic-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-mic-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="minimize"
                        data-bs-toggle="tooltip"
                        title="fe fe-minimize"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="minimize-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-minimize-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="minus"
                        data-bs-toggle="tooltip"
                        title="fe fe-minus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="minus-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-minus-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="minus-square"
                        data-bs-toggle="tooltip"
                        title="fe fe-minus-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="monitor"
                        data-bs-toggle="tooltip"
                        title="fe fe-monitor"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="moon"
                        data-bs-toggle="tooltip"
                        title="fe fe-moon"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="more-horizontal"
                        data-bs-toggle="tooltip"
                        title="fe fe-more-horizontal"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="more-vertical"
                        data-bs-toggle="tooltip"
                        title="fe fe-more-vertical"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="move"
                        data-bs-toggle="tooltip"
                        title="fe fe-move"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="music"
                        data-bs-toggle="tooltip"
                        title="fe fe-music"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="navigation"
                        data-bs-toggle="tooltip"
                        title="fe fe-navigation"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="navigation-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-navigation-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="octagon"
                        data-bs-toggle="tooltip"
                        title="fe fe-octagon"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="package"
                        data-bs-toggle="tooltip"
                        title="fe fe-package"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="paperclip"
                        data-bs-toggle="tooltip"
                        title="fe fe-paperclip"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="pause"
                        data-bs-toggle="tooltip"
                        title="fe fe-pause"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="pause-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-pause-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="percent"
                        data-bs-toggle="tooltip"
                        title="fe fe-percent"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-call"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-call"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-forwarded"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-forwarded"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-incoming"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-incoming"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-missed"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-missed"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="phone-outgoing"
                        data-bs-toggle="tooltip"
                        title="fe fe-phone-outgoing"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="pie-chart"
                        data-bs-toggle="tooltip"
                        title="fe fe-pie-chart"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="play"
                        data-bs-toggle="tooltip"
                        title="fe fe-play"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="play-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-play-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="plus"
                        data-bs-toggle="tooltip"
                        title="fe fe-plus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="plus-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-plus-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="plus-square"
                        data-bs-toggle="tooltip"
                        title="fe fe-plus-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="pocket"
                        data-bs-toggle="tooltip"
                        title="fe fe-pocket"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="power"
                        data-bs-toggle="tooltip"
                        title="fe fe-power"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="printer"
                        data-bs-toggle="tooltip"
                        title="fe fe-printer"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="radio"
                        data-bs-toggle="tooltip"
                        title="fe fe-radio"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="refresh-ccw"
                        data-bs-toggle="tooltip"
                        title="fe fe-refresh-ccw"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="refresh-cw"
                        data-bs-toggle="tooltip"
                        title="fe fe-refresh-cw"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="repeat"
                        data-bs-toggle="tooltip"
                        title="fe fe-repeat"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="rewind"
                        data-bs-toggle="tooltip"
                        title="fe fe-rewind"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="rotate-ccw"
                        data-bs-toggle="tooltip"
                        title="fe fe-rotate-ccw"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="rotate-cw"
                        data-bs-toggle="tooltip"
                        title="fe fe-rotate-cw"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="rss"
                        data-bs-toggle="tooltip"
                        title="fe fe-rss"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="save"
                        data-bs-toggle="tooltip"
                        title="fe fe-save"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="scissors"
                        data-bs-toggle="tooltip"
                        title="fe fe-scissors"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="search"
                        data-bs-toggle="tooltip"
                        title="fe fe-search"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="send"
                        data-bs-toggle="tooltip"
                        title="fe fe-send"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="server"
                        data-bs-toggle="tooltip"
                        title="fe fe-server"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="settings"
                        data-bs-toggle="tooltip"
                        title="fe fe-settings"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="share"
                        data-bs-toggle="tooltip"
                        title="fe fe-share"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="share-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-share-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="shield"
                        data-bs-toggle="tooltip"
                        title="fe fe-shield"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="shield-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-shield-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="shopping-bag"
                        data-bs-toggle="tooltip"
                        title="fe fe-shopping-bag"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="shopping-cart"
                        data-bs-toggle="tooltip"
                        title="fe fe-shopping-cart"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="shuffle"
                        data-bs-toggle="tooltip"
                        title="fe fe-shuffle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="sidebar"
                        data-bs-toggle="tooltip"
                        title="fe fe-sidebar"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="skip-back"
                        data-bs-toggle="tooltip"
                        title="fe fe-skip-back"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="skip-forward"
                        data-bs-toggle="tooltip"
                        title="fe fe-skip-forward"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="slack"
                        data-bs-toggle="tooltip"
                        title="fe fe-slack"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="slash"
                        data-bs-toggle="tooltip"
                        title="fe fe-slash"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="sliders"
                        data-bs-toggle="tooltip"
                        title="fe fe-sliders"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="smartphone"
                        data-bs-toggle="tooltip"
                        title="fe fe-smartphone"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="speaker"
                        data-bs-toggle="tooltip"
                        title="fe fe-speaker"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="square"
                        data-bs-toggle="tooltip"
                        title="fe fe-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="star"
                        data-bs-toggle="tooltip"
                        title="fe fe-star"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="stop-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-stop-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="sun"
                        data-bs-toggle="tooltip"
                        title="fe fe-sun"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="sunrise"
                        data-bs-toggle="tooltip"
                        title="fe fe-sunrise"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="sunset"
                        data-bs-toggle="tooltip"
                        title="fe fe-sunset"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="tablet"
                        data-bs-toggle="tooltip"
                        title="fe fe-tablet"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="tag"
                        data-bs-toggle="tooltip"
                        title="fe fe-tag"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="target"
                        data-bs-toggle="tooltip"
                        title="fe fe-target"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="terminal"
                        data-bs-toggle="tooltip"
                        title="fe fe-terminal"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="thermometer"
                        data-bs-toggle="tooltip"
                        title="fe fe-thermometer"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="thumbs-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-thumbs-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="thumbs-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-thumbs-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="toggle-left"
                        data-bs-toggle="tooltip"
                        title="fe fe-toggle-left"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="toggle-right"
                        data-bs-toggle="tooltip"
                        title="fe fe-toggle-right"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="trash"
                        data-bs-toggle="tooltip"
                        title="fe fe-trash"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="trash-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-trash-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="trending-down"
                        data-bs-toggle="tooltip"
                        title="fe fe-trending-down"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="trending-up"
                        data-bs-toggle="tooltip"
                        title="fe fe-trending-up"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="triangle"
                        data-bs-toggle="tooltip"
                        title="fe fe-triangle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="truck"
                        data-bs-toggle="tooltip"
                        title="fe fe-truck"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="tv"
                        data-bs-toggle="tooltip"
                        title="fe fe-tv"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="twitter"
                        data-bs-toggle="tooltip"
                        title="fe fe-twitter"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="type"
                        data-bs-toggle="tooltip"
                        title="fe fe-type"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="umbrella"
                        data-bs-toggle="tooltip"
                        title="fe fe-umbrella"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="underline"
                        data-bs-toggle="tooltip"
                        title="fe fe-underline"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="unlock"
                        data-bs-toggle="tooltip"
                        title="fe fe-unlock"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="upload"
                        data-bs-toggle="tooltip"
                        title="fe fe-upload"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="upload-cloud"
                        data-bs-toggle="tooltip"
                        title="fe fe-upload-cloud"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="user"
                        data-bs-toggle="tooltip"
                        title="fe fe-user"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="user-check"
                        data-bs-toggle="tooltip"
                        title="fe fe-user-check"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="user-minus"
                        data-bs-toggle="tooltip"
                        title="fe fe-user-minus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="user-plus"
                        data-bs-toggle="tooltip"
                        title="fe fe-user-plus"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="user-x"
                        data-bs-toggle="tooltip"
                        title="fe fe-user-x"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="users"
                        data-bs-toggle="tooltip"
                        title="fe fe-users"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="video"
                        data-bs-toggle="tooltip"
                        title="fe fe-video"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="video-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-video-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="voicemail"
                        data-bs-toggle="tooltip"
                        title="fe fe-voicemail"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="volume"
                        data-bs-toggle="tooltip"
                        title="fe fe-volume"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="volume-1"
                        data-bs-toggle="tooltip"
                        title="fe fe-volume-1"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="volume-2"
                        data-bs-toggle="tooltip"
                        title="fe fe-volume-2"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="volume-x"
                        data-bs-toggle="tooltip"
                        title="fe fe-volume-x"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="watch"
                        data-bs-toggle="tooltip"
                        title="fe fe-watch"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="wifi"
                        data-bs-toggle="tooltip"
                        title="fe fe-wifi"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="wifi-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-wifi-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="wind"
                        data-bs-toggle="tooltip"
                        title="fe fe-wind"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="x"
                        data-bs-toggle="tooltip"
                        title="fe fe-x"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="x-circle"
                        data-bs-toggle="tooltip"
                        title="fe fe-x-circle"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="x-square"
                        data-bs-toggle="tooltip"
                        title="fe fe-x-square"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="zap"
                        data-bs-toggle="tooltip"
                        title="fe fe-zap"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="zap-off"
                        data-bs-toggle="tooltip"
                        title="fe fe-zap-off"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="zoom-in"
                        data-bs-toggle="tooltip"
                        title="fe fe-zoom-in"
                      ></vue-feather>
                    </li>
                    <li>
                      <vue-feather
                        type="zoom-out"
                        data-bs-toggle="tooltip"
                        title="fe fe-zoom-out"
                      ></vue-feather>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Feather Icon",
      text: "Dashboard",
      text1: "Feather Icon",
      name: "/index",
    };
  },

  name: "icon-feather",
};
</script>
