<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span class="badges bg-lightgreen" v-if="record.Status === 'Approved'">{{
                record.Status
              }}</span>
              <span class="badges bg-lightred" v-else>{{ record.Status }}</span></template
            >
            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.reference }}</router-link
              >
            </template>
            <template v-else-if="column.key === 'pay'">
              <span class="badges bg-lightgreen" v-if="record.Payment === 'Paid'">{{
                record.Payment
              }}</span>
              <span class="badges bg-lightred" v-else>{{
                record.Payment
              }}</span></template
            >
            <template v-else-if="column.key === 'action'">
              <td class="action-table-data">
                <div class="edit-delete-action">
                  <a
                    class="me-2 p-2"
                    href="#edit-coupon"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <vue-feather type="edit"></vue-feather>
                  </a>
                  <a
                    class="confirm-text p-2"
                    @click="showConfirmation"
                    href="#delete-coupon"
                  >
                    <vue-feather type="trash-2"></vue-feather>
                  </a>
                </div>
              </td>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <saleslistmodal
    @paymentAddedListner="getSales"
    :order_id="selected_order_id"
    :sale_id="selected_sale_id"
  ></saleslistmodal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";

const columns = [
  {
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Code",
    dataIndex: "Code",
    key: "Code",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "Type",
    sorter: {
      compare: (a, b) => {
        a = a.Type.toLowerCase();
        b = b.Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Discount",
    dataIndex: "Discount",
    sorter: {
      compare: (a, b) => {
        a = a.Discount.toLowerCase();
        b = b.Discount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Limit",
    dataIndex: "Limit",
    sorter: {
      compare: (a, b) => {
        a = a.Limit.toLowerCase();
        b = b.Limit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Used",
    dataIndex: "Used",
    sorter: {
      compare: (a, b) => {
        a = a.Used.toLowerCase();
        b = b.Used.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Valid",
    dataIndex: "Valid",
    sorter: {
      compare: (a, b) => {
        a = a.Valid.toLowerCase();
        b = b.Valid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Name: "Coupons 21",
    Code_class: "badge badge-bgdanger",
    Code: "Christmas",
    Type: "Fixed",
    Discount: "$20",
    Limit: "4",
    Used: "1",
    Valid: "04 Jan 2023",
    Status_class: "badge badge-linesuccess",
    Status: "Active",
  },
  {
    id: "2",
    Name: "First Offer",
    Class_class: "badge badge-bgdanger",
    Code: "First Offer",
    Type: "Percentage",
    Discount: "10%",
    Limit: "47",
    Used: "10",
    Valid: "15 Feb 2023",
    Status_class: "badge badge-linesuccess",
    Status: "Active",
  },
  {
    id: "3",
    Name: "Offer 40",
    Code_class: "badge badge-bgdanger",
    Code: "40% Offer",
    Type: "Fixed",
    Discount: "$20",
    Limit: "21",
    Used: "14",
    Valid: "08 Apr 2023",
    Status_class: "badge badge-linesuccess",
    Status: "Active",
  },
  {
    id: "4",
    Name: "Subscription",
    Code_class: "badge badge-bgdanger",
    Code: "FirstSub01",
    Type: "Fixed",
    Discount: "$20",
    Limit: "9",
    Used: "7",
    Valid: "12 Aug 2023",
    Status_class: "badge badge-linesuccess",
    Status: "Active",
  },
];
export default {
  data() {
    return {
      filters: ["customer_name", "reference", "customer_account_type"],
      title: "Coupons",
      title1: "Manage your coupons",
      items: [
        {
          text: "Add Coupon",
          addname: "#add-coupon",
        },
      ],
      selected_order_id: null,
      selected_sale_id: null,
      columns,
      data,
      Completed: ["Completed", "Paid"],
    };
  },
  computed: {
    ...mapGetters(["hasAccess"]),
  },
  methods: {
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },
  mounted() {},
  name: "manage-coupons",
};
</script>
