<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table
          :filters="['name', 'account_number']"
          :data="accounts"
          :columns="columns"
          @pageChange="getUsersAccount($event)"
          @filter="getUsersAccount({ query: $event.query })"
          :paginationConfig="paginationConfig"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <div class="d-flex gap-2">
                <span
                  style="cursor: pointer;"
                  class="bg-lightgreen badges"
                  @click="selectUser(record.account_number)"
                  >Credit</span
                >
                <span
                  v-if="hasAccess('debit-user')"
                  style="cursor: pointer;"
                  data-bs-toggle="modal"
                  data-bs-target="#account-modal"
                  class="bg-danger badges"
                  @click="selectUser(record.account_number, 'Debit')"
                  >Debit</span
                >
                <span
                  v-if="hasDues(record)"
                  class="badges"
                  style="
                    cursor: pointer;
                    border: 1px solid #28c76f;
                    color: #28c76f;
                  "
                  title="This will use the user Balance to Clear all His/Her Dues"
                  @click="clearDues(record.account_number)"
                  >Clear Dues</span
                >
              </div>
            </template>
            <template v-if="column.key == 'account_number'">
              <router-link
                title="Click to View User Transactions"
                :to="'/users-account/' + record.account_number"
                >{{ record.account_number }}</router-link
              >
            </template>
          </template>
        </f-table>
      </div>
    </div>

    <useraccountmodal
      :_action="userAction"
      @completeListener="getUsersAccount"
      :_user="selectedUser"
    >
    </useraccountmodal>
    <!-- For WOB Return -->
    <crate-report-modal
      :action="crate.modalAction"
      :customTypes="crate.returnType"
    ></crate-report-modal>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Number",
    key: "account_number",
    dataIndex: "account_number",
    sorter: {
      compare: (a, b) => {
        a = a.account_number;
        b = b.account_number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    sorter: (a, b) => a.balance.localeCompare(b.balance),
  },
  {
    title: "Due",
    dataIndex: "due",
    sorter: (a, b) => a.due.localeCompare(b.due),
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import { crateModalActionTypes } from "../crate/constants";

export default {
  data() {
    return {
      filter: false,
      title: "Users Account",
      title1: "Manage your User",
      items: [],
      selectedUser: null,
      accounts: [],
      userAction: "Credit",
      selectedSummary: {},
      columns,
      crate: {
        returnType: ["WOB"],
        modalAction: {},
      },
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters({ hasAccess: "hasAccess" }),
  },
  methods: {
    ...mapGetters(["formatAmount"]),
    ...mapActions({
      _getUsersAccount: "users/getUsersAccount",
      _clearDues: "users/clearDues",
    }),
    selectUser(account_number, action = "Credit") {
      this.userAction = action;
      this.selectedUser = this.accounts.find(
        (account) => account.account_number == account_number
      );

      if (action == "Credit") {
        $("#account-modal").modal("show");
      }
    },
    getUsersAccount({ page = null, per_page = null, query = null } = {}) {
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };
      this.searchQuery = query;
      this._getUsersAccount({ params }).then((apiResponse) => {
        const response = apiResponse.data;
        this.accounts = response.data.map((user) => {
          return {
            id: user.id,
            name: user.name,
            account_number: user.account_number,
            balance: user.balance,
            due: user.due,
          };
        });
        this.paginationConfig = response.pagination;
      });
    },
    clearDues(accountNumber) {
      this._clearDues({ accountNo: accountNumber }).then((res) => {
        this.getUsersAccount();
      });
    },
    hasDues(record) {
      let due = Number.parseInt(record.due.replace(",", ""));
      let balance = Number.parseInt(record.balance.replace(",", ""));
      if (due != 0 && balance > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  mounted() {
    this.getUsersAccount();
  },
  name: "useraccount",
};
</script>
