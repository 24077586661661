<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <a class="product-img">
                <img :src="record.img" alt="product" />
              </a>
            </template>
            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" to="/editsubcategory">
                <img src="@/assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a
                class="me-3 confirm-text"
                href="javascript:void(0);"
                @click="showAlert"
              >
                <img src="@/assets/img/icons/delete.svg" alt="img" />
              </a>
            </template> </template
        ></f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

const columns = [
  {
    sorter: true,
  },
  {
    title: "Image",
    dataIndex: "img",
    key: "id",
    sorter: true,
  },
  {
    title: "Category",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Parent category",
    dataIndex: "parent_category",
    sorter: {
      compare: (a, b) => {
        a = a.parent_category.toLowerCase();
        b = b.parent_category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category Code",
    dataIndex: "code",
    sorter: {
      compare: (a, b) => {
        a = a.code.toLowerCase();
        b = b.code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created By",
    dataIndex: "CreatedBy",
    sorter: {
      compare: (a, b) => {
        a = a.CreatedBy.toLowerCase();
        b = b.CreatedBy.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filters: ["name", "code"],
      title: "Product Sub Category list",
      title1: "View/Search product Category",
      items: [
        {
          text: "Add Sub Category",
          addname: "/subaddcategory",
        },
      ],
      Category: ["Choose Category", "Computers"],
      Choose: ["Choose Sub Category", "Fruits"],
      CT001: ["CT001", "CT002"],
      data: [],
      columns,
    };
  },
  methods: {
    ...mapActions({
      _getSubCate: "subcategory/get",
    }),
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
    getSubCategories() {
      this.data = [];
      this._getSubCate({ category_id: "" }).then((response) => {
        this.data = response.data.data.map((subcategory) => {
          return {
            id: subcategory.id,
            img: subcategory.image,
            name: subcategory.name,
            parent_category: subcategory.category.name,
            description: subcategory.description,
            CreatedBy: subcategory.admin_id,
            code: subcategory.category.code,
          };
        });
      });
    },
  },
  mounted() {
    this.getSubCategories();
  },
  name: "subcategorylist",
};
</script>
