<template>
 <!-- Main Wrapper -->
 <div class="main-wrapper">
    <headerpos></headerpos>
        <sidebarfour></sidebarfour>
    
    <div class="page-wrapper page-wrapper-four">
        <div class="content">
            
            <indexpage></indexpage>
            
        </div>
    </div>

</div>
</template>