<template>
  <slot :filteredData="filtered" name="cardTop"> </slot>
  <div :class="'card ' + extraClass">
    <div class="card-body">
      <slot :filteredData="filtered" name="top"> </slot>
      <div v-if="search" class="table-top">
        <div :class="`search-set ${searchClass}`">
          <div class="search-input">
            <a class="btn btn-searchset d-sm-none"
              ><img src="../../../assets/img/icons/search-white.svg" alt="img"
            /></a>
            <div
              style="
                position: relative;
                gap: 2px;
                display: flex;
                align-items: center;
              "
            >
              <label>
                <span
                  style="
                    font-family: inherit;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    letter-spacing: 0.04rem;
                    font-size: 11px;
                    color: #637381;
                    background: #fff;
                  "
                  >{{ title }}</span
                >
                <input
                  class="form-control form-control-sm table-search"
                  placeholder="Search ..."
                  :title="title"
                  v-model="filter.query"
                  @change="filterTable()"
                />
              </label>
              <button
                @click="filterTable()"
                class="btn btn-sm btn-primary slide-in-left"
                v-if="filter.query"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div>
          <slot :filteredData="filtered" name="header"> </slot>
        </div>
      </div>
      <div
        class="table-responsive"
        :style="{ ...tableStyles, '--table-height': tableHeight }"
      >
        <a-table
          :pagination="pagination"
          :columns="columns"
          :data-source="filtered"
          :row-selection="rowSelection"
        >
          <template v-slot:bodyCell="{ column, record }">
            <template v-for="(_, name) in $slots">
              <!-- Skip Slot if Name is not bodyCell -->
              <slot
                v-if="name == 'bodyCell'"
                v-bind="{ column, record }"
                :name="name"
              ></slot>
            </template>
          </template>
        </a-table>
        <slot :data="filtered" name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    filters: Array,
    data: Array,
    searchWidth: String,
    extraClass: String,
    searchClass: String,
    paginationConfig: Object,
    tableStyles: Object,
    tableHeight: null,
    rowSelection: null,
  },
  data() {
    return {
      search: false,
      title: null,
      filter: {
        value: null,
      },
      filtered: this.$props.data,
      pagination: {},
    };
  },
  methods: {
    handlePageChange(page, per_page) {
      this.$emit("pageChange", { page, per_page });
    },
    filterTable() {
      this.$emit("filter", { query: this.filter.query });
      const filters = this.filters || [];
      const canFilter = this.filter.query === "" || filters.length === 0;
      this.filtered = canFilter
        ? this.data
        : this.data.filter((order) =>
            filters.some((filter) =>
              order[filter]
                .toLowerCase()
                .includes(this.filter.query.toLowerCase())
            )
          );
    },
    updatePagination() {
      if (this.$props.paginationConfig) {
        const paginationConfig = this.$props.paginationConfig;
        this.pagination.onChange = this.handlePageChange;
        this.pagination.current = paginationConfig.current_page;
        this.pagination.pageSize = paginationConfig.per_page;
        this.pagination.total =
          paginationConfig.total_pages * paginationConfig.per_page;
      }
    },
  },
  mounted() {
    this.filtered = this.$props.data;
    if (this.$props.filters) {
      this.search = true;
      this.title =
        "Search by " +
        this.$props.filters.flatMap((_filter) => {
          let filterName = " " + _filter.replace("_", " ");
          return filterName;
        });
    } else {
      this.title = "Search Not Supported";
      this.search = false;
    }
  },
  watch: {
    "filter.query": {
      handler(searchQuery) {
        if (searchQuery == "") {
          this.filtered = this.$props.data;
          this.$emit("filter", { query: "" });
        }
      },
    },
    data: {
      handler(_data) {
        this.filtered = _data;
      },
    },
    paginationConfig: {
      handler() {
        this.updatePagination();
      },
      deep: true,
      immediate: true,
    },
  },
  name: "f-table",
};
</script>
<style>
.table-responsive .ant-select div {
  overflow-y: inherit !important;
}
</style>
