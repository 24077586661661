<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card" v-if="user">
          <div class="card-body" id="user-transactions">
            <div class="card-sales-split">
              <h2>Total Transactions of ({{ accountNumber }})</h2>

              <div class="dropdown">
                <a
                  href="javascript:void(0);"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Export Data
                </a>

                <div class="dropdown-menu">
                  <a
                    class="mx-0"
                    v-for="(exportOption, index) in exportOptions"
                    :key="index"
                  >
                    <span
                      class="border-0 dropdown-item"
                      @click="excuteDownload(exportOption)"
                      >{{ exportOption }}</span
                    >
                  </a>
                </div>
              </div>

              <!-- <ul>
                <li>
                  <a @click="downloadTransactionReport" href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/printer.svg" alt="img"
                  /></a>
                </li>
              
                <li>
                  <a @click="downloadOrderReport()" href="javascript:void(0);"
                    ><img src="../../../assets/img/icons/order.svg" alt="img"
                  /></a>
                </li>
              </ul> -->
            </div>
            <div
              class="invoice-box table-height"
              style="
                max-width: 1600px;
                width: 100%;
                overflow: auto;
                margin: 15px auto;
                padding: 0;
                font-size: 14px;
                line-height: 24px;
                color: #555;
              "
            >
              <table
                cellpadding="0"
                cellspacing="0"
                style="width: 100%; line-height: inherit; text-align: left;"
              >
                <tbody>
                  <tr class="top">
                    <td colspan="6" style="padding: 5px; vertical-align: top;">
                      <table
                        style="
                          width: 100%;
                          line-height: inherit;
                          text-align: left;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              "
                            >
                              <font
                                style="
                                  vertical-align: inherit;
                                  margin-bottom: 25px;
                                "
                              >
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >Account Info</font
                                > </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  Customer Name
                                </font> </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  Account Number</font
                                > </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  Balance</font
                                > </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                >
                                  Due</font
                                > </font
                              ><br />
                            </td>
                            <td
                              style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              "
                            >
                              <font
                                style="
                                  vertical-align: inherit;
                                  margin-bottom: 25px;
                                "
                              >
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  "
                                  >&nbsp;</font
                                > </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  "
                                  >{{ user.name }}
                                </font> </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  "
                                >
                                  {{ user.account_number }}
                                </font>
                              </font>
                              <br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  "
                                >
                                  ₦ {{ user.balance }}</font
                                > </font
                              ><br />
                              <font style="vertical-align: inherit;">
                                <font
                                  style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  "
                                >
                                  ₦ {{ user.due }}</font
                                > </font
                              ><br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <f-table
                :filters="history[historyType].filters"
                :data="history[historyType].data"
                :columns="history[historyType].columns"
              >
                <template #header>
                  <vue-select
                    style="width: 200px;"
                    v-model="historyType"
                    :options="historyTypes"
                    placeholder="History Type"
                  />
                </template>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key == 'action'">
                    <span
                      class="bg-lightgreen badges"
                      data-bs-toggle="modal"
                      data-bs-target="#viewDetail"
                      style="cursor: pointer;"
                      @click="setSelectedDescription(record.note)"
                    >
                      View Note
                    </span>
                    <span
                      v-if="record.type == 'Credit' && isVendor"
                      class="ms-2 badges success-border"
                      style="cursor: pointer;"
                      @click="reverseTransaction(record.id)"
                    >
                      Reverse
                    </span>
                  </template>
                  <template v-if="column.key == 'order_reference'">
                    <router-link
                      title="Click to View Order"
                      :to="'/orders/' + record.id"
                      >{{ record.order_reference }}</router-link
                    >
                  </template>
                </template>
              </f-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="viewDetail"
      data-bs-focus="false"
      tabindex="-1"
      aria-labelledby="viewDetail"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Transaction Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <p>{{ selectedDescription }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import html2pdf from "html2pdf.js";
import { mapActions, mapGetters } from "vuex";

const outstandingColumn = [
  {
    sorter: true,
  },
  {
    title: "Order Reference",
    dataIndex: "order_reference",
    key: "order_reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference;
        b = b.reference;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: {
      compare: (a, b) => {
        a = a.total;
        b = b.total;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Paid",
    dataIndex: "paid",
    sorter: (a, b) => a.paid.localeCompare(b.paid),
  },
  {
    title: "Due",
    dataIndex: "due",
    sorter: {
      compare: (a, b) => {
        a = a.due.toLowerCase();
        b = b.due.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const transactionColumn = [
  {
    sorter: true,
  },
  {
    title: "Reference",
    dataIndex: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference;
        b = b.reference;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount;
        b = b.amount;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Method",
    dataIndex: "method",
    sorter: {
      compare: (a, b) => {
        a = a.method.toLowerCase();
        b = b.method.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Added By",
    dataIndex: "added_by",
    sorter: {
      compare: (a, b) => {
        a = a.added_by.toLowerCase();
        b = b.added_by.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const correctionColumn = [
  {
    sorter: true,
  },
  {
    title: "Reference",
    dataIndex: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference;
        b = b.reference;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount;
        b = b.amount;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Admin",
    dataIndex: "admin",
    sorter: {
      compare: (a, b) => {
        a = a.added_by.toLowerCase();
        b = b.added_by.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      historyTypes: ["Transactions", "Outstanding"],
      historyType: "Transactions",
      title: "User Transactions",
      title1: "View User Transactions",
      selectedDescription: null,
      transactions: [],
      accountNumber: null,
      user: null,

      exportOptions: ["Transaction Report", "Order Report"],
      selectedOption: null,
      history: {
        Transactions: {
          columns: transactionColumn,
          data: [],
          filters: ["reference", "amount"],
        },
        Outstanding: {
          columns: outstandingColumn,
          data: [],
          filters: ["reference", "amount"],
        },
        "System Correction": {
          columns: correctionColumn,
          data: [],
          filters: ["amount"],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["formatAmount", "isSuperAdmin", "isVendor"]),
  },
  methods: {
    ...mapGetters(["formatAmount"]),
    ...mapActions({
      _getTransactions: "users/getTransactions",
    }),
    excuteDownload(exportOption) {
      switch (exportOption) {
        case this.exportOptions[0]:
          this.downloadTransactionReport();
          break;
        case this.exportOptions[1]:
          this.downloadOrderReport();
          break;
      }
      this.selectedOption = null;
    },
    setSelectedDescription(description) {
      this.selectedDescription = description;
    },
    getTransactions() {
      let accountNumber = this.$route.params.accountNumber;
      this.accountNumber = accountNumber;
      this._getTransactions({ accountNo: accountNumber }).then((response) => {
        let data = response.data.data;
        this.history.Outstanding.data = data.outstanding.map((order) => {
          return {
            id: order.id,
            order_reference: order.reference,
            total: order.total,
            paid: order.paid,
            due: order.due,
            date: order.date,
          };
        });
        this.history.Transactions.data = data.transactions.map(
          (transaction) => {
            return {
              id: transaction.id,
              reference: transaction.reference,
              amount: transaction.amount,
              type: transaction.type,
              method: transaction.method,
              note: transaction.note,
              date: transaction.date,
              added_by: transaction.admin.name,
            };
          }
        );
        this.history["System Correction"].data = data.corrections.map(
          (transaction) => {
            return {
              reference: transaction.reference,
              amount: transaction.amount,
              type: transaction.type,
              method: transaction.method,
              note: transaction.note,
              date: transaction.date,
              admin: transaction.admin.name,
            };
          }
        );
        this.user = response.data.data.user;
      });
    },
    reverseTransaction(transactionId) {
      store.commit("confirm", {
        title: "Payments Made with this Transaction Will be Reversed Too!",
        callback: (response) => {
          if (response) {
            store
              .dispatch("users/reverseTransaction", {
                transactionId: transactionId,
              })
              .then((response) => {
                this.getTransactions();
                store.commit("notify", {
                  title: "Transaction Reversed Successfully",
                  description: "",
                  type: "success",
                });
              });
          }
        },
      });
    },
    downloadTransactionReport() {
      store
        .dispatch("users/downloadTransactions", {
          accountNo: this.accountNumber,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.accountNumber}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    },
    downloadOrderReport() {
      store
        .dispatch("users/downloadOrders", { accountNo: this.accountNumber })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.accountNumber}-ORDER.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  mounted() {
    if (this.isSuperAdmin || this.isVendor) {
      this.historyTypes.push("System Correction");
    }
    this.getTransactions();
  },
  name: "usertransactions",
};
</script>
