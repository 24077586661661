<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table
          @filter="fetchProducts({ query: $event.query })"
          @pageChange="fetchProducts($event)"
          :paginationConfig="paginationConfig"
          :filters="filters"
          :columns="columns"
          :data="data"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <img :src="record.img" alt="Image" />
                </a>
                <a href="javascript:void(0);">{{ record.product_name }}</a>
              </div>
            </template>
            <template v-if="column.key == 'admin'">
              <a :href="'admin' + record.admin.id">
                {{ record.admin.name }}
              </a>
            </template>
            <template v-if="column.key == 'stocked_desc'">
              <a href="#" @click="viewInventoryHistory(record.id)">
                {{ record.stocked_desc }}
              </a>
            </template>
            <template v-if="column.key == 'qty'">
              <span v-if="record.Qty == 0" class="badges bg-lightred"
                >Out Of Stock</span
              >
              <span v-else>
                {{ record.Qty }}
              </span>
            </template>

            <template v-else-if="column.key === 'action'">
              <div class="text-center gap-2">
                <a-button
                  @click="openRestock(record)"
                  v-if="hasAccess('restock-product')"
                  >Restock</a-button
                >
                <a
                  class="action-set"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      class="me-3 dropdown-item"
                      :to="'products/' + record.id"
                    >
                      View Details
                    </router-link>
                  </li>
                  <li>
                    <a
                      v-if="canDelete"
                      class="dropdown-item confirm-text"
                      href="javascript:void(0);"
                      @click="daleteProduct(record.id)"
                    >
                      Delete
                    </a>
                  </li>
                  <li>
                    <router-link
                      class="me-3 dropdown-item"
                      :to="'editproduct/' + record.id"
                    >
                      Edit
                    </router-link>
                  </li>
                  <li>
                    <a
                      v-if="hasFullAccess"
                      class="dropdown-item confirm-text"
                      href="javascript:void(0);"
                      @click="openRestock(record, true)"
                    >
                      Quantity Correction
                    </a>
                  </li>
                </ul>
              </div>
            </template>
            <template v-if="column.key === 'status'">
              <span v-if="!hasAccess('update-product')">
                {{ record.status ? "Active" : "InActive" }}
              </span>

              <div
                v-if="hasAccess('update-product')"
                class="status-toggle d-flex justify-content-between align-items-center"
              >
                <input
                  :name="record.id"
                  type="checkbox"
                  :id="record.id"
                  :checked="record.status"
                  @change="
                    updateProductStatus(record.id, $event.target.checked)
                  "
                  class="check"
                />
                <label :for="record.id" class="checktoggle">checkbox</label>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <InventoryHistory :productId="modalProductId" />
  <Restock
    :product="modalProduct"
    @updateCompleted="fetchProducts()"
    :is-correction="restockModal.isCorrection"
  />
</template>
<script>
import { formatAmount } from "@/helpers";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import InventoryHistory from "./modals/inventory-history.vue";
import Restock from "./modals/restock.vue";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Product Name",
    dataIndex: "product_name",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.product_name.toLowerCase();
        b = b.product_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SKU",
    dataIndex: "SKU",
    sorter: (a, b) => a.SKU.localeCompare(b.SKU),
  },
  {
    title: "Last Stocked",
    key: "stocked_desc",
    sorter: {
      compare: (a, b) => {
        a = a.stocked_desc.toLowerCase();
        b = b.stocked_desc.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Available",
    dataIndex: "available_qty_desc",
    sorter: {
      compare: (a, b) => {
        a = a.available_qty_desc.toLowerCase();
        b = b.available_qty_desc.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Crate Price",
    dataIndex: "crate_price",
    sorter: {
      compare: (a, b) => {
        a = a.crate_price.toLowerCase();
        b = b.crate_price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Bottle Price",
    dataIndex: "bottle_price",
    sorter: {
      compare: (a, b) => {
        a = a.bottle_price.toLowerCase();
        b = b.bottle_price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created By",
    key: "admin",
    dataIndex: "admin",
    sorter: {
      compare: (a, b) => {
        a = a.admin.toLowerCase();
        b = b.admin.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "status",
    sorter: true,
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const units = {
  onlyBottle: "Bottle",
  onlyCrate: "Crate",
  bottleAndCrate: "Bottle & Crate",
};
export default {
  data() {
    return {
      filter: false,
      filters: ["SKU", "product_name"],
      title: "Product List",
      title1: "Manage your products",
      items: [
        {
          text: "Add New Product",
          addname: "/addproduct",
        },
      ],
      Product: ["Choose Product", "Macbook pro", "Orange"],
      Category: ["Choose Sub Category", "Computer"],
      Choose: ["Choose Category", "Computers", "Fruits"],
      Brand: ["Brand", "N/D"],
      Price: ["Price", "150.00"],
      data: [],
      modalProduct: null,
      modalProductId: null,
      restockModal: {
        isCorrection: false,
      },
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
      columns,
      units,
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
      hasFullAccess: "hasFullAccess",
    }),
    canDelete() {
      return store.getters.isSuperAdmin || store.getters.isVendor;
    },
  },
  methods: {
    openRestock(product, isCorrection = false) {
      this.modalProduct = product;
      this.restockModal.isCorrection = isCorrection;
      $("#productRestock").modal("show");
    },
    viewInventoryHistory(productId) {
      this.modalProductId = productId;
      $("#inventoryHistory").modal("show");
    },
    daleteProduct(productId) {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteProduct(productId).then((res) => {
            const index = this.data.findIndex((p) => p.id === productId);
            this.data.splice(index, 1);
            store.commit("notify", {
              type: "success",
              title: "Product Deleted",
            });
          });
        }
      });
    },
    ...mapActions({
      _fetchProducts: "product/get",
      _updateProducts: "product/update",
      _deleteProduct: "product/delete",
    }),
    formatPriceByUnit(product, unit) {
      const priceDetails = {
        crate_price: product.crate_price,
        bottle_price: product.bottle_price,
      };

      if (unit == units.onlyBottle) {
        priceDetails.bottle_price =
          product.bottle_price > 0 ? product.bottle_price : "--";
        priceDetails.crate_price = "--";
      } else if (unit == units.onlyCrate) {
        priceDetails.crate_price =
          product.crate_price > 0 ? product.crate_price : "--";
        priceDetails.bottle_price = "--";
      }

      return {
        crate_price:
          priceDetails.crate_price != "--"
            ? formatAmount(priceDetails.crate_price, true)
            : "--",
        bottle_price:
          priceDetails.bottle_price != "--"
            ? formatAmount(priceDetails.bottle_price, true)
            : "--",
      };
    },
    fetchProducts({ page = null, per_page = null, query = null } = {}) {
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };
      this.searchQuery = query;
      let status = this.$route.meta.status || "active";
      this._fetchProducts({ status: status, params })
        .then((apiResponse) => {
          const response = apiResponse.data;
          this.data = response.data.map((product) => {
            const priceDetails = this.formatPriceByUnit(product, product.unit);
            return {
              id: product.id,
              img: product.images[0]?.url,
              product_name: product.name,
              SKU: product.sku,
              Category: product.category.name,
              Brand: product.brand.name,
              crate_price: priceDetails.crate_price,
              bottle_price: priceDetails.bottle_price,
              stocked_desc: product.stocked_desc,
              qty_per_crate: product.qty_per_crate,
              excess_bottles: product.excess_bottles,
              available_quantity: product.available_quantity,
              available_qty_desc: product.available_qty_desc,
              admin: product.admin,
              unit: product.unit,
              status: product.is_active == 1 ? true : false,
            };
          });
          this.paginationConfig = response.pagination;
        })
        .catch((error) => {
          console.info(error);
          Swal.fire("Can't Fetch Products");
        });
    },
    updateProductStatus(product_id, new_status) {
      this._updateProducts({
        product_id: product_id,
        payload: { is_active: new_status },
      })
        .then((response) => {
          Swal.fire({
            title: "Product Updated",
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchProducts();

    if (!this.hasAccess("create-products")) {
      this.items = [];
    }
  },
  watch: {
    "$route.meta.status": {
      handler() {
        this.fetchProducts();
        this.paginationConfig = { current_page: 1, per_page: 10 };
      },
    },
  },
  components: { InventoryHistory, Restock },
  name: "productlist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
