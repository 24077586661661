<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'product'">
              <td class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <img :src="record.product.image" alt="product" />
                </a>

                <a style="" :href="'products/' + record.product.id">{{
                  record.product.name
                }}</a>
              </td>
            </template>
            <template v-else-if="column.key === 'Status'">
              <span class="badges bg-lightgreen" v-if="record.Status === 'Processed'">{{
                record.Status
              }}</span>
              <span class="badges bg-lightyellow" v-else-if="record.Status === 'Pending'">{{ record.Status }}</span>
              <span class="badges bg-lightred" v-else="record.Status === 'Pending'">{{
                record.Status
              }}</span></template>
            <template v-else-if="column.key === 'pay'">
              <span class="badges bg-lightgreen" v-if="record.PaymentStatus === 'Approved'">{{ record.PaymentStatus
              }}</span>

              <span class="badges bg-lightred" v-else="record.PaymentStatus === 'Unpaid'">{{ record.PaymentStatus
              }}</span></template>
            <template v-else-if="column.key === 'reference'">
              <router-link title="Click to View Order" :to="'orders/' + record.order_id">{{ record.reference
              }}</router-link>
            </template>
            <template v-else-if="column.key === 'action'">
              <div class="d-flex gap-2" v-if="record.Status != 'Processed'">
                <span v-if="hasAccess('.*approve-sales-return.*')" style="cursor: pointer"
                  v-on:click="approveSalesReturn(record.id)" class="badges bg-lightgreen">Process</span>
                <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert">
                  <img src="../../../assets/img/icons/delete.svg" alt="img" />
                </a>
              </div>
              <!-- <router-link class="me-3" to="/editpurchasereturn">
                      <img src="../../../assets/img/icons/edit.svg" alt="img" />
                    </router-link> -->
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },
  {
    title: "Order Reference",
    dataIndex: "reference",
    key: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer",
    dataIndex: "customer_name",
    sorter: {
      compare: (a, b) => {
        a = a.customer_name.toLowerCase();
        b = b.customer_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Product Name",
    key: "product",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Returned Quantity",
    dataIndex: "Quantity",
    key: "Quantity",
    sorter: {
      compare: (a, b) => {
        a = a.Quantity.toLowerCase();
        b = b.Quantity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: (a, b) => a.Date.localeCompare(b.Date),
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "Total",
    sorter: {
      compare: (a, b) => {
        a = a.Total.toLowerCase();
        b = b.Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filters: ["customer_name", "reference", "product_name"],
      title: "Sales Return List",
      title1: "Manage your Returns",
      items: [
        {
          text: "Add New Sales Return",
          addname: "/createsalesreturn",
        },
      ],
      data: [],
      columns,
      startdate: currentDate,
      Choose: ["Choose Customer", "Customer["],
      Status: ["Choose Status", "Inprogress"],
      Payment: ["Choose Payment Status", "Payment Status"],
    };
  },
  computed: {
    ...mapGetters(["formatAmount", "hasAccess"]),
  },
  methods: {
    ...mapActions({
      _getSalesReturn: "saleReturn/get",
      _approveSalesReturn: "saleReturn/approve",
    }),
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
    getSalesReturn() {
      this.data = [];
      this._getSalesReturn().then((response) => {
        response.data.data.map((sale_return) => {
          this.data.push({
            id: sale_return.id,
            Quantity: sale_return.qty,
            Date: sale_return.date,
            customer_name: sale_return.user.name,
            reference: sale_return.order.reference,
            order_id: sale_return.order.id,
            Status: sale_return.status,
            Total: this.formatAmount(sale_return.total),
            product: sale_return.product,
            product_name: sale_return.product.name,
          });
        });
      });
    },
    approveSalesReturn(id) {
      this._approveSalesReturn({
        sales_return_product_id: id,
      })
        .then((response) => {
          Swal.fire({
            title: "Sale Return Approved",
            icon: "success",
          });
          this.getSalesReturn();
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.getSalesReturn();
  },
  name: "salesreturnlist",
};
</script>
