<template>
  <div>
    <!-- Return Crate Modal -->
    <div class="modal fade" id="returncrate" data-bs-focus="false" tabindex="-1" aria-labelledby="returncrate"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" v-if="action.type == actionTypes.CRATE_RETURN">
          <div class="modal-header">
            <h5 class="modal-title">Return Crate</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="userDetails">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Username</label>
                  <input type="text" readonly :value="userDetails.user_name" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Select Crate Type</label>
                  <vue-select name="crate_type" required v-model="crateReturn.product_id" :options="crateTypes"
                    placeholder="Select a Crate Type" />
                  <span class="text-danger" v-if="errors.crate_type">
                    {{ errors.crate_type }}</span>
                </div>
              </div>

              <div v-if="selectedCrateDetail">
                <div v-if="selectedCrateDetail?.pending == 0">
                  <h6 style="color: #212b36" class="fw-semibold">
                    {{ userDetails.user_name }} Has no Pending
                    {{ selectedCrateDetail.name }} Crate
                  </h6>
                </div>
                <!-- RETURN FORM -->
                <div class="row" v-else>
                  <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-group">
                      <label>Enter Returned Quantity
                        <i class="badge bg-dark bg-opacity-50">
                          PENDING: {{ selectedCrateDetail?.pending }}
                        </i>
                      </label>
                      <input min="1" :max="selectedCrateDetail?.pending" name="returned" v-model="crateReturn.returned"
                        type="number" class="form-control" required />
                      <span class="text-danger" v-if="errors.returned">
                        {{ errors.returned }}
                      </span>
                    </div>
                  </div>

                  <!-- WOB FORM 
                  <div class="row col-12 m-0 p-0" v-if="crateReturn.return_type == 'WOB'">
                    <h5 class="fw-bold my-2 mb-4">WOB Form</h5>

                    <div class="col-lg-6 col-sm-12 col-12">
                      <div class="form-group">
                        <label>WOB Price</label>
                        <input type="text" readonly :value="formatAmount(selectedCrateDetail?.wob_price, true)" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                      <div class="form-group">
                        <label>Pending Due</label>
                        <input type="text" readonly :value="formatAmount(requiredAmount, true)" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                      <div class="form-group">
                        <label>Received Amount</label>
                        <input name="received_amount" class="form-control" type="number"
                          v-model="crateReturn.received_amount" :max="requiredAmount" />
                        <span class="text-danger" v-if="errors.received_amount">
                          {{ errors.received_amount }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-12">
                      <div class="form-group">
                        <label>Payment Method</label>
                        <vue-select v-model="crateReturn.payment_method" :options="paymentMethods"
                          placeholder="Payment Method" />
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="userDetails && selectedCrateDetail">
            <button id="submit" @click="returnCrate" type="button" class="btn btn-submit"
              v-if="selectedCrateDetail?.pending > 0">
              Submit
            </button>
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Crate Details Modal -->
    <div class="modal fade" id="cratedetails" data-bs-focus="false" tabindex="-1" aria-labelledby="cratedetails"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" v-if="action.type == actionTypes.CRATE_DETAILS">
          <div class="modal-header">
            <h5 class="modal-title">Crate Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <f-table :columns="crateDetails.columns" :data="crateDetails.data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'status'">
                  <div class="d-flex align-items-center">
                    <span style="cursor: pointer" :class="[
          'badges',
          record.status == 'Returned' ? 'bg-lightgreen' : 'bg-lightgrey',
        ]">
                      {{ record.status }}
                    </span>
                  </div>
                </template>
                <template v-if="column.key === 'wob'">
                  <div class="d-flex align-items-center">
                    <span>
                      {{ formatAmount(record.wob, true) }}
                    </span>
                  </div>
                </template>
              </template>
            </f-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { crateModalActionTypes } from "../constants";
import { formatTimeStamp, dateIsToday, stringToSlug } from "@/helpers";

const crateDetailsColumns = [
  {
    sorter: true,
  },
  {
    title: "Crate Type",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total.localeCompare(b.total),
  },
  {
    title: "Pending",
    dataIndex: "pending",
    sorter: (a, b) => a.pending.localeCompare(b.pending),
  },
  {
    title: "Returned",
    dataIndex: "returned",
    sorter: (a, b) => a.returned.localeCompare(b.returned),
  },
  {
    title: "WOB",
    dataIndex: "wob",
    key: "wob",
    sorter: (a, b) => a.wob.localeCompare(b.wob),
  },
  {
    title: "Status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
];

export default {
  props: {
    action: Object,
    customTypes: Array,
    selectedReturnType: String
  },
  data() {
    return {
      access_code: null,
      crateReturn: {
        returned: null,
        return_type: null,
        received_amount: 0,
        product_id: null,
      },
      errors: {},
      crateDetails: {
        columns: crateDetailsColumns,
        data: [],
      },
      actionTypes: crateModalActionTypes,
    };
  },
  computed: {
    ...mapGetters({
      formatAmount: "formatAmount",
      hasAccess: "hasAccess",
    }),
    selectedCrateDetail() {
      // find the selected crate type
      // get the pending from it
      let selectedCrateDetail = this.crateDetails.data.find(
        (crateDetail) => crateDetail.product_id == this.crateReturn.product_id
      );
      return selectedCrateDetail;
    },
    /** Amount the User is Owning */
    requiredAmount() {
      return this.selectedCrateDetail?.pending * this.selectedCrateDetail?.wob_price;
    },
    crateTypes() {
      return this.crateDetails.data.map((crateDetail) => {
        return {
          text: crateDetail.name,
          id: crateDetail.product_id,
        };
      });
    },
  },
  methods: {
    validate() {
      store
        .dispatch("checkInputValidity", { callbackElementId: "submit" })
        .then((result) => {
          this.errors = result;
        });
    },
    returnCrate() {
      store
        .dispatch("crate/returnOrderCrate", {
          userId: this.userDetails.user_id,
          payload: { ...this.crateReturn },
        })
        .then((res) => {
          store.commit("notify", {
            title: "Crate Returned Successfully",
            type: "success",
          });
          this.resetData();
          this.$emit("crateReturned");
          $("#returncrate").modal("hide");
        });
    },
    resetData() {
      this.crateReturn = {
        returned: null,
        received_amount: 0,
        product_id: null,
      };
    },
    getCrateReport4User(userId, dateRange = {}) {
      store
        .dispatch("crate/getCrateReports", { dateRange: dateRange, userId: userId })
        .then((response) => {
          if (!dateRange.startDate || dateRange.startDate) {
            this.summaryDate = dateIsToday(dateRange.startDate)
              ? "Today"
              : formatTimeStamp(dateRange.startDate, false);
          }
          this.crateDetails.data = response.data.data;
        });
    },
  },
  mounted() {
  },
  watch: {
    userDetails: {
      handler(_new) {
        this.resetData();
      },
      deep: true,
    },
    crateReturn: {
      handler(_new) {
        this.validate();
      },
      deep: true,
    },
    action: {
      handler(action) {
        this.resetData();
        this.getCrateReport4User(action.data.user_id, action.data.dateRange);
        switch (action.type) {
          case this.actionTypes.CRATE_DETAILS:
            break;

          case this.actionTypes.CRATE_RETURN:
            this.userDetails = action.data;
            break;

          default:
            break;
        }
      },
      deep: true,
    },
  },
};
</script>
