import { api } from "@/config";

export const payments = {
  url(order_id, id = "") {
    let path = `/${order_id}/payments`;
    if (id != "") {
      path = "/" + id;
    }
    return `/orders${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { order_id }) {
      return api.get(payments.url(order_id));
    },
    getPaymentReport({ context }, { dateRange }) {
      return api.get(`/payment-report`, {
        params: {
          start_date: dateRange.startDate,
          end_date: dateRange.endDate,
        },
      });
    },
    show({ context }, { order_id }) {
      return api.get(payments.url(order_id));
    },
    add({ context }, { order_id, payload }) {
      return api.post(payments.url(order_id), payload);
    },
  },
};
