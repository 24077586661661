<template>
  <div>
    <!-- show payment Modal -->
    <div class="modal fade" id="account-modal" data-bs-focus="false" tabindex="-1" aria-labelledby="account-modal"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ _action }} User</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="_action == 'Credit'" class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Customer</label>
                  <div class="input-groupicon">
                    <input class="form-control" :value="user.name" readonly />
                    <div class="addonset">
                      <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Account Number</label>
                  <input type="text" readonly :value="user.account_number" />
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Balance</label>
                  <input type="text" readonly :value="user.balance" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Due</label>
                  <input type="text" readonly :value="user.due" />
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Amount</label>
                  <input required class="form-control" name="amount" v-model="new_credit.amountPreview" type="text" />
                  <span class="text-danger" v-if="errors.amount">
                    {{ errors.amount }}
                  </span>
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Payment Method</label>
                  <vue-select v-model="new_credit.method" :options="paymentMethods" placeholder="Cash" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Note</label>
                  <textarea required name="note" v-model="new_credit.note" class="form-control"></textarea>

                  <span class="text-danger" v-if="errors.note">
                    {{ errors.note }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Customer</label>
                  <div class="input-groupicon">
                    <input class="form-control" :value="user.name" readonly />
                    <div class="addonset">
                      <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Account Number</label>
                  <input type="text" readonly :value="user.account_number" />
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Balance</label>
                  <input type="text" readonly :value="user.balance" />
                </div>
              </div>

              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>Amount</label>
                  <input required class="form-control" name="amount" v-model="newDebit.amountPreview" type="text" />
                  <span class="text-danger" v-if="errors.amount">
                    {{ errors.amount }}
                  </span>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group mb-0">
                  <label>Note</label>
                  <textarea required name="note" v-model="newDebit.note" class="form-control"></textarea>

                  <span class="text-danger" v-if="errors.note">
                    {{ errors.note }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="submit" type="button" class="btn btn-submit" v-on:click="checkAccess()">
              Submit
            </button>
            <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { notification } from "ant-design-vue";
import store from "@/store";
const currentDate = ref(new Date());
export default {
  props: {
    _user: Object,
    _action: String,
  },
  data() {
    return {
      startdate: currentDate,
      access_code: null,
      user: {},
      errors: {},
      options: {
        duration: 30000000,
      },
      newDebit: {
        amount: null,
        note: null,
        access_code: null,
      },
      new_credit: {
        method: "Cash",
        amount: null,
        note: null,
        access_code: null,
      },
      paymentMethods: ["Cash", "POS", "Bank / Transfer", "Card"],
      payments: [],
    };
  },
  computed: {
    ...mapGetters({
      formatAmount: "formatAmount",
      hasAccess: "hasAccess",
    }),
    ...mapState(["adminPermissions"]),
  },
  methods: {
    ...mapMutations({
      _notify: "notify",
    }),
    ...mapActions({
      _creditUser: "users/credit",
      _showOtpDialog: "showOtpDialog",
      _sendAccessCode: "accessCode/add",
      checkInputValidity: "checkInputValidity",
    }),
    verifyOtp() {
      this._showOtpDialog({
        title: "Enter Access Code Code",
        description: "Access Code Sent to the Sales Manager!",
      }).then((response) => {
        this.access_code = response.value;
        this.new_credit.access_code = this.access_code;
        // get the access code
        if (this.access_code != null) {
          this.$props._action == "Credit" ? this.creditUser() : this.debitUser();
        } else {
          Swal.fire("You need the Authorization Payment Code");
        }
      });
    },
    checkAccess() {
      let adminPermissions = this.adminPermissions;
      if (adminPermissions.length > 0) {
        // check if the user is the super admin
        if (adminPermissions[0] == "*" || this.hasAccess("credit-user")) {
          this.$props._action == "Credit" ? this.creditUser() : this.debitUser();
        } else {
          this._sendAccessCode({
            permission_code:
              this.$props._action == "Credit" ? "credit-user" : "debit-user",
            item_id: this.user.account_number,
            extras: {
              amount: this.$props._action == "Credit" ? this.new_credit.amount : this.newDebit.amount
            }
          }).then((response) => {
            this.verifyOtp();
          });
          Swal.fire({
            title: "Sending Authorization Code To Sales Manager",
            html: `<div class="spinner-border text-dark" role="status"></div>`,
            closeOnClickOutside: false,
            buttons: false,
          });
        }
      }
    },
    debitUser() {
      store
        .dispatch("users/debit", {
          accountNo: this.user.account_number,
          payload: this.newDebit,
        })
        .then((response) => {
          this.newDebit = {
            amount: null,
            note: null,
            access_code: null,
          };
          $(".modal").modal("hide");
          this._notify({
            title: "Account Debited",
            message: this.user.name + "'s Account Has Successfully been Debited",
          });
          this.$emit("completeListener");
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
    creditUser() {
      this._creditUser({
        accountNo: this.user.account_number,
        payload: this.new_credit,
      })
        .then((response) => {
          this.new_credit = {
            method: "Cash",
            amount: null,
            note: null,
          };
          $(".modal").modal("hide");
          this._notify({
            title: "Account Credited",
            message: this.user.name + "'s Account Has Successfully been Credited",
          });
          this.$emit("completeListener");
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
    validateForm() {
      this.checkInputValidity({ callbackElementId: "submit" }).then((errors) => {
        this.errors = errors;
      });
    },
  },
  mounted() {
  },
  watch: {
    "new_credit.amountPreview": {
      handler(val) {
        let amount = Number.parseInt(val?.replaceAll(",", "")) || 0;
        this.new_credit.amount = amount;
        this.new_credit.amountPreview = amount.toLocaleString("en-US");
      },
    },
    "newDebit.amountPreview": {
      handler(val) {
        let amount = Number.parseInt(val?.replaceAll(",", "")) || 0;
        this.newDebit.amount = amount;
        this.newDebit.amountPreview = amount.toLocaleString("en-US");
      },
    },
    new_credit: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
    newDebit: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
    _user: {
      handler(_new) {
        this.user = this.$props._user;
      },
    },
  },
};
</script>
