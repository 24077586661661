<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <!-- Row -->
        <div class="row">
          <div class="col-sm-12">
            <!-- Custom Boostrap Validation -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Custom Bootstrap Form Validation</h5>
                <p class="card-text">
                  For custom Bootstrap form validation messages, you’ll need to add the
                  <code>novalidate</code> boolean attribute to your form. For server side
                  validation
                  <a
                    href="https://getbootstrap.com/docs/4.1/components/forms/#server-side"
                    target="_blank"
                    >read full documentation</a
                  >.
                </p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm">
                    <b-form class="needs-validation" novalidate>
                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <label for="validationCustom01">First name</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationCustom01"
                            placeholder="First name"
                            value="Mark"
                            required
                          ></b-form-input>
                          <div class="valid-feedback">Looks good!</div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="validationCustom02">Last name</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationCustom02"
                            placeholder="Last name"
                            value="Otto"
                            required
                          ></b-form-input>
                          <div class="valid-feedback">Looks good!</div>
                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="validationCustomUsername">Username</label>
                          <div class="input-group">
                            <span class="input-group-text" id="inputGroupPrepend">@</span>
                            <b-form-input
                              type="text"
                              class="form-control"
                              id="validationCustomUsername"
                              placeholder="Username"
                              aria-describedby="inputGroupPrepend"
                              required
                            ></b-form-input>
                            <div class="invalid-feedback">Please choose a username.</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label for="validationCustom03">City</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationCustom03"
                            placeholder="City"
                            required
                          ></b-form-input>
                          <div class="invalid-feedback">Please provide a valid city.</div>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="validationCustom04">State</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationCustom04"
                            placeholder="State"
                            required
                          ></b-form-input>
                          <div class="invalid-feedback">
                            Please provide a valid state.
                          </div>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="validationCustom05">Zip</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationCustom05"
                            placeholder="Zip"
                            required
                          ></b-form-input>
                          <div class="invalid-feedback">Please provide a valid zip.</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="invalidCheck"
                            required
                          />
                          <label class="form-check-label" for="invalidCheck">
                            Agree to terms and conditions
                          </label>
                          <div class="invalid-feedback">
                            You must agree before submitting.
                          </div>
                        </div>
                      </div>
                      <b-button variant="primary" type="submit">Submit form</b-button>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Custom Boostrap Validation -->

            <!-- Default Browser Validation -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Browser defaults</h5>
                <p class="card-text">
                  Not interested in custom validation feedback messages or writing
                  JavaScript to change form behaviors? All good, you can use the browser
                  defaults. Try submitting the form below.
                </p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm">
                    <b-form>
                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <label for="validationDefault01">First name</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationDefault01"
                            placeholder="First name"
                            value="Mark"
                            required
                          ></b-form-input>
                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="validationDefault02">Last name</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationDefault02"
                            placeholder="Last name"
                            value="Otto"
                            required
                          ></b-form-input>
                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="validationDefaultUsername">Username</label>
                          <div class="input-group">
                            <span class="input-group-text" id="inputGroupPrepend2"
                              >@</span
                            >
                            <b-form-input
                              type="text"
                              class="form-control"
                              id="validationDefaultUsername"
                              placeholder="Username"
                              aria-describedby="inputGroupPrepend2"
                              required
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label for="validationDefault03">City</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationDefault03"
                            placeholder="City"
                            required
                          ></b-form-input>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="validationDefault04">State</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationDefault04"
                            placeholder="State"
                            required
                          ></b-form-input>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="validationDefault05">Zip</label>
                          <b-form-input
                            type="text"
                            class="form-control"
                            id="validationDefault05"
                            placeholder="Zip"
                            required
                          ></b-form-input>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="invalidCheck2"
                            required
                          />
                          <label class="form-check-label" for="invalidCheck2">
                            Agree to terms and conditions
                          </label>
                        </div>
                      </div>
                      <b-button variant="primary" type="submit">Submit form</b-button>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Default Browser Validation -->

            <!-- Server Side Validation -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Server side</h5>
                <p class="card-text">
                  We recommend using client side validation, but in case you require
                  server side, you can indicate invalid and valid form fields with
                  <code>.is-invalid</code> and <code>.is-valid</code>. Note that
                  <code>.invalid-feedback</code> is also supported with these classes.
                </p>
              </div>
              <div class="card-body">
                <b-form>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="validationServer01">First name</label>
                      <b-form-input
                        type="text"
                        class="form-control is-valid"
                        id="validationServer01"
                        placeholder="First name"
                        value="Mark"
                        required
                      ></b-form-input>
                      <div class="valid-feedback">Looks good!</div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationServer02">Last name</label>
                      <b-form-input
                        type="text"
                        class="form-control is-valid"
                        id="validationServer02"
                        placeholder="Last name"
                        value="Otto"
                        required
                      ></b-form-input>
                      <div class="valid-feedback">Looks good!</div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationServerUsername">Username</label>
                      <div class="input-group">
                        <span class="input-group-text" id="inputGroupPrepend3">@</span>
                        <b-form-input
                          type="text"
                          class="form-control is-invalid"
                          id="validationServerUsername"
                          placeholder="Username"
                          aria-describedby="inputGroupPrepend3"
                          required
                        ></b-form-input>
                        <div class="invalid-feedback">Please choose a username.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="validationServer03">City</label>
                      <b-form-input
                        type="text"
                        class="form-control is-invalid"
                        id="validationServer03"
                        placeholder="City"
                        required
                      ></b-form-input>
                      <div class="invalid-feedback">Please provide a valid city.</div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="validationServer04">State</label>
                      <b-form-input
                        type="text"
                        class="form-control is-invalid"
                        id="validationServer04"
                        placeholder="State"
                        required
                      ></b-form-input>
                      <div class="invalid-feedback">Please provide a valid state.</div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="validationServer05">Zip</label>
                      <b-form-input
                        type="text"
                        class="form-control is-invalid"
                        id="validationServer05"
                        placeholder="Zip"
                        required
                      ></b-form-input>
                      <div class="invalid-feedback">Please provide a valid zip.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-check">
                      <input
                        class="form-check-input is-invalid"
                        type="checkbox"
                        value=""
                        id="invalidCheck3"
                        required
                      />
                      <label class="form-check-label" for="invalidCheck3">
                        Agree to terms and conditions
                      </label>
                      <div class="invalid-feedback">
                        You must agree before submitting.
                      </div>
                    </div>
                  </div>
                  <b-button variant="primary" type="submit">Submit form</b-button>
                </b-form>
              </div>
            </div>
            <!-- /Server Side Validation -->

            <!-- Supported Elements -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Supported elements</h5>
                <p class="card-text">
                  Form validation styles are also available for bootstrap custom form
                  controls.
                </p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm">
                    <b-form class="was-validated">
                      <div class="form-check form-checkbox mb-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customControlValidation1"
                          required
                        />
                        <label class="form-check-label" for="customControlValidation1"
                          >Check this custom checkbox</label
                        >
                        <div class="invalid-feedback">Example invalid feedback text</div>
                      </div>
                      <div class="form-check form-radio">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="customControlValidation2"
                          name="radio-stacked"
                          required
                        />
                        <label class="form-check-label" for="customControlValidation2"
                          >Toggle this custom radio</label
                        >
                      </div>
                      <div class="form-check form-radio mb-3">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="customControlValidation3"
                          name="radio-stacked"
                          required
                        />
                        <label class="form-check-label" for="customControlValidation3"
                          >Or toggle this other custom radio</label
                        >
                        <div class="invalid-feedback">
                          More example invalid feedback text
                        </div>
                      </div>
                      <div class="form-group">
                        <vue-select
                          :options="Numbers"
                          placeholder="Open this select menu"
                        />
                        <div class="invalid-feedback">
                          Example invalid custom select feedback
                        </div>
                      </div>

                      <div class="mb-3">
                        <input
                          type="file"
                          class="form-control"
                          aria-label="file example"
                          required
                        />
                        <div class="invalid-feedback">
                          Example invalid form file feedback
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Supported Elements -->

            <!-- Validation Tooltips -->
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Tooltips</h5>
                <p class="card-text">
                  You can swap the <code>.{valid|invalid}-feedback</code> classes for
                  <code>.{valid|invalid}-tooltip</code> classes to display validation
                  feedback in a styled tooltip.
                </p>
              </div>
              <div class="card-body">
                <b-form class="needs-validation" novalidate>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="validationTooltip01">First name</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        placeholder="First name"
                        value="Mark"
                        required
                      ></b-form-input>
                      <div class="valid-tooltip">Looks good!</div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationTooltip02">Last name</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltip02"
                        placeholder="Last name"
                        value="Otto"
                        required
                      ></b-form-input>
                      <div class="valid-tooltip">Looks good!</div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="validationTooltipUsername">Username</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltipUsername"
                        placeholder="Username"
                        required
                      ></b-form-input>
                      <div class="invalid-tooltip">
                        Please choose a unique and valid username.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="validationTooltip03">City</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltip03"
                        placeholder="City"
                        required
                      ></b-form-input>
                      <div class="invalid-tooltip">Please provide a valid city.</div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="validationTooltip04">State</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltip04"
                        placeholder="State"
                        required
                      ></b-form-input>
                      <div class="invalid-tooltip">Please provide a valid state.</div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="validationTooltip05">Zip</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        id="validationTooltip05"
                        placeholder="Zip"
                        required
                      ></b-form-input>
                      <div class="invalid-tooltip">Please provide a valid zip.</div>
                    </div>
                  </div>
                  <b-button variant="primary" type="submit">Submit form</b-button>
                </b-form>
              </div>
            </div>
            <!-- /Validation Tooltips -->
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Numbers: ["Open this select menu", "One", "Two", "Three"],
      title: "Form Validation",
      text: "Dashboard",
      text1: "Form Validation",
      name: "/index",
    };
  },
  name: "form-validation",
};
</script>
