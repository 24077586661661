<template>
  <div>
    <center style="margin-top: 25vh;" v-if="!hasDashBoardAccess()">
      <h3>Looks Like You Can't Access The Dashboard :(.</h3>
      <br />
      <a href="mailto:admin@inventory.com">Contact The Admin</a>
    </center>
    <div
      v-if="(isSuperAdmin || isVendor) & (Object.values(dashboard).length > 0)"
      class="row"
    >
      <div class="">
        <h3 class="card-title mb-3 mt-3">{{ titles }}</h3>

        <div
          class="col-lg-4 col-sm-6 col-12 d-flex mb-3 gap-2"
          style="padding-right: 25px;"
        >
          <a-range-picker class="col-10" @change="setSpotDate" />
          <button class="btn btn-primary" @click="getSpotCheck()">Fetch</button>
        </div>
        <br />
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das1">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.total_stock_amount) }}</h4>
            <h5>Total Stock Amount</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
        <span class="tooltip">This is An Estimated Amount</span>
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das2">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.dispatched_due) }}</h4>
            <h5>Total Dispatched Pending Payments</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>

      <router-link
        to="/inventoryreport"
        class="report col-lg-3 col-sm-6 col-12 d-flex"
      >
        <div class="dash-count das3">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.empties_due) }}</h4>
            <h5>Empties Due</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="credit-card"></vue-feather>
          </div>
        </div>
      </router-link>
      <router-link
        to="/inventoryreport"
        class="report col-lg-3 col-sm-6 col-12 d-flex"
        v-if="spotCheck.data.remaining_products"
      >
        <div class="dash-count das5">
          <div class="dash-counts">
            <h4 v-if="!!spotCheck.data.remaining_products.crates">
              {{ spotCheck.data.remaining_products.crates }} Crates
            </h4>
            <h4 v-if="!!spotCheck.data.remaining_products.bottles">
              {{ spotCheck.data.remaining_products.bottles }} Bottles
            </h4>
            <h5>Total Available Stock</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="pie-chart"></vue-feather>
          </div>
        </div>
      </router-link>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das5">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.vsr_due) }}</h4>
            <h5>Total VSR Due</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das3">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.wholeseller_due) }}</h4>
            <h5>Total WholeSeller Due</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das2">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.guiness_direct_due) }}</h4>
            <h5>Total Guinness Direct Due</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>
      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das4">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.se_due) }}</h4>
            <h5>Total SE Due</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="canAccess('saleslist') && Object.values(dashboard).length > 0"
      class="row"
    >
      <div class="">
        <h3 class="card-title mb-3 mt-3">{{ title }}</h3>

        <div
          class="col-lg-4 col-sm-6 col-12 d-flex mb-3 gap-2"
          style="padding-right: 25px;"
        >
          <a-range-picker class="col-10" @change="onDateChange" />
          <button class="btn btn-primary" @click="getAdminDashboard">
            Fetch
          </button>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget dash2">
          <div class="dash-widgetimg">
            <span
              ><img src="../../../assets/img/icons/dash3.svg" alt="img"
            /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              <vue3-autocounter
                class="counter"
                ref="counter"
                :startAmount="0"
                :delay="3"
                :endAmount="dashboard.total_new_orders"
                :duration="3"
                :autoinit="true"
              />
            </h5>
            <h6>Total Orders</h6>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget">
          <div class="dash-widgetimg">
            <span
              ><img src="../../../assets/img/icons/order.svg" alt="img"
            /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              ₦<vue3-autocounter
                class="counter"
                ref="counter"
                :startAmount="0"
                :delay="3"
                :endAmount="dashboard.orders_amount"
                :duration="5"
                :autoinit="true"
              />
            </h5>
            <h6>Total Orders Amount</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget dash1">
          <div class="dash-widgetimg">
            <span
              ><img src="../../../assets/img/icons/cash1.svg" alt="img"
            /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              ₦<vue3-autocounter
                class="counter"
                ref="counter"
                :startAmount="0"
                :delay="3"
                :endAmount="dashboard.total_new_payments"
                :duration="5"
                :autoinit="true"
              />
            </h5>
            <h6>Total Payments</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget dash3">
          <div class="dash-widgetimg">
            <span
              ><img src="../../../assets/img/icons/users1.svg" alt="img"
            /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              <vue3-autocounter
                class="counter"
                ref="counter"
                :startAmount="0"
                :delay="3"
                :endAmount="dashboard.total_new_users"
                :duration="5"
                :autoinit="true"
              />
            </h5>
            <h6>New Customers</h6>
          </div>
        </div>
      </div>
      <!-- <div hidden class="report col-lg-2 col-sm-6 col-12 d-flex">
      <div class="dash-count">
        <div class="dash-counts">
          <h4>{{ dashboard.total_new_users }}</h4>
          <h5>New Customers</h5>
        </div>
        <div class="dash-imgs">
          <vue-feather type="user"></vue-feather>
        </div>
      </div>
    </div> -->
      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das1">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.payments.balance) }}</h4>
            <h5>Total Balance Payment</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="credit-card"></vue-feather>
          </div>
        </div>
      </div>
      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das2">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.payments.rabitlala) }}</h4>
            <h5>Total Rabitlala Payment</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das4">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.payments.pos) }}</h4>
            <h5>Total POS Payment</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>

      <div class="report col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das5">
          <div class="dash-counts">
            <h4>₦{{ formatAmount(spotCheck.data.payments.bank_transfer) }}</h4>
            <h5>Total Bank / Transfer Payment</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="bar-chart"></vue-feather>
          </div>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->
    <div class="row" v-if="Object.values(dashboard).length > 0">
      <div class="col-lg-7 col-sm-12 col-12 d-flex">
        <div v-if="canAccess('saleslist')" class="card flex-fill">
          <div
            class="card-header pb-0 d-flex justify-content-between align-items-center"
          >
            <h5 class="card-title mb-0">Purchase & Sales</h5>
            <div class="graph-sets">
              <ul>
                <li class="me-4">
                  <span>Sales</span>
                </li>
                <li class="ms-2">
                  <span>Purchase</span>
                </li>
              </ul>
              <div class="dropdown">
                <button
                  class="btn btn-white btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  2022
                  <img
                    src="../../../assets/img/icons/dropdown.svg"
                    alt="img"
                    class="ms-2"
                  />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2022</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2021</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2020</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <apexchart
              type="area"
              height="300"
              :options="basicAreaChart.sline"
              :series="basicAreaChart.series"
            >
            </apexchart> -->
          </div>
        </div>
      </div>
      <!-- class="col-lg-5 col-sm-12 col-12 d-flex" -->
      <div v-if="hasAccess('products')" class="col-lg-5 col-sm-12 col-12">
        <div class="card flex-fill" style="height: 400px;">
          <div
            class="card-header pb-0 d-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-0">Recently Added Products</h4>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="dropset"
              >
                <i class="fa fa-ellipsis-v"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <router-link to="/products" class="dropdown-item"
                    >Product List</router-link
                  >
                </li>
                <li>
                  <router-link to="/addproduct" class="dropdown-item"
                    >Product Add</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <a-table
                :columns="productColumns"
                :data-source="newProducts"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                @change="onChange"
              >
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key == 'sno'">
                    <span>{{ index + 1 }}</span>
                  </template>

                  <template v-else-if="column.key == 'name'">
                    <router-link :to="'products/' + record.id">{{
                      record.name
                    }}</router-link>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="card flex-fill" style="height: 400px;">
          <div
            class="card-header pb-0 d-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-0">Goods on Transit</h4>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="dropset"
              >
                <i class="fa fa-ellipsis-v"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <router-link to="/products" class="dropdown-item"
                    >Product List</router-link
                  >
                </li>
                <li>
                  <router-link to="/addproduct" class="dropdown-item"
                    >Product Add</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <a-table
                :columns="productColumns"
                :data-source="newProducts"
                @change="onChange"
              >
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key == 'sno'">
                    <span>{{ index + 1 }}</span>
                  </template>

                  <template v-else-if="column.key == 'name'">
                    <router-link :to="'products/' + record.id">{{
                      record.name
                    }}</router-link>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canAccess('products')" class="card mb-0">
      <div class="card-body">
        <h4 class="card-title">New Orders</h4>
        <div class="table-responsive">
          <a-table
            :columns="orderColumns"
            :data-source="newOrders"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            @change="onChange"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'status'">
                <span
                  class="badges bg-lightgreen"
                  v-if="record.Status === 'Completed'"
                  >{{ record.Status }}</span
                >
                <span
                  class="badges bg-lightred"
                  v-else-if="record.Status === 'Pending'"
                  >{{ record.Status }}</span
                >
                <span class="badges bg-lightgrey" v-else>{{
                  record.Status
                }}</span></template
              >
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <div v-if="hasAccess('approved-sales')" class="card mb-0 mt-4 p-4">
      <f-table
        :columns="warehouse.summary.columns"
        :data="warehouse.summary.data"
        :filters="warehouse.summary.filters"
      >
        <template #cardTop>
          <div class="">
            <apexchart
              :options="warehouse.chart.options"
              :series="warehouse.chart.series"
              height="450"
            ></apexchart>
          </div>

          <div class="mb-3">
            <h4 class="card-title">Warehouse Summary</h4>
            <div class="d-flex justify-content-between">
              <div class="d-md-flex gap-1">
                <a-range-picker
                  style="width: 250px;"
                  @change="setWarehouseDate"
                />
                <!-- <a-date-picker style="width: 250px" @change="setWarehouseDate" /> -->
                <button
                  style="margin: 5px;"
                  class="btn btn-primary"
                  @click="getWarehouseSummary"
                >
                  Fetch
                </button>
              </div>
            </div>
          </div>
        </template>
        <template #header="{ filteredData }">
          <div class="">
            <div class="d-flex justify-content-between gap-3 mb-2">
              <span>Total Purchase: </span>
              <span class="fw-bold">{{
                dataIsUnique(filteredData, "product_name")
                  ? sumArrayKey(filteredData, "qty")
                  : "---"
              }}</span>
            </div>
            <div class="d-flex justify-content-between gap-3">
              <span>Available Qty: </span>
              <span class="fw-bold">{{
                dataIsUnique(filteredData, "product_name")
                  ? filteredData[0]?.available_qty
                  : "---"
              }}</span>
            </div>
          </div>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'product'">
            <div class="productimgname">
              <a href="javascript:void(0);" class="product-img">
                <img :src="record.product.image" alt="product" />
              </a>
              <a href="javascript:void(0);">{{ record.product.name }}</a>
            </div>
          </template>

          <template v-else-if="column.key === 'reference'">
            <router-link
              title="Click to View Order"
              :to="'orders/' + record.order_id"
              >{{ record.reference }}</router-link
            >
          </template>

          <template v-else-if="column.key === 'status'">
            <span
              class="badges bg-lightgreen"
              v-if="record.status == 'Completed' || record.status == 'Approved'"
              >{{ record.status }}</span
            >
            <span
              class="badges bg-lightred"
              v-else-if="record.status === 'Pending'"
              >{{ record.status }}</span
            >
            <span class="badges bg-lightgrey" v-else>{{
              record.status
            }}</span></template
          >
        </template>
      </f-table>
    </div>
    <div v-if="hasAccess('access-.*-sales')" class="card mb-0 mt-4">
      <div class="card-body">
        <div class="mb-3">
          <h4 class="card-title">Sales Summary</h4>
          <div class="d-flex justify-content-between">
            <div class="d-md-flex gap-1">
              <a-range-picker style="width: 250px;" @change="setSalesDate" />
              <button
                style="margin: 5px;"
                class="btn btn-primary"
                @click="getSalesSummary"
              >
                Fetch
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <f-table
            :columns="sales.summary.columns"
            :data="sales.summary.data"
            :filters="sales.summary.filters"
          >
            <template #header="{ filteredData }">
              <div class="">
                <div class="d-flex justify-content-between gap-3 mb-2">
                  <span>Total Purchase: </span>
                  <span class="fw-bold">{{
                    formatAmount(
                      sumArrayKey(filteredData, "total_amount"),
                      true
                    )
                  }}</span>
                </div>
              </div>
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'reference'">
                <router-link
                  title="Click to View Order"
                  :to="'orders/' + record.order_id"
                  >{{ record.reference }}</router-link
                >
              </template>

              <template v-else-if="column.key === 'status'">
                <span
                  class="badges bg-lightgreen"
                  v-if="
                    record.status == 'Completed' || record.status == 'Approved'
                  "
                  >{{ record.status }}</span
                >
                <span
                  class="badges bg-lightred"
                  v-else-if="record.status === 'Pending'"
                  >{{ record.status }}</span
                >
                <span class="badges bg-lightgrey" v-else>{{
                  record.status
                }}</span></template
              >
            </template>
          </f-table>
        </div>
      </div>
    </div>
    <!-- PAYMENT SUMMARY -->
    <div v-if="hasAccess('access-.*-sales')" class="card mb-0 mt-4">
      <div class="card-body">
        <div class="mb-3">
          <h4 class="card-title">Payment Summary</h4>
          <div class="d-flex justify-content-between">
            <div class="d-md-flex gap-1">
              <a-range-picker style="width: 250px;" @change="setDepositDate" />
              <button
                style="margin: 5px;"
                class="btn btn-primary"
                @click="getAllDeposit()"
              >
                Fetch
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <f-table
            :filters="['admin_name', 'user_name', 'payment_method']"
            :columns="deposit.columns"
            :data="deposit.data"
          >
          </f-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { basicAreaChart, salesChart, warehouseChart } from "./chart";
import store from "@/store";
import {
  warehouseSummaryColumns,
  saleSummaryColumns,
  orderColumns,
  productColumns,
  depositColumns,
} from "./columns";

import { useLocalStorage } from "@/hooks/useLocalStorage";
import { dataIsUnique, formatAmount, sumArrayKey } from "@/helpers";

const storage = useLocalStorage();
const title = ref("Today's Analysis");
const titles = ref("Stella Spot Check");
const stock = ref([]);
const dateRange = ref({
  startDate: null,
  endDate: null
});
const newOrders = ref([]);
const spotCheck = ref({
  dateRange: {
    startDate: null,
    endDate: null,
  },
  data: {
    payments: {
      balance: 0,
      rabitlala: 0,
      pos: 0,
      cash: 0,
      bank_transfer: 0,
    },
    vsr_due: 0,
    se_due: 0,
    wholeseller_due: 0,
    guiness_direct_due: 0,
  },
});
const sales = ref({
  summary: {
    filters: ["reference", "customer_name"],
    columns: saleSummaryColumns,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    data: [],
  },
  chart: salesChart,
});
const deposit = ref({
  columns: depositColumns,
  dateRange: {
    startDate: null,
    endDate: null,
  },
  data: [],
});
const warehouse = ref({
  summary: {
    columns: warehouseSummaryColumns,
    filters: ["product_name", "customer_name"],
    dateRange: {
      startDate: null,
      endDate: null,
    },
    data: [],
  },
  chart: warehouseChart(() => null, []),
});
const newProducts = ref([]);
const dashboard = ref({});
const analysisDate = ref("Today's");

const canAccess = (access) => store.getters["canAccess"](access);
const hasAccess = (access) => store.getters["hasAccess"](access);

const isSuperAdmin = computed(() => store.getters["isSuperAdmin"]);
const isVendor = computed(() => store.getters["isVendor"]);

// functions
function onDateChange(context, dates) {
  dateRange.value.startDate = dates[0];
  dateRange.value.endDate = dates[1];
}

function setWarehouseDate(context, dates) {
  warehouse.value.summary.dateRange.startDate = dates[0];
  warehouse.value.summary.dateRange.endDate = dates[1];
}
function setSalesDate(context, dates) {
  sales.value.summary.dateRange.startDate = dates[0];
  sales.value.summary.dateRange.endDate = dates[1];
}
function setSpotDate(context, dates) {
  spotCheck.value.dateRange.startDate = dates[0];
  spotCheck.value.dateRange.endDate = dates[1];
}
function setDepositDate(context, dates) {
  deposit.value.dateRange.startDate = dates[0];
  deposit.value.dateRange.endDate = dates[1];
}
function hasDashBoardAccess() {
  if (
    canAccess("saleslist") ||
    canAccess("products") ||
    hasAccess("approved-sales") ||
    hasAccess("access-.*-sales")
  ) {
    return true;
  }
}
function mapOrders(orders) {
  return orders.map((order) => ({
    id: order.id,
    "Order number": order.reference,
    Customername: order.user.name,
    date: order.date,
    Amount: order.total,
    Status: order.status,
  }));
}
function mapProducts(products) {
  return products.map((product) => ({
    id: product.id,
    name: product.name,
    sku: product.sku,
    qty: product.qty,
    price: product.price,
  }));
}
async function getAdminDashboard() {
  try {
    const response = await store.dispatch("dashboard/get", {
      dateRange: dateRange,
    });
    const { data } = response.data;
    dashboard.value = data;
    title.value = response.data.message;
    analysisDate.value = data.date;
    newOrders.value = mapOrders(data.new_orders);
    newProducts.value = mapProducts(data.new_products);
  } catch (error) {
    console.error("Error fetching admin dashboard:", error);
    // Handle error appropriately
  }
}
function get_stock() {
  store.dispatch("product/get_stock").then((response) => {
    stock.value = response.data.data;
    const chartData = { data: [], names: [] };
    stock.value.forEach((product) => {
      if (product.available_quantity > 0) {
        chartData.data.push(product.available_quantity);
        chartData.names.push(product.name);
      }
    });
    warehouse.value.chart = warehouseChart(
      getWarehouseChartProductUnit,
      chartData.data,
      chartData.names
    );
  });
}
function getSpotCheck() {
  store
    .dispatch("dashboard/getSpotCheck", spotCheck.value.dateRange)
    .then((response) => {
      let data = response.data.data;
      spotCheck.value.data = data;
    });
}
function getAllDeposit() {
  store
    .dispatch("dashboard/getAllDeposit", deposit.value.dateRange)
    .then((response) => {
      let data = response.data.data.map((deposit) => {
        return {
          admin_name: deposit.admin.name,
          user_name: deposit.user.name,
          amount: deposit.amount,
          payment_method: deposit.method,
          date: deposit.date,
        };
      });
      deposit.value.data = data;
    });
}
function getWarehouseSummary() {
  store
    .dispatch("sales/getWareHouseSummary", {
      dateRange: warehouse.value.summary.dateRange,
    })
    .then((response) => {
      warehouse.value.summary.data = response.data.data.map((summary) => {
        return {
          id: summary.id,
          order_id: summary.order_id,
          sale_id: summary.sale_id,
          date: summary.date,
          customer_name: summary.customer_name,
          reference: summary.order_reference,
          status: summary.status,
          product: summary.product,
          product_name: summary.product.name,
          available_qty: summary.product.available_crates_desc,
          qty: summary.qty,
          qty_text: summary.qty_text,
        };
      });
    });
}
function getSalesSummary() {
  store
    .dispatch("sales/getSaleSummary", {
      dateRange: sales.value.summary.dateRange,
    })
    .then((response) => {
      sales.value.summary.data = response.data.data.map((summary) => {
        return {
          id: summary.id,
          due: summary.due,
          date: summary.date,
          paid: summary.paid,
          total: summary.total,
          total_amount: summary.total.replace(",", ""),
          status: summary.status,
          order_id: summary.order_id,
          over_paid: summary.over_paid,
          reference: summary.reference,
          customer_name: summary.customer_name,
        };
      });
    });
}
function getWarehouseChartProductUnit(index) {
  return stock.value.filter((product) => product.available_quantity > 0)[index]
    ?.available_qty_desc;
}
onMounted(() => {
  if (isSuperAdmin.value || isVendor.value) {
    getAdminDashboard();
    getSpotCheck();
    title.value = "Today's Analysis";
  }
  if (hasAccess("access-.*-sales")) {
    getSalesSummary();
  }
  if (hasAccess("access-deposit")) {
    getAllDeposit();
  }
  if (hasAccess("approved-sales")) {
    getWarehouseSummary();
    get_stock();
  }
  const mode = storage.getItem("mode") === "store";
  const store_name = storage.getItem("store-name") === "store";
  titles.value = mode ? `Spot Check For ${store_name}` : "Stella Spot Check";
});
</script>
<style lang="css">
.dash-counts h5 {
  /* font-size: 13px !important; */
}

.dash-counts h4 {
  font-size: 20px !important;
}
</style>
