<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items">
        </pageaddheader>

        <div class="row">
          <div class="col-12">
            <f-table
              :filters="filters"
              :columns="columns"
              :data="notifications"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'action'">
                  <div class="text-center gap-2 d-flex align-items-center">
                    <a
                      class="me-1 confirm-text"
                      @click="deleteNotification(record.id)"
                      href="javascript:void(0);"
                    >
                      <img
                        src="../../../assets/img/icons/delete.svg"
                        alt="img"
                      />
                    </a>
                    <a
                      class="action-set"
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link
                          class="me-3 dropdown-item"
                          :to="'/notification/edit/' + record.id"
                        >
                          Edit
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </f-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import store from "@/store";

const columns = [
  {
    sorter: true,
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: {
      compare: (a, b) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Body",
    dataIndex: "body",
    sorter: {
      compare: (a, b) => {
        a = a.body.toLowerCase();
        b = b.body.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Deliver At",
    dataIndex: "deliver_at",
    sorter: {
      compare: (a, b) => {
        a = a.deliver_at.toLowerCase();
        b = b.deliver_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: false,
  },
];
const filters = ["title", "body", "deliver_at"];
const title = "Scheduled Notifications";
const title1 = "View all Scheduled Notifications";
const items = ref([]);

// ref data
const notifications = ref([]);
const getNotifications = async () => {
  const response = await store.dispatch("pushNotification/get");
  notifications.value = response.data.data;
};
const deleteNotification = async () => {
  await store.dispatch("pushNotification/delete");
  getNotifications();
  store.commit("notify", {
    message: "Notification Deleted Successfully",
  });
};
onMounted(() => {
  getNotifications();
});
</script>
