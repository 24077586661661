import { notification } from "ant-design-vue";
import { router } from "@/router";
import { notifyBrowser, setAccessToken } from "@/helpers";

export default {
  SET_ADMIN_PERMISSIONS: (state, payload) => {
    localStorage.setItem("ADMIN_PERMISSIONS", btoa(JSON.stringify(payload)));
    state.adminPermissions = payload;
  },
  SET_ADMIN_PROFILE: (state, payload) => {
    state.adminProfile = payload;
  },
  storeToken({ context }, token) {
    localStorage.setItem("token", btoa(token));
  },
  bearerToken({ context }, token) {
    setAccessToken(token);
  },
  storeUser({ context }, adminData) {
    localStorage.setItem("adminData", adminData);
  },
  logOut() {
    localStorage.clear();
    router.push({ name: "signin" });
  },
  showLoader({ context }, { title } = { title: null }) {
    Swal.fire({
      title: title,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      html: `<div class="spinner-border text-warning" role="status"></div>`,
    });
  },
  confirm(
    { context },
    { title, message, callback, confirmButtonText, cancelButtonText } = {
      confirmButtonText: null,
      cancelButtonText: null,
    }
  ) {
    // Use sweetalert2
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      confirmButtonColor: "#FF9F43",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText || "Yes, Continue",
      cancelButtonText: cancelButtonText || "Cancel",
    }).then((result) => {
      callback(result.isConfirmed, result);
    });
  },
  closeLoader({ context }) {
    Swal.close();
  },
  notify({ commit }, { type, title, message, callbackUrl = null }) {
    if (!type) {
      type = "success";
    }
    notification[type]({
      message: title,
      description: message,
    });
    notifyBrowser(title, message, callbackUrl);
  },
};
