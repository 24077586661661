import { api } from "@/config";

export const sales = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/sales${path}`;
  },
  namespaced: true,
  actions: {
    getWareHouseSummary({ context }, { dateRange }) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get(sales.url("report"), {
        params: params,
      });
    },
    getSaleSummary({ context }, { dateRange }) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get(sales.url("summary"), {
        params: params,
      });
    },
    getPendingSales({ context }, { params } = {}) {
      return api.get(sales.url("pending"), { params: params });
    },
    getCompletedSales({ context }, { params } = {}) {
      return api.get(sales.url("completed"), { params: params });
    },
    get({ context }, { type, params } = { type: "New", params: {} }) {
      return api.get(sales.url(), {
        params: {
          ...params,
          status: type,
        },
      });
    },
    show({ context }, { sale_id }) {
      return api.get(sales.url(sale_id));
    },
    add({ context }, { order_ref_or_ids }) {
      return api.post(sales.url(), {
        order_id_or_refs: order_ref_or_ids,
      });
    },
    update({ context }, { sale_id, payload }) {
      return api.patch(sales.url(sale_id), payload);
    },
    action({ context }, { saleId, action, payload = {} }) {
      return api.patch(sales.url(`${saleId}/${action}`), payload);
    },
    executeSaleAction(
      { context, dispatch },
      { saleId, action, accessCode = null }
    ) {
      return dispatch("action", {
        saleId: saleId,
        action: action,
        payload: { access_code: accessCode },
      });
    },
  },
};

export const saleReturn = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/sales/return${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }) {
      return api.get(saleReturn.url(), {});
    },
    show({ context }, { sale_return_id }) {
      return api.get(saleReturn.url(sale_return_id));
    },
    add({ context }, { payload }) {
      return api.post(saleReturn.url(), payload);
    },
    approve(
      { context },
      { sale_return_id, sales_return_product_id } = {
        sale_return_id: null,
        sales_return_product_id: null,
      }
    ) {
      return api.patch(saleReturn.url("approve"), {
        sales_return_product_id: sales_return_product_id,
        sale_return_id: sale_return_id,
      });
    },
  },
};
