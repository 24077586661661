<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper" v-if="Object.values(paymentReport).length > 0">
      <div class="content">
        <div class="row">
          <div class="">
            <!-- <pageheader :title="title" :title1="title1" :items="items"/> -->
            <pageaddheader :title="title" :title1="title1" :items="items" />

            <div class="col-lg-4 col-sm-6 col-12 d-flex mb-3 gap-2" style="padding-right: 25px">
              <!-- <a-range-picker class="col-10" @change="setDateRange" :disabled-date="disabledAfterToday" /> -->
              <custom-datepicker @change="setDateRange"></custom-datepicker>
              <button class="btn btn-primary" @click="getPayments()">Fetch</button>

            </div>
            <br />
          </div>

          <div class="report col-lg-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das1">
              <div class="dash-counts">
                <h4>{{ formatAmount(paymentReport.balance, true) }}</h4>
                <h5>From User Balance</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="bar-chart"></vue-feather>
              </div>
            </div>
          </div>

          <div class="report col-lg-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das2">
              <div class="dash-counts">
                <h4>{{ formatAmount(paymentReport.rabitlala, true) }}</h4>
                <h5>Total Rabitlala Payment</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="bar-chart"></vue-feather>
              </div>
            </div>
          </div>

          <router-link to="/inventoryreport" class="report col-lg-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das3">
              <div class="dash-counts">
                <h4>{{ formatAmount(paymentReport.pos, true) }}</h4>
                <h5>Total POS Payment</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="credit-card"></vue-feather>
              </div>
            </div>
            <span class="tooltip">This is An Estimated Amount</span>
          </router-link>
          <router-link to="/inventoryreport" class="report col-lg-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das5">
              <div class="dash-counts">
                <h4>{{ formatAmount(paymentReport.bank_transfer, true) }}</h4>
                <h5>Total Bank / Transfer Payment</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="pie-chart"></vue-feather>
              </div>
            </div>
          </router-link>
        </div>

        <!-- /order list -->
        <f-table :filters="['customer_name', 'admin_name', 'amount']" :columns="columns" :data="paymentReport.payments">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'customer_name'">
              <span>{{ record.user.name }}</span>
            </template>
            <template v-if="column.key === 'admin_name'">
              <span>{{ record.admin.name }}</span>
            </template>
            <template v-if="column.key === 'method'">
              <span>{{ record.user.method }}</span>
            </template>
          </template>
        </f-table>

        <!-- /order list -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
  },
  {
    title: "Admin name",
    dataIndex: "admin_name",
    sorter: (a, b) => a.admin_name.localeCompare(b.admin_name),
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Admin",
  //   dataIndex: "customer_name",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Paid.toLowerCase();
  //       b = b.Paid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Method",
    dataIndex: "method",
    sorter: (a, b) => a.method.localeCompare(b.method),
  },
];

import { mapActions, mapGetters } from "vuex";
import store from "../../../store";
import { formatAmount } from '@/helpers';

export default {

  data() {
    return {
      filter: true,
      title: "Payment Report",
      filter: {
        value: null,
      },
      title1: "Manage your Reports",
      startdate: currentDate,
      data: [],
      filtered: [],
      columns,
      paginationConfig: {},
      paymentReport: [],
      formatAmount,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    setDateRange(context, dates) {
      this.dateRange.startDate = dates[0];
      this.dateRange.endDate = dates[1];
    },
    ...mapActions({
      _getOrders: "payments/get",
      _deleteOrder: "payments/delete",
    }),
    deleteOrder(orderId) {
      Swal.fire({
        title: "Are you sure?",
        text: "This Order Will be deleted entirely!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteOrder({ orderId: orderId }).then((res) => {
            store.commit("notify", {
              type: "success",
              title: "Order Delete",
              message: "Order Deleted Successfully",
            });
            this.getPayments();
          });
        }
      });
    },
    getPayments() {
      store
        .dispatch("payments/getPaymentReport", { dateRange: this.dateRange })
        .then((response) => {
          this.paymentReport = response.data.data;
          this.paymentReport.payments = response.data.data.payments.map((payment) => {
            return {
              customer_name: payment.user.name,
              date: payment.date,
              amount: payment.amount,
              admin_name: payment.admin.name,
              method: payment.method,
            };
          })
        });
    },
  },
  mounted() {
    this.getPayments();
    if (!this.hasAccess("create-orders")) {
      this.items = [];
    }
  },
  name: "invoicereport",
};
</script>
