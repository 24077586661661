<template>
  <div id="app">
    <loader :loading="showLoader" />
    <router-view v-if="isReady" />
    <div v-if="showChat">
      <Movable>
        <Chat />
      </Movable>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { isAuth } from "./helpers";
import { socket } from "@/config/websocket";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Chat from "./components/chat.vue";
import Movable from "./components/movable.vue";

const store = useStore();
const route = useRoute();
const status = ref(false);
const isReady = ref(false);
const showLoader = computed(() => {
  return store.state.pendingRequests > 0 ? true : false;
});
const showChat = computed(() => {
  if (isAuth()) {
    let path = route.path || "";
    if (path.match("chat") || path.match("/signin")) {
      return false;
    } else {
      return true;
    }
  }
  return false;
});

const getProfile = async () => {
  const response = await store.dispatch("getProfile");
  const adminDetails = response.data.data;
  store.commit("SET_ADMIN_PERMISSIONS", adminDetails.permissions);
  delete adminDetails.permissions;
  store.commit("SET_ADMIN_PROFILE", adminDetails);
  isReady.value = true;
};

const loadWebsocketListners = () => {
  if (socket && isAuth()) {
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      switch (data.channel) {
        case "admin.message.alert":
          switch (data.type) {
            case "NOTIFICATION":
              store.commit("notify", {
                message: data.message,
                title: data.title,
                type: "info",
              });
              store.dispatch("notification/getNotifications");
              break;
            case "CHAT":
              store.commit("notify", {
                message: "You Have a new Message",
                title: null,
                type: "info",
              });
              store.state.chat.newMessage = data;
              console.log(data);
              break;
          }
          break;
        default:
          break;
      }
    });
  }
};

const init = () => {
  if (isAuth()) {
    loadWebsocketListners();
    getProfile();
  } else {
    isReady.value = true;
  }
};

onMounted(() => {
  init();
  window.addEventListener("userLoggedIn", (event) => {
    init();
  });
});
</script>

<style>
.table-responsive {
  min-height: var(--table-height, 350px);
}

.swal2-html-container {
  overflow: hidden !important;
}
</style>
