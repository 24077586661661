<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <div class="row">
          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Default Rating</h5>
              </div>
              <div class="card-body">
                <p>This is the most basic example of ratings.</p>
                <star-rating v-bind:star-size="20" v-bind:show-rating="false" />
              </div>
            </div>
          </div>
          <!-- /Rating -->

          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Score</h5>
              </div>
              <div class="card-body">
                <p>Stars with a saved rating.</p>
                <star-rating
                  v-bind:star-size="20"
                  v-bind:rating="3"
                  v-bind:show-rating="false"
                />
              </div>
            </div>
          </div>
          <!-- /Rating -->

          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Max Number</h5>
              </div>
              <div class="card-body">
                <p>Change the max numbers of stars</p>
                <star-rating
                  v-bind:star-size="20"
                  v-bind:max-rating="10"
                  v-bind:show-rating="false"
                />
              </div>
            </div>
          </div>
          <!-- /Rating -->

          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Read Only</h5>
              </div>
              <div class="card-body">
                <p>Prevent users from voting</p>
                <star-rating
                  v-bind:star-size="20"
                  v-bind:read-only="true"
                  v-bind:rating="3"
                  v-bind:show-rating="false"
                />
              </div>
            </div>
          </div>
          <!-- /Rating -->

          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Half Rating</h5>
              </div>
              <div class="card-body">
                <p>You can represent a float score as a half star icon.</p>
                <star-rating
                  v-bind:star-size="20"
                  v-bind:increment="0.5"
                  v-bind:rating="3.5"
                  v-bind:show-rating="false"
                />
              </div>
            </div>
          </div>
          <!-- /Rating -->

          <!-- Rating -->
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Custom Icon</h5>
              </div>
              <div class="card-body">
                <p>Use any icon you want.</p>
                <star-rating v-bind:star-size="20" v-bind:show-rating="false" />
              </div>
            </div>
          </div>
          <!-- /Rating -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Rating",
      text: "Dashboard",
      text1: "Rating",
      name: "/index",
    };
  },
  name: "rating",
};
</script>
