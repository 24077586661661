import { Envs, NODE_ENV, isDevelopment } from "./env";

export const startYear = 2023;

let apiUrl = "https://lasaro.dev";
switch (NODE_ENV) {
  case Envs.demo:
    apiUrl = "https://diskspin.lasarodynamics.com";
    break;
  case Envs.production:
    apiUrl = "https://tunnel9.lasarodynamics.com";
    break;
}

const baseUrl = `${apiUrl}/api/`;

const adminUrl = `${baseUrl}admin/v1`;
const superAdminUrl = `${baseUrl}admin/v1/`;
const ctrlPanelUrl = `${adminUrl}/control-panel/`;
const userUrl = `${baseUrl}v1/`;

//
const socketUrl = isDevelopment
  ? "wss://192.168.1.113:8765"
  : "wss://bqgwrfnx8h.execute-api.eu-west-2.amazonaws.com/development";

const endpoints = {
  utils: {
    notifications: `notifications`,
  },
  system: {
    ping: `${baseUrl}ping`,
  },
  auth: {
    login: `${adminUrl}/login`,
    logout: `${adminUrl}/logout`,
    register: `${adminUrl}/register`,
    email: `${adminUrl}/email`,
    verifyEmail: `${adminUrl}/verify-email`,
  },

  admin: {
    states: `${adminUrl}/states`,
    storeName: `${adminUrl}/store_name`,
    auth: {
      register: `${adminUrl}/register`,
      login: `${adminUrl}/login`,
      email: `${adminUrl}/email`,
      verifyEmail: `${adminUrl}/verify-email`,
    },
    chat: {
      connection: `${adminUrl}/chat/connection`,
      users: `${adminUrl}/chat/users`,
    },
    admin: {
      updateProfile: `${adminUrl}/admin`,
      getProfile: `${adminUrl}`,
      deposit: `${adminUrl}/admin/deposit`,
      history: `${adminUrl}/admin/history`,
      spotCheck: `${adminUrl}/admin/spot-check`,
    },
    sale: {
      report: `${adminUrl}/sales/report`,
      pending: `${adminUrl}/sales/pending`,
      summary: `${adminUrl}/sales/summary`,
      completed: `${adminUrl}/sales/completed`,
    },
    productStock: `${adminUrl}/product/stock`,
    paymentReport: `${adminUrl}/payment-report`,
    purchaseReport: `${adminUrl}/purchase-report`,
    supplierReport: `${adminUrl}/supplier-report`,
    vendorReport: `${adminUrl}/vendor-report`,
  },
  controlPanel: {
    chat: `${ctrlPanelUrl}chat`,
    admin: `${ctrlPanelUrl}admin`,
    accessCode: `${ctrlPanelUrl}access-code`,
    productImage: `${ctrlPanelUrl}product-image`,
    accountType: `${ctrlPanelUrl}account-type`,
    printTransactions: (accountNo) =>
      `${ctrlPanelUrl}print/${accountNo}transactions`,
    crateType: `${ctrlPanelUrl}crate-type`,
    receipts: `${ctrlPanelUrl}receipts`,
    advertsMobileSplash: `${ctrlPanelUrl}adverts/mobile/splash`,
    bankDetails: `${ctrlPanelUrl}bank-details`,
    crates: `${ctrlPanelUrl}crates`,
    approveSalesReturn: `${ctrlPanelUrl}sales/return/approve`,
    salesReturn: `${ctrlPanelUrl}sales/return`,
    adminRole: `${ctrlPanelUrl}admin-role`,
    adminPermission: `${ctrlPanelUrl}admin-permission`,
    confirmTransit: (transitId) =>
      `${ctrlPanelUrl}product/transit/${transitId}confirm`,
    productTransit: `${ctrlPanelUrl}product/transit`,
    updateSubcategory: (id) => `${ctrlPanelUrl}subcategories/${id}`,
    updateBrand: (id) => `${ctrlPanelUrl}brands/${id}`,
    updateProduct: (id) => `${ctrlPanelUrl}product/${id}`,
    updateCategory: (id) => `${ctrlPanelUrl}categories/${id}`,
    users: {
      creditUser: (accountNo) => `${ctrlPanelUrl}users/${accountNo}credit`,
      clearDues: (accountNo) => `${ctrlPanelUrl}users/${accountNo}clear-dues`,
      transactions: (accountNo) =>
        `${ctrlPanelUrl}users/${accountNo}transactions`,
      outstanding: (accountNo) =>
        `${ctrlPanelUrl}users/${accountNo}outstanding`,
      accounts: `${ctrlPanelUrl}users/accounts`,
      accountDetails: (accountNo) =>
        `${ctrlPanelUrl}users/accounts/${accountNo}`,
    },
    sales: `${ctrlPanelUrl}sales`,
    orders: {
      index: `${ctrlPanelUrl}orders`,
      destroy: (orderId) => `${ctrlPanelUrl}orders/${orderId}`,
      show: (orderId) => `${ctrlPanelUrl}orders/${orderId}`,
      payments: (orderId) => `${ctrlPanelUrl}orders/${orderId}payments`,
    },
    archive: {
      orders: `${ctrlPanelUrl}archive/orders`,
    },
  },
  superAdmin: {
    pendingVendors: `${superAdminUrl}pending-vendors`,
    rejectedVendors: `${superAdminUrl}rejected-vendors`,
    vendors: (status, page) =>
      `${superAdminUrl}vendors?status=${status}&page=${page}`,
    updateVendorRequest: `${superAdminUrl}update-vendor-request`,
    updateVendorAdminRequest: `${superAdminUrl}update-vendor-admin-request`,
    authenticateAsStore: `${superAdminUrl}auth-as-store`,
    logOutFromStore: `${superAdminUrl}deauth-as-store`,
    getStoreNames: `${superAdminUrl}stores`,
  },
};

export {
  endpoints,
  socketUrl,
  baseUrl,
  adminUrl,
  superAdminUrl,
  ctrlPanelUrl,
  userUrl,
};
