<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span class="badges bg-lightgreen" v-if="record.Status === 'Approved'">{{
                record.Status
              }}</span>
              <span class="badges bg-lightred" v-else>{{ record.Status }}</span></template
            >
            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.reference }}</router-link
              >
            </template>
            <template v-else-if="column.key === 'pay'">
              <span class="badges bg-lightgreen" v-if="record.Payment === 'Paid'">{{
                record.Payment
              }}</span>
              <span class="badges bg-lightred" v-else>{{
                record.Payment
              }}</span></template
            >
            <template v-else-if="column.key === 'action'">
              <td class="action-table-data">
                <div class="edit-delete-action">
                  <a
                    class="me-2 p-2"
                    href="#edit-coupon"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <vue-feather type="edit"></vue-feather>
                  </a>
                  <a
                    class="confirm-text p-2"
                    @click="showConfirmation"
                    href="#delete-coupon"
                  >
                    <vue-feather type="trash-2"></vue-feather>
                  </a>
                </div>
              </td>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <saleslistmodal
    @paymentAddedListner="getSales"
    :order_id="selected_order_id"
    :sale_id="selected_sale_id"
  ></saleslistmodal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Warehouse",
    dataIndex: "Warehouse",
    sorter: {
      compare: (a, b) => {
        a = a.Warehouse.toLowerCase();
        b = b.Warehouse.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Shop",
    dataIndex: "Shop",
    sorter: {
      compare: (a, b) => {
        a = a.Shop.toLowerCase();
        b = b.Shop.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Product",
    dataIndex: "Product",
    key: "Product",
    sorter: {
      compare: (a, b) => {
        a = a.Product.toLowerCase();
        b = b.Product.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Person",
    dataIndex: "Person",
    key: "Person",
    sorter: {
      compare: (a, b) => {
        a = a.Person.toLowerCase();
        b = b.Person.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Notes",
    dataIndex: "Notes",
    key: "Notes",
    sorter: {
      compare: (a, b) => {
        a = a.Notes.toLowerCase();
        b = b.Notes.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Warehouse: "Lobar Handy",
    Shop: "Selosy",
    Product: "Nike Jordan",
    Product_img: "stock-img-02.png",
    Date: "25 Jul 2023",
    Person: "Steven",
    Person_img: "user-08.jpg",
    Notes: "View Note",
  },
  {
    id: "2",
    Warehouse: "Quaint Warehouse",
    Shop: "Logerro",
    Product: "Apple Series 5 Watch",
    Product_img: "stock-img-03.png",
    Date: "28 Jul 2023",
    Person: "Gravely",
    Person_img: "user-04.jpg",
    Notes: "View Note",
  },
  {
    id: "3",
    Warehouse: "Traditional Warehouse",
    Shop: "Vesloo",
    Product: "Amazon Echo Dot",
    Product_img: "stock-img-04.png",
    Date: "24 Jul 2023",
    Person: "Kevin",
    Person_img: "user-09.jpg",
    Notes: "View Note",
  },
  {
    id: "4",
    Warehouse: "Cool Warehouse",
    Shop: "Crompy",
    Product: "Lobar Handy",
    Product_img: "stock-img-05.png",
    Date: "15 Jul 2023",
    Person: "Grillo",
    Person_img: "user-10.jpg",
    Notes: "View Note",
  },
];
export default {
  data() {
    return {
      filters: ["customer_name", "reference", "customer_account_type"],
      title: "Stock Adjustment",
      title1: "Manage your stock adjustment",
      items: [
        {
          text: "Add New",
          addname: "#add-stock",
        },
      ],
      selected_order_id: null,
      selected_sale_id: null,
      columns,
      data,
      Completed: ["Completed", "Paid"],
    };
  },
  computed: {
    ...mapGetters(["hasAccess"]),
  },
  methods: {
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },
  mounted() {},
  name: "manage-coupons",
};
</script>
