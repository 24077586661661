<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card" v-if="Object.values(roleDetails).length > 0">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-lg-6 col-sm-6 col-12">
                <label>Role Name</label>
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-12 d-flex gap-2">
                    <input type="text" v-model="roleDetails.name" placeholder="Enter Role Name" class="col-lg-8" />
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-12">
                <label>Order Status</label>
                <vue-select :options="orderStatuses" 
                  v-model="roleDetails.settings.ORDER_STATUS" />
              </div>
            </div>
            <div class="row mb-3" v-if="!isSuperAdmin">
              <f-table :filters="filters" :data="permissions" :columns="permissionsColumns">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key == 'action'">
                    <label class="inputcheck">
                      <input :value="record.id" v-model="roleDetails.permissions" type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </template>
                </template>
              </f-table>
            </div>
            <div class="col-lg-12">
              <a v-on:click="updateRole" href="javascript:void(0);" class="btn btn-submit me-2">Update</a>
              <router-link to="/admin-role" class="btn btn-cancel">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { _sale } from "@/store/models";
import { router } from "@/router";
import { OrderStatuses } from "@/models/order";

const permissionsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  { title: "Action", key: "action" },
];
export default {
  data() {
    return {
      title: "Role Detail",
      title1: "View A Role Details",
      filters: ["name", "description"],
      roleDetails: {},
      permissions: [],
      roleDetails: {
        settings: { ORDER_STATUS: null },
        permissions: [],
        name: null,
      },
      permissionsColumns,
      orderStatuses: [{ id: 'Default', text: "Default" }, ...Object.values(OrderStatuses)],
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
      isVendor: "isVendor",
    }),
  },
  methods: {
    ...mapActions({
      _getRoleDetail: "adminRole/get",
      _updateRole: "adminRole/update",
      _getPermissions: "adminPermission/get",
    }),

    processSettings(settings) {
      return Object.entries(settings)
        .filter(([, value]) => value !== 'Default')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    },
    getRoleDetail() {
      let role_id = this.$route.params.id;
      this._getRoleDetail({ role_id: role_id }).then((response) => {
        const data = response.data.data;
        // add the role details to roleDetails so we can update the name and permission
        this.roleDetails.name = data.name;
        this.roleDetails.settings = data.settings;
        data.permissions.map((permission) => {
          this.roleDetails.permissions.push(permission.permission_id);
        });
      });
    },
    getPermissions() {
      if (!this.isSuperAdmin) {
        this._getPermissions().then((response) => {
          this.permissions = response.data.data;
        });
      }
    },
    updateRole() {
      let payload = { ...this.roleDetails };
      let role_id = this.$route.params.id;
      payload.settings = this.processSettings(this.roleDetails.settings);
      this._updateRole({ id: role_id, payload: payload }).then((response) => {
        Swal.fire({ title: "Role Updated", icon: "success" });
        router.push({ name: "admin-role" });
      });
    },
  },
  async mounted() {
    this.getRoleDetail();
    this.getPermissions();
  },
  name: "role-details",
};
</script>
