<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Delivery Number</label>
                  <input required class="form-control" v-model="transit.delivery_number" type="text"
                    name="delivery_number" />
                  <span class="text-danger" v-if="errors.delivery_number">{{
                    errors.delivery_number
                    }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">

                <div class="form-group">
                  <label>Order Number</label>
                  <input required class="form-control" v-model="transit.order_number" type="text" name="order_number" />
                  <span class="text-danger" v-if="errors.order_number">{{
                    errors.order_number
                    }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">

                <div class="form-group">
                  <label>Transfer Number</label>
                  <input required class="form-control" v-model="transit.transfer_number" type="text"
                    name="transfer_number" />
                  <span class="text-danger" v-if="errors.transfer_number">{{
                    errors.transfer_number
                    }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">

                <div class="form-group">
                  <label>Purchase Number</label>
                  <input required class="form-control" v-model="transit.purchase_number" type="text"
                    name="purchase_number" />
                  <span class="text-danger" v-if="errors.purchase_number">{{
                    errors.purchase_number
                    }}</span>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">

                <div class="form-group">
                  <label>Vehicle Number</label>
                  <input required class="form-control" v-model="transit.vehicle_number" type="text"
                    name="vehicle_number" />
                  <span class="text-danger" v-if="errors.vehicle_number">{{
                    errors.vehicle_number
                    }}</span>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Order Date</label>
                  <b-form-input required v-model="transit.order_date" type="date" name="order_date" />
                  <span class="text-danger" v-if="errors.order_date">{{
                    errors.order_date
                    }}</span>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div class="d-flex justify-content-between">
                  <b style="
                      font-weight: bold;
                      margin-bottom: 15px;
                      display: block;
                    ">In Transit Products</b>
                  <div class="add-icon">
                    <span><img v-on:click="addProduct()" src="../../../../assets/img/icons/plus1.svg"
                        alt="img" /></span>
                  </div>
                </div>

                <table class="table table-striped">
                  <thead>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Number</th>
                    <th>Quantity</th>
                    <th>Batch No</th>
                    <th>Action</th>
                  </thead>
                  <tbody v-if="transit.products">
                    <tr v-for="(product, index) in transit.products" :key="index">
                      <td>
                        <vue-select style="width: 200px !important; font-size: 13px; overflow: hidden" required
                          :options="availableProducts" v-model="product.product_id" placeholder="Select a Product"
                          :name="index + '_product'"></vue-select>

                        <span class="text-danger" v-if="errors[index + '_product']">Please Select A Product</span>
                      </td>
                      <td>
                        <input step="any" readonly :value="getProductDescription(product.product_id)"
                          class="form-control" :name="index + '_description'" />

                      </td>
                      <td>
                        <input step="any" required v-model="product.number" class="form-control" type="number" min="0"
                          :name="index + '_number'" />
                        <span class="text-danger" v-if="errors[index + '_number']">{{ errors[index + "_number"]
                          }}</span>
                      </td>
                      <td>
                        <input step="any" required v-model="product.qty" class="form-control" type="number" min="0"
                          :name="index + '_quantity'" />
                        <span class="text-danger" v-if="errors[index + '_quantity']">{{ errors[index + "_quantity"]
                          }}</span>
                      </td>
                      <td>
                        <input step="any" required v-model="product.batch_number" class="form-control" type="number"
                          min="0" :name="index + '_batch_number'" />
                        <span class="text-danger" v-if="errors[index + '_batch_number']">{{ errors[index +
                          "_batch_number"] }}</span>
                      </td>
                      <td>
                        <a class="me-3 confirm-text" @click="deleteProduct(index)" href="javascript:void(0);">
                          <img src="../../../../assets/img/icons/delete.svg" alt="img" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-lg-12">
                <button disabled id="submit" v-on:click="addTransit" href="javascript:void(0);"
                  class="btn btn-submit me-2">
                  Submit
                </button>
                <router-link to="/transits" class="btn btn-cancel">Cancel</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import { router } from "@/router";
import { mapActions, mapMutations } from "vuex";
import store from "@/store"
import { productStore } from "@/store/product"
export default {
  data() {
    return {
      title: "In Transit Add",
      title1: "Create new Intransit",
      errors: {},
      transit: {
        delivery_number: null,
        order_number: null,
        transfer_number: null,
        purchase_number: null,
        vehicle_number: null,
        order_date: null,
        products: [
          {
            product_id: null,
            number: null,
            batch_number: null,
            qty: null,
          },
        ],
      },
      availableProducts: []
    };
  },
  methods: {
    ...mapActions({
      _addProduct: "product/add",
      _getProducts: "product/fetchProductsFewDetails",
    }),
    getProducts() {
      this._getProducts({ status: "active" })
        .then((response) => {
          this.availableProducts = response.data.data.map((product) => {
            return {
              id: product.id,
              text: product.name,
              description: product.description
            };
          });
        })
        .catch((error) => {
          console.info(error);
          Swal.fire("Can't Fetch Products");
        });
    },
    async deleteProduct(index) {
      this.transit.products.splice(index, 1);
      this.validate();
    },
    async addProduct() {
      this.transit.products.push({
        name: null,
        number: null,
        description: null,
        batch_number: null,
        qty: null,
      });
      this.validate();
    },
    addTransit() {
      if (this.transit.products.length > 0) {

        productStore.dispatch("addTransit", this.transit).then((res) => {
          store.commit("notify", {
            title: "New Transit",
            message: "Transit Successfully Added",
            type: "success"
          });
          this.$router.push("/transits?reload=true");
        })
      } else {
        Swal.fire({
          title: "Please add some products",
          icon: "warning"
        });
      }
    },
    validate() {
      store.dispatch("checkInputValidity", { callbackElementId: "submit" }).then((res) => {
        this.errors = res;
      });
    },
    getProductDescription(id) {
      return (this.availableProducts.find((product) => product.id == id))?.description;
    }
  },
  watch: {
    transit: {
      handler(_new) {
        this.validate();
      },
      deep: true,
    },
    errors: {
      handler(_new) {
        let btn = document.getElementById("submit");
        let length = Object.keys(_new).length;
        if (length > 0) {
          btn.setAttribute("disabled", true);
        } else {
          btn.removeAttribute("disabled", false);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getProducts();
  },
  name: "addtransit",
};
</script>
