<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>Parent Category</label>
                  <vue-select
                    v-model="subcategory.category_id"
                    :options="Category"
                    placeholder="Choose Category"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-12">
                <div class="form-group">
                  <label>Sub Category Name</label>
                  <input v-model="subcategory.name" type="text" />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    v-model="subcategory.description"
                    class="form-control"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label> SubCategory Image</label>
                  <div class="image-upload">
                    <input type="file" @input="pickImage($event.target)" />
                    <div class="image-uploads">
                      <img
                        src="@/assets/img/icons/upload.svg"
                        alt="img"
                      />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product-list">
                  <ul class="row">
                    <li v-if="image">
                      <div class="productviews">
                        <div class="productviewsimg">
                          <img :src="image.src" alt="img" />
                        </div>
                        <div class="productviewscontent">
                          <div class="productviewsname">
                            <h2>{{ image.name }}</h2>
                            <h3>{{ image.size }}KB</h3>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="hideset"
                            v-on:click="filter = !filter"
                            >x</a
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12">
                <a
                  v-on:click="addSubcategory"
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                  >Submit</a
                >
                <router-link to="/subcategorylist" class="btn btn-cancel"
                  >Cancel</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import { router } from "@/router";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      Category: [{ text: "Choose Category", id: 0, selected: true }],
      title: "Product Add sub Category",
      title1: "Create new product Category",
      image: null,
      subcategory: {
        name: null,
        category_id: null,
        description: null,
        image: null,
      },
    };
  },
  methods: {
    ...mapActions({
      _addSubCate: "subcategory/add",
      _getCategories: "category/get",
    }),
    addSubcategory() {
      if (
        (this.subcategory.name == null,
        this.subcategory.category_id == null,
        this.subcategory.description == null)
      ) {
        Swal.fire("Complete Form", "Please Complete the form");
      } else {
        this._addSubCate({ payload: this.subcategory })
          .then((response) => {
            Swal.fire("SubCategory Added!", "SubCategory Successfully Added");
            router.push({ name: "subcategorylist" });
          })
          .catch((error) => {
            Swal.fire("SubCategory Not Added!", "Unable to add SubCategory");
          });
      }
    },
    getCategories() {
      this._getCategories()
        .then((response) => {
          response.data.data.map((category) => {
            this.Category.push({
              id: category.id,
              text: category.name,
            });
          });
        })
        .catch((error) => {});
    },
    pickImage(event) {
      let file = event.files[0];
      this.subcategory.image = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = {
          src: e.target.result,
          name: file.name,
          size: Math.floor(e.total / 1000),
        };
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    this.getCategories();
  },
  name: "subaddcategory",
};
</script>
