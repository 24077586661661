<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="row" v-if="Object.values(productdetails).length > 0">
          <div class="col-lg-8 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div
                    class="bar-code-view"
                    style="width: fit-content !important"
                  >
                    <vue-barcode
                      id="bar_code"
                      :value="productdetails.SKU"
                    ></vue-barcode>

                    <!-- <img src="../../../assets/img/barcode1.png" alt="barcode" /> -->
                    <a class="printimg">
                      <img
                        src="../../../assets/img/icons/printer.svg"
                        alt="print"
                        @click="downloadQR('bar_code')"
                      />
                    </a>
                  </div>
                  <div
                    class="bar-code-view"
                    style="width: fit-content !important"
                  >
                    <qrcode-vue
                      id="qr_code"
                      style="padding-right: 10px"
                      :value="productdetails.SKU"
                      :size="150"
                      level="L"
                    />

                    <a class="printimg">
                      <img
                        src="../../../assets/img/icons/download.svg"
                        alt="print"
                        @click="downloadQR('qr_code')"
                      />
                    </a>
                  </div>
                </div>

                <div class="productdetails">
                  <ul class="product-bar">
                    <li
                      v-for="(value, key, index) in productdetails"
                      :key="index.id"
                    >
                      <h4>{{ key }}</h4>
                      <h6>{{ value }}</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="slider-product-details">
                  <carousel>
                    <slide
                      v-for="(image, index) in productimages"
                      :key="index"
                      class="owl-carousel owl-theme product-slide"
                    >
                      <div class="slider-product">
                        <img :src="image.url" alt="img" />
                        <h4>{{ productdetails.name }}</h4>
                        <!-- <h6>581kb</h6> -->
                      </div>
                    </slide>
                    <template #addons>
                      <navigation />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// import productdetails from "../../../assets/json/productdetails.json";
import { mapActions } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      productdetails: {},
      productimages: [],
      title: "Product Details",
      title1: "Full details of a product",
    };
  },
  methods: {
    ...mapActions({
      _getProduct: "product/get",
    }),
    getProduct() {
      this._getProduct({ product_id: this.$route.params.id }).then(
        (response) => {
          let product = response.data.data;
          this.productdetails = {
            Name: product.name,
            Category: product.category.name,
            SubCategory: product.sub_category.name,
            SKU: product.sku,
            "Min Qty Threshold": product.min_qty_threshold,
            "Max Qty Threshold": product.max_qty_threshold,
            "Available Crates": product.available_crates_desc,
            "Available Bottles": product.available_bottles,
            "Crate Price": product.crate_price,
            "Bottle Price": product.bottle_price,
            "Delivery Cost": product.delivery_cost,
            status: product.is_active == 1 ? "Active" : "False",
            Description: product.description,
          };
          this.productimages = product.images;
        }
      );
    },
    downloadQR(id) {
      let canvas = document.getElementById(id);
      let image = canvas
        .toDataURL("image/png", 1.0)
        .replace("image/png", "image/octet-stream");
      var link = document.createElement("a");
      link.download = this.productdetails.Name + ".png";
      link.href = image;
      link.click();
    },
  },
  mounted() {
    this.getProduct();
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    QrcodeVue,
  },
  name: "product-details",
};
</script>
