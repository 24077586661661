<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <!-- <pageheader :title="title" :title1="title1" :items="items"/> -->
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Status === 'Approved'"
                >{{ record.Status }}</span
              >
              <span
                class="badges bg-lightred"
                v-else-if="record.Status === 'Pending'"
                >{{ record.Status }}</span
              >
              <span
                class="badges bg-lightgrey"
                v-else="record.Status === 'New'"
                >{{ record.Status }}</span
              ></template
            >
            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.order_reference }}</router-link
              >
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Order Reference",
    dataIndex: "order_reference",
    key: "reference",
    sorter: (a, b) => a["order_reference"].localeCompare(b["order_reference"]),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: {
      compare: (a, b) => {
        a = a.customer_name.toLowerCase();
        b = b.customer_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];

import { mapActions } from "vuex";

export default {
  data() {
    return {
      filters: ["order_reference", "customer_name"],
      title: "Invoice Report",
      title1: "Manage your Orders",
      startdate: currentDate,
      items: [
        {
          text: "Add Order",
          addname: "/add-order",
        },
      ],
      data: [],
      columns,
    };
  },
  methods: {
    ...mapActions({
      _getOrders: "orders/get",
    }),
    getOrders() {
      this._getOrders().then((response) => {
        this.data = response.data.data.map((order) => {
          return {
            order_id: order.id,
            order_reference: order.reference,
            customer_name: order.user.name,
            date: order.date,
            Amount: order.total,
            Status: order.status,
          };
        });
      });
    },
  },
  mounted() {
    this.getOrders();
  },
  name: "invoicereport",
};
</script>
