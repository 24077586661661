import { api } from "@/config";
import { endpoints } from "@/constants";

export const vendorReport = {
  url() {
    return `${baseUrl}vendor-report`;
  },
  namespaced: true,
  state: {
    vendors: [],
  },
  mutations: {
    setVendors: (state, vendors) => {
      state.vendors = vendors;
    },
  },
  actions: {
    get({ context }) {
      return api.get(vendorReport.url());
    },
    async getStoreNames({ commit, rootGetters }) {
      if (rootGetters.isSuperAdmin) {
        api.get(endpoints.superAdmin.getStoreNames).then((response) => {
          commit("setVendors", response.data.data);
        });
      }
      return { data: [] };
    },
  },
};
