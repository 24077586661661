<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1">
          <template #right>
            <div class="d-flex gap-1 align-items-center">
              <div class="form-group" v-if="isSuperAdmin">
                <label class="form-label" style="">Select Vendor</label>
                <a-select
                  v-model:value="selectedVendorId"
                  show-search
                  class="ant-select"
                  style="width: 200px;"
                  :options="vendors"
                  :filter-option="filterOption"
                ></a-select>
              </div>

              <button
                @click="importProducts()"
                :disabled="selectedProductsId.length == 0"
                class="btn btn-primary d-flex align-items-center justify-content-center"
              >
                <vue-feather size="20" type="plus" /> Import Products
              </button>
            </div>
          </template>
        </pageaddheader>

        <f-table
          @pageChange="fetchProducts($event)"
          :filters="filters"
          :columns="columns"
          :data="products"
          :paginationConfig="paginationConfig"
          :row-selection="{
            selectedRowKeys: selectedProductsId,
            onChange: onSelectChange,
          }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <a-image :src="record.img" alt="" />
                  <!-- <image-with-fallback class="rounded-0" :src="record.img" :alt="record.product_name" /> -->
                </a>
                <a href="javascript:void(0);">{{ record.product_name }}</a>
              </div>
            </template>
            <template v-if="column.key === 'admin'">
              <a :href="'admin' + record.admin.id">
                {{ record.admin.name }}
              </a>
            </template>
            <template v-if="column.key === 'qty'">
              <span v-if="record.Qty == 0" class="badges bg-lightred"
                >Out Of Stock</span
              >
              <span v-else>
                {{ record.Qty }}
              </span>
            </template>

            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" :to="'products/' + record.id">
                <img src="../../../assets/img/icons/eye.svg" alt="img" />
              </router-link>
              <router-link class="me-3" :to="'editproduct/' + record.id">
                <img src="../../../assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a
                v-if="a"
                class="confirm-text"
                href="javascript:void(0);"
                @click="showAlert"
              >
                <img src="../../../assets/img/icons/delete.svg" alt="img" />
              </a>
            </template>
            <template v-if="column.key === 'status'">
              <span v-if="!hasAccess('update-product')">
                {{ record.Status ? "Active" : "InActive" }}
              </span>

              <div
                v-if="hasAccess('update-product')"
                class="status-toggle d-flex justify-content-between align-items-center"
              >
                <input
                  :name="record.id"
                  type="checkbox"
                  :id="record.id"
                  :checked="record.Status"
                  @change="
                    updateProductStatus(record.id, $event.target.checked)
                  "
                  class="check"
                />
                <label :for="record.id" class="checktoggle">checkbox</label>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const store = useStore();
const columns = ref([
  {
    sorter: true,
  },
  {
    title: "Product Name",
    dataIndex: "product_name",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.product_name.toLowerCase();
        b = b.product_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Brand",
    dataIndex: "Brand",
    sorter: true,
  },
]);
const vendors = computed(() =>
  isSuperAdmin.value
    ? store.state.vendorReport.vendors.map((vendor) => ({
        label: vendor.name,
        value: vendor.id,
      }))
    : []
);
const products = ref([]);
const paginationConfig = ref({});
const filters = ref(["product_name"]);
const title = ref("Product List");
const title1 = ref("Manage your products");

const isSuperAdmin = computed(() => store.getters["isSuperAdmin"] || false);
const hasAccess = (permission) => store.getters["hasAccess"](permission);
const formatAmount = (amount) =>
  computed(() => store.getters["formatAmount"](amount));

const selectedVendorId = ref(null);
const selectedProductsId = ref([]);
const onSelectChange = (selectedRowKeys) => {
  selectedProductsId.value = selectedRowKeys;
};

const fetchVendors = async () => {
  try {
    await store.dispatch("vendorReport/getStoreNames");
  } catch (error) {
    console.info(error);
    Swal.fire("Can't Fetch Vendors");
  }
};
const fetchProducts = async ({ page = 1, per_page = 10 } = {}) => {
  const params = {
    page: page,
    per_page: per_page,
  };
  store
    .dispatch("product/get", { forImport: true, params })
    .then((apiResponse) => {
      const response = apiResponse.data;
      products.value = response.data.map((product) => {
        return {
          key: product.id,
          id: product.id,
          img: product.images[0]?.url,
          product_name: product.name,
          Category: product.category.name,
          Brand: product.brand.name,
        };
      });
      paginationConfig.value = response.pagination;
    })
    .catch((error) => {
      console.info(error);
      Swal.fire("Can't Fetch Products");
    });
};

const importProducts = async () => {
  if (isSuperAdmin.value && !selectedVendorId.value) {
    Swal.fire({
      title: "Select A Store",
      icon: "warning",
    });
    return;
  }
  store
    .dispatch("product/import", {
      products_id: selectedProductsId.value,
      vendor_id: selectedVendorId.value,
    })
    .then((response) => {
      selectedProductsId.value = [];
      if (!isSuperAdmin.value) {
        router.push("/products");
      }
      Swal.fire({
        title: "Product Imported Successfully",
        icon: "success",
      });
    })
    .catch((error) => {
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
    });
};

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

onMounted(() => {
  fetchProducts();
  if (isSuperAdmin.value) {
    fetchVendors();
  }
});
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
