export const warehouseSummaryColumns = [
    {
        sorter: true,
    },
    {
        title: "Product",
        key: "product",
        sorter: {
            compare: (a, b) => {
                a = a.Product.name.toLowerCase();
                b = b.Product.name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Qty",
        dataIndex: "qty_text",
        sorter: {
            compare: (a, b) => {
                a = a.qty.toLowerCase();
                b = b.qty.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Available Qty",
        dataIndex: "available_qty",
        sorter: {
            compare: (a, b) => {
                a = a.available_qty.toLowerCase();
                b = b.available_qty.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Order Reference",
        key: "reference",
        dataIndex: "reference",
        sorter: (a, b) => a["reference"].localeCompare(b["reference"]),
    },
    {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: {
            compare: (a, b) => {
                a = a.customer_name.toLowerCase();
                b = b.customer_name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: {
            compare: (a, b) => {
                a = a.status.toLowerCase();
                b = b.status.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];
export const saleSummaryColumns = [
    {
        sorter: true,
    },
    {
        title: "Order Reference",
        key: "reference",
        sorter: {
            compare: (a, b) => {
                a = a.reference.name.toLowerCase();
                b = b.reference.name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: {
            compare: (a, b) => {
                a = a.customer_name.toLowerCase();
                b = b.customer_name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Total",
        dataIndex: "total",
        sorter: {
            compare: (a, b) => {
                a = a.total.toLowerCase();
                b = b.total.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Due",
        dataIndex: "due",
        sorter: {
            compare: (a, b) => {
                a = a.due.toLowerCase();
                b = b.due.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: {
            compare: (a, b) => {
                a = a.status.toLowerCase();
                b = b.status.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];
export const orderColumns = [
    {
        sorter: true,
    },

    {
        title: "Order  number",
        dataIndex: "Order number",
        sorter: (a, b) => a["Order number"].localeCompare(b["Order number"]),
    },
    {
        title: "Customer name",
        dataIndex: "Customername",
        sorter: {
            compare: (a, b) => {
                a = a.Customername.toLowerCase();
                b = b.Customername.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: "Amount",
        dataIndex: "Amount",
        sorter: {
            compare: (a, b) => {
                a = a.Amount.toLowerCase();
                b = b.Amount.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Admin",
        dataIndex: "Customername",
        sorter: {
            compare: (a, b) => {
                a = a.Paid.toLowerCase();
                b = b.Paid.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Status",
        dataIndex: "Status",
        key: "status",
        sorter: {
            compare: (a, b) => {
                a = a.Status.toLowerCase();
                b = b.Status.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];
export const productColumns = [
    {
        title: "Sno",
        key: "sno",
        sorter: {
            compare: (a, b) => {
                a = a.sno.toLowerCase();
                b = b.sno.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: {
            compare: (a, b) => {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Price",
        dataIndex: "price",
        sorter: {
            compare: (a, b) => {
                a = a.price.toLowerCase();
                b = b.price.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Quantity",
        dataIndex: "qty",
        sorter: {
            compare: (a, b) => {
                a = a.price.toLowerCase();
                b = b.price.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "SKU",
        dataIndex: "sku",
        sorter: {
            compare: (a, b) => {
                a = a.price.toLowerCase();
                b = b.price.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];

export const columns = [
    {
        title: "Sno",
        dataIndex: "SNo",
        sorter: {
            compare: (a, b) => {
                a = a.SNo.toLowerCase();
                b = b.SNo.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Product Code",
        dataIndex: "ProductCode",
        sorter: {
            compare: (a, b) => {
                a = a.ProductCode.toLowerCase();
                b = b.ProductCode.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Product Name",
        dataIndex: "ProductName",
        key: "image",
        sorter: {
            compare: (a, b) => {
                a = a.ProductName.toLowerCase();
                b = b.ProductName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Brand Name",
        dataIndex: "BrandName",
        sorter: {
            compare: (a, b) => {
                a = a.BrandName.toLowerCase();
                b = b.BrandName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Category Name",
        dataIndex: "CategoryName",
        sorter: {
            compare: (a, b) => {
                a = a.CategoryName.toLowerCase();
                b = b.CategoryName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Expiry Date",
        dataIndex: "ExpiryDate",
        sorter: {
            compare: (a, b) => {
                a = a.ExpiryDate.toLowerCase();
                b = b.ExpiryDate.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];
export const depositColumns = [

    {
        title: "Admin Name",
        dataIndex: "admin_name",
        sorter: {
            compare: (a, b) => {
                a = a.admin_name.toLowerCase();
                b = b.admin_name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Customer Name",
        dataIndex: "user_name",
        sorter: {
            compare: (a, b) => {
                a = a.user_name.toLowerCase();
                b = b.user_name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Amount",
        dataIndex: "amount",
        sorter: {
            compare: (a, b) => {
                a = a.amount.toLowerCase();
                b = b.amount.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Payment Method",
        dataIndex: "payment_method",
        sorter: {
            compare: (a, b) => {
                a = a.payment_method.toLowerCase();
                b = b.payment_method.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Date",
        dataIndex: "date",
        sorter: {
            compare: (a, b) => {
                a = a.date.toLowerCase();
                b = b.date.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];
export const column = [
    {
        title: "Sno",
        dataIndex: "SNo",
        sorter: {
            compare: (a, b) => {
                a = a.SNo.toLowerCase();
                b = b.SNo.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Product Code",
        dataIndex: "ProductCode",
        sorter: {
            compare: (a, b) => {
                a = a.ProductCode.toLowerCase();
                b = b.ProductCode.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Product Name",
        dataIndex: "ProductName",
        key: "image",
        sorter: {
            compare: (a, b) => {
                a = a.ProductName.toLowerCase();
                b = b.ProductName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Brand Name",
        dataIndex: "BrandName",
        sorter: {
            compare: (a, b) => {
                a = a.BrandName.toLowerCase();
                b = b.BrandName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Category Name",
        dataIndex: "CategoryName",
        sorter: {
            compare: (a, b) => {
                a = a.CategoryName.toLowerCase();
                b = b.CategoryName.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Expiry Date",
        dataIndex: "ExpiryDate",
        sorter: {
            compare: (a, b) => {
                a = a.ExpiryDate.toLowerCase();
                b = b.ExpiryDate.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
];