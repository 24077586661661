import { api } from "@/config";

export const limitIncrease = {
  url(...queries) {
    let extras = queries.length ? `/${queries.join("/")}` : "";
    return `/credit-limit/requests${extras}`;
  },
  namespaced: true,
  actions: {
    get({ context }, params = {}) {
      return api.get(limitIncrease.url(), {
        params: params,
      });
    },
    update({ context }, { requestId, action }) {
      return api.post(limitIncrease.url(requestId, action), {
        _method: "PATCH",
      });
    },
  },
};
