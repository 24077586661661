<template>
  <!-- show payment Modal -->
  <div class="modal fade" id="confirmtransitproduct" data-bs-focus="false" tabindex="-1"
    aria-labelledby="confirmtransitproduct" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Transit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Product Name</label>
                <div class="input-groupicon">
                  <input class="form-control" :value="transitProductUpdate.name" readonly />
                  <div class="addonset">
                    <img src="../../../../assets/img/icons/calendars.svg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Product Number</label>
                <input type="text" readonly :value="transitProductUpdate.number" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Quantity</label>
                <input type="text" readonly :value="transitProductUpdate.quantity" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Batch Number</label>
                <input type="text" readonly :value="transitProductUpdate.batch_number" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Recieved</label>
                <input type="number" class="form-control form-control-sm" v-model="transitProductUpdate.received" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Rejects</label>
                <input type="number" class="form-control form-control-sm" v-model="transitProductUpdate.rejects" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Returned</label>
                <input v-model="transitProductUpdate.returned" type="number" class="form-control form-control-sm" />
              </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Shortage</label>
                <input type="number" class="form-control form-control-sm" v-model="transitProductUpdate.shortage"
                  placeholder="Shortage" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Damage</label>
                <input type="number" class="form-control form-control-sm" v-model="transitProductUpdate.damage"
                  placeholder="Damage" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="transitProductUpdate.status != 'Confirmed'" type="button" class="btn btn-submit"
            v-on:click="checkAccess()">
            Submit
          </button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->
</template>

<script>
import { productStore } from "@/store/product";
import { _sale } from "@/store/models";
import { ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
const currentDate = ref(new Date());
export default {
  props: {
    selectedProducts: Object,
    transitDetails: Object,
  },
  data() {
    return {
      transitProductUpdate: {},
      transitId: null,
      transit: {},
      access_code: null
    };
  },
  computed: {
    ...mapGetters({
      formatAmount: "formatAmount",
      hasAccess: "hasAccess",
    }),
    ...mapState(["adminPermissions"]),
  },
  methods: {
    ...mapActions({
      _getPayments: "payments/get",
      _confirmTransit: "payments/add",
      _getSaleDetail: "sales/show",
      _showOtpDialog: "showOtpDialog",
      _sendAccessCode: "accessCode/add",
      // _getPayments: "payments/delete",
    }),

    verifyOtp() {
      this._showOtpDialog({
        title: "Enter Authorization Code",
        description: "Authorization Code Sent to the Warehouse Manager!",
      }).then((response) => {
        this.access_code = response.value;
        // get the access code
        if (this.access_code != null) {
          this.confirmTransit();
        } else {
          Swal.fire("You need the Authorization Code");
        }
      });
    },
    checkAccess() {
      let adminPermissions = this.adminPermissions;
      if (adminPermissions.length > 0) {
        // check if the user is the super admin
        if (adminPermissions[0] == "*" || this.hasAccess("confirm-transit")) {
          this.confirmTransit();
        } else {
          let productDetails = { ...this.transitProductUpdate };
          productDetails.product_id = productDetails.id; // Set the Product Id to product_id
          delete productDetails["id"];

          this._sendAccessCode({
            permission_code: "confirm-transit",
            item_id: this.transitId,
            details: {
              transit_id: this.transitId,
              delivery_number: this.transit.delivery_number,
              order_number: this.transit.order_number,
              ...productDetails,
            },
          }).then((response) => {
            this.verifyOtp();
          });
          Swal.fire({
            title: "Sending Authorization Code To Warehouse Manager",
            html: `<div class="spinner-border text-dark" role="status"></div>`,
            closeOnClickOutside: false,
            buttons: false,
          });
        }
      }
    },
    confirmTransit() {
      productStore
        .dispatch("confirmTransit", {
          access_code: this.access_code,
          transitId: this.transitId,
          productsData: [this.transitProductUpdate],
        })
        .then((response) => {
          Swal.fire("Transit Confirmed");

          $(".modal").modal("hide");
          this.$emit("transitConfirmedListener");
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  watch: {
    selectedProducts: {
      handler(_new) {
        this.transitId = this.$route.params.id;
        this.transit = this.$props.transitDetails;
        this.transitProductUpdate = _new;
      },
    },
  },
};
</script>
