<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Brand Name</label>
                  <b-form-input v-model="brand.name" type="text" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="brand.description"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label> Brand Image</label>
                  <div class="image-upload">
                    <input @input="pickImage($event.target)" type="file" />
                    <div class="image-uploads">
                      <img src="@/assets/img/icons/upload.svg" alt="img" />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product-list">
                  <ul class="row">
                    <li v-if="image">
                      <div class="productviews">
                        <div class="productviewsimg">
                          <img :src="image.src" alt="img" />
                        </div>
                        <div class="productviewscontent">
                          <div class="productviewsname">
                            <h2>{{ image.name }}</h2>
                            <h3>{{ image.size }}KB</h3>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="hideset"
                            v-on:click="filter = !filter"
                            >x</a
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12">
                <a
                  v-on:click="addBrand"
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                  >Submit</a
                >
                <router-link to="/brandlist" class="btn btn-cancel">Cancel</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import { router } from "@/router";
import { mapActions,mapMutations } from "vuex";
export default {
  data() {
    return {
      title: "Brand ADD",
      title1: "Create new Brand",
      image: null,
      brand: {
        name: null,
        description: null,
        image: null,
      },
    };
  },
  methods: {
    ...mapActions({
      _addBrand: "brand/add",
    }),
    ...mapMutations({
      _showLoader: "showLoader",
    }),
    addBrand() {
      if ((this.brand.name == null, this.brand.description == null)) {
        Swal.fire("Complete Form", "Please Complete the form");
      } else {
        this._addBrand({ payload: this.brand })
          .then((response) => {
            Swal.fire("Brand Added!", "Brand Successfully Added");
            router.push({ name: "brandlist" });
          })
      }
    },
    pickImage(event) {
      let file = event.files[0];
      this.brand.image = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = {
          src: e.target.result,
          name: file.name,
          size: Math.floor(e.total / 1000),
        };
      };
      reader.readAsDataURL(file);
    },
  },
  name: "addbrand",
};
</script>
