import { api } from "@/config";
import Vuex from "vuex";

const product = {
  namespaced: true,
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/product${path}`;
  },
  actions: {
    get(
      { context },
      {
        status = "active",
        product_id = "",
        store_id = null,
        forImport = false,
        params = {},
      } = {}
    ) {
      forImport = forImport ? 1 : 0;
      return api.get(product.url(product_id), {
        params: {
          status,
          product_id,
          store_id,
          forImport,
          ...params,
        },
      });
    },
    fetchProductsFewDetails({ context }) {
      return api.get(product.url("few-details"));
    },
    get_stock({ context }) {
      return api.get(product.url("stock"));
    },
    getInventoryHistory({ context }, productId) {
      return api.get(product.url(`inventory/${productId}`));
    },
    add({ context }, { payload }) {
      return api.post(product.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete({ context }, productId) {
      return api.delete(product.url(productId));
    },
    restock({ context }, { productId, payload }) {
      return api.post(product.url(`restock/${productId}`), payload);
    },
    correctQty({ context }, { productId, payload }) {
      return api.post(product.url(`correct-qty/${productId}`), payload);
    },
    update({ context }, { product_id, payload }) {
      return api.patch(product.url(product_id), payload);
    },
    import({ context }, { products_id, vendor_id = null } = {}) {
      return api.post(product.url("import"), {
        vendor_id: vendor_id,
        products_id: products_id,
      });
    },
  },
};

const productImage = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/product-image${path}`;
  },
  namespaced: true,
  actions: {
    add({ context }, { product_id, images }) {
      return api.post(
        productImage.url(),
        { product_id: product_id, images: images },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    get({ context }, { product_id }) {
      return api.get(productImage.url(), {
        params: { product_id: product_id },
      });
    },
    delete({ context }, { imageId }) {
      return api.delete(productImage.url(imageId));
    },
  },
};

const subcategory = {
  url(_path = "") {
    let path = _path != "" ? "/" + _path : "";
    return `/subcategories${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { category_id }) {
      let params = "";
      if (category_id) {
        params = {
          category_id: category_id,
        };
      }
      return api.get(subcategory.url(), {
        params: params,
      });
    },
    add({ context }, { payload }) {
      return api.post(subcategory.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};
const brand = {
  url(_path = "") {
    let path = _path != "" ? "/" + _path : "";
    return `/brands${path}`;
  },
  namespaced: true,
  actions: {
    get() {
      return api.get(brand.url());
    },
    add({ context }, { payload }) {
      return api.post(brand.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};

const productStore = new Vuex.Store({
  state: {
    transits: [],
  },
  mutations: {
    SET_TRANSIT(state, transitData) {
      state.transits = transitData;
    },
  },
  actions: {
    addTransit({ commit }, transitData) {
      return api.post(`/product/transit`, transitData);
    },
    confirmTransit({ commit }, { access_code, transitId, productsData }) {
      return api.post(`/product/transit/${transitId}/confirm`, {
        products: productsData,
        access_code: access_code,
      });
    },
    async getTransits({ commit }) {
      try {
        let response = await api.get(`/product/transit`);
        commit("SET_TRANSIT", response.data.data);
      } catch (error) {}
    },
  },
  getters: {
    getTransit(state) {
      return state.transit;
    },
    getProducts(state) {
      return state.products;
    },
  },
});

export { product, productImage, subcategory, brand, productStore };
