<template>
    <div :class="`d-flex ${$props.class}`">
        <b-form-input step="any" :required="required" :disabled="disabled" v-model="internalValue" class="siwtch-input"
            type="number" min="0" :placeholder="label" :name="name" />
        <a-switch v-model:checked="internalChecked" class="switch" />
    </div>
</template>
<script>
export default {
    props: {
        label: String,
        value: String,
        name: String,
        class: String,
        checked: Boolean,
        required: Boolean,
        disabled: Boolean
    },
    data() {
        return {
            internalValue: this.value,
            internalChecked: this.checked,
        };
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
        },
        internalValue(newInternalValue) {
            this.$emit('update:value', newInternalValue);
        },

        checked(newValue) {
            if (!newValue) {
                this.internalValue = null;
            }
            this.internalChecked = newValue;
        },
        internalChecked(newinternalChecked) {
            this.$emit('update:checked', newinternalChecked);
            this.$emit('checkUpdated', newinternalChecked);
        }
    }
}
</script>
<style>
.switch {
    border-radius: 0 0.372rem 0.372rem 0 !important;
    height: auto !important;
}

.siwtch-input {
    border-radius: 0.372rem 0 0 0.372rem !important;
}

.switch .ant-switch-handle::before {
    border-radius: 0.372rem !important;
}

.switch .ant-switch-handle {
    height: 90% !important;
}
</style>