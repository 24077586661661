<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <!-- /order list -->
        <f-table
          @pageChange="getOrders($event)"
          :paginationConfig="paginationConfig"
          :filters="['order_reference', 'customer_name']"
          :columns="columns"
          :data="data"
          @filter="getOrders({ query: $event.query })"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Status === 'Completed'"
                >{{ record.Status }}</span
              >
              <span
                class="badges bg-lightgrey"
                v-else-if="record.Status === 'Pending'"
                >{{ record.Status }}</span
              >
              <span class="badges bg-lightyellow" v-else>{{
                record.Status
              }}</span></template
            >
            <template v-if="column.key === 'order_reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.id"
                >{{ record["order_reference"] }}</router-link
              >
            </template>

            <template v-if="column.key === 'action'">
              <a
                v-if="hasAccess('delete-an-order')"
                class="me-3 confirm-text"
                @click="deleteOrder(record.id)"
                href="javascript:void(0);"
              >
                <img src="../../../assets/img/icons/delete.svg" alt="img" />
              </a>
            </template>
          </template>
        </f-table>

        <!-- /order list -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Order  number",
    dataIndex: "order_reference",
    key: "order_reference",
    sorter: (a, b) => a["order_reference"].localeCompare(b["order_reference"]),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: {
      compare: (a, b) => {
        a = a.customer_name.toLowerCase();
        b = b.customer_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Admin",
  //   dataIndex: "customer_name",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Paid.toLowerCase();
  //       b = b.Paid.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Status",
    dataIndex: "Status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
  },
];

import { mapActions, mapGetters } from "vuex";
import store from "../../../store";

export default {
  data() {
    return {
      filter: true,
      title: "Order Report",
      filter: {
        value: null,
      },
      title1: "Manage your Orders",
      startdate: currentDate,
      items: [
        {
          text: "Add Order",
          addname: "/add-order",
        },
      ],
      data: [],
      filtered: [],
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
      columns,
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
    }),
  },
  methods: {
    ...mapActions({
      _getOrders: "archive/orders",
      _deleteOrder: "orders/delete",
    }),
    deleteOrder(orderId) {
      store.commit("confirm", {
        title: "Are you sure?",
        message: "This Order Will be deleted entirely!",
        callback: (isConfirmed) => {
          if (isConfirmed) {
            this._deleteOrder({ orderId: orderId }).then((res) => {
              store.commit("notify", {
                type: "success",
                title: "Order Delete",
                message: "Order Deleted Successfully",
              });
              this.getOrders();
            });
          }
        },
      });
    },
    getOrders({ page = null, per_page = null, query = null } = {}) {
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };
      this.searchQuery = query;
      this._getOrders(params).then((apiResponse) => {
        const response = apiResponse.data;
        this.data = response.data.map((order) => {
          return {
            id: order.id,
            order_reference: order.reference,
            customer_name: order.user.name,
            date: order.date,
            Amount: order.total,
            Status: order.status,
          };
        });
        this.paginationConfig = response.pagination;
      });
    },
  },
  mounted() {
    this.getOrders();
    if (!this.hasAccess("create-orders")) {
      this.items = [];
    }
  },
  name: "archiveOrders",
};
</script>
