<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :data="data" :filters="filters" :columns="columns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <img :src="record.image" alt="product" />
                </a>
                <a href="javascript:void(0);">{{ record.name }}</a>
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <router-link class="me-3" :to="'editcategory/' + record.id">
                <img src="@/assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a v-if="isSuperAdmin()" class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record.id)">
                <img src="@/assets/img/icons/delete.svg" alt="img" />
              </a>
            </template> </template></f-table>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Category Name",
    dataIndex: "name",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category Code",
    dataIndex: "code",
    sorter: (a, b) => a.code.localeCompare(b.code),
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    title: "Created By",
    dataIndex: "create",
    sorter: (a, b) => a.create.localeCompare(b.create),
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filters: ["name", "code"],
      title: "Product Category list",
      title1: "View/Search product Category",
      items: [
        {
          text: "Add Category",
          addname: "/addcategory",
        },
      ],
      data: [],
      columns,
      Choose: ["Choose Category", "Computers"],
      Category: ["Choose Sub Category", "Fruits"],
      Brand: ["Choose Sub Brand", "Iphone"],
    };
  },
  methods: {
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
    }),
    showAlert(categoryId) {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteCategory(categoryId).then((res) => {
            Swal.fire("Deleted!", "Category Deleted.");
            this.getCategories();
          })
        }
      });
    },
    ...mapActions({
      _getCategories: "category/get",
      _deleteCategory: "category/delete",
    }),
    getCategories() {
      this._getCategories().then((response) => {
        this.data = response.data.data.map((category) => {
          return {
            image: category.image,
            name: category.name,
            id: category.id,
            code: category.code,
            description: category.description,
            create: category.admin_id,
          };
        });
      });
    },
  },
  mounted() {
    this.getCategories();
  },
  name: "categorylist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
