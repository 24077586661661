<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table
          :data="crateReports"
          :columns="reportColumns"
          :table-styles="{ '--table-height': 'auto' }"
          :filters="crateReportFilters"
        >
          <!-- CARD TOP -->
          <template #cardTop>
            <div class="d-flex justify-content-end col-12 my-3">
              <div class="col-lg-6 col-sm-6 col-12 d-flex gap-2">
                <a-range-picker class="col-10" @change="setDateRange" />
                <button class="btn btn-primary w-100" @click="getCrateReport()">
                  Fetch
                </button>
              </div>
            </div>
          </template>

          <!-- TABLE TOP -->
          <template #top>
            <div class="card-title d-flex">Summary for {{ summaryDate }}</div>
          </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'user'">
              <a
                href="#"
                @click="showModal(record, crateModalActionTypes.CRATE_DETAILS)"
                data-bs-toggle="modal"
                data-bs-target="#cratedetails"
                title="Click to View Details"
                >{{ record.user_name }}</a
              >
            </template>
            <template v-if="column.key === 'action'">
              <div class="d-flex align-items-center">
                <span
                  @click="showModal(record, crateModalActionTypes.CRATE_RETURN)"
                  style="cursor: pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#returncrate"
                  class="badges bg-lightgreen"
                >
                  Return Crates
                </span>

                <div class="text-center d-non">
                  <a
                    class="action-set"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="showModal(record, crateModalActionTypes.CRATE_DETAILS)"
                        data-bs-toggle="modal"
                        data-bs-target="#cratedetails"
                        style="cursor: pointer"
                        class="dropdown-item"
                      >
                        <img
                          src="@/assets/img/icons/eye1.svg"
                          class="me-2"
                          alt="img"
                        />View Detail</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>

  <crate-report-modal
    :action="modalAction"
    @crateReturned="getCrateReport()"
  ></crate-report-modal>
</template>
<script>
import { crateModalActionTypes } from "../constants";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import { formatTimeStamp, dateIsToday, stringToSlug } from "@/helpers";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
  },
  {
    title: "Order Reference",
    dataIndex: "reference",
    key: "reference",
    sorter: (a, b) => a.reference.localeCompare(b.reference),
  },
  {
    title: "Total Crates",
    dataIndex: "total",
    sorter: (a, b) => a.total.localeCompare(b.total),
  },

  {
    title: "Returned Crates",
    dataIndex: "returned",
    sorter: {
      compare: (a, b) => {
        a = a.returned.toLowerCase();
        b = b.returned.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const summaryColumns = [
  {
    sorter: true,
  },
  {
    title: "Crate Type",
    dataIndex: "crate_type",
    sorter: (a, b) => a.crate_type.localeCompare(b.crate_type),
  },
  {
    title: "Total Pending Crates",
    dataIndex: "total_crates",
    key: "total_crates",
    sorter: (a, b) => a.total_crates.toString().localeCompare(b.total_crates.toString()),
  },
  {
    title: "Total Crate Returned",
    dataIndex: "total_crate_returned",
    key: "total_crate_returned",
    sorter: (a, b) =>
      a.total_crate_returned.toString().localeCompare(b.total_crate_returned.toString()),
  },
  {
    title: "Total WOB",
    dataIndex: "total_wob",
    key: "total_wob",
    sorter: (a, b) => a.total_wob.toString().localeCompare(b.total_wob.toString()),
  },
];

const reportColumns = [
  {
    sorter: true,
  },
  {
    title: "Customer Name",
    dataIndex: "user_name",
    key: "user",
    sorter: (a, b) => a.user_name.localeCompare(b.user_name),
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total.localeCompare(b.total),
  },
  {
    title: "Pending",
    dataIndex: "pending",
    sorter: (a, b) => a.pending.localeCompare(b.pending),
  },
  {
    title: "Returned",
    dataIndex: "returned",
    sorter: (a, b) => a.returned.localeCompare(b.returned),
  },
  {
    title: "Action",
    key: "action",
  },
];
export default {
  data() {
    return {
      filters: ["customer_name", "reference"],
      type: null,
      selected_crate_id: null,
      crateDateRange: {
        startDate: null,
        endDate: null,
      },
      columns,
      title: "Crate Report",
      title1: "Manage your crates / empties",
      currentView: "Report",
      summaryDate: "Today",
      summaryColumns,
      crateReport: [],
      reportColumns,
      crateReports: [],
      crateReportFilters: ["user_name"],
      modalAction: {
        type: null,
        data: {},
      },
      crateModalActionTypes,
    };
  },
  computed: {
    ...mapGetters(["hasAccess", "is_supervisor", "formatAmount"]),
  },
  methods: {
    showModal(crate, type) {
      this.modalAction.type = type;
      const userDetails = {
        dateRange: { ...this.crateDateRange },
        user_id: crate.user.id,
        user_name: crate.user.name,
      };

      switch (type) {
        case crateModalActionTypes.CRATE_RETURN:
          this.modalAction.data = userDetails;
          break;
        case crateModalActionTypes.CRATE_DETAILS:
          this.modalAction.data = userDetails;
          break;
      }
    },
    getCrateReport() {
      const type = this.$route.params.type;
      const isPrevious = type == "previous" ? 1 : 0;
      store
        .dispatch("crate/getCrateReports", {
          dateRange: this.crateDateRange,
          params: {
            is_previous: isPrevious,
          },
        })
        .then((response) => {
          if (!this.crateDateRange.startDate || this.crateDateRange.startDate) {
            this.summaryDate = dateIsToday(this.crateDateRange.startDate)
              ? "Today"
              : formatTimeStamp(this.crateDateRange.startDate, false);
          }
          this.crateReports = response.data.data.map((crateReport) => {
            return {
              user_name: crateReport.user.name,
              user_id: crateReport.user.id,
              ...crateReport,
            };
          });
        });
    },
    setDateRange(context, dates) {
      this.crateDateRange.startDate = dates[0];
      this.crateDateRange.endDate = dates[1];
    },
    async checkAccess(sale_id, new_status) {
      let adminPermissions = store.state.adminPermissions();

      if (adminPermissions.length > 0) {
        // check if the user is the super admin
        if (adminPermissions[0] == "*") {
          this.updateSaleStatus(sale_id, new_status);
        } else {
          this._sendAccessCode({
            permission_code: "dispatch-sales",
            item_id: sale_id,
          }).then((response) => {
            this.verifyOtp(sale_id, new_status);
          });

          Swal.fire({
            title: "Sending Authorization Code To Supervisor",
            html: `<div class="spinner-border text-dark" role="status"></div>`,
            closeOnClickOutside: false,
            buttons: false,
          });
        }
      }
    },
    findCrateType(record, id) {
      return record.crate_summary.find(
        (crateSummary) => crateSummary.crate_type.id == id
      );
    },
    generateCrateReportColumns(crateTypes) {
      let extraColumns = crateTypes.map((crateType) => {
        return {
          title: crateType.name,
          crateTypeId: crateType.id,
          type: "crateSummary",
        };
      });
      this.reportColumns = [
        {
          sorter: true,
        },
        {
          title: "Customer Name",
          dataIndex: "user_name",
          sorter: (a, b) => a.user_name.localeCompare(b.user_name),
        },
        ...extraColumns,
        {
          title: "Action",
          key: "action",
        },
      ];
    },
  },
  watch: {
    "$route.params.type": {
      handler(_new) {
        this.type = _new;
        this.getCrateReport();
      },
    },
  },
  mounted() {
    this.type = this.$route.meta.type;
    this.getCrateReport();

    if (!this.hasAccess("create-sales")) {
      this.items = [];
    }
  },
  name: "crate-list",
};
</script>
