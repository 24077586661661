<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo_lg.jpg" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo_lg.jpg" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Sign In</h3>
                <h4>Please login to your account</h4>
              </div>
              <div class="login">
                <div class="form-login">
                  <label>Email</label>
                  <div class="form-addons">
                    <Field name="email" type="text" v-model="formData.email" @input="validate" class="form-control"
                      :class="{ 'is-invalid': errors.email }" />

                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <img src="../../../assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field v-if="showPassword" @input="validate" name="password" type="text" v-model="formData.password"
                      class="form-control pass-input" :class="{ 'is-invalid': errors.password }" />
                    <Field v-else @input="validate" name="password" v-model="formData.password" type="password"
                      class="form-control pass-input" :class="{ 'is-invalid': errors.password }" />
                    <span @click="toggleShow" class="fas toggle-password" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="form-login">
                  <div class="alreadyuser">
                    <h4>
                      <router-link to="/forgetpassword" class="hover-a">Forgot Password?</router-link>
                    </h4>
                  </div>
                </div>
                <div class="form-login">
                  <!-- <button class="btn btn-login" @click="submitForm">Sign In</button> -->
                  <button @click="login" class="btn btn-login">Sign In</button>
                </div>
                <div class="signinform text-center">
                  <h4>
                    Don’t have an account?
                    <router-link to="signup" class="hover-a">Sign Up</router-link>
                  </h4>
                </div>
                <!-- <div class="form-setlogin">
                  <h4>Or sign up with</h4>
                </div>
                <div class="form-sociallink">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="../../../assets/img/icons/google.png" class="me-2" alt="google" />
                        Sign Up using Google
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="../../../assets/img/icons/facebook.png" class="me-2" alt="google" />
                        Sign Up using Facebook
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
          <div class="login-img">
            <img src="../../../assets/img/login.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { mapActions, mapGetters, mapMutations, useStore } from "vuex";
import { Form, Field } from "vee-validate";
import { router } from "../../../router";
import { useLoggedIn } from "@/helpers/events";
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      formData: {
        password: null,
        email: null,
        admin_type: "SUPER_ADMIN",
      },
      errors: {},
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    ...mapGetters({ adminFirstTab: "adminFirstTab" }),
  },
  methods: {
    ...mapActions({
      _login: "login",
    }),
    ...mapMutations({
      _storeUser: "storeUser",
      _storeToken: "bearerToken",
    }),

    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    validate() {
      let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let result = false;
      if (!this.formData.email) {
        this.errors.email = "Email is Required";
        result = false;
      } else if (!this.formData.email.match(regex)) {
        this.errors.email = "Enter a valid Email";
        result = false;
      } else if (!this.formData.password) {
        this.errors.password = "Password is Required";
        result = false;
      } else {
        this.errors.password = null;
        this.errors.email = null;
        return true;
      }
    },
    login() {
      if (this.validate()) {
        this._login({ credentials: this.formData }).then((response) => {
          if (response?.data?.data?.bearer_token) {
            useLoggedIn();
            this._storeToken(response.data.data.bearer_token);
            // router.replace({ name: "index" });
            window.location = "/";
          }
        });
      }
    },
  },
  mounted() {
    localStorage.clear();
  },
};
</script>
