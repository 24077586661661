<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <headerpos></headerpos>
    <sidebarone></sidebarone>
    <!-- <sidebar></sidebar> -->

    <div class="page-wrapper page-wrapper-one">
      <div class="content">
        <indexpage></indexpage>
      </div>
    </div>
  </div>
</template>
