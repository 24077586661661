const Envs = {
  local: "local",
  demo: "demo",
  production: "production",
};
const NODE_ENV = process.env.NODE_ENV;

const isDevelopment = NODE_ENV != "production";
const isDemo = NODE_ENV == "demo"; 

export { Envs, NODE_ENV, isDevelopment, isDemo };
