<template>
  <div class="snack-bar-cotainer">
    <div class="">You are viewing <strong class="text-bold">{{ text }}</strong></div>
    <button class="btn text-danger" color="pink" variant="text" @click="close" >
      Close
    </button>
  </div>
</template>

<script>
export default {
  props: {
    initialSnackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      snackbar: this.initialSnackbar,
      text: this.text,
      close: this.close,
    };
  },
};
</script>

<style scoped>
.snack-bar-cotainer {
  position: fixed;
  transform: translate(-50, 0);
  left: 50%;
  top: 8vh;
  gap: 10px;
  padding: 6px 15px;
  box-shadow: 0 11px 5px -10px rgba(0, 0, 0, 0.2), 0 0 1px grey  ;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  width: max-content;
  max-width: 100%;
  padding-right: 0;
}

.snack-bar-cotainer::after {
  content: "";
}
</style>
