<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="page-header">
          <div class="page-title">
            <h4>Payment Settings</h4>
            <h6>Manage Payment Settings</h6>
          </div>
          <div class="page-btn">
            <a class="btn btn-added" data-bs-toggle="modal" data-bs-target="#addpayment"><img
                src="../../../assets/img/icons/plus.svg" alt="img" class="me-2" />Add Payment Settings</a>
          </div>
        </div>
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-input">
                  <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" class="form-control form-control-sm" placeholder="Search..."
                        aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                        src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="table-responsive">
              <a-table :columns="columns" :data-source="data" :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }" @change="onChange">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div class="status-toggle d-flex justify-content-between align-items-center">
                      <input type="checkbox" :id="record.id" class="check" @change="
                        updateDetailStatus(record.id, $event.target.checked)
                        " :checked="record.is_active == 1 ? true : false" />
                      <label :for="record.id" class="checktoggle">checkbox</label>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <a class="me-3" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editpayment"
                        @click="selectBankDetail(record)">
                        <img src="../../../assets/img/icons/edit.svg" alt="img" />
                      </a>
                      <a class="me-3 confirm-text" href="javascript:void(0);" @click="deleteBankDetail(record.id)">
                        <img src="../../../assets/img/icons/delete.svg" alt="img" />
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
  <paymentsetmodal @onbankDetailAdded="getBankDetails" :selected="selectedBankDetail"></paymentsetmodal>
</template>
<script>
import { mapActions } from "vuex";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Bank Name",
    dataIndex: "bank_name",
    sorter: {
      compare: (a, b) => {
        a = a.bank_name.toLowerCase();
        b = b.bank_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Number",
    dataIndex: "account_number",
    sorter: {
      compare: (a, b) => {
        a = a.account_number.toLowerCase();
        b = b.account_number.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    sorter: {
      compare: (a, b) => {
        a = a.account_name.toLowerCase();
        b = b.account_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "id",
    sorter: true,
  },
  {
    title: "Action",
    key: "action",
    class: "text-end",
    sorter: true,
  },
];

export default {
  data() {
    return {
      data: [],
      selectedBankDetail: null,
      columns,
    };
  },
  methods: {
    ...mapActions({
      _getBankDetails: "bankDetailsget",
      _updateDetail: "bankDetailsupdate",
      _deleteDetail: "bankDetailsdelete",
    }),
    deleteBankDetail(id) {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteDetail({ id: id }).then((res) => this.getBankDetails());
        }
      });
    },
    selectBankDetail(data) {
      this.selectedBankDetail = data;
    },
    getBankDetails() {
      this._getBankDetails().then((response) => {
        this.data = response.data.data;
      });
    },
    updateDetailStatus(id, status) {
      this._updateDetail({ id: id, payload: { is_active: status } }).then(
        (response) => {
          this.getBankDetails();
        }
      );
    },
  },
  mounted() {
    this.getBankDetails();
  },
  name: "paymentsettings",
};
</script>
