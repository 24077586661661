import { api } from "@/config";

export default {
  /**
   *
   * @param {string} callbackElementId The Id of the Element to Disable if there are errors
   * @returns Object
   */
  checkInputValidity({ context }, { callbackElementId }) {
    let errors = {};
    let inputs = document.querySelectorAll("input");
    let textareas = document.querySelectorAll("textarea");
    let selects = document.querySelectorAll("select");

    ["selects", "textareas", "inputs"].map(function (selector) {
      document.querySelectorAll(selector).forEach((element) => {
        if (element.name) {
          if (element.checkValidity() || element.value != 0) {
            delete errors[element.name];
            element.classList.remove("is-invalid");
          } else {
            errors[element.name] = element.validationMessage;
            element.classList.add("is-invalid");
          }
        }
      });
    });

    let callbackElement = document.getElementById(callbackElementId);
    if (callbackElement) {
      if (Object.values(errors).length > 0) {
        callbackElement.disabled = true;
      } else {
        callbackElement.disabled = false;
      }
    }
    return errors;
  },
  // eslint-disable-next-line no-unused-vars
  async login({ context }, { credentials }) {
    localStorage.clear();
    return await api.post("/login", credentials);
  },
  delete({ context }, { path, id }) {
    return api.delete(`/${path}`);
  },
  getProfile({ context }) {
    return api.get("");
  },
  updateProfile({ context }, { payload }) {
    return api.post(
      "",
      { ...payload, _method: "PATCH" },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  showOtpDialog: ({ context }, { title, description }) => {
    return Swal.fire({
      title: description,
      text: title,
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Verify",
      showLoaderOnConfirm: true,
    });
  },
};
