import { api } from "@/config";

export const accessCode = {
  url() {
    return `/access-code`;
  },
  namespaced: true,
  actions: {
    add(
      { context },
      { permission_code, item_id, details, item_type, extras } = {
        item_type: null,
        details: null,
        extras: {},
      }
    ) {
      return api.post(
        accessCode.url(),
        {
          permission_code: permission_code,
          item_id: item_id,
          item_type: item_type,
          details: details,
          ...extras,
        },
        {}
      );
    },
  },
};
