function handleErrorDisplay(errors, element) {
  if (errors[element.name]) {
    element.classList.add("is-invalid");
    let errorElement = element.parentElement.querySelector(".invalid-feedback");
    if (!errorElement) {
      errorElement = document.createElement("div");
      errorElement.classList.add("invalid-feedback");
      element.parentElement.appendChild(errorElement);
    }
    errorElement.textContent = errors[element.name];
  } else {
    element.classList.remove("is-invalid");
    let errorElement = element.parentElement.querySelector(".invalid-feedback");
    if (errorElement) {
      errorElement.remove();
    }
  }
}

export function checkInputValidity(callbackElementId = null) {
  let errors = {};
  ["input[name]", "textarea[name]", "select[name]"].forEach((type) => {
    document.querySelectorAll(type).forEach((element) => {
     if (!element.validity.valid) {
        errors[element.name] = element.validationMessage;
      } else {
        delete errors[element.name];
      }

      // Manage error classes and messages
      handleErrorDisplay(errors, element);
    });
  });

  if (callbackElementId) {
    let callbackElement = document.getElementById(callbackElementId);
    if (callbackElement) {
      callbackElement.disabled = Object.values(errors).length > 0;
    }
  }
  return errors;
}
