<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="">
            <!-- <pageheader :title="title" :title1="title1" :items="items"/> -->
            <pageaddheader :title="title" :title1="title1" />

            <div class="col-lg-4 col-sm-6 col-12 d-flex mb-3 gap-2" style="padding-right: 25px">
              <a-range-picker style="width: 250px" @change="setDateRange" />
              <button style="margin: 5px" class="btn btn-primary" @click="getWarehouseSummary">
                Fetch
              </button>

            </div>
            <br />
          </div>

          <f-table :columns="report.columns" :data="report.data" :filters="report.filters">
            <template #header="{ filteredData }">
              <div class="">
                <div class="d-flex justify-content-between gap-3 mb-2">
                  <span>Total Purchase: </span>
                  <span class="fw-bold">{{
                    dataIsUnique(filteredData, "product_name")
                      ? sumArrayKey(filteredData, "qty")
                      : "---"
                  }}</span>
                </div>
                <div class="d-flex justify-content-between gap-3">
                  <span>Available Qty: </span>
                  <span class="fw-bold">{{
                    dataIsUnique(filteredData, "product_name")
                      ? filteredData[0]?.available_qty
                      : "---"
                  }}</span>
                </div>
              </div>
            </template>

            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'product'">
                <div class="productimgname">
                  <a href="javascript:void(0);" class="product-img">
                    <img :src="record.product.image" alt="product" />
                  </a>
                  <a href="javascript:void(0);">{{ record.product.name }}</a>
                </div>
              </template>

              <template v-else-if="column.key === 'reference'">
                <router-link title="Click to View Order" :to="'orders/' + record.order_id">{{ record.reference
                  }}</router-link>
              </template>

              <template v-else-if="column.key === 'status'">
                <span class="badges bg-lightgreen" v-if="record.status == 'Completed' || record.status == 'Approved'">{{
                  record.status }}</span>
                <span class="badges bg-lightred" v-else-if="record.status === 'Pending'">{{ record.status }}</span>
                <span class="badges bg-lightgrey" v-else>{{
                  record.status
                }}</span></template>
            </template>
          </f-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import store from "../../../store";
import { warehouseSummaryColumns } from "../dashboard/columns";
import { dataIsUnique, sumArrayKey } from "@/helpers";

const title = ref("Purchase Report");
const title1 = ref("Manage your Reports");
const report = ref({
  columns: warehouseSummaryColumns,
  filters: ["product_name", "customer_name"],
  dateRange: {
    startDate: null,
    endDate: null,
  },
  data: [],
});

function setDateRange(context, dates) {
  report.value.dateRange.startDate = dates[0];
  report.value.dateRange.endDate = dates[1];
};
function getWarehouseSummary() {
  store.dispatch("sales/getWareHouseSummary", {
    dateRange: report.value.dateRange,
  }).then((response) => {
    report.value.data = response.data.data.map((summary) => {
      return {
        id: summary.id,
        order_id: summary.order_id,
        sale_id: summary.sale_id,
        date: summary.date,
        customer_name: summary.customer_name,
        reference: summary.order_reference,
        status: summary.status,
        product: summary.product,
        product_name: summary.product.name,
        available_qty: summary.product.available_crates_desc,
        qty: summary.qty,
        qty_text: summary.qty_text,
      };
    });
  });
};
onMounted(() => {
  getWarehouseSummary();
})
</script>
