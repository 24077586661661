import { api } from "@/config";

const url = (url, ...queries) => {
  let extras = queries.length ? `/${queries.join("/")}` : "";
  return `/${url}${extras}`;
};

export const crate = {
  namespaced: true,
  state: {
    crateTypes: [],
    orderCrates: [],
    orderCrateSummary: [],
  },
  mutations: {
    async SET_CRATE_TYPES(state, crateTypes) {
      state.crateTypes = crateTypes;
    },
  },
  actions: {
    /** CRATE TYPE START */
    async deleteCrateType({ commit }, id) {
      return await api
        .delete(`/crate-type/${id}`, id)
        .then((response) => {
          commit("SET_CRATE_TYPES", response.data.data);
        })
        .catch((err) => {});
    },
    async addCrateType({ commit }, payload) {
      return await api.post("/crate-type", payload).then((response) => {
        commit("SET_CRATE_TYPES", response.data.data);
      });
    },
    updateCrateType({ commit }, { id, payload }) {
      return api
        .post(`/crate-type/${id}`, { _method: "PATCH", ...payload })
        .then((response) => {
          commit("SET_CRATE_TYPES", response.data.data);
        });
    },
    async getCrateTypes({ commit, state }, force = false) {
      try {
        if (state.crateTypes.length <= 0 || force == true) {
          let response = await api.get(`/crate-type`);
          await commit("SET_CRATE_TYPES", response.data.data);
        }
      } catch (error) {}
    },

    /**
     * This Returns all the Crate Report and a User's Crate Report when the UserId id Passed
     * @returns
     */
    getCrateReports(
      { commit, state },
      { dateRange, userId, params } = {
        dateRange: {},
        userId: null,
        params: {},
      }
    ) {
      const requestParams = { ...params };
      if (dateRange.startDate) {
        requestParams.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        requestParams.end_date = dateRange.endDate;
      }
      return api.get(url("crates", userId || ""), {
        params: requestParams,
      });
    },
    /** CRATE TYPE END */

    /** ORDER CRATES START */
    returnOrderCrate({ commit }, { userId, payload }) {
      return api.post(`/crates/${userId}`, { _method: "PATCH", ...payload });
    },

    /** ORDER CRATES END */
  },
};
