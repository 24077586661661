<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <headerpos></headerpos>
            <sidebarone></sidebarone>
            <sidebar></sidebar>
        <div class="container">
            <div class="page-wrapper page-wrapper-three">
                <div class="content">
                    
                    <indexpage></indexpage>
                    
                </div>
            </div>
        </div>
    </div>
</template>

