<template>
  <!-- Mobile Menu -->
  <div class="dropdown mobile-user-menu">
    <a
      href="javascript:void(0);"
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      ><i class="fa fa-ellipsis-v"></i
    ></a>
    <div class="dropdown-menu dropdown-menu-right">
      <router-link class="dropdown-item" to="/profile">My Profile</router-link>
      <router-link class="dropdown-item" to="/generalsettings"
        >Settings</router-link
      >
      <span class="dropdown-item" @click="logOut">Logout</span>
    </div>
  </div>
  <!-- /Mobile Menu -->
</template>
<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations({
      logOut: "logOut",
    }),
  },
};
</script>
