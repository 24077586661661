<template>
      <a-range-picker
        @change="setDateRange" 
        :options="{ maxDate: currentDate }"
        :disabled-date="disabledAfterToday" 
      />
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());

export default {
  name: "Datepicker",
  methods: {
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(23, 0, 0, 0);
      return date > today
    },
    setDateRange(context, dates){
      this.$emit("change", context, dates)
    }
  },
};
</script>
