import { api } from "@/config";
import { socket } from "@/config/websocket";
import { endpoints } from "@/constants";

export const notification = {
  url(...queryies) {
    let extras = queryies.length ? `/${queryies.join("/")}` : "";
    return `${endpoints.utils.notifications}${extras}`;
  },
  namespaced: true,
  state: {
    notifications: [],
    unreadNotifications: [],
  },
  mutations: {
    setNotifications: (state, notifications) => {
      state.notifications = notifications;
      state.unreadNotifications = notifications.filter(
        (nofication) => nofication.seen == false
      );
    },
  },
  actions: {
    async getNotifications({ commit }) {
      try {
        const response = await api.get(notification.url());
        const data = response.data.data;
        commit("setNotifications", data);
      } catch (error) {}
    },
    markSeen({ dispatch }, { id }) {
      try {
        const response = api.post(notification.url(id), {
          _method: "PATCH",
        });
        dispatch("getNotifications");
      } catch (error) {}
    },
  },
};
