<template>
  <div v-if="!isLaoding" class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo_lg.jpg" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo_lg.jpg" alt="" />
              </router-link>

              <div class="login">
                <div class="login-userheading">
                  <h1
                    style="
                      color: #ff9f43;
                      font-family: Work Sans, sans-serif;
                      font-weight: 800;
                    "
                  >
                    Hi there!
                  </h1>
                </div>
                <div
                  class="alert alert-success"
                  role="alert"
                  style="
                    background-color: rgba(0, 0, 0, 0.05);
                    font-family: Work Sans, sans-serif;
                    border: none;
                  "
                >
                  <Perloading />
                  Congratulations your email has been verified.
                  <router-link to="#" class="login-link"
                    >We will let you know when you account is Approved</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="login-img">
            <img src="../../../assets/img/login.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import axios from "axios";
import store from "@/store";
import { endpoints } from "@/constants";

import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  name: "EmailVerified",

  data() {
    return {
      token: "",
      isLaoding: true,
    };
  },

  methods: {
    // Your existing methods
    async verifyEmail() {
      try {
        let token = this.$route.query.token;
        if (!token) return this.router.push("/signup");
        const response = await axios.post(endpoints.auth.verifyEmail, { token: token });
        this.isLaoding = false;
        // Handle success
        // this.$router.push("/signin");
        store.commit("closeLoader");
      } catch (error) {
        console.error(error);
        // Handle error
        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.errors
        ) {
          this.errors = error.response.data.errors;
        }

        // Reset errors if not 422 (validation error)
        if (error.response && error.response.status !== 422) {
          this.errors = {};
        }
      }
    },
  },

  mounted() {
    store.commit("showLoader");
    // Set a timeout to navigate to the new page after 15 seconds

    let token = this.$route.query.token;
    this.verifyEmail();

  },
};
</script>
