<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" v-model="user.first_name" />
                </div>

                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="user.email" />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <div class="pass-group">
                    <input v-if="showPassword" type="text" class="pass-input" v-model="user.password" />
                    <input v-else type="password" class="pass-input" v-model="user.password" />
                    <span class="fas toggle-password" @click="toggleShow" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                  </div>
                </div>
                <div class="form-group">
                  <label>Company Name</label>
                  <input type="text" v-model="user.company_name" />
                </div>
                <div class="form-group" v-if="hasAccess('issue-custom-credit-limit')">
                  <label for="has_custom_credit_limit">Has Custom Credit Limit</label>
                  <div class="status-toggle d-flex justify-content-between align-items-center">
                    <input type="checkbox" class="check" id="has_custom_credit_limit"
                      v-model="user.has_custom_credit_limit" />
                    <label for="has_custom_credit_limit" class="checktoggle"></label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" v-model="user.last_name" />
                </div>
                <div class="form-group">
                  <label>Mobile</label>
                  <input type="text" v-model="user.mobile_number" />
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <div class="pass-group">
                    <input v-if="showPassword1" type="text" class="pass-input" v-model="password1" />
                    <input v-else type="password" class="pass-input" v-model="password1" />
                    <span class="fas toggle-password" @click="toggleShow1" :class="{
                      'fa-eye': showPassword1,
                      'fa-eye-slash': !showPassword1,
                    }"></span>
                  </div>
                </div>
                <div class="form-group">
                  <label>Account Type</label>
                  <vue-select :options="accountTypes" v-model="user.account_type_id" placeholder="Select Account Type" />
                </div>

                <div class="form-group" v-show="user.has_custom_credit_limit">
                  <label for="has_custom_credit_limit">Custom Credit Limit</label>
                  <input type="text" v-model="user.credit_limit" />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Profile Picture</label>
                  <div class="image-upload image-upload-new">
                    <img v-if="image_preview" style="
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        opacity: 0.5;
                      " :src="image_preview" />
                    <input type="file" @input="pickFile($event.target)" />
                    <div class="image-uploads">
                      <img src="../../../assets/img/icons/upload.svg" alt="img" />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <a @click="addUser" href="javascript:void(0);" class="btn btn-submit me-2">Submit</a>
                <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import { router } from "@/router";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      title: "User Management",
      title1: "Add/Update User",
      showPassword: false,
      showPassword1: false,
      password: null,
      password1: null,
      accountTypes: [],
      image_preview: null,
      user: {
        first_name: null,
        last_name: null,
        email: null,
        mobile_number: null,
        company_name: null,
        has_custom_credit_limit: false,
        credit_limit: null,
        account_type_id: null,
        profile_picture: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapGetters(["hasAccess", "formatAmount"]),
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    ...mapActions({
      _getAccountTypes: "accountType/get",
      _addUser: "users/add",
    }),
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    getAccountTypes() {
      this._getAccountTypes().then((response) => {
        this.accountTypes = response.data.data.map((account_type) => {
          return {
            text: account_type.name,
            id: account_type.id,
          };
        });
      });
    },
    pickFile(event) {
      let file = event.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image_preview = e.target.result;
      };

      this.user.profile_picture = file;
      reader.readAsDataURL(file);
    },
    addUser() {
      let payload = {...this.user};
      payload.has_custom_credit_limit = payload.has_custom_credit_limit ? 1 : 0;
      this._addUser({ payload: payload })
        .then((response) => {
          Swal.fire({
            title: "User Added",
            icon: "success",
          });
          router.push({ name: "userlists" });
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
          });
        });
      Swal.fire({
        title: "Adding User",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        html: `<div class="spinner-border text-warning" role="status"></div>`,
      });
    },
  },
  mounted() {
    this.getAccountTypes();
  },
  name: "newuser",
};
</script>
