import { api } from "@/config";

export const purchases = {
  url(order_id, id = "") {
    let path = `/${order_id}/purchases`;
    if (id != "") {
      path = "/" + id;
    }
    return `/orders${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { order_id }) {
      return api.get(purchases.url(order_id));
    },
    getPurchaseReport({ context }, { dateRange }) {
      return api.get(`/purchase-report`, {
        params: {
          start_date: dateRange.startDate,
          end_date: dateRange.endDate,
        },
      });
    },
  },
};
export const suppliers = {
  url(order_id, id = "") {
    let path = `/${order_id}/suppliers`;
    if (id != "") {
      path = "/" + id;
    }
    return `/orders${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }, { order_id }) {
      return api.get(suppliers.url(order_id));
    },
    getSupplierReport({ context }, { dateRange }) {
      return api.get(`/supplier-report`, {
        params: {
          start_date: dateRange.startDate,
          end_date: dateRange.endDate,
        },
      });
    },
  },
};
