import store from "@/store";

/**
 * This Updates a data in the local storage by increasing or decreasing the value
 * as request are made and completed.
 * It helps us to know if there are still pending requests
 * @param {Boolean} increase
 */
export function updatePendingRequest(increase = true) {
  let pendingRequests = store.state.pendingRequests;

  pendingRequests = increase
    ? pendingRequests + 1
    : Math.max(pendingRequests - 1, 0);
  store.state.pendingRequests = pendingRequests;
}
