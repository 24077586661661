/* eslint-disable */
import { createStore } from "vuex";

import { api } from "@/config";
import {
  product,
  productImage,
  subcategory,
  brand,
  transitStore,
} from "./product";
import { sales, saleReturn } from "./order/sale";
import { mobileAds } from "./adverts/mobileAds";
import { chat } from "./chat";
import { crate } from "./product/crate";
import { accountType } from "./user/accountType";
import { receipts } from "./order/receipts";
import { adminPermission } from "./admin/adminPermission";
import { bankDetails } from "./settings/bankDetails";
import { accessCode } from "./admin/accessCode";
import { adminRole } from "./admin/adminRole";
import { dashboard } from "./dashboard";
import { admin } from "./admin";
import { category } from "./product/category";
import { users } from "./user/users";
import { limitIncrease } from "./user/limitIncrease";
import { archive } from "./archive/archive";
import { orders } from "./order";
import { utils } from "./utils";
import { vendorReport } from "./vendor";
import { payments } from "./order/payments";
import { purchases, suppliers } from "./order/salesReports";
import { notification } from "./utils/notification";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { print } from "./utils/print";
import { pushNotification } from "./notification";

const store = createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    pushNotification,
    adminPermission,
    productImage,
    accountType,
    limitIncrease,
    vendorReport,
    notification,
    subcategory,
    bankDetails,
    saleReturn,
    accessCode,
    purchases,
    suppliers,
    adminRole,
    mobileAds,
    dashboard,
    dashboard,
    payments,
    category,
    receipts,
    product,
    archive,
    orders,
    brand,
    sales,
    utils,
    users,
    admin,
    chat,
    crate,
    users,
    print,
  },
});
export default store;
