import axios from "axios";
import { router } from "@/router";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import {
  endpoints as endpoint,
  baseUrl as baseurl,
  adminUrl,
  socketUrl,
} from "@/constants";
import { getAccessToken, isAuth } from "@/helpers";
import { updatePendingRequest } from "@/helpers/request";
import store from "@/store";

const url = baseurl;
const baseUrl = adminUrl;

const storage = useLocalStorage();

const endpoints = {
  states: `${baseUrl}states`,
  storenameexists: `${baseUrl}store_name`,
  auth: {
    signup: `${baseUrl}register`,
  },
};

const api = axios.create({
  baseURL: baseUrl,
  validateStatus: function (status) {
    if (status == 401) {
      if (isAuth() != null) {
        Swal.fire("Access Denied", "", "error");
        return false;
      }
      return false;
    } else if (status == 200 || status == 201) {
      return true;
    }
    return false;
  },
});

function getAuthorizationHeader() {
  return { Authorization: `Bearer ${getAccessToken()}` };
}

function showLoader(url, params = []) {
  const shouldShowLoader = [
    "chat",
    "update-vendor-admin-request",
    "signup",
    "pending-vendors",
    "rejected-vendors",
    "store",
    "vendors",
    "store_name",
    "notifications",
  ].filter((path) => url?.includes(path));

  const shouldShowLoaderParams = ["search_query"].filter((path) =>
    Object.keys(params ?? {}).includes(path)
  );
  return !shouldShowLoader.length && !shouldShowLoaderParams.length;
}

function toastError(url, method) {
  return store.state.toastError;
}

/**
 * This Handles the Authentication Properly
 */
api.interceptors.response.use(
  function (config) {
    if (showLoader(config?.config?.url)) {
      updatePendingRequest(false);
    }
    return config;
  },
  function (error) {
    if (showLoader(error?.config?.url)) {
      updatePendingRequest(false);
    }
    try {
      if (error?.response?.data?.message?.match(/authenticated/)) {
        Swal.fire({
          title: "Admin not Logged in",
          icon: "error",
        });
        localStorage.clear();
      } else {
        if (error?.response?.data?.message) {
          if (toastError(error?.config?.url, error?.config?.method)) {
            Swal.fire(error?.response?.data?.message, "", "error");
          }
        } else {
          Swal.fire(
            "Unable to connect to the server. Please check your internet connection and Refresh the Page",
            "",
            "warning"
          );
        }
      }
      throw error;
    } catch (error) {
      throw error;
    }
  }
);

api.interceptors.request.use(
  function (request) {
    if (showLoader(request?.url)) {
      updatePendingRequest();
    }

    if (storage.getItem("mode") === "store") {
      const store_id = atob(storage.getItem("store-id"));
      if (!(request.url === baseUrl))
        request.params = { ...request.params, store_id };
    }
    request.headers.Authorization = getAuthorizationHeader().Authorization;

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

async function pingSystem() {
  const isReachable = await axios.get(endpoint.system.ping);
  return isReachable.status === 200;
}

export {
  api,
  baseUrl,
  socketUrl,
  endpoints,
  getAuthorizationHeader,
  pingSystem,
};
