import { api } from "@/config";

export const archive = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/archive${path}`;
  },
  namespaced: true,
  actions: {
    orders({ context }, params) {
      return api.get(archive.url("orders"), { params: params });
    },
  },
};
