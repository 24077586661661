import { api } from "@/config";

export const admin = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/admin${path}`;
  },
  namespaced: true,
  actions: {
    get(
      { context },
      { account_type, reason } = { account_type: null, reason: null }
    ) {
      let params = {};
      if (account_type) {
        params = {
          account_type: account_type,
          reason: reason,
        };
      }
      return api.get(admin.url(), {
        params: params,
      });
    },
    show({ context }, { admin_id }) {
      return api.get(admin.url(admin_id));
    },
    delete({ context }, { admin_id }) {
      return api.delete(admin.url(admin_id));
    },
    add({ context }, { payload }) {
      return api.post(admin.url(), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    update({ context }, { admin_id, payload }) {
      return api.post(
        admin.url(admin_id),
        { ...payload, _method: "PATCH" },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
