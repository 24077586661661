<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper chat-page">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="col-lg-12">
          <div class="row chat-window" style="height: 70vh">
            <!-- Chat User List -->

            <div class="col-lg-5 col-xl-4 chat-cont-left chat-container">
              <div class="card mb-sm-3 mb-md-0 contacts_card flex-fill" style="height: 100%">
                <div class="card-header chat-search">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="search_btn"><i class="fas fa-search"></i></span>
                    </div>
                    <input type="text" placeholder="Search" class="form-control search-chat rounded-pill" />
                  </div>
                </div>

                <div class="card-body contacts_body chat-users-list chat-scroll" v-if="newChat">
                  <a v-for="account in accounts" :key="account.chat_id" href="javascript:void(0);"
                    class="media d-flex active" @click="loadChats(account.chat_id, 'new')">
                    <div class="media-img-wrap flex-shrink-0">
                      <div class="avatar avatar-away">
                        <img :src="account.profile_picture" alt="User Image" class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body flex-grow-1">
                      <div style="text-align: left">
                        <span class="d-block user-name">{{ account.name }}</span>
                        <span class="d-block user-last-chat">
                          {{ account.accountType.name }}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="card-body contacts_body chat-users-list chat-scroll" v-if="!newChat">
                  <a v-for="chat in allChat" :key="chat.recipient.chat_id" href="javascript:void(0);"
                    class="media d-flex active" @click="loadChats(chat.recipient.chat_id)">
                    <div class="media-img-wrap flex-shrink-0">
                      <div class="avatar avatar-away">
                        <img :src="chat.recipient.profile_picture" alt="User Image" class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body flex-grow-1">
                      <div>
                        <div class="user-name">{{ chat.recipient.name }}</div>
                        <div class="user-last-chat" style="text-align: left">
                          {{ chat.message }}
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time">{{ chat.date }}</div>
                        <div class="badge badge-success badge-pill">
                          {{ chat.new_messages > 0 ? chat.new_messages : null }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <button class="btn btn-primary toggle-btn" @click="toggleNewChat()">
                  <span v-if="!this.newChat">+</span>
                  <span v-if="this.newChat">&times;</span>
                </button>
              </div>
            </div>
            <!-- Chat User List -->

            <div v-if="!recipient.name" class="col-lg-7 col-xl-8 chat-cont-right chat-container">
              <div class="card mb-0" style="
                  display: flex;
                  justify-content: center;
                  place-items: center;
                  height: 100%;
                ">
                <h4>Start A New Chat</h4>
              </div>
            </div>
            <!-- Chat Content -->
            <div v-if="recipient.name" class="col-lg-7 col-xl-8 chat-cont-right" :style="'height: 100%;' + recipient.name
                ? 'visibility:visible; opacity: 1;right: 0;height: 100%'
                : ''
              ">
              <!-- Chat History -->
              <div class="card mb-0" style="height: 100%">
                <div class="card-header msg_head">
                  <div class="d-flex bd-highlight">
                    <a id="back_user_list" href="javascript:void(0)" @click="showChats" class="back-user-list">
                      <i class="fas fa-chevron-left"></i>
                    </a>
                    <div class="img_cont">
                      <img :src="recipient.profile_picture" alt="User Image" class="avatar-img rounded-circle" />
                    </div>
                    <div class="user_info">
                      <span><strong id="receiver_name">{{
                        recipient.name
                      }}</strong></span>
                      <p class="mb-0">Messages</p>
                    </div>
                  </div>
                </div>

                <div class="card-body msg_card_body chat-scroll" ref="chats" id="chats">
                  <ul class="list-unstyled" v-if="privateChats.length > 0">
                    <li :class="'media d-flex ' +
                      (chat.sender.chat_id == adminData.chat_id
                        ? 'sent'
                        : 'received')
                      " v-for="chat in privateChats" :key="chat.id">
                      <div class="avatar flex-shrink-0">
                        <img :src="chat.sender.profile_picture" alt="User Image" class="avatar-img rounded-circle" />
                      </div>
                      <div class="media-body flex-grow-1">
                        <div class="msg-box">
                          <div>
                            <p>{{ chat.message }}</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>{{ chat.date }}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <!-- <li class="media sent d-flex">
                      <div class="avatar flex-shrink-0">
                        <img
                          src="../../../assets/img/customer/customer5.jpg"
                          alt="User Image"
                          class="avatar-img rounded-circle"
                        />
                      </div>
                      <div class="media-body flex-grow-1">
                        <div class="msg-box">
                          <div>
                            <p>Where?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <p>
                              OK, my name is Limingqiang. I like singing,
                              playing basketballand so on.
                            </p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <div class="chat-msg-attachments">
                              <div class="chat-attachment">
                                <img
                                  src="../../../assets/img/product/product15.jpg"
                                  alt="Attachment"
                                />
                                <a href="" class="chat-attach-download">
                                  <i class="fas fa-download"></i>
                                </a>
                              </div>
                            </div>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:50 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li> -->
                  </ul>
                </div>

                <div class="card-footer">
                  <div class="input-group">
                    <input v-model="newMessage" class="form-control type_msg mh-auto empty_check"
                      placeholder="Type your message..." />
                    <button class="btn btn-primary btn_send" @click="sendMessage">
                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Chat Content -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { socket } from "@/config/websocket";
import { mapActions, mapGetters } from "vuex";
import store from "../../../store";
export default {
  name: "chat",
  data() {
    return {
      newChat: false,
      allChat: null,
      privateChats: [],
      newMessage: null,
      recipient: {
        name: null,
        chat_id: null,
        profile_picture: null,
      },
      adminData: null,
      accounts: [],
    };
  },

  methods: {
    ...mapActions({
      _getChattedUsers: "chat/getChattedUsers",
      _getChat: "chat/getChats",
      _sendMessage: "chat/sendMessage",
      _updateConnectionId: "chat/updateConnectionId",
    }),
    scrollToBottom() {
      const container = this.$refs.chats || null;
      if (container) {
        container.scrollTop = 1000000;
      }
    },
    showChats() {
      this.recipient.name = null;
      this.recipient.chat_id = null;
    },
    getUsers() {
      store.dispatch("chat/getUsers").then((response) => {
        this.accounts = response.data.data.map((user) => {
          return {
            id: user.id,
            name: user.name,
            chat_id: user.chat_id,
            accountType: user.account_type,
            profile_picture: user.profile_picture,
          };
        });
      });
    },
    loadChats(sender_id, type = null) {
      this._getChat({ sender_id: sender_id }).then((response) => {
        let data = response.data.data;
        this.privateChats = data.chats;
        this.recipient.name = data.recipient.name;
        this.recipient.chat_id = data.recipient.chat_id;
        this.recipient.profile_picture = data.recipient.profile_picture;

        setTimeout(() => {
          this.scrollToBottom();
        }, 1);
      });
      if (type == "new") {
        this.newChat = false;
      }
    },
    toggleNewChat() {
      this.newChat = !this.newChat;
    },
    getChattedUsers() {
      this._getChattedUsers().then((response) => {
        this.allChat = response.data.data;
      });
    },
    sendMessage() {
      this._sendMessage({
        receiver_chat_id: this.recipient.chat_id,
        message: this.newMessage,
      }).then((res) => {
        this.newMessage = null;
        this.loadChats(this.recipient.chat_id);
        this.getChattedUsers();
      });
    },
    updateConnectionId(conectionID) {
      this._updateConnectionId(conectionID).then((res) => { });
    },
    /**
     * This Retrieves New Chats for this User
     * @param {String} sender_chat_id The Chat Id of the Sender
     */
    updateChat(sender_chat_id) {
      this.getChattedUsers(); // Refresh all the chat
      // check if the message is from the current user the admin is chatting
      if (this.recipient.chat_id == sender_chat_id) {
        this.loadChats(sender_chat_id); // Load the Chats fro this Particular User to Update the Chat Page
      }
    },
  },

  mounted() {
    this.adminData = store.state.adminProfile;
    if (this.adminData) {
      this.getChattedUsers();
      this.getUsers();

      socket.addEventListener("message", (event) => {
        let message = JSON.parse(event.data);
        switch (message.channel) {
          case "admin.message.alert":
            // Refresh Chat
            let sender_chat_id = message.sender_chat_id;
            this.updateChat(sender_chat_id);
            break;
          default:
            break;
        }
      });
    }
  },
};
</script>
<style>
.avatar-img {
  overflow: hidden !important;
}

.toggle-btn {
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  bottom: 5%;
}

.toggle-btn span {
  font-size: 24px;
}

.chat-container {
  height: 100%;
}
</style>
