<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a class="product-img">
                  <img :src="record.img" alt="product" />
                </a>

                <router-link
                  style="color: #ff9f43"
                  title="Click to View Product"
                  :to="'products/' + record.id"
                  >{{ record.name }}</router-link
                >
              </div></template
            >
          </template></f-table
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },

  {
    title: "Product Name",
    key: "id",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "SKU",
    dataIndex: "sku",
    sorter: {
      compare: (a, b) => {
        a = a.sku.toLowerCase();
        b = b.sku.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category Name",
    dataIndex: "category_name",
    sorter: {
      compare: (a, b) => {
        a = a.category_name.toLowerCase();
        b = b.category_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Brand",
    dataIndex: "brand_name",
    sorter: {
      compare: (a, b) => {
        a = a.brand_name.toLowerCase();
        b = b.brand_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Crate Price",
    dataIndex: "crate_price",
    sorter: {
      compare: (a, b) => {
        a = a.crate_price.toLowerCase();
        b = b.crate_price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Available Crates",
    dataIndex: "available_crates_desc",
    sorter: {
      compare: (a, b) => {
        a = a.available_crates;
        b = b.available_crates;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    // Price x Quantity
    title: "Quantity Amount",
    dataIndex: "quantity_amount",
    sorter: {
      compare: (a, b) => {
        a = a["quantity_amount"].toLowerCase();
        b = b["quantity_amount"].toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Purchase",
    dataIndex: "total_purchase",
    sorter: {
      compare: (a, b) => {
        a = a.total_purchase;
        b = b.total_purchase;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Purchase Amount",
    dataIndex: "total_purchase_amount",
    sorter: {
      compare: (a, b) => {
        a = a.total_purchase_amount;
        b = b.total_purchase_amount;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];

export default {
  data() {
    return {
      filters: ["name", "sku"],
      title: "Inventory Report",
      title1: "Manage your Inventory Report",
      data: [],
      columns,
      startdate: currentDate,
      Suppliers: ["Choose Suppliers", "Suppliers"],
    };
  },
  computed: {
    ...mapGetters(["formatAmount"]),
  },
  methods: {
    ...mapActions({
      _get_stock: "product/get_stock",
    }),

    get_stock() {
      this._get_stock().then((response) => {
        response.data.data.map((product) => {
          this.data.push({
            id: product.id,
            img: product.image?.url,
            name: product.name,
            sku: product.sku,
            category_name: product.category.name,
            brand_name: product.brand.name,
            crate_price: this.formatAmount(product.crate_price),
            available_crates_desc: product.available_crates_desc,
            available_crates: product.available_crates,
            total_purchase: product.total_purchase,
            total_purchase_amount: this.formatAmount(product.total_purchase_amount),
            quantity_amount: this.formatAmount(product.quantity_amount),
          });
        });
      });
    },
  },
  mounted() {
    this.get_stock();
  },
  name: "inventoryreport",
};
</script>
