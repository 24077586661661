<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper cardhead">
      <div class="content container-fluid">
        <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">Themify Icon</div>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <i
                        class="ti-arrow-up"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-right"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-left"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-down"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrows-vertical"
                        data-bs-toggle="tooltip"
                        title="ti-arrows-vertical"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrows-horizontal"
                        data-bs-toggle="tooltip"
                        title="ti-arrows-horizontal"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-up"
                        data-bs-toggle="tooltip"
                        title="ti-angle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-right"
                        data-bs-toggle="tooltip"
                        title="ti-angle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-left"
                        data-bs-toggle="tooltip"
                        title="ti-angle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-down"
                        data-bs-toggle="tooltip"
                        title="ti-angle-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-double-up"
                        data-bs-toggle="tooltip"
                        title="ti-angle-double-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-double-right"
                        data-bs-toggle="tooltip"
                        title="ti-angle-double-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-double-left"
                        data-bs-toggle="tooltip"
                        title="ti-angle-double-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-angle-double-down"
                        data-bs-toggle="tooltip"
                        title="ti-angle-double-down"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-move" data-bs-toggle="tooltip" title="ti-move"></i>
                    </li>
                    <li>
                      <i
                        class="ti-fullscreen"
                        data-bs-toggle="tooltip"
                        title="ti-fullscreen"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-top-right"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-top-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-top-left"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-top-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-circle-up"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-circle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-circle-right"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-circle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-circle-left"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-circle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrow-circle-down"
                        data-bs-toggle="tooltip"
                        title="ti-arrow-circle-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-arrows-corner"
                        data-bs-toggle="tooltip"
                        title="ti-arrows-corner"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-split-v"
                        data-bs-toggle="tooltip"
                        title="ti-split-v"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-split-v-alt"
                        data-bs-toggle="tooltip"
                        title="ti-split-v-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-split-h"
                        data-bs-toggle="tooltip"
                        title="ti-split-h"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-point-up"
                        data-bs-toggle="tooltip"
                        title="ti-hand-point-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-point-right"
                        data-bs-toggle="tooltip"
                        title="ti-hand-point-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-point-left"
                        data-bs-toggle="tooltip"
                        title="ti-hand-point-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-point-down"
                        data-bs-toggle="tooltip"
                        title="ti-hand-point-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-back-right"
                        data-bs-toggle="tooltip"
                        title="ti-back-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-back-left"
                        data-bs-toggle="tooltip"
                        title="ti-back-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-exchange-vertical"
                        data-bs-toggle="tooltip"
                        title="ti-exchange-vertical"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-wand" data-bs-toggle="tooltip" title="ti-wand"></i>
                    </li>
                    <li>
                      <i class="ti-save" data-bs-toggle="tooltip" title="ti-save"></i>
                    </li>
                    <li>
                      <i
                        class="ti-save-alt"
                        data-bs-toggle="tooltip"
                        title="ti-save-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-direction"
                        data-bs-toggle="tooltip"
                        title="ti-direction"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-direction-alt"
                        data-bs-toggle="tooltip"
                        title="ti-direction-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-user" data-bs-toggle="tooltip" title="ti-user"></i>
                    </li>
                    <li>
                      <i class="ti-link" data-bs-toggle="tooltip" title="ti-link"></i>
                    </li>
                    <li>
                      <i class="ti-unlink" data-bs-toggle="tooltip" title="ti-unlink"></i>
                    </li>
                    <li>
                      <i class="ti-trash" data-bs-toggle="tooltip" title="ti-trash"></i>
                    </li>
                    <li>
                      <i class="ti-target" data-bs-toggle="tooltip" title="ti-target"></i>
                    </li>
                    <li>
                      <i class="ti-tag" data-bs-toggle="tooltip" title="ti-tag"></i>
                    </li>
                    <li>
                      <i
                        class="ti-desktop"
                        data-bs-toggle="tooltip"
                        title="ti-desktop"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-tablet" data-bs-toggle="tooltip" title="ti-tablet"></i>
                    </li>
                    <li>
                      <i class="ti-mobile" data-bs-toggle="tooltip" title="ti-mobile"></i>
                    </li>
                    <li>
                      <i class="ti-email" data-bs-toggle="tooltip" title="ti-email"></i>
                    </li>
                    <li>
                      <i class="ti-star" data-bs-toggle="tooltip" title="ti-star"></i>
                    </li>
                    <li>
                      <i class="ti-spray" data-bs-toggle="tooltip" title="ti-spray"></i>
                    </li>
                    <li>
                      <i class="ti-signal" data-bs-toggle="tooltip" title="ti-signal"></i>
                    </li>
                    <li>
                      <i
                        class="ti-shopping-cart"
                        data-bs-toggle="tooltip"
                        title="ti-shopping-cart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-shopping-cart-full"
                        data-bs-toggle="tooltip"
                        title="ti-shopping-cart-full"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-settings"
                        data-bs-toggle="tooltip"
                        title="ti-settings"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-search" data-bs-toggle="tooltip" title="ti-search"></i>
                    </li>
                    <li>
                      <i
                        class="ti-zoom-in"
                        data-bs-toggle="tooltip"
                        title="ti-zoom-in"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-zoom-out"
                        data-bs-toggle="tooltip"
                        title="ti-zoom-out"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-cut" data-bs-toggle="tooltip" title="ti-cut"></i>
                    </li>
                    <li>
                      <i class="ti-ruler" data-bs-toggle="tooltip" title="ti-ruler"></i>
                    </li>
                    <li>
                      <i
                        class="ti-ruler-alt-2"
                        data-bs-toggle="tooltip"
                        title="ti-ruler-alt-2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-ruler-pencil"
                        data-bs-toggle="tooltip"
                        title="ti-ruler-pencil"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-ruler-alt"
                        data-bs-toggle="tooltip"
                        title="ti-ruler-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-bookmark"
                        data-bs-toggle="tooltip"
                        title="ti-bookmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-bookmark-alt"
                        data-bs-toggle="tooltip"
                        title="ti-bookmark-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-reload" data-bs-toggle="tooltip" title="ti-reload"></i>
                    </li>
                    <li>
                      <i class="ti-plus" data-bs-toggle="tooltip" title="ti-plus"></i>
                    </li>
                    <li>
                      <i class="ti-minus" data-bs-toggle="tooltip" title="ti-minus"></i>
                    </li>
                    <li>
                      <i class="ti-close" data-bs-toggle="tooltip" title="ti-close"></i>
                    </li>
                    <li>
                      <i class="ti-pin" data-bs-toggle="tooltip" title="ti-pin"></i>
                    </li>
                    <li>
                      <i class="ti-pencil" data-bs-toggle="tooltip" title="ti-pencil"></i>
                    </li>
                    <li>
                      <i
                        class="ti-pencil-alt"
                        data-bs-toggle="tooltip"
                        title="ti-pencil-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-paint-roller"
                        data-bs-toggle="tooltip"
                        title="ti-paint-roller"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-paint-bucket"
                        data-bs-toggle="tooltip"
                        title="ti-paint-bucket"
                      ></i>
                    </li>
                    <li><i class="ti-na" data-bs-toggle="tooltip" title="ti-na"></i></li>
                    <li>
                      <i class="ti-medall" data-bs-toggle="tooltip" title="ti-medall"></i>
                    </li>
                    <li>
                      <i
                        class="ti-medall-alt"
                        data-bs-toggle="tooltip"
                        title="ti-medall-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-marker" data-bs-toggle="tooltip" title="ti-marker"></i>
                    </li>
                    <li>
                      <i
                        class="ti-marker-alt"
                        data-bs-toggle="tooltip"
                        title="ti-marker-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-lock" data-bs-toggle="tooltip" title="ti-lock"></i>
                    </li>
                    <li>
                      <i class="ti-unlock" data-bs-toggle="tooltip" title="ti-unlock"></i>
                    </li>
                    <li>
                      <i
                        class="ti-location-arrow"
                        data-bs-toggle="tooltip"
                        title="ti-location-arrow"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-layout" data-bs-toggle="tooltip" title="ti-layout"></i>
                    </li>
                    <li>
                      <i class="ti-layers" data-bs-toggle="tooltip" title="ti-layers"></i>
                    </li>
                    <li>
                      <i
                        class="ti-layers-alt"
                        data-bs-toggle="tooltip"
                        title="ti-layers-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-key" data-bs-toggle="tooltip" title="ti-key"></i>
                    </li>
                    <li>
                      <i class="ti-image" data-bs-toggle="tooltip" title="ti-image"></i>
                    </li>
                    <li>
                      <i class="ti-heart" data-bs-toggle="tooltip" title="ti-heart"></i>
                    </li>
                    <li>
                      <i
                        class="ti-heart-broken"
                        data-bs-toggle="tooltip"
                        title="ti-heart-broken"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-stop"
                        data-bs-toggle="tooltip"
                        title="ti-hand-stop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-open"
                        data-bs-toggle="tooltip"
                        title="ti-hand-open"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-hand-drag"
                        data-bs-toggle="tooltip"
                        title="ti-hand-drag"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-flag" data-bs-toggle="tooltip" title="ti-flag"></i>
                    </li>
                    <li>
                      <i
                        class="ti-flag-alt"
                        data-bs-toggle="tooltip"
                        title="ti-flag-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-flag-alt-2"
                        data-bs-toggle="tooltip"
                        title="ti-flag-alt-2"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-eye" data-bs-toggle="tooltip" title="ti-eye"></i>
                    </li>
                    <li>
                      <i class="ti-import" data-bs-toggle="tooltip" title="ti-import"></i>
                    </li>
                    <li>
                      <i class="ti-export" data-bs-toggle="tooltip" title="ti-export"></i>
                    </li>
                    <li>
                      <i class="ti-cup" data-bs-toggle="tooltip" title="ti-cup"></i>
                    </li>
                    <li>
                      <i class="ti-crown" data-bs-toggle="tooltip" title="ti-crown"></i>
                    </li>
                    <li>
                      <i
                        class="ti-comments"
                        data-bs-toggle="tooltip"
                        title="ti-comments"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-comment"
                        data-bs-toggle="tooltip"
                        title="ti-comment"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-comment-alt"
                        data-bs-toggle="tooltip"
                        title="ti-comment-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-thought"
                        data-bs-toggle="tooltip"
                        title="ti-thought"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-clip" data-bs-toggle="tooltip" title="ti-clip"></i>
                    </li>
                    <li>
                      <i class="ti-check" data-bs-toggle="tooltip" title="ti-check"></i>
                    </li>
                    <li>
                      <i
                        class="ti-check-box"
                        data-bs-toggle="tooltip"
                        title="ti-check-box"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-camera" data-bs-toggle="tooltip" title="ti-camera"></i>
                    </li>
                    <li>
                      <i
                        class="ti-announcement"
                        data-bs-toggle="tooltip"
                        title="ti-announcement"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-brush" data-bs-toggle="tooltip" title="ti-brush"></i>
                    </li>
                    <li>
                      <i
                        class="ti-brush-alt"
                        data-bs-toggle="tooltip"
                        title="ti-brush-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-palette"
                        data-bs-toggle="tooltip"
                        title="ti-palette"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-briefcase"
                        data-bs-toggle="tooltip"
                        title="ti-briefcase"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-bolt" data-bs-toggle="tooltip" title="ti-bolt"></i>
                    </li>
                    <li>
                      <i
                        class="ti-bolt-alt"
                        data-bs-toggle="tooltip"
                        title="ti-bolt-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-blackboard"
                        data-bs-toggle="tooltip"
                        title="ti-blackboard"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-bag" data-bs-toggle="tooltip" title="ti-bag"></i>
                    </li>
                    <li>
                      <i class="ti-world" data-bs-toggle="tooltip" title="ti-world"></i>
                    </li>
                    <li>
                      <i
                        class="ti-wheelchair"
                        data-bs-toggle="tooltip"
                        title="ti-wheelchair"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-car" data-bs-toggle="tooltip" title="ti-car"></i>
                    </li>
                    <li>
                      <i class="ti-truck" data-bs-toggle="tooltip" title="ti-truck"></i>
                    </li>
                    <li>
                      <i class="ti-timer" data-bs-toggle="tooltip" title="ti-timer"></i>
                    </li>
                    <li>
                      <i class="ti-ticket" data-bs-toggle="tooltip" title="ti-ticket"></i>
                    </li>
                    <li>
                      <i
                        class="ti-thumb-up"
                        data-bs-toggle="tooltip"
                        title="ti-thumb-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-thumb-down"
                        data-bs-toggle="tooltip"
                        title="ti-thumb-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-stats-up"
                        data-bs-toggle="tooltip"
                        title="ti-stats-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-stats-down"
                        data-bs-toggle="tooltip"
                        title="ti-stats-down"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-shine" data-bs-toggle="tooltip" title="ti-shine"></i>
                    </li>
                    <li>
                      <i
                        class="ti-shift-right"
                        data-bs-toggle="tooltip"
                        title="ti-shift-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-shift-left"
                        data-bs-toggle="tooltip"
                        title="ti-shift-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-shift-right-alt"
                        data-bs-toggle="tooltip"
                        title="ti-shift-right-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-shift-left-alt"
                        data-bs-toggle="tooltip"
                        title="ti-shift-left-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-shield" data-bs-toggle="tooltip" title="ti-shield"></i>
                    </li>
                    <li>
                      <i
                        class="ti-notepad"
                        data-bs-toggle="tooltip"
                        title="ti-notepad"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-server" data-bs-toggle="tooltip" title="ti-server"></i>
                    </li>
                    <li>
                      <i class="ti-pulse" data-bs-toggle="tooltip" title="ti-pulse"></i>
                    </li>
                    <li>
                      <i
                        class="ti-printer"
                        data-bs-toggle="tooltip"
                        title="ti-printer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-power-off"
                        data-bs-toggle="tooltip"
                        title="ti-power-off"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-plug" data-bs-toggle="tooltip" title="ti-plug"></i>
                    </li>
                    <li>
                      <i
                        class="ti-pie-chart"
                        data-bs-toggle="tooltip"
                        title="ti-pie-chart"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-panel" data-bs-toggle="tooltip" title="ti-panel"></i>
                    </li>
                    <li>
                      <i
                        class="ti-package"
                        data-bs-toggle="tooltip"
                        title="ti-package"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-music" data-bs-toggle="tooltip" title="ti-music"></i>
                    </li>
                    <li>
                      <i
                        class="ti-music-alt"
                        data-bs-toggle="tooltip"
                        title="ti-music-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-mouse" data-bs-toggle="tooltip" title="ti-mouse"></i>
                    </li>
                    <li>
                      <i
                        class="ti-mouse-alt"
                        data-bs-toggle="tooltip"
                        title="ti-mouse-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-money" data-bs-toggle="tooltip" title="ti-money"></i>
                    </li>
                    <li>
                      <i
                        class="ti-microphone"
                        data-bs-toggle="tooltip"
                        title="ti-microphone"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-menu" data-bs-toggle="tooltip" title="ti-menu"></i>
                    </li>
                    <li>
                      <i
                        class="ti-menu-alt"
                        data-bs-toggle="tooltip"
                        title="ti-menu-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-map" data-bs-toggle="tooltip" title="ti-map"></i>
                    </li>
                    <li>
                      <i
                        class="ti-map-alt"
                        data-bs-toggle="tooltip"
                        title="ti-map-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-location-pin"
                        data-bs-toggle="tooltip"
                        title="ti-location-pin"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-light-bulb"
                        data-bs-toggle="tooltip"
                        title="ti-light-bulb"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-info" data-bs-toggle="tooltip" title="ti-info"></i>
                    </li>
                    <li>
                      <i
                        class="ti-infinite"
                        data-bs-toggle="tooltip"
                        title="ti-infinite"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-id-badge"
                        data-bs-toggle="tooltip"
                        title="ti-id-badge"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-hummer" data-bs-toggle="tooltip" title="ti-hummer"></i>
                    </li>
                    <li>
                      <i class="ti-home" data-bs-toggle="tooltip" title="ti-home"></i>
                    </li>
                    <li>
                      <i class="ti-help" data-bs-toggle="tooltip" title="ti-help"></i>
                    </li>
                    <li>
                      <i
                        class="ti-headphone"
                        data-bs-toggle="tooltip"
                        title="ti-headphone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-harddrives"
                        data-bs-toggle="tooltip"
                        title="ti-harddrives"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-harddrive"
                        data-bs-toggle="tooltip"
                        title="ti-harddrive"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-gift" data-bs-toggle="tooltip" title="ti-gift"></i>
                    </li>
                    <li>
                      <i class="ti-game" data-bs-toggle="tooltip" title="ti-game"></i>
                    </li>
                    <li>
                      <i class="ti-filter" data-bs-toggle="tooltip" title="ti-filter"></i>
                    </li>
                    <li>
                      <i class="ti-files" data-bs-toggle="tooltip" title="ti-files"></i>
                    </li>
                    <li>
                      <i class="ti-file" data-bs-toggle="tooltip" title="ti-file"></i>
                    </li>
                    <li>
                      <i class="ti-zip" data-bs-toggle="tooltip" title="ti-zip"></i>
                    </li>
                    <li>
                      <i class="ti-folder" data-bs-toggle="tooltip" title="ti-folder"></i>
                    </li>
                    <li>
                      <i
                        class="ti-envelope"
                        data-bs-toggle="tooltip"
                        title="ti-envelope"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-dashboard"
                        data-bs-toggle="tooltip"
                        title="ti-dashboard"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-cloud" data-bs-toggle="tooltip" title="ti-cloud"></i>
                    </li>
                    <li>
                      <i
                        class="ti-cloud-up"
                        data-bs-toggle="tooltip"
                        title="ti-cloud-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-cloud-down"
                        data-bs-toggle="tooltip"
                        title="ti-cloud-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-clipboard"
                        data-bs-toggle="tooltip"
                        title="ti-clipboard"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-calendar"
                        data-bs-toggle="tooltip"
                        title="ti-calendar"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-book" data-bs-toggle="tooltip" title="ti-book"></i>
                    </li>
                    <li>
                      <i class="ti-bell" data-bs-toggle="tooltip" title="ti-bell"></i>
                    </li>
                    <li>
                      <i
                        class="ti-basketball"
                        data-bs-toggle="tooltip"
                        title="ti-basketball"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-bar-chart"
                        data-bs-toggle="tooltip"
                        title="ti-bar-chart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-bar-chart-alt"
                        data-bs-toggle="tooltip"
                        title="ti-bar-chart-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-archive"
                        data-bs-toggle="tooltip"
                        title="ti-archive"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-anchor" data-bs-toggle="tooltip" title="ti-anchor"></i>
                    </li>
                    <li>
                      <i class="ti-alert" data-bs-toggle="tooltip" title="ti-alert"></i>
                    </li>
                    <li>
                      <i
                        class="ti-alarm-clock"
                        data-bs-toggle="tooltip"
                        title="ti-alarm-clock"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-agenda" data-bs-toggle="tooltip" title="ti-agenda"></i>
                    </li>
                    <li>
                      <i class="ti-write" data-bs-toggle="tooltip" title="ti-write"></i>
                    </li>
                    <li>
                      <i class="ti-wallet" data-bs-toggle="tooltip" title="ti-wallet"></i>
                    </li>
                    <li>
                      <i
                        class="ti-video-clapper"
                        data-bs-toggle="tooltip"
                        title="ti-video-clapper"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-video-camera"
                        data-bs-toggle="tooltip"
                        title="ti-video-camera"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-vector" data-bs-toggle="tooltip" title="ti-vector"></i>
                    </li>
                    <li>
                      <i
                        class="ti-support"
                        data-bs-toggle="tooltip"
                        title="ti-support"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-stamp" data-bs-toggle="tooltip" title="ti-stamp"></i>
                    </li>
                    <li>
                      <i class="ti-slice" data-bs-toggle="tooltip" title="ti-slice"></i>
                    </li>
                    <li>
                      <i
                        class="ti-shortcode"
                        data-bs-toggle="tooltip"
                        title="ti-shortcode"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-receipt"
                        data-bs-toggle="tooltip"
                        title="ti-receipt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-pin2" data-bs-toggle="tooltip" title="ti-pin2"></i>
                    </li>
                    <li>
                      <i
                        class="ti-pin-alt"
                        data-bs-toggle="tooltip"
                        title="ti-pin-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-pencil-alt2"
                        data-bs-toggle="tooltip"
                        title="ti-pencil-alt2"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-eraser" data-bs-toggle="tooltip" title="ti-eraser"></i>
                    </li>
                    <li>
                      <i class="ti-more" data-bs-toggle="tooltip" title="ti-more"></i>
                    </li>
                    <li>
                      <i
                        class="ti-more-alt"
                        data-bs-toggle="tooltip"
                        title="ti-more-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-microphone-alt"
                        data-bs-toggle="tooltip"
                        title="ti-microphone-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-magnet" data-bs-toggle="tooltip" title="ti-magnet"></i>
                    </li>
                    <li>
                      <i
                        class="ti-line-double"
                        data-bs-toggle="tooltip"
                        title="ti-line-double"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-line-dotted"
                        data-bs-toggle="tooltip"
                        title="ti-line-dotted"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-line-dashed"
                        data-bs-toggle="tooltip"
                        title="ti-line-dashed"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-ink-pen"
                        data-bs-toggle="tooltip"
                        title="ti-ink-pen"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-info-alt"
                        data-bs-toggle="tooltip"
                        title="ti-info-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-help-alt"
                        data-bs-toggle="tooltip"
                        title="ti-help-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-headphone-alt"
                        data-bs-toggle="tooltip"
                        title="ti-headphone-alt"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-gallery"
                        data-bs-toggle="tooltip"
                        title="ti-gallery"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-face-smile"
                        data-bs-toggle="tooltip"
                        title="ti-face-smile"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-face-sad"
                        data-bs-toggle="tooltip"
                        title="ti-face-sad"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-credit-card"
                        data-bs-toggle="tooltip"
                        title="ti-credit-card"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ti-comments-smiley"
                        data-bs-toggle="tooltip"
                        title="ti-comments-smiley"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-time" data-bs-toggle="tooltip" title="ti-time"></i>
                    </li>
                    <li>
                      <i class="ti-share" data-bs-toggle="tooltip" title="ti-share"></i>
                    </li>
                    <li>
                      <i
                        class="ti-share-alt"
                        data-bs-toggle="tooltip"
                        title="ti-share-alt"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-rocket" data-bs-toggle="tooltip" title="ti-rocket"></i>
                    </li>
                    <li>
                      <i
                        class="ti-new-window"
                        data-bs-toggle="tooltip"
                        title="ti-new-window"
                      ></i>
                    </li>
                    <li>
                      <i class="ti-rss" data-bs-toggle="tooltip" title="ti-rss"></i>
                    </li>
                    <li>
                      <i
                        class="ti-rss-alt"
                        data-bs-toggle="tooltip"
                        title="ti-rss-alt"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemifyIcon from "vue-themify-icons";

export default {
  data() {
    return {
      title: "Themify Icon",
      text: "Dashboard",
      text1: "Themify Icon",
      name: "/index",
    };
  },
  components: {
    ThemifyIcon,
  },
  name: "icon-themify",
};
</script>
