import { api } from "@/config";

export const dashboard = {
  namespaced: true,
  actions: {
    get({ context }, { dateRange } = {}) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get("/history", {
        params: params,
      });
    },
    getSpotCheck({ context }, dateRange = {}) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get("/spot-check", {
        params: params,
      });
    },
    getAllDeposit({ context }, dateRange = {}) {
      const params = {};
      if (dateRange.startDate) {
        params.start_date = dateRange.startDate;
      }
      if (dateRange.endDate) {
        params.end_date = dateRange.endDate;
      }
      return api.get("/deposit", {
        params: params,
      });
    },
  },
};
