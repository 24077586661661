<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table :filters="filters" :columns="columns" :data="transits">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <img :src="record.img" alt="product" />
                </a>
                <a href="javascript:void(0);">{{ record.product_name }}</a>
              </div>
            </template>
            <template v-if="column.key === 'admin'">
              <a :href="'admin' + record.admin.id">
                {{ record.admin.name }}
              </a>
            </template>

            <template v-if="column.key === 'delivery_number'">
              <router-link class="me-3" :to="'transits/' + record.id">
                {{ record.delivery_number }}
              </router-link>
            </template>
            <template v-else-if="column.key === 'action'"> </template>

            <template v-if="column.key === 'created_by'">
              {{ record.admin.name }}
            </template>
            <template v-if="column.key === 'status'">
              <span class="badges bg-lightgreen" v-if="record.status == 'Confirmed'">{{
                              record.status
                              }}</span>
              <span class="badges bg-lightgrey" v-else>{{ record.status }} </span>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { productStore } from "@/store/product";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Delivery No",
    dataIndex: "delivery_number",
    key: "delivery_number",
    sorter: {
      compare: (a, b) => {
        a = a.product_name.toLowerCase();
        b = b.product_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Order No",
    dataIndex: "order_number",
    sorter: (a, b) => a.SKU.localeCompare(b.SKU),
  },
  {
    title: "Transfer No",
    dataIndex: "transfer_number",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Purchase No",
    dataIndex: "purchase_number",
    sorter: true,
  },
  {
    title: "Vehicle No",
    dataIndex: "vehicle_number",
    sorter: {
      compare: (a, b) => {
        a = a.vehicle_number.toLowerCase();
        b = b.vehicle_number.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Order Date",
    dataIndex: "order_date",
    sorter: {
      compare: (a, b) => {
        a = a.order_date.toLowerCase();
        b = b.order_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Created By",
    key: "created_by",
    sorter: {
      compare: (a, b) => {
        a = a.admin.toLowerCase();
        b = b.admin.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    key: "status",
    sorter: true,
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filter: false,
      filters: ["SKU", "product_name"],
      title: "In Transit List",
      title1: "Manage your In Transits",
      items: [
        {
          text: "Add New Transit",
          addname: "/add-intransit",
        },
      ],
      Product: ["Choose Product", "Macbook pro", "Orange"],
      Category: ["Choose Sub Category", "Computer"],
      Choose: ["Choose Category", "Computers", "Fruits"],
      Brand: ["Brand", "N/D"],
      Price: ["Price", "150.00"],
      data: [],
      columns,
    };
  },
  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
      formatAmount: "formatAmount",
    }),
    transits() {
      return productStore.state.transits;
    },
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
    ...mapActions({
      _getProducts: "product/fetchProductsFewDetails",
      _updateProducts: "product/update",
    }),

    async getIntransits() {
      let reload = this.$route.query["reload"];
      if (!productStore.state.transits || !productStore.state.transits.length || reload == 'true') {
        await productStore.dispatch("getTransits");
      }
    },
  },
  mounted() {
  },
  created() {
    this.getIntransits();
  },
  watch: {},
  name: "transitlist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
