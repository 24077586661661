<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table
          :data="data"
          :columns="columns"
          :filters="filters"
          @pageChange="getSales($event)"
          @filter="getSales({ query: $event.query })"
          :paginationConfig="paginationConfig"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Status === 'Completed'"
                >{{ record.Status }}</span
              >
              <span
                class="badges bg-lightgrey"
                v-else-if="record.Status === 'Pending'"
                >{{ record.Status }}</span
              >
              <span class="badges bg-lightyellow" v-else>{{
                record.Status
              }}</span></template
            >
            <template v-else-if="column.key === 'pay'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Payment === 'Paid'"
                >{{ record.Payment }}</span
              >
              <span class="badges bg-lightred" v-else>{{
                record.Payment
              }}</span></template
            >
            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.reference }}</router-link
              >
            </template>
            <template v-else-if="column.key === 'action'">
              <div class="text-center">
                <a
                  class="action-set"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      :to="'orders/' + record.order_id"
                      class="dropdown-item"
                      ><img
                        src="../../../assets/img/icons/eye1.svg"
                        class="me-2"
                        alt="img"
                      />Sale Detail</router-link
                    >
                  </li>
                  <li hidden>
                    <router-link to="/edit-sales" class="dropdown-item"
                      ><img
                        src="../../../assets/img/icons/edit.svg"
                        class="me-2"
                        alt="img"
                      />Edit Sale</router-link
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      class="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#showpayment"
                      v-on:click="showPayments(record.id, record.order_id)"
                      ><img
                        src="../../../assets/img/icons/dollar-square.svg"
                        class="me-2"
                        alt="img"
                      />Show Payments</a
                    >
                  </li>
                  <li>
                    <!-- Quick On -->
                    <a
                      v-if="true"
                      href="javascript:void(0);"
                      class="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#createpayment"
                      v-on:click="showPayments(record.id, record.order_id)"
                      ><img
                        src="../../../assets/img/icons/plus-circle.svg"
                        class="me-2"
                        alt="img"
                      />Create Payment</a
                    >
                  </li>
                  <li hidden>
                    <a href="javascript:void(0);" class="dropdown-item"
                      ><img
                        src="../../../assets/img/icons/download.svg"
                        class="me-2"
                        alt="img"
                      />Download pdf</a
                    >
                  </li>
                </ul>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <saleslistmodal
    @paymentAddedListner="getSales"
    :order_id="selected_order_id"
    :sale_id="selected_sale_id"
  >
  </saleslistmodal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "id",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment",
    dataIndex: "Payment",
    key: "pay",
    sorter: {
      compare: (a, b) => {
        a = a.Payment.toLowerCase();
        b = b.Payment.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: {
      compare: (a, b) => {
        a = a.total.toLowerCase();
        b = b.total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Paid",
    dataIndex: "Paid",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Over Paid",
    dataIndex: "Over Paid",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due",
    dataIndex: "Due",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      filters: ["customer_name", "reference"],
      title: "Sales List",
      title1: "Manage your sales",
      items: [
        {
          text: "Add Sales",
          addname: "/add-sales",
        },
      ],
      selected_order_id: null,
      selected_sale_id: null,
      data: [],
      columns,
      Completed: ["Completed", "Paid"],
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters(["hasAccess", "formatAmount"]),
  },
  methods: {
    ...mapActions({
      _getSales: "sales/get",
      _getPendingSales: "sales/getPendingSales",
      _getCompletedSales: "sales/getCompletedSales",
    }),
    /**
     * This Populates The Data wth the sales gotten from the response
     */
    loadSales(apiResponse) {
      this.data = apiResponse.data.map((sale) => {
        return {
          id: sale.id,
          order_id: sale.order_id,
          Date: sale.date,
          customer_name: sale.customer_name,
          reference: sale.reference,
          Status: sale.status,
          Payment: sale.payment_status,
          total: sale.total,
          Paid: sale.paid,
          "Over Paid": sale.over_paid,
          Due: sale.due,
          // Due: "0.00",
          // Biller: "Admin",
        };
      });
      this.paginationConfig = apiResponse.pagination;
    },
    getSales({ page = null, per_page = null, query = null } = {}) {
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };

      this.searchQuery = query;
      let type = this.$route.meta.type || null;
      if (type == "Pending") {
        this._getPendingSales({ params }).then((response) => {
          this.loadSales(response.data);
        });
      } else if (type == "Completed") {
        this._getCompletedSales({ params }).then((response) => {
          this.loadSales(response.data);
        });
      } else {
        this._getSales({ type: "Pending", params }).then((response) => {
          this.loadSales(response.data);
        });
      }
    },
    showPayments(sale_id, order_id) {
      this.selected_order_id = order_id;
      this.selected_sale_id = sale_id;
    },
  },
  mounted() {
    if (!this.hasAccess("create-sales")) {
      this.items = [];
    }
    this.getSales();
  },
  watch: {
    "$route.meta.type": {
      handler(_new) {
        this.getSales();
      },
    },
  },
  name: "saleslist",
};
</script>
