<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" v-model="admin.first_name" />
                </div>

                <div class="form-group">
                  <label>Email</label>
                  <input type="text" v-model="admin.email" />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <div class="pass-group">
                    <input v-if="showPassword" type="text" class="pass-input" v-model="admin.password" />
                    <input v-else type="password" class="pass-input" v-model="admin.password" />
                    <span class="fas toggle-password" @click="toggleShow" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" v-model="admin.last_name" />
                </div>
                <div class="form-group">
                  <label>Role</label>
                  <vue-select style="width: 278.1px !important;" :options="adminRoles" v-model="admin.role_id"
                    placeholder="Select Role" />
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <div class="pass-group">
                    <input v-if="showPassword1" type="text" class="pass-input" v-model="password1" />
                    <input v-else type="password" class="pass-input" v-model="password1" />
                    <span class="fas toggle-password" @click="toggleShow1" :class="{
                      'fa-eye': showPassword1,
                      'fa-eye-slash': !showPassword1,
                    }"></span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label> Profile Picture</label>
                  <div class="image-upload image-upload-new">
                    <img v-if="image_preview" style="
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        opacity: 0.5;
                      " :src="image_preview" />
                    <input type="file" @input="pickFile($event.target)" />
                    <div class="image-uploads">
                      <img src="../../../assets/img/icons/upload.svg" alt="img" />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>

              <a-collapse v-model:activeKey="activeKey">
                <a-collapse-panel key="1" header="Assigned Permissions">
                  <f-table :filters="filters" :data="permissions" :columns="permissionsColumns">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key == 'action'">
                        <label class="inputcheck">
                          <input :value="record.id" v-model="admin.assigned_permissions" type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </template>
                    </template>
                  </f-table>
                </a-collapse-panel>
              </a-collapse>

              <div class="col-lg-12 mt-3">
                <a @click="updateAdmin" href="javascript:void(0);" class="btn btn-submit me-2">Submit</a>
                <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              </div>
            </div>
          </div>
        </div>



        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapMutations } from "vuex";

const permissionsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  { title: "Action", key: "action" },
];
export default {
  data() {
    return {
      filters: ["name", "description"],
      showPassword: false,
      showPassword1: false,
      password: null,
      password1: null,
      title: "Admin Management",
      title1: "Edit/Update Admin",
      admin_id: null,
      adminRoles: [],
      image_preview: null,
      admin: {
        email: null,
        first_name: null,
        last_name: null,
        profile_picture: null,
        password: null,
        role_id: null,
        assigned_permissions: [],
      },
      permissions: [],
      permissionsColumns,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    ...mapActions({
      _getAdminRole: "adminRole/get",
      _getAdminDetail: "admin/show",
      _updateAdmin: "admin/update",
    }),
    ...mapMutations({
      _showLoader: "showLoader",
    }),
    pickFile(event) {
      let file = event.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image_preview = e.target.result;
      };

      this.admin.profile_picture = file;
      reader.readAsDataURL(file);
    },
    getAdminRole() {
      this._getAdminRole().then((response) => {
        this.adminRoles = response.data.data.map((adminRole) => {
          return {
            text: adminRole.name,
            id: adminRole.id,
          };
        });
      });
    },
    getAdminDetail() {
      let admin_id = this.$route.params.id;
      this.admin_id = admin_id;
      this._getAdminDetail({ admin_id: admin_id }).then((response) => {
        let admin = response.data.data;
        this.admin = {
          first_name: admin.first_name,
          last_name: admin.last_name,
          email: admin.email,
          mobile_number: admin.mobile_number,
          role_id: admin.role.id,
          password: null,
          assigned_permissions: admin.assigned_permissions.map(
            (permission) => permission.id
          ),
        };
        this.image_preview = admin.profile_picture;
      });
    },
    updateAdmin() {
      this._updateAdmin({
        admin_id: this.admin_id,
        payload: this.admin,
      }).then((response) => {
        store.commit("notify", {
          message: "Admin Successfully Updated",
          type: "success",
          title: "",
        });
        this.$router.push("/admin");
      });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    getPermissions() {
      store.dispatch("adminPermission/get").then((response) => {
        this.permissions = response.data.data;
      });
    },
  },
  mounted() {
    this.getAdminRole();
    this.getAdminDetail();
    this.getPermissions();
  },
  name: "adminedit",
};
</script>
