<template>
    <slot v-if="canAccessFeature" />
</template>
<script setup>

const props = defineProps({
    tag: {
        type: String,
        required: true
    }
});

const canAccessFeature = () => {
    return true;
}
</script>