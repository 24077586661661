<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />
        <f-table
          :data="data"
          :columns="columns"
          :filters="filters"
          @pageChange="getSales($event)"
          @filter="getSales({ query: $event.query })"
          :paginationConfig="paginationConfig"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Status === 'Approved'"
                >{{ record.Status }}</span
              >
              <span class="badges bg-lightred" v-else>{{
                record.Status
              }}</span></template
            >

            <template v-else-if="column.key === 'pay'">
              <span
                class="badges bg-lightgreen"
                v-if="record.Payment === 'Paid'"
                >{{ record.Payment }}</span
              >
              <span class="badges bg-lightred" v-else>{{
                record.Payment
              }}</span></template
            >

            <template v-else-if="column.key === 'reference'">
              <router-link
                title="Click to View Order"
                :to="'orders/' + record.order_id"
                >{{ record.reference }}</router-link
              >
            </template>

            <template v-else-if="column.key === 'action'">
              <div class="d-flex gap-2" style="place-items: center;">
                <div class="d-flex gap-2">
                  <span
                    style="cursor: pointer;"
                    v-on:click="dispatchSale(record.id)"
                    class="badges bg-lightgreen"
                    v-if="hasAccess('dispatch-sales') || !is_supervisor"
                    >Dispatch</span
                  >
                </div>
                <div class="text-center">
                  <a
                    class="action-set"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        :to="'orders/' + record.order_id"
                        class="dropdown-item"
                        ><img
                          src="../../../assets/img/icons/eye1.svg"
                          class="me-2"
                          alt="img"
                        />Sale Detail</router-link
                      >
                    </li>

                    <li>
                      <a
                        href="javascript:void(0);"
                        class="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#showpayment"
                        v-on:click="showPayments(record.id, record.order_id)"
                        ><img
                          src="../../../assets/img/icons/dollar-square.svg"
                          class="me-2"
                          alt="img"
                        />Show Payments</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
    sorter: {
      compare: (a, b) => {
        a = a.reference.toLowerCase();
        b = b.reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "Total",
    sorter: {
      compare: (a, b) => {
        a = a.Total.toLowerCase();
        b = b.Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Paid",
    dataIndex: "Paid",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Over Paid",
    dataIndex: "Over Paid",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due",
    dataIndex: "Due",
    sorter: {
      compare: (a, b) => {
        a = a.Paid.toLowerCase();
        b = b.Paid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filters: ["customer_name", "reference"],
      title: "Packed Sales List",
      title1: "Manage your sales",
      items: [
        {
          text: "Add Sales",
          addname: "/add-sales",
        },
      ],
      selected_order_id: null,
      selected_sale_id: null,
      access_code: null,
      data: [],
      columns,
      Completed: ["Completed", "Paid"],
      paginationConfig: { current_page: 1, per_page: 10 },
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters(["hasAccess", "is_supervisor"]),
  },
  methods: {
    ...mapActions({
      _getSales: "sales/get",
      _showOtpDialog: "showOtpDialog",
      _sendAccessCode: "accessCode/add",
    }),
    getSales({ page = null, per_page = null, query = null } = {}) {
      const params = {
        page: page ?? this.paginationConfig.current_page,
        per_page: per_page ?? this.paginationConfig.per_page,
        query: query ?? this.searchQuery,
      };

      this.searchQuery = query;
      this._getSales({ type: "Packed", params }).then((apiResponse) => {
        const response = apiResponse.data;
        this.data = response.data.map((sale) => {
          return {
            id: sale.id,
            order_id: sale.order_id,
            Date: sale.date,
            customer_name: sale.customer_name,
            reference: sale.reference,
            Status: sale.status,
            Payment: sale.payment_status,
            Total: sale.total,
            Paid: sale.paid,
            "Over Paid": sale.over_paid,
            Due: sale.due,
            // Due: "0.00",
            // Biller: "Admin",
          };
        });
        this.paginationConfig = response.pagination;
      });
    },
    showPayments(sale_id, order_id) {
      this.selected_order_id = order_id;
      this.selected_sale_id = sale_id;
    },
    verifyOtp(saleId) {
      this._showOtpDialog({
        title: "Enter Authorization Code",
        description: "Authorization Code Sent to the Supervisor!",
      }).then((response) => {
        this.access_code = response.value;
        // get the access code
        if (this.access_code != null) {
          this.executeSaleAction(saleId);
        } else {
          Swal.fire("You need the Authorization Code");
        }
      });
    },
    async dispatchSale(saleId) {
      if (store.getters.isSuperAdmin || store.getters.isVendor) {
        this.executeSaleAction(saleId);
      } else {
        this._sendAccessCode({
          permission_code: "dispatch-sales",
          item_id: saleId,
        }).then((response) => {
          this.verifyOtp(saleId);
        });

        Swal.fire({
          title: "Sending Authorization Code To Supervisor",
          html: `<div class="spinner-border text-dark" role="status"></div>`,
          closeOnClickOutside: false,
          buttons: false,
        });
      }
    },
    executeSaleAction(saleId) {
      store
        .dispatch("sales/executeSaleAction", {
          saleId: saleId,
          action: "dispatch",
          accessCode: this.access_code,
        })
        .then((response) => {
          store.commit("notify", {
            title: `Order Successfully Updated`,
            type: "success",
          });
          this.getSales();
        })
        .catch((error) => {
          Swal.fire(error.response.data.message, "", "error");
        });
    },
  },
  mounted() {
    if (!this.hasAccess("create-sales")) {
      this.items = [];
    }
    this.getSales();
  },
  name: "confirm-sales",
};
</script>
