import { router } from "@/router";
import { formatAmount, formatTimeStamp, toReadableAmount } from "@/helpers";

export default {
  formatAmount: (context) => (amount, addNairaSign = false) => {
    return formatAmount(amount, addNairaSign);
  },
  toReadableAmount: (context) => (amount) => {
    return toReadableAmount(amount);
  },
  formatTimeStamp: (context) => (timestamp) => {
    return formatTimeStamp(timestamp);
  },
  /**
   * This Will Return The First Tab This Admin Has Access To
   * Under Development
   */

  is_supervisor: (state) => {
    let role = state.adminProfile;

    if (role.admin_role_id == 7 || role.admin_role_id == 8) {
      return true;
    }
    return false;
  },
  isSuperAdmin: (state) => {
    return state.adminPermissions[0] == "*";
  },
  isVendor: (state) => {
    return state.adminPermissions[0] == "**";
  },
  hasFullAccess: (state, getters) => {
    return getters.isSuperAdmin || getters.isVendor;
  },
  /**
   * This Checks if an Admin has the Specified Access
   */
  hasAccesses: (state) => (...accesses) => {
    let adminAccesses = state.adminPermissions;
    if (adminAccesses[0] == "*" || adminAccesses[0] == "**") {
      return true;
    }
    for (let index = 0; index < accesses.length; index++) {
      let access = accesses[index];
      if (adminAccesses.find((adminAccess) => adminAccess == access)) {
        return true;
      }
    }
    return false;
  },
  hasAccess: (state) => (accessName) => {
    let adminAccesses = state.adminPermissions;
    if (adminAccesses[0] == "*" || adminAccesses[0] == "**") {
      return true;
    }
    if (adminAccesses.find((adminAccess) => adminAccess.match(accessName))) {
      return true;
    }
    return false;
  },
  getRoutePermission: (state) => (path) => {
    let pathRoute = router.getRoutes().find((route) => route.path == path);
    return pathRoute?.meta?.access;
  },
  canAccess: (state, getters) => (path) => {
    return getters.hasAccess(getters.getRoutePermission(path));
  },
  getToken() {
    return localStorage.getItem("token");
  },
};
