<template>
  <section :class="'floating-chat ' + (chat ? 'active' : 'inactive')">
    <!-- Main Wrapper -->
    <a v-if="!chat" @click="showChatBox" class="chat-icon">
      <i class="fas fa-comment" aria-hidden="true"></i>
      <span class="chat-icon-badge" v-if="hasUnreadMsg">{{ unreadMsg }}</span>
    </a>
    <div v-else class="row chat-window" style="height: 100%;">
      <span @click="closeChatBox" class="close-chat"> &times; </span>
      <!-- Chat User List -->

      <div v-if="!recipient.name" class="chat-cont-left chat-container">
        <div
          class="card mb-sm-3 mb-md-0 contacts_card flex-fill"
          style="height: 100%;"
        >
          <div class="card-header chat-search">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="search_btn"><i class="fas fa-search"></i></span>
              </div>
              <input
                type="text"
                placeholder="Search"
                class="form-control search-chat rounded-pill"
              />
            </div>
          </div>

          <div
            class="card-body contacts_body chat-users-list chat-scroll"
            v-if="newChat"
          >
            <a
              v-for="account in accounts"
              :key="account.chat_id"
              href="javascript:void(0);"
              class="media d-flex active"
              @click="loadChats(account.chat_id, 'new')"
            >
              <div class="media-img-wrap flex-shrink-0">
                <div class="avatar avatar-away">
                  <img
                    :src="account.profile_picture"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
              </div>
              <div class="media-body flex-grow-1">
                <div style="text-align: left;">
                  <span class="d-block user-name">{{ account.name }}</span>
                  <span class="d-block user-last-chat">
                    {{ account.accountType.name }}
                  </span>
                </div>
              </div>
            </a>
          </div>
          <div
            class="card-body contacts_body chat-users-list chat-scroll"
            v-if="!newChat"
          >
            <div
              v-if="allChat.length == 0"
              class="col-lg-7 col-xl-8 chat-cont-right chat-container"
            >
              <div
                class="card mb-0"
                style="
                  display: flex;
                  justify-content: center;
                  place-items: center;
                  height: 100%;
                "
              >
                <h4>No Recent Chat</h4>
              </div>
            </div>

            <a
              v-for="chat in allChat"
              :key="chat.recipient.chat_id"
              href="javascript:void(0);"
              class="media d-flex active"
              @click="loadChats(chat.recipient.chat_id)"
            >
              <div class="media-img-wrap flex-shrink-0">
                <div class="avatar avatar-away">
                  <img
                    :src="chat.recipient.profile_picture"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
              </div>
              <div class="media-body flex-grow-1">
                <div>
                  <div class="user-name">{{ chat.recipient.name }}</div>
                  <div class="user-last-chat" style="text-align: left;">
                    {{ chat.message }}
                  </div>
                </div>
                <div>
                  <div class="last-chat-time">{{ chat.date }}</div>
                  <div class="badge badge-success badge-pill">
                    {{ chat.new_messages > 0 ? chat.new_messages : null }}
                  </div>
                </div>
              </div>
            </a>
          </div>

          <button class="btn btn-primary toggle-btn" @click="toggleNewChat()">
            <span v-if="!this.newChat">+</span>
            <span v-if="this.newChat">&times;</span>
          </button>
        </div>
      </div>

      <!-- Chat User List -->

      <!-- Chat Content -->
      <div v-else class="chat-cont-right chat-container" style="height: 100%;">
        <!-- Chat History -->
        <div class="card mb-0" style="height: 100%;">
          <div class="card-header msg_head">
            <div class="d-flex bd-highlight">
              <a class="back-user-list" @click="showChats">
                <i class="fas fa-chevron-left"></i>
              </a>
              <div class="img_cont">
                <img
                  class="rounded-circle user_img"
                  :src="recipient.profile_picture"
                  alt=""
                />
              </div>
              <div class="user_info">
                <span
                  ><strong id="receiver_name">{{
                    recipient.name
                  }}</strong></span
                >
                <p class="mb-0">Messages</p>
              </div>
            </div>
          </div>

          <div
            class="card-body msg_card_body chat-scroll"
            ref="chats"
            id="chats"
          >
            <ul class="list-unstyled" v-if="privateChats.length > 0">
              <li
                v-for="chat in privateChats"
                :key="chat.id"
                :class="
                  'media d-flex ' +
                  (chat.sender.chat_id == adminData.chat_id
                    ? 'sent'
                    : 'received')
                "
              >
                <div class="avatar flex-shrink-0">
                  <img
                    :src="chat.sender.profile_picture"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="media-body flex-grow-1">
                  <div class="msg-box">
                    <div>
                      <p>{{ chat.message }}</p>
                      <ul class="chat-msg-info">
                        <li>
                          <div class="chat-time">
                            <span>{{ chat.date }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <!-- <li class="media sent d-flex">
                      <div class="avatar flex-shrink-0">
                        <img
                          src="../assets/img/customer/customer5.jpg"
                          alt="User Image"
                          class="avatar-img rounded-circle"
                        />
                      </div>
                      <div class="media-body flex-grow-1">
                        <div class="msg-box">
                          <div>
                            <p>Where?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <p>
                              OK, my name is Limingqiang. I like singing,
                              playing basketballand so on.
                            </p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <div class="chat-msg-attachments">
                              <div class="chat-attachment">
                                <img
                                  src="../assets/img/product/product15.jpg"
                                  alt="Attachment"
                                />
                                <a href="" class="chat-attach-download">
                                  <i class="fas fa-download"></i>
                                </a>
                              </div>
                            </div>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:50 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li> -->
            </ul>
          </div>

          <div class="card-footer">
            <div class="input-group">
              <input
                v-model="newMessage"
                class="form-control type_msg mh-auto empty_check"
                placeholder="Type your message..."
              />
              <button class="btn btn-primary btn_send" @click="sendMessage">
                <i
                  v-if="!isSending"
                  class="fa fa-paper-plane"
                  aria-hidden="true"
                ></i>
                <vue-feather
                  v-if="isSending"
                  type="loader"
                  data-bs-toggle="tooltip"
                  title="fe fe-loader"
                ></vue-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Chat Content -->
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import { isAuth } from "@/helpers";
export default {
  name: "chat",
  data() {
    return {
      top: 100, // Initial position (top)
      left: 100, // Initial position (left)
      offsetX: 0, // Offset X for dragging
      offsetY: 0, // Offset Y for dragging
      dragging: false,
      droplets: [], // Store droplet positions
      newChat: false,
      chat: false,
      allChat: [],
      isSending: false,
      privateChats: [],
      /** If A New Msg Has Been Sent */
      hasUnreadMsg: false,
      unreadMsg: 0,
      newMessage: null,
      recipient: {
        name: null,
        chat_id: null,
        profile_picture: null,
      },
      accounts: [],
    };
  },
  computed: {
    adminData() {
      return store.state.adminProfile;
    },
  },
  methods: {
    startDrag(event) {
      this.offsetX = event.clientX - this.left;
      this.offsetY = event.clientY - this.top;
      this.dragging = true;
      document.addEventListener("mousemove", this.dragTo);
      document.addEventListener("mouseup", this.stopDrag);
    },
    dragTo(event) {
      if (this.dragging) {
        this.left = event.clientX - this.offsetX;
        this.top = event.clientY - this.offsetY;
        this.addDroplet(this.left, this.top + 50);
      }
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.dragTo);
      document.removeEventListener("mouseup", this.stopDrag);
      setTimeout(() => {
        this.droplets = [];
      }, 1000);
    },
    addDroplet(left, top) {
      const id = Date.now();
      this.droplets.push({ id, left, top });
      setTimeout(() => {
        this.droplets = this.droplets.filter((droplet) => droplet.id !== id);
      }, 1000);
    },
    ...mapActions({
      _getChattedUsers: "chat/getChattedUsers",
      _getChat: "chat/getChats",
      _sendMessage: "chat/sendMessage",
    }),
    showChatBox() {
      if (!this.dragging) {
        this.chat = true;
        this.hasUnreadMsg = false;
      }
    },
    closeChatBox() {
      this.chat = false;
    },
    showChats() {
      this.recipient.name = null;
      this.recipient.chat_id = null;
    },
    scrollToBottom() {
      const container = this.$refs.chats;
      container.scrollTop = 1000000;
    },
    getUsers() {
      store.dispatch("chat/getUsers").then((response) => {
        this.accounts = response.data.data.map((user) => {
          return {
            id: user.id,
            name: user.name,
            chat_id: user.chat_id,
            accountType: user.account_type,
            profile_picture: user.profile_picture,
          };
        });
      });
    },
    loadChats(sender_id, type = null) {
      this._getChat({ sender_id: sender_id }).then((response) => {
        let data = response.data.data;
        this.privateChats = data.chats;
        this.recipient.name = data.recipient.name;
        this.recipient.chat_id = data.recipient.chat_id;
        this.recipient.profile_picture = data.recipient.profile_picture;
        setTimeout(() => {
          this.scrollToBottom();
        }, 0.5);
      });
      if (type == "new") {
        this.newChat = false;
      }
    },
    toggleNewChat() {
      this.newChat = !this.newChat;
    },
    getChattedUsers() {
      this._getChattedUsers().then((response) => {
        this.allChat = response.data.data;
        this.unreadMsg = 0;
        /**
         * Check if there an unread msg
         */
        this.unreadMsg = this.allChat.reduce(
          (totalNewMsg, chat) => totalNewMsg + chat.new_messages,
          0
        );

        if (this.unreadMsg > 0) {
          this.hasUnreadMsg = true;
        } else {
          this.hasUnreadMsg = false;
        }
      });
    },
    sendMessage() {
      this.isSending = true;
      this._sendMessage({
        receiver_chat_id: this.recipient.chat_id,
        message: this.newMessage,
      })
        .then((res) => {
          this.newMessage = null;
          this.loadChats(this.recipient.chat_id);
          this.getChattedUsers();
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    /**
     * This Retrieves New Chats for this User
     * @param {String} sender_chat_id The Chat Id of the Sender
     */
    updateChat(sender_chat_id) {
      this.getChattedUsers(); // Refresh all the chat
      // check if the message is from the current user the admin is chatting
      if (this.recipient.chat_id == sender_chat_id) {
        this.loadChats(sender_chat_id); // Load the Chats fro this Particular User to Update the Chat Page
      }
    },
  },

  mounted() {
    if (isAuth()) {
      if (isAuth()) {
        this.getChattedUsers();
        this.getUsers();
      }
    }
  },
  watch: {
    "$store.state.chat.newMessage": {
      handler(newMessage) {
        if (newMessage) {
          this.updateChat(newMessage.sender_chat_id);
        }
      },
    },
  },
};
</script>
<style scoped>
.floating-chat.active {
  height: max(500px, 70vh) !important;
  width: 90%;
}

@media (max-width: 1000px) {

}

.avatar-img {
  overflow: hidden !important;
}

.back-user-list {
  display: flex !important;
  align-items: center;
}

.close-chat {
  cursor: pointer;
  color: #fff;
  background: #ff9f43;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 100;
  width: fit-content;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-size: 15px;
}

.toggle-btn {
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  bottom: 5%;
}

.toggle-btn span {
  font-size: 24px;
}

.chat-scroll {
  height: 100%;
  padding-bottom: 10px;
}

.chat-container {
  min-width: 450px;
  height: 100% !important;
}

@media (max-width: 992px) {
  .chat-cont-right {
    right: 0px;
    opacity: 1;
    width: 100%;
    visibility: visible;
  }

  .no-chat {
    display: none;
  }

  .chat-scroll {
    height: 80vh !important;
    max-height: 80vh !important;
    padding-bottom: 0px !important;
  }

  .chat-container {
    min-width: 100%;
    max-height: 100% !important;
  }
}

.droplet {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: #ff9f43;
  border-radius: 50%;
  animation: drip 1s ease-out forwards;
}

@keyframes drip {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
