<template>
  <!-- show payment Modal -->
  <div
    class="modal fade"
    id="productRestock"
    tabindex="-1"
    aria-labelledby="productRestock"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ isCorrection ? "Correct Product Quantity" : "Retsock Product" }}
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="product">
            <!-- Restock -->
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="form-group">
                  <label>{{ getPlaceholder() }}</label>
                  <input
                    :max="
                      productRestock.reduce ? product.available_quantity : null
                    "
                    :min="0"
                    name="stocked"
                    type="number"
                    v-model="productRestock.stocked"
                    class="form-control col-6"
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-sm-12 col-12"
                v-if="product.unit == units.bottleAndCrate"
              >
                <div class="form-group">
                  <label>Excess Bottles</label>
                  <input
                    :min="0"
                    name="excess_bottles"
                    type="number"
                    v-model="productRestock.excess_bottles"
                    class="form-control col-6"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Note(optional)</label>
                  <a-textarea
                    v-model:value="productRestock.note"
                    show-count
                    :maxlength="200"
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-sm-12 col-12"
                v-if="hasAccess('reduce-product-quantity')"
              >
                <div class="flex">
                  <a-switch v-model:checked="productRestock.reduce" /> Reduce
                  Quantity
                </div>
              </div>

              <div class="col-12">
                <div class="summary">
                  <b class="d-block mb-3">Summary</b>
                  <h3>
                    {{ product.available_quantity }} {{ operator }} ({{
                      productRestock.stocked
                    }}) = {{ newQuantity }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-submit" @click="submit()" id="submit">
            Submit
          </button>
          <button primary class="btn btn-cancel" @click="closeModal()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { units } from "@/models";
import { checkInputValidity } from "@/helpers/validator";
import { performCalculation } from "@/helpers";
// stores
const store = useStore();

const hasAccess = (access) => store.getters["hasAccess"](access);

const newQuantity = computed(() => {
  if (!props.product) {
    return 0;
  }
  let availableQty = performCalculation(
    Number.parseInt(props.product.available_quantity),
    productRestock.stocked,
    operator.value
  );
  if (props.product.unit == units.bottleAndCrate) {
    const newExcess = performCalculation(
      props.product.excess_bottles,
      productRestock.excess_bottles,
      operator.value
    );
    if (newExcess > props.product.qty_per_crate) {
      const crates = Math.floor(newExcess / props.product.qty_per_crate);
      const excessBottle = newExcess % props.product.qty_per_crate;
      availableQty = performCalculation(availableQty, crates, operator.value);
      return `${availableQty} Crate(s) ${excessBottle} Bottle(s)`;
    }
    return `${availableQty} Crate(s) ${newExcess} Bottle(s)`;
  }
  return `${availableQty} ${props.product.unit}`;
});
// props
const props = defineProps({
  product: Object,
});
const emit = defineEmits({
  updateCompleted: () => {},
});

const operator = computed(() => {
  return productRestock.reduce ? "-" : "+";
});
const productRestock = reactive({
  stocked: 0,
  excess_bottles: 0,
  reduce: false,
  note: null,
});

watch(
  productRestock,
  (newVal) => {
    checkInputValidity("submit");
  },
  { immediate: true }
);
watch(props.product, (newProduct) => {});
async function restock() {
  await store.dispatch("product/restock", {
    productId: props.product.id,
    payload: productRestock,
  });
  closeModal();
  store.commit("notify", {
    title: "Success",
    message: "Product Restocked successfully",
    type: "success",
  });
}

async function submit() {
  await restock();
  emit("updateCompleted");
}
function resetData() {
  productRestock.reduce = false;
  productRestock.note = null;
  productRestock.excess_bottles = 0;
  productRestock.stocked = 0;
}
function closeModal() {
  $("#productRestock").modal("hide");
  resetData();
}
function getPlaceholder() {
  return props.product.unit == units.onlyBottle ? "Bottles" : "Crates";
}
onMounted(() => {
  $("#productRestock").on("shown.bs.modal", (event) => {
    resetData();
  });
});
</script>

<style>
.summary {
  border-radius: 5px;
  background: #f0f0f0;
  margin: 10px auto;
  padding: 10px;
}

.summary b {
  font-weight: bold;
}
</style>
