import { api, baseUrl } from ".";
import { socketUrl } from "@/constants";

let isAuth = !!localStorage.getItem("token");
let socket = null;
if (isAuth) {
  socket = new WebSocket(socketUrl);

  const updateConnectionId = (connectionID) => {
    api.post(`/connection`, {
      connection_id: connectionID,
    });
  };

  socket.addEventListener("close", (event) => {});
  socket.addEventListener("open", (event) => {
    socket.send(
      JSON.stringify({
        action: "getConnectionId",
      })
    );
  });

  socket.addEventListener("message", (event) => {
    let message = JSON.parse(event.data);
    switch (message.channel) {
      case "admin.get.connection.id":
        updateConnectionId(message.connectionId);
        break;
      default:
        break;
    }
  });
}
export { socket };
