<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table :data="data" :columns="columns" :filters="filters">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <span class="badges bg-lightgreen" v-if="record.status === 'Approved'">{{
                record.status
                }}</span>
              <span class="badges bg-lightgrey" v-else-if="record.status === 'Pending'">{{
                record.status
                }}</span>
              <span class="badges bg-lightred" v-else>{{
                record.status
                }}</span></template>

            <template v-if="column.key === 'order'">
              <router-link title="Click to View Order" :to="'/orders/' + record.order.id">{{
                record.order.reference
                }}</router-link>
            </template>


            <template v-else-if="column.key === 'action'">
              <div class="d-flex gap-2" style="place-items: center">
                <div class="d-flex gap-2" v-if="isPending && isVendor">
                  <span style="cursor: pointer" v-on:click="updateLimitIncrease(record.id, 'approve')"
                    class="badges bg-lightgreen">Approve</span>
                  <span style="cursor: pointer" v-on:click="updateLimitIncrease(record.id, 'reject')"
                    class="badges bg-lightred">Reject</span>
                </div>
                <div class="text-center">
                  <a class="action-set" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="true">
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                        data-bs-target="#showDetails" v-on:click="showDetails(record)"><img
                          src="../../../assets/img/icons/dollar-square.svg" class="me-2" alt="img" />Show Details</a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
  <limit-increase-modal :selectedLimitIncrease="selectedLimitIncrease">
  </limit-increase-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import { capitalize, formatAmount, formatTimeStamp } from "@/helpers";
const columns = [
  {
    sorter: true,
  },
  {
    title: "Requested By",
    dataIndex: "admin_name",
    sorter: (a, b) => a.admin_name.localeCompare(b.admin_name),
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
  },
  {
    title: "Debt Health",
    dataIndex: "debt_health",
    sorter: {
      compare: (a, b) => {
        a = a.debt_health.toLowerCase();
        b = b.debt_health.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Request Amount",
    dataIndex: "requested_amount",
    key: "requested_amount",
    sorter: {
      compare: (a, b) => {
        a = a.requested_amount.toLowerCase();
        b = b.requested_amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Current Limit",
    dataIndex: "current_limit",
    key: "current_limit",
    sorter: {
      compare: (a, b) => {
        a = a.current_limit.toLowerCase();
        b = b.current_limit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
    sorter: {
      compare: (a, b) => {
        a = a.order.toLowerCase();
        b = b.order.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
    sorter: {
      compare: (a, b) => {
        a = a.payment_date.toLowerCase();
        b = b.payment_date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Requested On",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      filters: ["customer_name", "admin_name"],
      title: "Limit Increase Request",
      title1: "Manage your Credit Limit Increase requests",
      items: [],
      selectedLimitIncrease: {},
      data: [],
      columns,
      isPending: false,
    };
  },
  computed: {
    ...mapGetters(["hasAccess", "isVendor"]),
  },
  methods: {
    getLimitIncrease() {
      this.$store
        .dispatch("limitIncrease/get", { is_pending: this.isPending ? 1 : 0 })
        .then((response) => {
          this.data = response.data.data.map((request) => {
            return {
              id: request.id,
              current_limit: `₦${formatAmount(request.current_limit)}`,
              requested_amount: `₦${formatAmount(request.requested_amount)}`,
              payment_date: formatTimeStamp(request.payment_date),
              created_at: formatTimeStamp(request.created_at),
              status: request.status,
              debt_health: request.user.debt_health,
              customer_name: request.user.name,
              admin_name: request.requested_by.name,
              order: request.order
            };
          });
        });
    },
    showDetails(limitIncrease) {
      this.selectedLimitIncrease = limitIncrease;
    },
    updateLimitIncrease(requestId, action) {
      store.commit("confirm", {
        title: "Confirm This Action",
        message: `Do You really want to ${capitalize(action)} this request?`,
        callback: (isConfrimed) => {
          if (isConfrimed) {
            store
              .dispatch("limitIncrease/update", { requestId: requestId, action: action })
              .then((response) => {
                store.commit("notify", {
                  title: `Request Successfully ${capitalize(action)}`,
                  type: "success",
                });
                this.getLimitIncrease();
              })
              .catch((error) => {
                Swal.fire(error.response.data.message, "", "error");
              });
          }
        },
      });
    },
    checkStatus(newStatus) {
      this.title = `${capitalize(newStatus)} Limit Increase Request`;
      if (newStatus == "pending") {
        this.isPending = true;
      } else {
        this.isPending = false;
      }
      this.getLimitIncrease();
    },
  },
  mounted() {
    this.checkStatus(this.$route.meta.status);
  },
  watch: {
    "$route.meta.status": {
      handler(newStatus) {
        this.checkStatus(newStatus);
      },
    },
  },
  name: "limitincrease",
};
</script>
