<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items">
          <template #right>
            <span @click="addNew()" data-bs-toggle="modal" data-bs-target="#cratetypemodal" class="btn btn-added"><img
                src="@/assets/img/icons/plus.svg" class="me-2" alt="img" />
              Add Crate Type
            </span>
          </template>
        </pageaddheader>
        <f-table :data="crateTypes" :filters="filters" :columns="columns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'id'">
              <div class="productimgname">
                <a href="javascript:void(0);" class="product-img">
                  <img :src="record.image" alt="product" />
                </a>
                <a href="javascript:void(0);">{{ record.name }}</a>
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <span class="me-3 pointer" @click="selectCrateType(record.id)" data-bs-toggle="modal"
                data-bs-target="#cratetypemodal">
                <img src="@/assets/img/icons/edit.svg" alt="img" />
              </span>
              <a class="me-3 confirm-text" href="javascript:void(0);" @click="deleteCrateType(record.id)">
                <img src="@/assets/img/icons/delete.svg" alt="img" />
              </a>
            </template>
            <template v-else-if="column?.dataIndex && column?.dataIndex.match(/price/i)">
              {{ formatAmount(record[column.dataIndex]) }}
            </template>
          </template></f-table>
      </div>
    </div>
    <cratetypemodal :crateType="selectedCrateType" @addCrateType="addCrateType($event)"
      @updateCrateType="updateCrateType($event)"></cratetypemodal>
  </div>
</template>
<script>
const columns = [
  {
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Crate Price",
    dataIndex: "crate_price",
    sorter: (a, b) => a.crate_price.localeCompare(b.crate_price),
  },
  {
    title: "WOB Price",
    dataIndex: "wob_price",
    sorter: (a, b) => a.wob_price.localeCompare(b.wob_price),
  },
  {
    title: "Bottle Per Crate",
    dataIndex: "bottle_per_crate",
    sorter: (a, b) =>
      a.bottle_per_crate.toString().localeCompare(b.bottle_per_crate.toString()),
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import store from "@/store";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filters: ["name"],
      title: "Crate Types list",
      title1: "View/Edit Crate Types",
      items: [],
      selectedCrateType: null,
      data: [],
      columns,
    };
  },
  computed: {
    ...mapGetters({
      formatAmount: "formatAmount",
    }),
    crateTypes() {
      return store.state.crate.crateTypes;
    },
  },
  methods: {
    addNew() {
      this.selectedCrateType = null
    },
    selectCrateType(crateTypeId) {
      this.selectedCrateType = this.crateTypes.find(
        (crateType) => crateType.id == crateTypeId
      );
    },
    deleteCrateType(id) {
      store.commit("confirm", {
        title: "Confirm Crate Type Delete",
        message: "This will Affect Products that belong to this Crate Type",
        callback: (isConfrimed) => {
          if (isConfrimed) {
            store.dispatch("crate/deleteCrateType", id).then((res) => {
              store.commit("notify", {
                title: "",
                message: "Crate Type Successfully Deleted",
                type: "success",
              });
            });
          }
        },
      });
    },
    getCrateTypes(force = false) {
      store.dispatch("crate/getCrateTypes", force);
    },
    updateCrateType({ id, crateType }) {
      store
        .dispatch("crate/updateCrateType", { id: id, payload: crateType })
        .then((res) => {
          store.commit("notify", {
            title: "",
            message: "Crate Type Successfully Updated",
            type: "success",
          });
        });
    },
    addCrateType(newCrateType) {
      store.dispatch("crate/addCrateType", newCrateType);
    },
  },
  mounted() {
    this.getCrateTypes();
  },
  name: "categorylist",
};
</script>
<style>
.ant-tooltip {
  display: none !important;
}
</style>
