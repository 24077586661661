<template>
  <div :class="{ 'loader': true, 'min': isMinimized }" v-if="loading" @click="toggleLoader">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="d-block pb-2" v-if="!isMinimized"><i class="msg">Click Anywhere to Hide</i></div>
      <div class="spinner">
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

// store
const store = useStore();
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const isMinimized = computed(() => {
  return store.state.loaderMinimized;
})

function toggleLoader() {
  store.state.loaderMinimized = !store.state.loaderMinimized;
}
</script>
<style>
.msg {
  color: var(--PRIMARY-COLOR);
}

.loader {
  background-color: rgba(0, 0, 0, 0.2);
  /* Semi-transparent white */
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it appears above other content */
}

.loader.min {
  border-radius: 10px 0 0 10px;
  height: 50px;
  width: 100px;
  top: calc(100% - 100px);
  left: calc(100% - 100px);
}

.loader.min .spinner {
  width: 30px;
  height: 30px;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: var(--PRIMARY-COLOR);
  /* Color of the spinner */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.toggle {
  cursor: pointer;
  border-radius: 10px;
  color: var(--PRIMARY-COLOR);
}

.toggle span {
  background: #fff;
  padding: 0 7px;
  font-weight: bold;
  border-radius: 50%;
}
</style>
