const basicAreaChart = {
  sline: {
    chart: {
      id: "vuechart-example",
    },

    chart: {
      type: "bar",
      height: 300,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },

    colors: ["#28C76F", "#EA5455"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "",
            offsetY: 40,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    xaxis: {
      categories: [
        "Jan ",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
    },
  },
  series: [
    {
      name: "Sales",
      data: [50, 45, 60, 70, 50, 45, 60, 70, 80],
    },
    {
      name: "Purchase",
      data: [50, -54, -45, -35, -21, -54, -45, -35],
    },
  ],
};

const warehouseChart = (
  formatter = (index) => "",
  data = [],
  productNames = []
) => ({
  /** The Data Values */
  series: [{ name: "Available Quantity", data }],
  options: {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        columnWidth: "55%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: (val, opt) => {
        return "";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Total Stock Summary",
    },
    xaxis: { categories: productNames },
    yaxis: {
      labels: {
        show: true,
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const quantity = formatter(dataPointIndex);
        return `<div class="apexcharts-tooltip-custom p-2">
                  <span><b style="font-weight:bold">${productNames[dataPointIndex]}</b>: ${quantity}</span>
                </div>`;
      },
    },
  },
});

const salesChart = {
  /** The Data Values */
  series: [{ name: "Available Crates", data: [] }],
  options: {
    chart: {
      width: 380,
      type: "bar",
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        columnWidth: "55%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Total Stock Summary",
    },
    xaxis: { categories: [] },
    yaxis: {
      labels: {
        show: false,
      },
    },
  },
};
export { basicAreaChart, warehouseChart, salesChart };
