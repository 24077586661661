import { api } from "@/config";

export const utils = {
  namespaced: true,
  actions: {
    getStates({ context }) {
      return api.get("/states");
    },
    checkStoreName({ context }, storeName) {
      return api.post("/store_name", { store_name: storeName });
    },
    checkEmailName({ context }, emailName) {
      return api.post("/email", { email_name: emailName });
    },
    registerVendor({ context }, payload) {
      return api.post("/register", payload);
    },
  },
};
