<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <div class="row" v-if="product">
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Product Name</label>
                  <input
                    required
                    class="form-control"
                    v-model="product.name"
                    type="text"
                    name="name"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Category</label>
                  <vue-select
                    required
                    aria-required="true"
                    v-model="product.category_id"
                    :options="Category"
                    placeholder="Choose Category"
                    name="category"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Sub Category</label>
                  <vue-select
                    :options="SubCategory"
                    v-model="product.sub_category_id"
                    placeholder="Choose Sub Category"
                    name="sub_category"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Brand</label>
                  <vue-select
                    v-model="product.brand_id"
                    :options="Brand"
                    placeholder="Choose Brand"
                    name="brand"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Unit</label>
                  <vue-select
                    :options="Units"
                    v-model="product.unit"
                    placeholder="Choose Unit"
                    name="unit"
                    required
                  />
                </div>
              </div>
              <!-- qty -->
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('C', 'BC')">
                <div class="form-group">
                  <label>Available Crates</label>
                  <input
                    required
                    class="form-control"
                    v-model="product.available_crates"
                    type="number"
                    min="0"
                    name="available_crates"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('B', 'BC')">
                <div class="form-group">
                  <label>Available Bottles</label>
                  <b-form-input
                    required
                    :model-value="
                      product.available_bottles + product.excess_bottles
                    "
                    type="number"
                    min="0"
                    readonly
                    name="available_bottles"
                    v-if="unitIs('BC')"
                  />
                  <b-form-input
                    required
                    v-model="product.available_bottles"
                    type="number"
                    min="0"
                    name="available_bottles"
                    v-else
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('BC')">
                <div class="form-group">
                  <label>Excess Bottle</label>
                  <input
                    required
                    class="form-control"
                    v-model="product.excess_bottles"
                    type="number"
                    min="0"
                    name="excess_bottles"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('BC')">
                <div class="form-group">
                  <label>Bottles Per Crate</label>
                  <b-form-input
                    required
                    v-model="product.qty_per_crate"
                    type="number"
                    min="0"
                    name="qty_per_crate"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Expiry Date</label>
                  <b-form-input
                    required
                    v-model="product.expiry_date"
                    type="date"
                    name="expiry_date"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Min Qty Threshold</label>
                  <b-form-input
                    required
                    v-model="product.min_qty_threshold"
                    type="number"
                    min="0"
                    name="min_qty_threshold"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Max Qty Threshold</label>
                  <b-form-input
                    required
                    v-model="product.max_qty_threshold"
                    type="number"
                    min="0"
                    name="max_qty_threshold"
                  />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <label>Description</label>
                  <b-form-textarea
                    required
                    id="textarea"
                    rows="3"
                    max-rows="6"
                    v-model="product.description"
                    name="description"
                  ></b-form-textarea>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('C', 'BC')">
                <div class="form-group">
                  <label>Crate Price</label>
                  <b-form-input
                    step="any"
                    required
                    v-model="product.crate_price"
                    name="crate_price"
                    type="number"
                    min="0"
                    placeholder="Crate Price"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-6 col-12" v-if="unitIs('B', 'BC')">
                <div class="form-group">
                  <label>Bottle Price</label>
                  <b-form-input
                    step="any"
                    required
                    v-model="product.bottle_price"
                    name="bottle_price"
                    type="number"
                    min="0"
                    placeholder="Bottle Price"
                  />
                </div>
              </div>

              <div class="col-lg-3 col-sm-6 col-12">
                <div class="form-group">
                  <label>Wob Price</label>
                  <input-switch
                    name="wob_price"
                    label="WOB Price"
                    v-model:value="product.wob_price"
                    v-model:checked="product.has_crate"
                    :required="product.has_crate"
                    :disabled="!product.has_crate"
                  >
                  </input-switch>
                </div>
              </div>
              <!-- EXTRA START -->
              <a-tabs
                v-model:activeKey="activeKey"
                tab-position="top"
                animated
                class="mb-5"
                id="priceVariant"
                style="min-height: 150px;"
              >
                <template #rightExtra>
                  <a-dropdown-button @click="handleButtonClick">
                    <div>
                      <a
                        class="dropdown-toggle"
                        href="javascript:void(0);"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        More Options
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item">
                          <a-checkbox
                            v-model:checked="product.extras.has_user_prices"
                            value="Crate Price"
                            >User Price</a-checkbox
                          >
                        </a>
                        <a class="dropdown-item">
                          <a-checkbox
                            v-model:checked="product.extras.has_price_variants"
                            value="Price Variation"
                            >Price Variation</a-checkbox
                          >
                        </a>
                      </div>
                    </div>
                  </a-dropdown-button>
                </template>
                <a-tab-pane
                  key="cratePrices"
                  tab="Crate Prices"
                  v-if="product.extras.has_user_prices"
                >
                  <div class="col-12 mb-3">
                    <b-table
                      :items="product?.user_prices"
                      :fields="userPriceFields"
                      bordered
                      striped
                    >
                      <template #cell(account_type)="data">
                        <vue-select
                          :required="!data.item.isSample"
                          v-model="data.item.account_type_id"
                          placeholder="Select Account Type"
                          class="w-100"
                          :options="unsetAccountTypes"
                          v-if="data.item.isSample"
                        />
                        <span v-else> {{ data.item.account_type.name }}</span>
                      </template>
                      <template #cell(required_qty)="data">
                        <b-form-input
                          :required="!data.item.isSample"
                          v-model="data.item.required_qty"
                          type="number"
                          min="1"
                          :name="`${data.index}_qty`"
                          class="form-control"
                        />
                      </template>
                      <template #cell(price)="data">
                        <b-form-input
                          step="any"
                          :required="!data.item.isSample"
                          v-model="data.item.price"
                          type="number"
                          min="1"
                          :name="`${data.index}_price`"
                          class="form-control"
                        />
                      </template>
                      <template #cell(action)="data">
                        <a
                          v-if="data.item.isSample"
                          class="confirm-text"
                          href="javascript:void(0);"
                          @click="showAlert"
                        >
                          <button class="btn btn-primary" @click="addUserPrice">
                            Add
                          </button>
                        </a>
                        <a
                          v-else
                          class="confirm-text"
                          href="javascript:void(0);"
                          @click="removeUserPrice(data.index)"
                        >
                          <img
                            src="../../../assets/img/icons/delete.svg"
                            alt="img"
                          />
                        </a>
                      </template>
                    </b-table>
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  key="priceVariant"
                  tab="Price Variation"
                  v-if="product.extras.has_price_variants"
                >
                  <div class="row">
                    <div
                      class="col-lg-4 col-md-4 col-12 pe-md-4 pe-lg-4"
                      :style="{ borderRight: '1px solid rgba(0,0,0,0.1)' }"
                    >
                      <a-typography-text strong class="my-4 b-block">
                        Add Price Variation
                      </a-typography-text>
                      <div class="row my-3">
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Name</label>
                            <input
                              v-model="priceVariant.name"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Price</label>
                            <input
                              v-model="priceVariant.price"
                              step="any"
                              class="form-control form-control-sm"
                              type="number"
                              min="0"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Unit</label>
                            <a-select
                              v-model:value="priceVariant.unit"
                              class="w-100"
                              :options="variantUnits"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="form-group">
                            <label>Quantity</label>
                            <input
                              v-model="priceVariant.qty"
                              step="any"
                              class="form-control form-control-sm"
                              type="number"
                              min="0"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                          <button
                            class="btn btn-sm btn-primary"
                            @click="addpriceVariant()"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-12 overflow-hidden">
                      <div
                        class="row gap-2 overflow-scroll overflow-x-hidden p-3"
                        style="max-height: 260px;"
                      >
                        <Badge
                          outlined
                          v-for="(price, index) in product.price_variants"
                          @clicked="removepriceVariant(index)"
                          right-icon="typcn typcn-delete"
                          title="Click To Delete"
                        >
                          {{
                            `${price.name}(${formatAmount(price.price, true)})`
                          }}<br />
                          {{ `${price.qty} ${price.unit}(s)` }}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
              <!-- EXTRA END -->

              <div class="col-lg-12">
                <div class="form-group">
                  <label> Product Image</label>
                  <div class="image-upload">
                    <input
                      name="image"
                      accept="image/*"
                      @input="pickFile($event.target)"
                      type="file"
                      multiple
                    />
                    <div class="image-uploads">
                      <img
                        src="../../../assets/img/icons/upload.svg"
                        alt="img"
                      />
                      <h4>Drag and drop a file to upload</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product-list">
                  <ul class="row">
                    <li v-for="(image, index) in images" :key="index">
                      <div class="productviews">
                        <div class="productviewsimg">
                          <img :src="image.src" alt="img" />
                        </div>
                        <div class="productviewscontent">
                          <div class="productviewsname">
                            <h2>{{ image.name }}</h2>
                            <h3>{{ image.size }}KB</h3>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="hideset"
                            v-on:click="
                              deleteImage(index, image.id, image.is_uploaded)
                            "
                            >x</a
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12">
                <button
                  disabled
                  id="submit"
                  v-on:click="updateProduct"
                  href="javascript:void(0);"
                  class="btn btn-submit me-2"
                >
                  Submit
                </button>
                <router-link to="/products" class="btn btn-cancel"
                  >Cancel</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script setup>
import Badge from "@/components/Badge.vue";
import { formatAmount } from "@/helpers";
import { checkInputValidity } from "@/helpers/validator";
import { Product } from "@/models/products";
import { computed, ref, watch, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// hooks
const store = useStore();
const route = useRoute();
const router = useRouter();
// data
const units = {
  onlyBottle: "Bottle",
  onlyCrate: "Crate",
  bottleAndCrate: "Bottle & Crate",
};
const Units = ref(Object.values(units));
const variantUnits = computed(() => {
  if (unitIs("BC")) {
    return [{ value: "Bottle" }, { value: "Crate" }];
  } else {
    return [{ value: product.unit }];
  }
});

const title = ref("Product Update");
const title1 = ref("Update product");
const Category = ref([{ text: "Choose Category", id: 0, selected: true }]);
const SubCategory = ref([
  { text: "Choose Sub Category", id: 0, selected: true },
]);
const Brand = ref([{ text: "Choose Brand", id: 0, selected: true }]);
const accountTypes = ref([]);
const unsetAccountTypes = computed(() => {
  return accountTypes.value.filter(
    (account_type) =>
      !product.user_prices.some(
        (price) => !price.isSample && price.account_type_id == account_type.id
      )
  );
});

const product = reactive(new Product());
const productId = ref(null);
const images = ref([]);
const priceVariant = ref({
  name: null,
  price: null,
  unit: null,
  qty: null,
});

const userPriceFields = ref([
  { key: "account_type", label: "User" },
  { key: "required_qty", label: "Quantity" },
  { key: "price", label: "Price" },
  { key: "action", label: "Action" },
]);

// methods
/**
 * @typedef {'C' | 'B' | 'BC'} Unit
 */
/**
 * Checks if a product's unit matches any of the specified units.
 *
 * @param {...Unit} _units - A list of unit identifiers to check against the product's unit.
 *                           Valid identifiers are:
 *                           - 'C' for onlyCrate
 *                           - 'B' for onlyBottle
 *                           - 'BC' for bottleAndCrate
 * @returns {boolean} - Returns true if the product's unit matches any of the specified units, otherwise false.
 */
const unitIs = (..._units) => {
  const unitsMapping = {
    C: units.onlyCrate,
    B: units.onlyBottle,
    BC: units.bottleAndCrate,
  };
  return _units.some((unit) => unitsMapping[unit] == product.unit);
};
const addpriceVariant = () => {
  product.price_variants.push(priceVariant.value);
  priceVariant.value = {};
};

const removepriceVariant = (index) => {
  product.price_variants.splice(index, 1);
};

function getCategories() {
  store.dispatch("category/get").then((response) => {
    response.data.data.map((category) => {
      Category.value.push({
        text: category.name,
        id: category.id,
      });
    });
  });
}
function getBrands() {
  store.dispatch("brand/get").then((response) => {
    response.data.data.map((brand) => {
      Brand.value.push({
        text: brand.name,
        id: brand.id,
      });
    });
  });
}
function getSubCategories(categoryId) {
  store
    .dispatch("subcategory/get", { category_id: categoryId })
    .then((response) => {
      SubCategory.value = response.data.data.map((subcategory) => {
        return {
          text: subcategory.name,
          id: subcategory.id,
        };
      });
      setTimeout(() => {
        checkValidity();
      }, 100);
    });
}
function deleteImage(index, imageId) {
  if (images.value.length > 1) {
    store
      .dispatch("productImage/delete", { imageId: imageId })
      .then((response) => {
        images.value.splice(index, 1);
        product.images.splice(index, 1);
      });
  } else {
    Swal.fire({
      title: "A Product Must Have atleast One Image",
      icon: "error",
    });
  }
}
function pickFile(target) {
  let files = target.files;
  store
    .dispatch("productImage/add", {
      product_id: productId.value,
      images: files,
    })
    .then((response) => {
      getProductImages();
    });
}
function getProductImages() {
  store
    .dispatch("productImage/get", { product_id: productId.value })
    .then((response) => {
      images.value = response.data.data.map((image) => {
        return {
          src: image.url,
          name: "",
          size: "",
          id: image.id,
        };
      });
    });
}
function getProductDetail() {
  productId.value = route.params.id;
  store
    .dispatch("product/get", { product_id: productId.value })
    .then((response) => {
      let productDetails = response.data.data;
      product.fillProductDetails(productDetails);
      checkValidity();
      // load images
      getProductImages();
    });
}

function checkValidity() {
  checkInputValidity("submit");
}

function updateProduct() {
  store
    .dispatch("product/update", {
      product_id: productId.value,
      payload: product.prepData(),
    })
    .then((response) => {
      Swal.fire("Product Updated", "");
      Object.assign(product, new Product());
      router.push({ name: "productlist" });
    });
}

function fetchAccountTypes() {
  const response = store.dispatch("accountType/get").then((response) => {
    accountTypes.value = response.data.data.map((account_type) => {
      return {
        text: account_type.name,
        id: account_type.id,
      };
    });
  });
}

const addUserPrice = () => {
  const data = product.user_prices[0];
  const selectedAccountType = accountTypes.value.find(
    (accountType) => accountType.id == data.account_type_id
  );
  console.log(selectedAccountType, data);
  product.user_prices.push({
    account_type: { name: selectedAccountType.text },
    account_type_id: data.account_type_id,
    price: data.price,
    required_qty: data.required_qty,
  });
  // reset
  data.price = 1;
  data.required_qty = 1;
  data.account_type_id = null;
};
const removeUserPrice = (index) => {
  product.user_prices.splice(index, 1);
};
// watch
watch(
  () => product,
  (newProduct) => {
    if (newProduct && Object.values(newProduct).length > 0) {
      if (unitIs("BC")) {
        newProduct.available_bottles =
          newProduct.qty_per_crate * newProduct.available_crates;
      }
    }
    setTimeout(() => {
      checkValidity();
    }, 2);
  },
  { deep: true }
);

watch(
  () => product.category_id,
  (newCategoryId) => {
    getSubCategories(newCategoryId);
  },
  { deep: true }
);

// lifecycle hooks
onMounted(() => {
  getCategories();
  getBrands();
  getProductDetail();
  fetchAccountTypes();
});
</script>
