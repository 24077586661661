import { api } from "@/config";

export const receipts = {
  url(_path = "") {
    let path = _path != "" ? `/${_path}` : "";
    return `/receipts${path}`;
  },
  namespaced: true,
  actions: {
    get({ context }) {
      return api.get(receipts.url());
    },
    show({ context }, { receipt_id }) {
      return api.get(receipts.url(receipt_id));
    },
    update({ context }, { receipt_id, payload }) {
      return api.patch(receipts.url(receipt_id), payload);
    },
  },
};
