<template>
  <!-- add account type Modal -->
  <div class="modal fade" id="cratetypemodal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Crate Type {{ isUpdate }}</h5>
          <b-button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Name</label>
                <input v-model="currentCrateType.name" class="form-control" type="text" name="name" required
                  v-bind="readOnly ? { 'readonly': true } : null" />
                <span class="text-danger" v-if="errors.name">
                  {{ errors.name }}
                </span>
              </div>
            </div>


            <div class="col-12">
              <div class="form-group">
                <label>Crate Price</label>
                <input v-model="currentCrateType.crate_price" class="form-control" type="number" name="crate_price"
                  required v-bind="readOnly ? { 'readonly': true } : null" />
                <span class="text-danger" v-if="errors.crate_price">
                  {{ errors.crate_price }}
                </span>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>WOB Price</label>
                <input v-model="currentCrateType.wob_price" class="form-control" type="number" name="wob_price" required
                  v-bind="readOnly ? { 'readonly': true } : null" />
                <span class="text-danger" v-if="errors.wob_price">
                  {{ errors.wob_price }}
                </span>
              </div>

            </div>

            <div class="col-12">
              <div class="form-group">
                <label>Bottle Per Crate</label>
                <input v-model="currentCrateType.bottle_per_crate" class="form-control" type="number"
                  name="bottle_per_crate" required v-bind="readOnly ? { 'readonly': true } : null" />
                <span class="text-danger" v-if="errors.bottle_per_crate">
                  {{ errors.bottle_per_crate }}
                </span>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer" v-if="!readOnly">
          <button id="saveCrateType" type="button" class="btn btn-submit" @click="saveCrateType">
            Confirm
          </button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  props: {
    crateType: Array,
    readOnly: Boolean
  },
  data() {
    return {
      errors: {},
      isUpdate: false,
      currentCrateType: { ...this.$props.crateType } || {
        name: null,
        crate_price: null,
        wob_price: null,
        bottle_per_crate: null,
      },
    };
  },
  methods: {
    validate() {
      if (!this.readOnly) {
        store
          .dispatch("checkInputValidity", { callbackElementId: "saveCrateType" })
          .then((res) => {
            this.errors = res;
          });
      }
    },
    saveCrateType() {
      if (this.isUpdate) {
        this.updateCrateType();
      } else {
        this.addCrateType();
      }
      this.isUpdate = false;
      $("#cratetypemodal").modal("hide");
    },
    updateCrateType() {
      this.$emit("updateCrateType", { id: this.crateType.id, crateType: this.currentCrateType });
    },
    addCrateType() {
      this.$emit("addCrateType", this.currentCrateType);
      this.currentCrateType = {
        name: null,
        crate_price: null,
        wob_price: null,
        bottle_per_crate: null
      };
    },
  },
  watch: {
    crateType: {
      handler(_crateType) {
        if (_crateType) {
          this.isUpdate = true;
          this.currentCrateType = _crateType;
          setTimeout(() => {
            this.validate();
          }, 1);
        }
      },
      deep: true,
    },
    currentCrateType: {
      handler(_new) {
        this.validate();
      },
      deep: true,
    },
  },
};
</script>
