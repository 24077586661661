<template>
    <div @click="clicked()" :title="title"
        :class="`data ${!$slots.default && 'd-flex justify-content-between align-items-center'} gap-2 px-1 ${outlined && 'outline'}`"
        :style="style">
        <em v-if="leftIcon" :class="`${leftIcon} icon`"></em>
        <template v-if="$slots.default">
            <slot></slot>
        </template>
        <span v-else>{{ text }}</span>
        <em v-if="rightIcon" :class="`${rightIcon} icon`"></em>
    </div>
</template>
<script setup>
const props = defineProps({
    text: String,
    leftIcon: String,
    rightIcon: String,
    outlined: Boolean,
    title: String,
    style: null
});
const emit = defineEmits({
    "clicked": () => { }
});

function clicked() {
    emit("clicked");
}
</script>
<style scoped>
.data {
    --text-color: #fff;
    --bg-color: var(--PRIMARY-COLOR);
    cursor: pointer;
    width: max-content;
    font-size: 15px;
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 3px;
}

.data.outline {
    --text-color: var(--PRIMARY-COLOR);
    --bg-color: #fff;
    border: 1px solid var(--text-color);
}

.data .icon {
    margin-left: 2px;
}

.custom-control-input {
    accent-color: var(--COLOR-ACCENT);
}
</style>
