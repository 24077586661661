<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :items="items" />

        <f-table :columns="columns" :data="data" :filters="['name']">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <router-link v-if="!isSuperAdmin" class="me-3" :to="'admin-role/' + record.id">
                <img src="../../../assets/img/icons/edit.svg" alt="img" />
              </router-link>
              <a v-if="!isSuperAdmin" class="me-3 confirm-text" href="javascript:void(0);"
                @click="deleteRole(record.id)">
                <img src="../../../assets/img/icons/delete.svg" alt="img" />
              </a>
            </template>
          </template>
        </f-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapGetters } from "vuex"
const currentDate = ref(new Date());
const columns = [
  {
    sorter: true,
  },
  {
    title: "Role Name",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name;
        b = b.name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at;
        b = b.created_at;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import { mapActions } from "vuex";

export default {
  data() {
    return {
      filter: false,
      title: "Admin Role List",
      title1: "Manage Admin Roles",
      items: [
        {
          text: "Add Role",
          addname: "/newrole",
        },
      ],
      data: [],
      columns,
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: "isSuperAdmin",
    }),
  },
  methods: {
    ...mapActions({
      _getRoles: "adminRole/get",
      _deleteRoles: "adminRole/delete",
    }),
    deleteRole(id) {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteRoles({ id: id }).then((response) => {
            Swal.fire("Deleted!", "Role Deleted");
            this.getRoles();
          });
        }
      });
    },
    getRoles() {
      this._getRoles().then((response) => {
        this.data = response.data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
            visibility: role.visibility,
            created_at: role.created_at,
          };
        });
      });
    },
  },
  mounted() {

    if (this.isSuperAdmin) {
      this.items[0].text = "Add A Public Role";
    }
    this.getRoles();
  },
  name: "adminrolelist",
};
</script>
